import { Voucher } from 'services/voucher/types';
import { VoucherAction, VoucherActionConstants } from './actions';

export type VoucherState = {
  isLoading: boolean;
  voucherList: Array<Voucher>;
};

export const initialVoucherState: VoucherState = {
  isLoading: false,
  voucherList: [],
};

export function voucherReducer(
  state: VoucherState,
  action: VoucherAction,
): VoucherState {
  const { type } = action;

  switch (type) {
    case VoucherActionConstants.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading };
    case VoucherActionConstants.SET_VOUCHER_LIST:
      return { ...state, voucherList: action.payload.voucherList };
    default:
      return state;
  }
}
