import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Grid, Header, Icon, Loader, Image } from 'semantic-ui-react';
import _ from 'lodash';
import * as echarts from 'echarts';
import { getLatestIndicator } from 'services/dashboard';
import { IndicatorType } from 'services/dashboard/types';
import moment from 'moment';
import { getMonthsArray } from 'utils/function/getMonthsArray';

import silverLogo from 'assets/images/icon/silver-plan.svg';
import goldLogo from 'assets/images/icon/gold-plan.svg';

const Dashboard: React.FC = () => {
  const [indicators, setIndicators] = useState<IndicatorType>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [animationCompleted, setAnimationCompleted] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [animationId, setAnimationId] = useState<number>();
  const [isResizing, setIsResizing] = useState<boolean>();

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    getLatestIndicator(setIndicators, setIsLoading);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (animationCompleted) generateCharts();
  }, [animationCompleted]);

  useLayoutEffect(() => {
    if (isLoading) {
      if (animationCompleted) setAnimationCompleted(false);
      setTimeoutId(() => {
        return setTimeout(() => {
          const objs = document.getElementsByClassName('animatedNumber');
          animateNumbers(objs, 2000);
        }, 1000);
      });
    }
  }, [isLoading]);

  useLayoutEffect(() => {
    if (isResizing) {
      setAnimationCompleted(false);
      const objs = document.getElementsByClassName('animatedNumber');
      animateNumbers(objs);
    } else if (isResizing === false) {
      setAnimationCompleted(true);
      if (animationId) window.cancelAnimationFrame(animationId);
    }
  }, [isResizing]);

  const animateNumbers = (
    objs: HTMLCollectionOf<Element>,
    duration?: number,
  ) => {
    let startTimestamp;
    const step = timestamp => {
      if (!startTimestamp) startTimestamp = timestamp;
      if (duration) {
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        for (const obj of objs) {
          obj.innerHTML = `
        ${Math.floor((progress * 100) % 10)}${Math.floor(
            (progress * 100 + 1) % 10,
          )}`;
        }
        if (progress < 1) {
          setAnimationId(window.requestAnimationFrame(step));
        } else if (progress === 1) {
          setAnimationCompleted(true);
        }
      } else {
        const progress = (timestamp - startTimestamp) / 2000;
        for (const obj of objs) {
          obj.innerHTML = `
        ${Math.floor((progress * 100) % 10)}${Math.floor(
            (progress * 100 + 1) % 10,
          )}`;
        }
        setAnimationId(window.requestAnimationFrame(step));
      }
    };
    setAnimationId(window.requestAnimationFrame(step));
  };

  const handleResize = () => {
    if (timeoutId) clearTimeout(timeoutId);
    if (!isResizing) setIsResizing(true);
    setTimeoutId(() => {
      return setTimeout(() => {
        setIsResizing(false);
      }, 2000);
    });
  };

  const generateCharts = () => {
    const curMonth = moment(new Date()).month();
    const curYear = moment(new Date()).year();

    const reqByCompanyTypeOption = {
      title: {
        text: 'Richieste per tipo di azienda',
        left: 'center',
        top: '10px',
        textStyle: {
          fontFamily: 'Avenir Next',
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)',
      },
      toolbox: {
        show: true,
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      series: [
        {
          name: 'Richieste per tipo di azienda',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '50%'],
          //roseType: 'radius',
          height: '100%',
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 3,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
            },
          },
          data: [
            {
              value: indicators?.reqLimitedCompany,
              name: 'Società di capitali',
            },
            {
              value: indicators?.reqPartnership,
              name: 'Società di persone',
            },
            {
              value: indicators?.reqProfessionalFirm,
              name: 'Studio professionale',
            },
            {
              value: indicators?.reqFreelancer,
              name: 'Libero professionista',
            },
          ],
          //animationEasingUpdate: 'linear',
        },
      ],
      legend: {
        align: 'left',
        bottom: '10px',
        fontFamily: 'Avenir Next',
      },
      backgroundColor: '#fff',
      media: [
        {
          query: {
            maxWidth: 1400,
          },
          option: {
            width: '100%',
          },
        },
      ],
    };

    // const reqByDocOption = {
    //   title: {
    //     text: 'Richieste per tipo di documento generato',
    //     left: 'center',
    //     top: '10px',
    //     textStyle: {
    //       fontFamily: 'Avenir Next',
    //     },
    //   },
    //   tooltip: {
    //     trigger: 'item',
    //     formatter: '{b} : {c} ({d}%)',
    //   },
    //   toolbox: {
    //     show: true,
    //     feature: {
    //       mark: { show: true },
    //       dataView: { show: true, readOnly: false },
    //       restore: { show: true },
    //       saveAsImage: { show: true },
    //     },
    //   },
    //   series: [
    //     {
    //       name: 'Richieste per tipo di documento generato',
    //       type: 'pie',
    //       radius: ['40%', '70%'],
    //       center: ['50%', '50%'],
    //       height: '100%',
    //       itemStyle: {
    //         borderRadius: 5,
    //         borderColor: '#fff',
    //         borderWidth: 3,
    //       },
    //       label: {
    //         show: false,
    //         position: 'center',
    //       },
    //       emphasis: {
    //         label: {
    //           show: true,
    //         },
    //       },
    //       data: [
    //         {
    //           value: indicators?.reqRiassicurazione,
    //           name: 'Riassicurazione',
    //         },
    //         {
    //           value: indicators?.reqGaranziaDiretta,
    //           name: 'Garanzia Diretta',
    //         },
    //       ],
    //     },
    //   ],
    //   legend: {
    //     align: 'left',
    //     bottom: '10px',
    //     fontFamily: 'Avenir Next',
    //   },
    //   backgroundColor: '#fff',
    //   media: [
    //     {
    //       query: {
    //         maxWidth: 1400,
    //       },
    //       option: {
    //         width: '100%',
    //       },
    //     },
    //   ],
    // };

    const requestsTrend = {
      title: {
        text: 'Andamento delle richieste nel tempo',
        left: 'center',
        top: '10px',
        textStyle: {
          fontFamily: 'Avenir Next',
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c}',
      },
      xAxis: {
        type: 'category',
        data: _.reverse(getMonthsArray(curMonth, curYear)),
        axisLabel: {
          rotate: 45,
        },
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: indicators?.reqMonthlyTrend
            ? _.reverse(indicators?.reqMonthlyTrend)
            : [],
          type: 'line',
          lineStyle: {
            color: '#396288',
          },
          itemStyle: {
            color: '#396288',
          },
        },
      ],
      backgroundColor: '#fff',
    };

    const registrationsTrend = {
      title: {
        text: 'Andamento delle registrazioni nel tempo',
        left: 'center',
        top: '10px',
        textStyle: {
          fontFamily: 'Avenir Next',
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c}',
      },
      xAxis: {
        type: 'category',
        data: _.reverse(getMonthsArray(curMonth, curYear)),
        axisLabel: {
          rotate: 45,
        },
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: indicators?.regMonthlyTrend
            ? _.reverse(indicators?.regMonthlyTrend)
            : [],
          type: 'line',
          lineStyle: {
            color: '#FFC107',
          },
          itemStyle: {
            color: '#FFC107',
          },
        },
      ],
      backgroundColor: '#fff',
    };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const reqByCompanyTypeDom: HTMLElement =
      document.getElementById('reqByCompanyType')!;
    const reqByCompanyTypeChart = echarts.init(reqByCompanyTypeDom);
    if (!_.isEmpty(reqByCompanyTypeChart) && reqByCompanyTypeOption)
      reqByCompanyTypeChart
        ? reqByCompanyTypeChart.setOption(reqByCompanyTypeOption)
        : false;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const reqByDocDom: HTMLElement = document.getElementById('reqByDoc')!;
    // const reqByDocChart = echarts.init(reqByDocDom);
    // if (!_.isEmpty(reqByDocDom) && reqByDocOption)
    //   reqByDocChart ? reqByDocChart.setOption(reqByDocOption) : false;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const reqMonthlyTrendDom: HTMLElement =
      document.getElementById('reqMonthlyTrend')!;
    const reqMonthlyTrendChart = echarts.init(reqMonthlyTrendDom);
    if (!_.isEmpty(reqMonthlyTrendChart) && requestsTrend)
      reqMonthlyTrendChart
        ? reqMonthlyTrendChart.setOption(requestsTrend)
        : false;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const regMonthlyTrendDom: HTMLElement =
      document.getElementById('regMonthlyTrend')!;
    const regMonthlyTrendChart = echarts.init(regMonthlyTrendDom);
    if (!_.isEmpty(regMonthlyTrendChart) && registrationsTrend)
      regMonthlyTrendChart
        ? regMonthlyTrendChart.setOption(registrationsTrend)
        : false;
  };

  return (
    <>
      <div className="dashboard-container">
        <Helmet>
          <body className="customer customer-list" />
          <title>Allegato4.it - Dashboard</title>
          <script>{"smartsupp('chat:hide')"}</script>
          <meta name="description" content="indicators' dashboard" />
        </Helmet>
        <div className="dashboard__content">
          <Header className="dashboardHeader" dividing>
            <Grid>
              <Grid.Column>
                <Icon name="chart bar" />
                <h1>
                  <FormattedMessage
                    id="dashboard.title"
                    defaultMessage="Metriche di utilizzo"
                  />
                </h1>
              </Grid.Column>
              <Grid.Column>
                {/* <CustomerFilterForm onSubmit={onSubmit} /> */}
              </Grid.Column>
            </Grid>
          </Header>
          <div className="dashboardBody">
            <Grid.Row>
              <Grid.Column className="statsGrid">
                {Array(4)
                  .fill(1)
                  .map((x, i) => (
                    <div key={i} className="stat">
                      {i === 0 && (
                        <div>
                          <Icon name="clipboard" />
                          <span className="statName">
                            <FormattedMessage
                              id="dashboard.requests"
                              defaultMessage="Richieste totali"
                            />
                          </span>
                          {(!animationCompleted || isLoading) && (
                            <h1 className="animatedNumber">0</h1>
                          )}
                          {animationCompleted && !isLoading && (
                            <h1>{indicators?.requestsGrandTotal}</h1>
                          )}
                        </div>
                      )}
                      {i === 1 && (
                        <div>
                          <Icon name="clipboard list" />
                          <span className="statName">
                            <FormattedMessage
                              id="dashboard.ongoingRequests"
                              defaultMessage="Richieste in corso"
                            />
                          </span>
                          {(!animationCompleted || isLoading) && (
                            <h1 className="animatedNumber">0</h1>
                          )}
                          {animationCompleted && !isLoading && (
                            <h1>{indicators?.ongoingRequestsTotal}</h1>
                          )}
                        </div>
                      )}
                      {i === 2 && (
                        <div>
                          <Icon name="clipboard check" />
                          <span className="statName">
                            <FormattedMessage
                              id="dashboard.completedRequests"
                              defaultMessage="Richieste completate"
                            />
                          </span>
                          {(!animationCompleted || isLoading) && (
                            <h1 className="animatedNumber">0</h1>
                          )}
                          {animationCompleted && !isLoading && (
                            <h1>{indicators?.completedRequestsTotal}</h1>
                          )}
                        </div>
                      )}
                      {i === 3 && (
                        <div>
                          <Icon name="file archive" />
                          <span className="statName">
                            <FormattedMessage
                              id="dashboard.expiredRequests"
                              defaultMessage="Richieste non concluse"
                            />
                          </span>
                          {(!animationCompleted || isLoading) && (
                            <h1 className="animatedNumber">0</h1>
                          )}
                          {animationCompleted && !isLoading && (
                            <h1>{indicators?.expiredRequestsTotal}</h1>
                          )}
                        </div>
                      )}
                      <span className="untilToday">
                        <FormattedMessage
                          id="dashboard.untilToday"
                          defaultMessage="Fino ad oggi"
                        />
                      </span>
                    </div>
                  ))}
              </Grid.Column>
              <Grid.Column className="chart">
                {(!animationCompleted || isLoading) && (
                  <div id="reqMonthlyTrend">
                    <Loader active size="huge" />
                  </div>
                )}
                {animationCompleted &&
                  !isLoading &&
                  !_.isEmpty(indicators?.reqMonthlyTrend) && (
                    <div id="reqMonthlyTrend"></div>
                  )}
                {animationCompleted &&
                  !isLoading &&
                  _.isEmpty(indicators?.reqMonthlyTrend) && (
                    <h4 id="reqMonthlyTrend">
                      Andamento delle richieste non disponibile
                    </h4>
                  )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className="chart">
                {(!animationCompleted || isLoading) && (
                  <div id="regMonthlyTrend">
                    <Loader active size="huge" />
                  </div>
                )}
                {animationCompleted &&
                  !isLoading &&
                  !_.isEmpty(indicators?.regMonthlyTrend) && (
                    <div id="regMonthlyTrend"></div>
                  )}
                {animationCompleted &&
                  !isLoading &&
                  _.isEmpty(indicators?.regMonthlyTrend) && (
                    <h4 id="regMonthlyTrend">Dati non disponibili</h4>
                  )}
              </Grid.Column>
              <Grid.Column className="statsGrid">
                {Array(4)
                  .fill(1)
                  .map((x, i) => (
                    <div key={i} className="stat">
                      {i === 0 && (
                        <div>
                          <Image src={goldLogo}></Image>
                          <span className="statName">
                            <FormattedMessage
                              id="dashboard.goldenReqs"
                              defaultMessage="Richieste Gold"
                            />
                          </span>
                          {(!animationCompleted || isLoading) && (
                            <h1 className="animatedNumber">0</h1>
                          )}
                          {animationCompleted && !isLoading && (
                            <h1>{indicators?.reqGold}</h1>
                          )}
                        </div>
                      )}
                      {i === 1 && (
                        <div>
                          <Image src={silverLogo}></Image>
                          <span className="statName">
                            <FormattedMessage
                              id="dashboard.silverReqs"
                              defaultMessage="Richieste Silver"
                            />
                          </span>
                          {(!animationCompleted || isLoading) && (
                            <h1 className="animatedNumber">0</h1>
                          )}
                          {animationCompleted && !isLoading && (
                            <h1>{indicators?.reqSilver}</h1>
                          )}
                        </div>
                      )}
                      {i === 2 && (
                        <div>
                          <Icon name="calendar alternate" />
                          <span className="statName">
                            <FormattedMessage
                              id="dashboard.appointments"
                              defaultMessage="Consulenze prenotate"
                            />
                          </span>
                          {(!animationCompleted || isLoading) && (
                            <h1 className="animatedNumber">0</h1>
                          )}
                          {animationCompleted && !isLoading && (
                            <h1>{indicators?.appointmentsGrandTotal}</h1>
                          )}
                        </div>
                      )}
                      {i === 3 && (
                        <div>
                          <Icon name="stopwatch" />
                          <span className="statName">
                            <FormattedMessage
                              id="dashboard.avgCompletionTime"
                              defaultMessage="Tempo medio di completamento"
                            />
                          </span>
                          {(!animationCompleted || isLoading) && (
                            <h1 className="animatedNumber">0</h1>
                          )}
                          {animationCompleted && !isLoading && (
                            <div style={{ display: 'flex' }}>
                              <h1>{indicators?.reqAvgCompletionTime}</h1>
                              <h3>h</h3>
                            </div>
                          )}
                        </div>
                      )}
                      <span className="untilToday">
                        <FormattedMessage
                          id="dashboard.untilToday"
                          defaultMessage="Fino ad oggi"
                        />
                      </span>
                    </div>
                  ))}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className="chart-half">
                {(!animationCompleted || isLoading) && (
                  <div id="reqByCompanyType">
                    <Loader active size="huge" />
                  </div>
                )}
                {animationCompleted &&
                  isLoading === false &&
                  indicators?.requestsGrandTotal !== 'n.d.' && (
                    <div id="reqByCompanyType"></div>
                  )}
                {animationCompleted &&
                  isLoading === false &&
                  indicators?.requestsGrandTotal === 'n.d.' && (
                    <h4 id="reqByCompanyType">Dati non disponibili</h4>
                  )}
              </Grid.Column>
              <Grid.Column className="chart-half">
                <div id="reqByDoc"></div>
                {/* {(!animationCompleted || isLoading) && (
                  <div id="reqByDoc">
                    <Loader active size="huge" />
                  </div>
                )}
                {animationCompleted &&
                  !isLoading &&
                  indicators?.requestsGrandTotal !== 'n.d.' && (
                    <div id="reqByDoc"></div>
                  )}
                {animationCompleted &&
                  !isLoading &&
                  indicators?.requestsGrandTotal === 'n.d.' && (
                    <h4 id="reqByDoc">Dati non disponibili</h4>
                  )} */}
              </Grid.Column>
            </Grid.Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
