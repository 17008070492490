import { Request } from 'services/request/types';
import { RequestPollingAction, RequestPollingActionConstants } from './actions';

export type RequestPollingState = {
  request: Request | null;
  isTimeout: boolean;
  isError: boolean;
};

export const initialRequestPollingState: RequestPollingState = {
  request: null,
  isTimeout: false,
  isError: false,
};

export function requestPollingReducer(
  state: RequestPollingState,
  action: RequestPollingAction,
): RequestPollingState {
  const { type } = action;

  switch (type) {
    case RequestPollingActionConstants.POLLING_SUCCESS:
      return { ...state, request: action.payload.request };
    case RequestPollingActionConstants.POLLING_TIMEOUT:
      return { ...state, isTimeout: true };
    case RequestPollingActionConstants.POLLING_ERROR:
      return { ...state, isError: true };
    default:
      return state;
  }
}
