import { AgentAction, AgentActionConstants } from 'pages/Agent/reducer/actions';
import { AgentResponse } from 'pages/Agent/types';

export type AgentProfileState = {
  isLoading: boolean;
  agent: AgentResponse;
};

export const initialAgentProfileState: AgentProfileState = {
  isLoading: false,
  agent: {
    id: null,
    name: null,
    surname: null,
    email: null,
    type: null,
    vouchers: [],
  },
};

export function agentProfileReducer(
  state: AgentProfileState,
  action: AgentAction,
): AgentProfileState {
  const { type } = action;

  switch (type) {
    case AgentActionConstants.SET_AGENT:
      return { ...state, agent: action.payload.agent };
    default:
      return state;
  }
}
