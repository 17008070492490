import React from 'react';
import { Form, Input, SubmitButton, Field } from 'formik-semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import { Grid, Header } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

// Types
import { RegisterFormProps } from './types';
import { RegistrationRequest } from 'services/registration/types';
import { userRegistration } from 'services/registration';
import { PasswordInputFormik } from 'components/PasswordInputFormik';

const RegisterForm: React.FC<RegisterFormProps> = ({ setResponse }) => {
  /**
   * The registration request input values.
   */
  const initialValues: RegistrationRequest = {
    username: '',
    password: '',
    confirmPassword: '',
  };

  const intl = useIntl();

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) =>
          userRegistration(values, formikHelpers, setResponse)
        }
        validationSchema={Yup.object().shape({
          username: Yup.string()
            .required("L'indirizzo email è richiesto")
            .email()
            .nullable(),
          password: Yup.string()
            .required('La password è richiesta')
            .nullable()
            .min(8)
            .matches(
              /[\'^£$%&*()}{@#~?!><>,|=_+-]/,
              'La password deve contenere almeno un carattere speciale',
            )
            .matches(/\d/, 'La password deve contenere almeno un numero')
            .matches(
              /[a-z]/,
              'La password deve contenere almeno una lettera minuscola',
            )
            .matches(
              /[A-Z]/,
              'La password deve contenere almeno una lettera maiuscola',
            ),
          confirmPassword: Yup.string()
            .required('La password di conferma è richiesta')
            .nullable()
            .oneOf(
              [Yup.ref('password'), null],
              intl.formatMessage({
                id: 'register.errorPasswordNoMatch',
                defaultMessage: 'Entrambe le password devono combaciare',
              }),
            ),
        })}
      >
        {({ setFieldValue, handleBlur }) => (
          <Form className="register__form">
            <Grid padded textAlign="left">
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3" className="register__title--h3">
                    <FormattedMessage
                      id="register.title"
                      defaultMessage="Registrati"
                    />
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Input
                    name="username"
                    placeholder={intl.formatMessage({
                      id: 'app.email',
                      defaultMessage: 'email',
                    })}
                    label={
                      <FormattedMessage id="app.email" defaultMessage="email" />
                    }
                  />
                  <ErrorMessage name="username" component="span" />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns="equal">
                <Grid.Column>
                  <Field name="password">
                    {({ field }) => (
                      <PasswordInputFormik
                        placeholder={'Password*'}
                        label="Password*"
                        type="password"
                        setFieldValue={setFieldValue}
                        field={field}
                        onBlur={handleBlur}
                      />
                    )}
                  </Field>
                  {/* <Input
                  name="password"
                  placeholder={intl.formatMessage({
                    id: 'app.password',
                    defaultMessage: 'password',
                  })}
                  type="password"
                  label={
                    <FormattedMessage
                      id="app.password"
                      defaultMessage="password"
                    />
                  }
                /> */}
                  {/* <ErrorMessage name="password" component="span" /> */}
                </Grid.Column>
                <Grid.Column>
                  <Field name="confirmPassword">
                    {({ field }) => (
                      <PasswordInputFormik
                        placeholder={'Conferma password*'}
                        label="Conferma password*"
                        type="password"
                        setFieldValue={setFieldValue}
                        field={field}
                        onBlur={handleBlur}
                      />
                    )}
                  </Field>
                  {/* <Input
                    name="confirmPassword"
                    placeholder={intl.formatMessage({
                      id: 'app.confirmPassword',
                      defaultMessage: 'Conferma password',
                    })}
                    type="password"
                    label={
                      <FormattedMessage
                        id="app.confirmPassword"
                        defaultMessage="Conferma password"
                      />
                    }
                  />
                  <ErrorMessage name="confirmPassword" component="span" /> */}
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column textAlign="center">
                  <SubmitButton
                    className="button--dark"
                    content={
                      <FormattedMessage
                        id="register.submit"
                        defaultMessage="Registrati"
                      />
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RegisterForm;
