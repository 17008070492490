import { BenefitEntity } from 'components/Benefit/types';
import { Nullable } from 'utils/types';

export type InvestmentFormValues = {
  investmentAmount: Nullable<number>;
  investmentStarted: boolean;
  investmentFinished: boolean;
  dateFinish: Nullable<string>;
  dateStart: Nullable<string>;
  investmentDescription: Nullable<string>;
  landAmount: Nullable<number>;
  manufacturedAmount: Nullable<number>;
  equipmentAmount: Nullable<number>;
  assetsAmount: Nullable<number>;
  intangibleAmount: Nullable<number>;
  liquidityAmount: Nullable<number>;
  otherAmountDescription: Nullable<string>;
  otherAmount: Nullable<number>;
  investmentTotalAmount: Nullable<number>;
  facilitatedByOther: boolean;
  relatedLiquidityAmount: Nullable<number>;
  benefits: Array<BenefitEntity>;
  isTourismSpecialSectionEligible: Nullable<boolean>;
  additionalControl: Nullable<string>;
  isTourismEnergyRenovation: Nullable<boolean>;
  controlSchoolsAndLanguageCourses: Nullable<boolean>;
  controlCelebrationsPlanning: Nullable<boolean>;
};
export type DisabledByPurposesValues = {
  isFabbricatiOpereMurariePurpose: boolean;
  isMacchinariImpiantiProduttiviPurpose: boolean;
  isInvestimentiImmaterialiPurpose: boolean;
  isAttiviFinanziariPurpose: boolean;
};

export const DisabledByPurposeInitialValues = {
  isFabbricatiOpereMurariePurpose: false,
  isMacchinariImpiantiProduttiviPurpose: false,
  isInvestimentiImmaterialiPurpose: false,
  isAttiviFinanziariPurpose: false,
};
