import _ from 'lodash';
import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Header, Button, Tab } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createCustomer,
  getCustomerById,
  updateCustomer,
} from 'services/customer/index';
import {
  customerProfileReducer,
  CustomerProfileState,
  initialCustomerProfileState,
} from './reducer/reducer';
import {
  CustomerAction,
  CustomerActionConstants,
  CustomerPageActionConstants,
  CustomerType,
} from 'pages/Customer/reducer/actions';
import { FormikHelpers } from 'formik';

// Form
import WebuserProfileForm from 'components/Forms/WebuserProfileForm';
import BankProfileForm from 'components/Forms/BankProfileForm';
import AccountantProfileForm from 'components/Forms/AccountantProfileForm';
// Types
import { Customer } from 'services/customer/types';
import { FE_ROUTES } from 'utils/global/globalCostants';

export const CustomerProfileContext = createContext<{
  state: CustomerProfileState;
  dispatch: React.Dispatch<CustomerAction>;
}>({
  state: initialCustomerProfileState,
  dispatch: () => null,
});

const CustomerProfile: React.FC = () => {
  /** Get params */
  const { action, id } = useParams();

  /**
   * The react-intl object.
   */
  const intl = useIntl();

  /** Gets the reducer to receive GET request result */
  const [state, dispatch] = useReducer(
    customerProfileReducer,
    initialCustomerProfileState,
  );

  /**
   * The hook to navigate thorught the application.
   */
  const navigate = useNavigate();

  /**
   * Method used to get customer values filtered by given customer id with an async request.
   */
  const getCustomerValues = () => {
    // Get the customer
    if (id && action !== CustomerPageActionConstants.NEW) {
      getCustomerById(id, dispatch);
    }
  };

  /**
   * Reads customer values at startup.
   */
  useEffect(() => {
    getCustomerValues();
    if (action === 'new' && id)
      dispatch({
        type: CustomerActionConstants.SET_CUSTOMER_TYPE,
        payload: { customerType: id },
      });
  }, []);

  /**
   * Set the initial values from the request state.
   * It helps as we need to transform complex fields.
   */
  const initialValues = useMemo<Customer>(() => {
    const transformData = {
      id: state.customer.id,
      businessName: state.customer.businessName,
      email: state.customer.email,
      fiscalCode: state.customer.fiscalCode,
      pec: state.customer.pec,
      companyType: state.customer.companyType,
      vatNumber: state.customer.vatNumber,
      subscriptionType: state.customer.subscriptionType?.id || undefined,
      subscriptionActive: state.customer.subscriptionActive
        ? state.customer.subscriptionActive
        : undefined,
      consultingEnabled: state.customer.consultingEnabled
        ? state.customer.consultingEnabled
        : undefined,
      feasibilityCheckEnabled: state.customer.feasibilityCheckEnabled
        ? state.customer.feasibilityCheckEnabled
        : undefined,
      maxRequestsAmount: state.customer.maxRequestsAmount ?? 0,
      remainingRequests: state.customer.remainingRequests ?? 0,
      type: state.customer.type ?? undefined,
    };
    return transformData;
  }, [state.customer]);

  /**
   * Method used to manage the submit action from CustomerProfileForm component.
   * @param values Customer data.
   */
  const onSubmit = (
    values: Customer,
    formikHelpers: FormikHelpers<Customer>,
  ) => {
    // Clean data
    const data = cleanData(values);

    switch (action) {
      case CustomerPageActionConstants.MODIFY:
        // Update the customer
        if (id && state.customer.type) {
          updateCustomer(data, id, state.customer.type, formikHelpers);
        }
        break;
      case CustomerPageActionConstants.NEW:
        // Create the new customer
        if (id) {
          createCustomer(data, id, formikHelpers);
        }
    }
  };

  /**
   * Method used to manage the edit action for the current Customer.
   */
  const onEdit = () => {
    navigate(
      `${FE_ROUTES.CUSTOMER}/${CustomerPageActionConstants.MODIFY}/${id}`,
    );
  };

  /**
   * Method used to clean the data before sending the request to backend services.
   * @param values Customer data.
   */
  const cleanData = (values: Customer): Customer => {
    const cleanData = _.cloneDeep(values);

    _.unset(cleanData, 'createdAt');
    _.unset(cleanData, 'deletedAt');
    _.unset(cleanData, 'id');
    _.unset(cleanData, 'updatedAt');
    _.unset(cleanData, '_embedded');
    _.unset(cleanData, '_links');

    return cleanData;
  };

  return (
    <div>
      <Helmet>
        <title>
          {'Allegato4.it - ' +
            intl.formatMessage({
              id: `profile.title${state.customer.type}`,
              defaultMessage: 'Profilo cliente',
            })}
        </title>
        <meta name="description" content="customer profile page" />
        <script>{"smartsupp('chat:hide')"}</script>
      </Helmet>
      <div className="customer-container">
        <>
          <div>
            <Header className="customerHeader" dividing>
              <Grid>
                <Grid.Column width={4}>
                  <Header>
                    <h1>
                      <FormattedMessage
                        id="profile.title"
                        defaultMessage="Profilo cliente"
                      />
                    </h1>
                  </Header>
                </Grid.Column>
              </Grid>
            </Header>
            <div className="customerSectionBody">
              <div className="customerSectionForm">
                {(state.customer.type === CustomerType.WEBUSER ||
                  id === CustomerType.WEBUSER) && (
                  <Tab
                    renderActiveOnly={false}
                    activeIndex={0}
                    panes={[
                      {
                        menuItem: {
                          id: 'customerInfo',
                          content: (
                            <FormattedMessage
                              id="customerInfo"
                              defaultMessage="Informazioni del cliente"
                            />
                          ),
                          key: 'customerInfo',
                        },
                        pane: (
                          <Tab.Pane>
                            <WebuserProfileForm
                              customerType={
                                state.customer.type
                                  ? state.customer.type
                                  : id
                                  ? id
                                  : ''
                              }
                              initialValues={initialValues}
                              onSubmit={onSubmit}
                              mode={action}
                            />
                          </Tab.Pane>
                        ),
                      },
                    ]}
                  />
                )}
                {(state.customer.type === CustomerType.BANKCLIENT ||
                  id === CustomerType.BANKCLIENT) && (
                  <Tab
                    renderActiveOnly={false}
                    activeIndex={0}
                    panes={[
                      {
                        menuItem: {
                          id: 'customerInfo',
                          content: (
                            <FormattedMessage
                              id="customerInfo"
                              defaultMessage="Informazioni del cliente"
                            />
                          ),
                          key: 'customerInfo',
                        },
                        pane: (
                          <Tab.Pane>
                            <WebuserProfileForm
                              customerType={
                                state.customer.type
                                  ? state.customer.type
                                  : id
                                  ? id
                                  : ''
                              }
                              initialValues={initialValues}
                              onSubmit={onSubmit}
                              mode={action}
                            />
                          </Tab.Pane>
                        ),
                      },
                    ]}
                  />
                )}
                {(state.customer.type === CustomerType.ACCOUNTANTCLIENT ||
                  id === CustomerType.ACCOUNTANTCLIENT) && (
                  <Tab
                    renderActiveOnly={false}
                    activeIndex={0}
                    panes={[
                      {
                        menuItem: {
                          id: 'customerInfo',
                          content: (
                            <FormattedMessage
                              id="customerInfo"
                              defaultMessage="Informazioni del cliente"
                            />
                          ),
                          key: 'customerInfo',
                        },
                        pane: (
                          <Tab.Pane>
                            <WebuserProfileForm
                              customerType={
                                state.customer.type
                                  ? state.customer.type
                                  : id
                                  ? id
                                  : ''
                              }
                              initialValues={initialValues}
                              onSubmit={onSubmit}
                              mode={action}
                            />
                          </Tab.Pane>
                        ),
                      },
                    ]}
                  />
                )}
                {(state.customer.type === CustomerType.BANK ||
                  id === CustomerType.BANK) && (
                  <Tab
                    renderActiveOnly={false}
                    activeIndex={0}
                    panes={[
                      {
                        menuItem: {
                          id: 'customerInfo',
                          content: (
                            <FormattedMessage
                              id="customerInfo"
                              defaultMessage="Informazioni del cliente"
                            />
                          ),
                          key: 'customerInfo',
                        },
                        pane: (
                          <Tab.Pane>
                            <BankProfileForm
                              customerType={
                                state.customer.type
                                  ? state.customer.type
                                  : id
                                  ? id
                                  : ''
                              }
                              initialValues={initialValues}
                              onSubmit={onSubmit}
                              mode={action}
                            />
                          </Tab.Pane>
                        ),
                      },
                    ]}
                  />
                )}
                {(state.customer.type === CustomerType.ACCOUNTANT ||
                  id === CustomerType.ACCOUNTANT) && (
                  <Tab
                    renderActiveOnly={false}
                    activeIndex={0}
                    panes={[
                      {
                        menuItem: {
                          id: 'customerInfo',
                          content: (
                            <FormattedMessage
                              id="customerInfo"
                              defaultMessage="Informazioni del cliente"
                            />
                          ),
                          key: 'customerInfo',
                        },
                        pane: (
                          <Tab.Pane>
                            <AccountantProfileForm
                              customerType={state.customer.type ?? ''}
                              initialValues={initialValues}
                              onSubmit={onSubmit}
                              mode={action}
                            />
                          </Tab.Pane>
                        ),
                      },
                    ]}
                  />
                )}
              </div>
              <div className="customerSectionActions">
                {action === CustomerPageActionConstants.VIEW && (
                  <Button
                    type="button"
                    icon
                    onClick={onEdit}
                    className="button--primary--negative"
                  >
                    <FormattedMessage
                      id="profile.edit"
                      defaultMessage="Modifica"
                    />
                  </Button>
                )}
                {action !== CustomerPageActionConstants.VIEW && (
                  <Button
                    form="customerProfileForm"
                    type="submit"
                    icon
                    className="button--primary"
                  >
                    <FormattedMessage
                      id="profile.save"
                      defaultMessage="Salva informazioni"
                    />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default CustomerProfile;
