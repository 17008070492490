import { ErrorMessage, Field, Formik, FormikHelpers } from 'formik';
import React, { useMemo } from 'react';
import { Button, Grid, Header, Icon } from 'semantic-ui-react';
import { Checkbox, SubmitButton, Input, Form } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { States } from 'services/stateMachine/types';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { CompanyTypeConstants } from 'components/Forms/WebuserProfileForm/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { updateRequest } from 'services/request';
import { StepClienteIdentificavoAziendaValues } from './types';

/**
 * In this step we collect the information to identify the company (such as VAT number and company name).
 * @return
 */
export const StepClienteIdentificativoAzienda: React.FC = () => {
  /**
   * The state machine configuration.
   */
  const { state, dispatch } = useStateMachine();

  const intl = useIntl();

  /**
   * The initial state of the request.
   */
  const initialValues = useMemo<StepClienteIdentificavoAziendaValues>(() => {
    if (state.request) {
      return {
        companyType: state.request.companyType,
        vatNumber: state.request.vatNumber,
        fiscalCode: state.request.fiscalCode,
        flagPrivacy: false,
      };
    }

    return {
      companyType: '',
      vatNumber: '',
      fiscalCode: '',
      flagPrivacy: false,
    };
  }, [state.request]);

  /**
   * Go to the next step and ask for additional information about the customer via the CodicePlastico APIs.
   * Those fetching operation are going to be performed BE-side.
   * This submit can redirect the step to
   * @param values The form values
   * @param formikHelpers The Formik helpers
   */
  const onSubmit = (
    values: StepClienteIdentificavoAziendaValues,
    formikHelpers: FormikHelpers<StepClienteIdentificavoAziendaValues>,
  ) => {
    updateRequest(
      {
        ...values,
        id: state.request?.id,
        state: States.ClienteRichiestaPending,
      },
      dispatch,
      formikHelpers,
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        companyType: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.companyType',
              defaultMessage: 'La tipologia di società',
            }),
          )
          .required()
          .nullable()
          .oneOf([
            CompanyTypeConstants.FREELANCE,
            CompanyTypeConstants.LIMITEDCOMPANY,
            CompanyTypeConstants.PARTNERSHIP,
            CompanyTypeConstants.PROFESSIONALFIRM,
          ]),
        vatNumber: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.vatNumber',
              defaultMessage: 'Codice fiscale/P.IVA',
            }),
          )
          .nullable()
          .typeError('Codice fiscale/P.IVA è obbligatorio')
          .when(['fiscalCodeSameAsVATNo', 'companyType'], {
            is: (fiscalCodeSameAsVATNo: boolean, companyType: string) =>
              (companyType === CompanyTypeConstants.FREELANCE &&
                !fiscalCodeSameAsVATNo) ||
              companyType !== CompanyTypeConstants.FREELANCE,
            then: Yup.string()
              .typeError('Codice fiscale/P.IVA è obbligatorio')
              .required()
              .nullable(),
          }),
        fiscalCode: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.fiscalCode',
              defaultMessage: 'Codice fiscale',
            }),
          )
          .nullable()
          .typeError('Codice fiscale è obbligatorio')
          .when('companyType', {
            is: (companyType: string) =>
              companyType === CompanyTypeConstants.FREELANCE,
            then: Yup.string()
              .typeError('Codice fiscale è obbligatorio')
              .required()
              .nullable(),
          }),
        flagPrivacy: Yup.boolean().isTrue(
          intl.formatMessage({
            id: 'error.boolean.isTrue',
            defaultMessage: 'Devi spuntare la casella per continuare',
          }),
        ),
      })}
    >
      {({ values, setFieldValue }) => (
        <Form className="request__form">
          <Grid padded textAlign="center">
            <Grid.Row>
              <Grid.Column>
                <Header as="h2" className="login__title--h3">
                  <FormattedMessage
                    id="request.title"
                    defaultMessage="Elenco richieste"
                  />
                </Header>
                <Header.Subheader as="h3">
                  <FormattedMessage
                    id="request.subtitle"
                    defaultMessage="Abbiamo bisogno di alcuni dati prima di iniziare la compilazione"
                  />
                </Header.Subheader>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field name="companyType">
                  {({ field, form }) => (
                    <Button.Group className="request__company-types">
                      <Button
                        type="button"
                        primary={field.value === CompanyTypeConstants.FREELANCE}
                        checked={field.value === CompanyTypeConstants.FREELANCE}
                        onClick={() => {
                          form.setFieldValue(
                            'companyType',
                            CompanyTypeConstants.FREELANCE,
                          );
                        }}
                      >
                        <Icon name="suitcase" />
                        <FormattedMessage
                          id="profile.freelancer"
                          defaultMessage="Libero professionista"
                        />
                      </Button>
                      <Button
                        type="button"
                        primary={
                          field.value === CompanyTypeConstants.PARTNERSHIP
                        }
                        checked={
                          field.value === CompanyTypeConstants.PARTNERSHIP
                        }
                        onClick={() => {
                          form.setFieldValue(
                            'companyType',
                            CompanyTypeConstants.PARTNERSHIP,
                          );
                          if (values.fiscalCode)
                            setFieldValue('fiscalCode', '');
                        }}
                      >
                        <Icon name="users" />
                        <FormattedMessage
                          id="profile.partnership"
                          defaultMessage="Società di persone"
                        />
                      </Button>
                      <Button
                        type="button"
                        primary={
                          field.value === CompanyTypeConstants.LIMITEDCOMPANY
                        }
                        checked={
                          field.value === CompanyTypeConstants.LIMITEDCOMPANY
                        }
                        onClick={() => {
                          form.setFieldValue(
                            'companyType',
                            CompanyTypeConstants.LIMITEDCOMPANY,
                          );
                          if (values.fiscalCode)
                            setFieldValue('fiscalCode', '');
                        }}
                      >
                        <Icon name="building" />
                        <FormattedMessage
                          id="profile.limitedCompany"
                          defaultMessage="Società di capitali"
                        />
                      </Button>
                      <Button
                        type="button"
                        primary={
                          field.value === CompanyTypeConstants.PROFESSIONALFIRM
                        }
                        checked={
                          field.value === CompanyTypeConstants.PROFESSIONALFIRM
                        }
                        onClick={() => {
                          form.setFieldValue(
                            'companyType',
                            CompanyTypeConstants.PROFESSIONALFIRM,
                          );
                          if (values.fiscalCode)
                            setFieldValue('fiscalCode', '');
                        }}
                      >
                        <Icon name="file alternate" />
                        <FormattedMessage
                          id="profile.professionalFirm"
                          defaultMessage="Studio professionale"
                        />
                      </Button>
                    </Button.Group>
                  )}
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <ErrorMessage name="companyType" component="span" />
              </Grid.Column>
            </Grid.Row>
            {values.companyType === CompanyTypeConstants.FREELANCE && (
              <>
                <Grid.Row centered columns={1}>
                  <Grid.Column>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.fiscalCode"
                            defaultMessage="Codice fiscale"
                          />
                          *
                        </label>
                      }
                      name="fiscalCode"
                      type="text"
                    />
                    <ErrorMessage name="fiscalCode" component="span" />
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
            <Grid.Row centered columns={1}>
              <Grid.Column>
                <Input
                  label={
                    <>
                      <label>
                        {values.companyType ===
                        CompanyTypeConstants.FREELANCE ? (
                          <FormattedMessage
                            id="request.vatNumber"
                            defaultMessage="P.IVA"
                          />
                        ) : (
                          <FormattedMessage
                            id="request.fiscalCodeVATNo"
                            defaultMessage="Codice fiscale/P.IVA"
                          />
                        )}
                        *
                      </label>
                    </>
                  }
                  name="vatNumber"
                  type="text"
                />
                <ErrorMessage name="vatNumber" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Checkbox
                  name="flagPrivacy"
                  label={
                    <label>
                      <FormattedMessage
                        id="request.flagPrivacy"
                        defaultMessage="L'interessato dichiara di aver preso visione dei termini e
                condizioni e compreso l'informativa sulla privacy e
                acconsente al trattamento dei propri dati personali."
                      />
                    </label>
                  }
                />
                <ErrorMessage name="flagPrivacy" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <SubmitButton
                  content={
                    <FormattedMessage
                      id="request.submit"
                      defaultMessage="Avvia compilazione"
                    />
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
