import React, {
  createContext,
  useEffect,
  useReducer,
  useMemo,
  useState,
} from 'react';
import { ErrorMessage, Formik, FormikHelpers, Field } from 'formik';
import { Form, TextArea } from 'formik-semantic-ui-react';
import { Grid, Header, Container, Checkbox } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import _ from 'lodash';
import { Tooltip } from 'components/Tooltip';
import {
  initialPurposeOptionState,
  purposeOptionReducer,
  PurposeOptionState,
} from './reducer/reducer';
import { PurposeOptionAction } from './reducer/actions';
import { FormControlLabel } from '@material-ui/core';

import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { updateRequest } from 'services/request';
import { getPurposeOptionsList } from 'services/purpose';

import { StepFinalitaValues } from './types';
import { States } from 'services/stateMachine/types';
import { FormikWatcher } from 'components/FormikWatcher';
import { Values, FormikWatcherProps } from 'components/FormikWatcher/types';

export const CustomerContext = createContext<{
  state: PurposeOptionState;
  dispatch: React.Dispatch<PurposeOptionAction>;
}>({
  state: initialPurposeOptionState,
  dispatch: () => null,
});

export const StepFinalita: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state, dispatch } = useStateMachine();

  /**
   * The react-intl component.
   */
  const intl = useIntl();

  const [purposeOptionsState, purposeOptionDispatch] = useReducer(
    purposeOptionReducer,
    initialPurposeOptionState,
  );
  const [oneLinkedToInvestment, setOneLinkedToInvestment] =
    useState<boolean>(false);
  const [
    isTogglePurposePaymentConsumptionVisible,
    setIsTogglePurposePaymentConsumptionVisible,
  ] = useState<boolean>(false);
  const [isStartUp, setIsStartUp] = useState<boolean>(false);

  const initialValues = useMemo<StepFinalitaValues>(() => {
    if (state.request?.innovativeStartup === true) {
      setIsStartUp(false);
    } else if (
      _.isEmpty(state.request?.balances) ||
      state.request?.balances.length === 1
    ) {
      setIsStartUp(true);
    }
    const requestPurpose = state.request?.requestPurpose;
    const checkedIds = requestPurpose?.map(purpose => {
      if (
        purpose.relatedToInvestment &&
        purpose.code !== 'Liquidita_Connessa_All_Investimento'
      ) {
        setOneLinkedToInvestment(true);
      }
      if (
        purpose.code === 'Liquidita_Per_Pagamento_Fornitori' &&
        requestPurpose.length === 1
      ) {
        setIsTogglePurposePaymentConsumptionVisible(true);
      }
      return purpose.id;
    });
    return {
      purposeOptionList: null,
      requestPurpose: checkedIds,
      purposeNote: state.request?.purposeNote || null,
      isPurposePaymentConsumption2022:
        state.request?.isPurposePaymentConsumption2022 || false,
    };
  }, [state.request?.requestPurpose]);

  useEffect(() => {
    // Get purpose options collection
    getPurposeOptionsList(purposeOptionDispatch);
  }, []);

  const handleOnChange = (
    values: Values,
    args: Partial<FormikWatcherProps>,
  ) => {
    let oneInvestmentPurpose = false;
    let isToggleVisible = false;
    const purposesList = purposeOptionsState.purposeOptionList;
    let isNoteRequired = false;

    if (!_.isEmpty(purposeOptionsState.purposeOptionList)) {
      values.requestPurpose?.map(id => {
        const purpose = _.find(purposesList, {
          id: id,
        });
        if (typeof purpose == 'object') {
          if (
            purpose?.relatedToInvestment === true &&
            purpose?.code !== 'Liquidita_Connessa_All_Investimento'
          ) {
            oneInvestmentPurpose = true;
          }

          if (
            purpose?.code === 'Liquidita_Per_Pagamento_Fornitori' &&
            values.requestPurpose.length === 1
          ) {
            isToggleVisible = true;
          }
          if (
            purpose?.code === 'Liquidita_Per_Pagamento_Fornitori' &&
            values.requestPurpose.length > 1
          ) {
            if (args.setFieldValue)
              args.setFieldValue('isPurposePaymentConsumption2022', false);
          }

          // The note option is required only for "Linee_A_Breve".
          if (purpose.note === true && purpose.code === 'Linee_A_Breve') {
            isNoteRequired = true;
          }
        }
      });
      setOneLinkedToInvestment(oneInvestmentPurpose);
      setIsTogglePurposePaymentConsumptionVisible(isToggleVisible);
    }
    if (_.isEmpty(values.requestPurpose)) {
      if (args.setFieldValue)
        args.setFieldValue('isPurposePaymentConsumption2022', false);
    }

    // If the note field is required, than set a Formik field about it.
    // That field it's going to be handled as the other one (because it's not set in the initialValues)
    if (isNoteRequired !== values.isNoteRequired) {
      if (args.setFieldValue)
        args.setFieldValue('isNoteRequired', isNoteRequired);
    }
  };

  const onSubmit = (
    values: StepFinalitaValues,
    formikHelpers: FormikHelpers<StepFinalitaValues>,
  ) => {
    const payload = {
      requestPurpose: values?.requestPurpose,
      onePurposeRelatedToInvestment: oneLinkedToInvestment,
      purposeNote: values?.purposeNote,
      isPurposePaymentConsumption2022:
        values.isPurposePaymentConsumption2022 || false,
    };

    updateRequest(
      {
        ...payload,
        id: state.request?.id,
        state: oneLinkedToInvestment
          ? States.Finalita_Investimento
          : States.Finalita_Regimi,
      },
      dispatch,
      formikHelpers,
    );
  };

  const optionCheckbox = (option, values, setFieldValue) => {
    const optionCode = option.code;
    const optionId = option.id;
    const optionLabel = option.description;
    return (
      <Grid.Row className="step__finalita__option">
        <Grid.Column>
          <label className="step__finalita__label">
            <Field
              className="step__finalita__input"
              type="checkbox"
              name="requestPurpose"
              value={optionId}
              disabled={
                (optionCode === 'Liquidita_Connessa_All_Investimento' &&
                  !oneLinkedToInvestment) ||
                (isStartUp && !option.relatedToInvestment)
              }
              as={FormControlLabel}
              control={
                <Checkbox
                  checked={values.requestPurpose.includes(optionId)}
                  onChange={(e, d) => {
                    const purposeClone = _.clone(values.requestPurpose);
                    if (
                      d.checked &&
                      !values.requestPurpose.includes(optionId)
                    ) {
                      purposeClone.push(optionId);
                      setFieldValue('requestPurpose', purposeClone);
                    } else if (
                      !d.checked &&
                      values.requestPurpose.includes(optionId)
                    ) {
                      purposeClone.splice(
                        values.requestPurpose.indexOf(optionId),
                        1,
                      );
                      setFieldValue('requestPurpose', purposeClone);
                    }
                  }}
                />
              }
            />
            {optionLabel}
            {optionCode === 'Liquidita_Per_Pagamento_Fornitori' &&
              isTogglePurposePaymentConsumptionVisible === true && (
                <>
                  <Checkbox
                    toggle
                    name="isPurposePaymentConsumption2022"
                    label={{
                      children: (
                        <FormattedMessage
                          id="stepFinalita.isPurposePaymentConsumption2022"
                          defaultMessage="La liquidità è finalizzata alla copertura “dei costi d’esercizio per il pagamento delle fatture, per consumi energetici, emesse nei mesi di ottobre, novembre e dicembre 2022” e il relativo importo è stato stimato dal soggetto beneficiario finale (fornitore) sulla base dei costi d’esercizio sostenuti per il pagamento delle fatture. per consumi energetici, emesse dall’1 ottobre 2021 al 30 settembre 2022"
                        />
                      ),
                    }}
                  />
                </>
              )}
          </label>
        </Grid.Column>
        {optionCode === 'Liquidita_Connessa_All_Investimento' && (
          <Grid.Column className="step__finalita__tooltip">
            <Tooltip
              header={
                <Header as="h2">
                  <FormattedMessage
                    id="stepFinalita.relatedToInvestment"
                    defaultMessage="Liquidità connessa all'investimento"
                  />
                </Header>
              }
              content={
                <Container>
                  <p>
                    Solo per operazioni finalizzate alla realizzazione di un
                    programma di investimento. Tale quota di finanziamento,
                    destinata a finanziare il capitale circolante connesso alla
                    realizzazione dell’investimento, non puo’ essere superiore
                    al 40% dell’importo del finanziamento. A titolo
                    esemplificativo e non esaustivo ad esempio: costi per la
                    formazione del personale per l’utilizzo del nuovo
                    impianto/macchinario; costi di trasporto e installazione del
                    nuovo impianto/macchinario; oneri professionali finalizzati
                    alla realizzazione del programma d’investimento.
                  </p>
                </Container>
              }
            />
          </Grid.Column>
        )}
      </Grid.Row>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        requestPurpose: Yup.array()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'request.minPurposes',
              defaultMessage: 'Devi selezionare almeno una finalità',
            }),
          )
          .min(
            1,
            intl.formatMessage({
              id: 'request.minPurposes',
              defaultMessage: 'Devi selezionare almeno una finalità',
            }),
          ),
        purposeNote: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.requiredPurposeNote',
              defaultMessage: 'Tipologia di linea a breve',
            }),
          )
          .nullable()
          .when(['isNoteRequired'], {
            is: (isNoteRequired: boolean) => isNoteRequired,
            then: Yup.string().nullable().required(),
            otherwise: Yup.string().nullable().notRequired(),
          }),
      })}
    >
      {({ values, handleSubmit, setFieldValue, errors, isSubmitting }) => (
        <Form
          id={`form__${state.current}`}
          onSubmit={handleSubmit}
          loading={purposeOptionsState.isLoading}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <div className="titleTooltip">
                  <Header as="h3">
                    Per quali&nbsp;
                    <strong className="title__bold">finalità</strong>
                    &nbsp;hai richiesto il finanziamento?
                  </Header>
                  <Tooltip
                    header={
                      <Header as="h2">
                        <FormattedMessage
                          id="stepFinalita.purposeList"
                          defaultMessage="Elenco finalità"
                        />
                      </Header>
                    }
                    content={
                      <Container>
                        <p>
                          Seleziona una o più finalità per le quali si sta
                          richiedendo il finanziamento.
                        </p>
                      </Container>
                    }
                  />
                </div>
                <Header.Subheader as="h5">
                  <FormattedMessage
                    id="stepFinalita.formSubTitle"
                    defaultMessage="Aiutaci a predisporre il modello corretto per le tue esigenze"
                  />
                </Header.Subheader>
              </Grid.Column>
            </Grid.Row>
            {/*
            Multiple checkboxes with the same name attribute, but different
            value attributes will be considered a "checkbox group". Formik will automagically
            bind the checked values to a single array for your benefit. All the add and remove
            logic will be taken care of for you.
          */}
            <Grid.Row width={8} className="step__finalita__options">
              <div role="group" aria-labelledby="checkbox-group">
                {purposeOptionsState.purposeOptionList.map(
                  (option): JSX.Element =>
                    optionCheckbox(option, values, setFieldValue),
                )}
              </div>
            </Grid.Row>
            <ErrorMessage
              name="requestPurpose"
              component="span"
              className="error error-all"
            />
            {values?.isNoteRequired && (
              <Grid.Row>
                <Grid.Column width={16}>
                  <TextArea
                    name="purposeNote"
                    placeholder="Tipologia di linea a breve"
                    maxLength="100"
                    style={{ fontFamily: 'inherit', fontSize: '14px' }}
                    label={{
                      children: (
                        <FormattedMessage
                          id="stepDichiarazioni.purposeNote"
                          defaultMessage="Tipologia di linea a breve"
                        />
                      ),
                    }}
                  />
                  <ErrorMessage name="purposeNote" component="span" />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
          <FormikWatcher
            values={values}
            callback={handleOnChange}
            setFieldValue={setFieldValue}
            errors={errors}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};
