export const getRegimeDescription = (regime: string): string => {
  let description = '';
  switch (regime) {
    case 'AiutiTcf':
      description =
        'Aiuti a sostegno della liquidità sotto forma di garanzie sui prestiti (Regime TCF)';
      break;
    case 'AiutiDeMinimis':
      description = 'Aiuti "de Minimis"';
      break;
    case 'AiutiInvestimentiPmi':
      description =
        "“Aiuti agli investimenti a favore delle PMI” di cui alla parte XIII, paragrafo A, delle Disposizioni Operative e dell'articolo 17 del Regolamento (UE) n. 651/2014";
      break;
    case 'AiutiStartUp':
      description =
        "“Aiuti alle imprese in fase di avviamento” di cui alla parte XIII, paragrafo B, delle Disposizioni Operative e dell'articolo 22 del Regolamento (UE) n. 651/2014";
      break;
    case 'AiutiFinaziamentoDelRischio':
      description =
        "“Aiuti al finanziamento del rischio” di cui alla parte XIII, paragrafo C, delle Disposizioni Operative e dell'articolo 21 del Regolamento (UE) n. 651/2014";
      break;
    case 'AiutiInvestimentiPmiAgricoltura':
      description =
        'Aiuti agli investimenti a favore delle PMI del settore Agricoltura';
      break;
    case 'AiutiInvestimentiPmiPesca':
      description =
        'Aiuti agli investimenti innovativi a favore delle PMI del settore Pesca';
      break;
    case 'AiutiInvestimentiInnovativiPmiAcquacoltura':
      description =
        'Aiuti agli investimenti innovativi a favore delle PMI del settore Acquacoltura';
      break;
    case 'AiutiInvestimentiProduttiviPmiAcquacoltura':
      description =
        'Aiuti agli investimenti produttivi a favore delle PMI del settore Acquacoltura';
      break;
  }
  return description;
};
