import { MySelf } from 'utils/types/mySelf';
import { AppAction, appActionConstants } from './actions';

export type AppState = {
  isAuthenticated: boolean | undefined;
  isUserLoading: boolean;
  mySelf: MySelf | undefined;
  underMaintenance: boolean;
  openAppointmentModal: boolean;
};

export const initialAppState: AppState = {
  isAuthenticated: undefined,
  isUserLoading: false,
  mySelf: undefined,
  underMaintenance: false,
  openAppointmentModal: false,
};

export function appReducer(state: AppState, action: AppAction): AppState {
  const { type } = action;

  switch (type) {
    case appActionConstants.SET_SESSION:
      return { ...state, isAuthenticated: true };
    case appActionConstants.SET_USER_LOADING:
      return { ...state, isUserLoading: action.payload.isLoading };
    case appActionConstants.REVOKE_SESSION:
      return { ...state, isAuthenticated: false };
    case appActionConstants.SET_MYSELF:
      return { ...state, mySelf: action.payload.mySelf };
    case appActionConstants.SET_UNDER_MAINTENANCE:
      return { ...state, underMaintenance: action.payload.underMaintenance };
    case appActionConstants.SET_OPEN_APPOINTMENT_MODAL:
      return { ...state, openAppointmentModal: action.payload.isOpen };
    default:
      return state;
  }
}
