import _ from 'lodash';

// ENV name variables
export const DEVELOPMENT_ENV = 'development';
export const TEST_ENV = 'test';
export const STAGING_ENV = 'staging';
export const PRODUCTION_ENV = 'production';

/**
 * EXTERNAL CONFIG
 * This configuration can be overwritten using the config.json files.
 */
export let API = {
  BASE_URL: undefined,
  OAUTH_GRANT_PASSWORD: '/oauth',
  FIRST_AUTH_TOKEN: 'Basic ZnJvbnRlbmQ6ZnJvbnRlbmQ=',
  OAUTH_HYBRID: '/hybridauth',
  OAUTH_GOOGLE_POPUP: '/hybridauth/login/Google',
  OAUTH_GRAPH_POPUP: '/hybridauth/login/MicrosoftGraph',
  MYSELF: '/myself',
  USER_MANAGER: '/user-manager',
  PASSWORD_RECOVERY: '/password-recovery',
  ROLES: '/role',
  PERMISSIONS: '/permission',
  RESOURCES: '/resource',
  USERS: '/user',
  REGISTRATION: '/registration',
  ACTIVATION: '/activation',
  CUSTOMER: '/customer',
  ACCOUNTANT: '/accountant',
  ACCOUNTANT_CLIENT: '/accountant-client',
  BANK: '/bank',
  BANK_CLIENT: '/bank-client',
  WEB_USER: '/web-user',
  REQUEST: '/request',
  STATE_MACHINE: '/state-machine',
  PLAN: '/plan',
  LEGAL_REPRESENTATIVE: '/legal-representative',
  PURPOSE_OPTION: '/purpose-option',
  ACTIVATE_USER: '/activate-user',
  ATTACHMENT_MANAGER: '/attachment-manager',
  STRIPE_MANAGER: '/stripe-manager',
  GET_SCHEDULE: '/get-schedule',
  CREATE_EVENT: '/create-event',
  UPDATE_EVENT: '/update-event',
  GET_EVENT: '/get-event',
  PAYMENT: '/payment',
  INDICATOR: '/calculate-indicator',
  VOUCHER: '/voucher',
  AGENT: '/agent',
  GENERATE_VOUCHER: '/generate-voucher',
  MAINTENANCE_MODE: '/maintenance-mode',
};

let loaded = false;

/**
 * This function expose a setter in order to config the application in the very early phase.
 * (after change it the application in auto-restarted)
 */
export const setConfig: (cfg: Record<string, unknown>) => void = config => {
  API = _.merge(API, config);
  loaded = true;
};

export const isLoaded: () => boolean = () => loaded;
