import React, { createContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Button,
  Dimmer,
  Grid,
  Header,
  Loader,
  Segment,
} from 'semantic-ui-react';
import { userActivation } from 'services/registration';
import { AccountActivationAction } from './reducer/actions';
import { AccountActivationReducer } from './reducer/reducer';

// Types
import { AccountActivationState, initialAccountActivationState } from './types';

export const AccountActivationContext = createContext<{
  state: AccountActivationState;
  dispatch: React.Dispatch<AccountActivationAction>;
}>({
  state: initialAccountActivationState,
  dispatch: () => null,
});

const AccountActivation: React.FC = () => {
  // Get the query string parameters.
  const [state, dispatch] = useReducer(
    AccountActivationReducer,
    initialAccountActivationState,
  );
  const [searchParams] = useSearchParams();

  /**
   * Every time the page is reloading, it sets the status code.
   */
  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      userActivation(token, dispatch);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Allegato4.it - Attivazione account</title>
        <meta name="description" content="login page" />
        <script>{"smartsupp('chat:hide')"}</script>
      </Helmet>
      {state.status === null ? (
        <div>
          <div className="Loader">
            <Dimmer active inverted size="large">
              <Loader inverted>Attivazione in corso...</Loader>
            </Dimmer>
          </div>
        </div>
      ) : (
        <Grid>
          <>
            <Grid.Row textAlign="center">
              <Grid.Column>
                <Header>
                  {state.status === true ? (
                    <FormattedMessage
                      id="activation.thankTitle"
                      defaultMessage="Account attivato!"
                    />
                  ) : (
                    <FormattedMessage
                      id="activation.errorTitle"
                      defaultMessage="Errore!"
                    />
                  )}
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="center">
              <Grid.Column>
                <Segment>
                  {state.status === true ? (
                    <>
                      <FormattedMessage
                        id="activation.succesfulMessage"
                        defaultMessage="L'account è stato attivato! Prosegui la navigazione facendo l'accesso al portale {name}."
                        values={{
                          name: (
                            <FormattedMessage
                              id="app.name"
                              defaultMessage="Allegato4"
                            />
                          ),
                        }}
                      />
                      <Segment.Inline>
                        <Button
                          type="button"
                          content={
                            <Link to="/login">
                              <FormattedMessage
                                id="link.login"
                                defaultMessage="Accedi"
                              />
                            </Link>
                          }
                        />
                      </Segment.Inline>
                    </>
                  ) : (
                    <FormattedMessage
                      id="register.errorMessage"
                      defaultMessage="Ops! Qualcosa è andato storto"
                    />
                  )}
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </>
        </Grid>
      )}
    </>
  );
};

export default AccountActivation;
