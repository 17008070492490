import _ from 'lodash';
import standardClient from 'services/client/standardRequestClient';
import { FormikHelpers } from 'formik';

// Constants
import { API } from 'utils/global/backendRoutes';

// Types
import { StandardResponseError } from 'services/client/types';
import { RegistrationRequest, UserActivationResponse } from './types';
import { RegisterResponseState } from 'pages/Register/types';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
  AccountActivationAction,
  AccountActivationActionConstants,
} from 'pages/AccountActivation/reducer/actions';

export const userRegistration = (
  values: RegistrationRequest,
  formikHelpers: FormikHelpers<RegistrationRequest>,
  callback: (response: RegisterResponseState) => void,
): void => {
  standardClient({
    url: API.REGISTRATION,
    method: 'POST',
    data: {
      ...values,
    },
  })
    .then(() =>
      // The response is okay, so back to the response page.
      callback({
        email: values.username,
        sent: true,
      }),
    )
    .catch((error: AxiosResponse<StandardResponseError>) => {
      const errors = error?.data?.validation_messages;

      if (errors) {
        // Set the errors in the Formik bag.
        _.forOwn(errors, (value, key) => {
          for (const i in value) {
            formikHelpers.setFieldError(key, value[i]);
            break;
          }
        });
      } else {
        toast.error('Errore durante la registrazione');
      }
    })
    .finally(() => formikHelpers.setSubmitting(false));
};

export const userActivation = (
  token: string,
  dispatch: React.Dispatch<AccountActivationAction>,
): void => {
  standardClient({
    url: `${API.ACTIVATE_USER}?token=${token}`,
    method: 'GET',
  })
    .then((response: AxiosResponse<UserActivationResponse>) => {
      // Dispatch the notification for customer dictionary
      dispatch({
        type: AccountActivationActionConstants.SET_STATUS,
        payload: { status: response.data.status },
      });
    })
    .catch(() => {
      dispatch({
        type: AccountActivationActionConstants.SET_STATUS,
        payload: { status: false },
      });
      toast.error("Errore durante l'attivatione dell'utente");
    });
};
