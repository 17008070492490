import React, { useContext } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import { Form, Input, SubmitButton, Field } from 'formik-semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage, Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { PasswordInputFormik } from 'components/PasswordInputFormik';

// Types
import { LoginFormProps, LoginFormValues } from './types';
import { CONFIG, FE_ROUTES } from 'utils/global/globalCostants';
import { AppContext } from 'pages/App';

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const { state } = useContext(AppContext);

  const initialFormValues: LoginFormValues = {
    username: '',
    password: '',
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        username: Yup.string().required().email().nullable(),
        password: Yup.string().required().nullable(),
      })}
    >
      {({ setFieldValue, handleBlur }) => (
        <Form className="login__form">
          <Grid padded textAlign="left">
            <Grid.Row>
              <Grid.Column>
                <Header as="h3" className="login__title--h3">
                  <FormattedMessage id="login.title" defaultMessage="Accesso" />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="left">
              <Grid.Column>
                <Input
                  name="username"
                  placeholder="E-Mail"
                  label={
                    <FormattedMessage id="app.email" defaultMessage="email" />
                  }
                />
                <ErrorMessage name="username" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field name="password">
                  {({ field }) => (
                    <PasswordInputFormik
                      placeholder={'Password*'}
                      label="Password*"
                      type="password"
                      setFieldValue={setFieldValue}
                      field={field}
                      onBlur={handleBlur}
                    />
                  )}
                </Field>
                {/* <Input
                label={
                  <FormattedMessage
                    id="app.password"
                    defaultMessage="password"
                  />
                }
                name="password"
                placeholder="Password"
                type="password"
              />
              <ErrorMessage name="password" component="span" /> */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="login__password-row" columns={2}>
              <Grid.Column
                textAlign="left"
                className="login__password-link-register"
              >
                <Link to={FE_ROUTES.REGISTER}>
                  <FormattedMessage
                    id="login.linkRegister"
                    defaultMessage="Non hai un account? Registrati!"
                  />
                </Link>
              </Grid.Column>
              <Grid.Column
                textAlign="right"
                className="login__password-recovery"
              >
                <Link to={FE_ROUTES.PASSWORD_RECOVERY}>
                  <FormattedMessage
                    id="login.passwordRecovery"
                    defaultMessage="Hai dimenticato la password?"
                  />
                </Link>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <SubmitButton
                  className="button--dark"
                  content={
                    <FormattedMessage
                      id="login.submit"
                      defaultMessage="Accedi"
                    />
                  }
                  disabled={state.isUserLoading}
                  loading={state.isUserLoading}
                />
              </Grid.Column>
            </Grid.Row>
            {CONFIG.OFFICE_LOGIN_ACTIVE && (
              <Grid.Row>
                <Button
                  className="microsoft-button"
                  content="Login Office 365"
                  type="button"
                  icon="microsoft"
                  fluid
                  labelPosition="left"
                />
              </Grid.Row>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
