import { PurposeOption } from 'services/purpose/types';

export enum PurposeOptionActionContants {
  SET_PURPOSE_SET_PURPOSE_OPTIONS_LIST = 'PurposeOption/SET_PURPOSE_SET_PURPOSE_OPTIONS_LIST',
  SET_IS_LOADING = 'PurposeOption/SET_IS_LOADING',
  SET_REQUEST_PURPOSE = 'PurposeOption/SET_REQUEST_PURPOSE',
}
export type PurposeOptionAction =
  | {
      type: PurposeOptionActionContants.SET_PURPOSE_SET_PURPOSE_OPTIONS_LIST;
      payload: {
        purposeOptionList: Array<PurposeOption>;
      };
    }
  | {
      type: PurposeOptionActionContants.SET_IS_LOADING;
      payload: {
        isLoading: boolean;
      };
    }
  | {
      type: PurposeOptionActionContants.SET_REQUEST_PURPOSE;
      payload: {
        requestPurpose: Array<PurposeOption>;
      };
    };
