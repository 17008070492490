import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Formik, FormikHelpers, getIn } from 'formik';
import { Field, Form, Input, Radio, Checkbox } from 'formik-semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, TextArea, Header, Container } from 'semantic-ui-react';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as Yup from 'yup';
import _ from 'lodash';
import moment from 'moment';
import {
  DisabledByPurposeInitialValues,
  DisabledByPurposesValues,
  InvestmentFormValues,
} from './types';
import { CurrencyInputFormik } from 'components/CurrencyInputFormik';
import { InvestmentDetailsRow } from 'components/InvestmentDetailsRow';
import { Benefit } from 'components/Benefit';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { updateRequest } from 'services/request';
import { States } from 'services/stateMachine/types';
import { Tooltip } from 'components/Tooltip';
import { FormikWatcher } from 'components/FormikWatcher';
import { FormikWatcherProps, Values } from 'components/FormikWatcher/types';

export const StepInvestimento: React.FC = () => {
  const { state, dispatch } = useStateMachine();

  /** The react-intl object. */
  const intl = useIntl();
  const [controlSchoolsAndLanguageCourses, setControlSchoolAndLanguageCourses] =
    useState<boolean>(false);
  const [controlCelebrationsPlanning, setControlCelebrationsPlanning] =
    useState<boolean>(false);

  /** Initial values of form */
  const initialValues = useMemo<InvestmentFormValues>(() => {
    if (state.request) {
      return {
        investmentAmount: state.request.investmentAmount || null,
        investmentStarted: state.request.investmentStarted || false,
        investmentFinished: state.request.investmentFinished || false,
        dateFinish: state.request.dateFinish || null,
        dateStart: state.request.dateStart || null,
        investmentDescription: state.request.investmentDescription || '',
        landAmount: state.request.landAmount || null,
        manufacturedAmount: state.request.manufacturedAmount || null,
        equipmentAmount: state.request.equipmentAmount || null,
        assetsAmount: state.request.assetsAmount || null,
        intangibleAmount: state.request.intangibleAmount || null,
        liquidityAmount: state.request.liquidityAmount || null,
        otherAmountDescription: state.request.otherAmountDescription || '',
        otherAmount: state.request.otherAmount || null,
        investmentTotalAmount: state.request.investmentTotalAmount || null,
        relatedLiquidityAmount: state.request.relatedLiquidityAmount || null,
        facilitatedByOther: state.request.facilitatedByOther || false,
        benefits: state.request.benefits || [],
        isTourismSpecialSectionEligible:
          state.request.isTourismSpecialSectionEligible,
        additionalControl: state.request.additionalControl,
        isTourismEnergyRenovation: _.isNull(
          state.request.isTourismEnergyRenovation,
        )
          ? state.request.additionalControl
            ? false
            : null
          : state.request.isTourismEnergyRenovation,
        controlSchoolsAndLanguageCourses: controlSchoolsAndLanguageCourses,
        controlCelebrationsPlanning: controlCelebrationsPlanning,
      };
    }

    return {
      id: null,
      investmentAmount: null,
      investmentStarted: false,
      investmentFinished: false,
      dateFinish: null,
      dateStart: null,
      investmentDescription: '',
      landAmount: null,
      manufacturedAmount: null,
      equipmentAmount: null,
      assetsAmount: null,
      intangibleAmount: null,
      liquidityAmount: null,
      otherAmountDescription: '',
      otherAmount: null,
      investmentTotalAmount: null,
      facilitatedByOther: false,
      relatedLiquidityAmount: null,
      benefits: [],
      isTourismSpecialSectionEligible: null,
      additionalControl: null,
      isTourismEnergyRenovation: null,
      controlSchoolsAndLanguageCourses: controlSchoolsAndLanguageCourses,
      controlCelebrationsPlanning: controlCelebrationsPlanning,
    };
  }, [state.request]);

  const [disabledByPurposesValues, setDisabledByPurposesValues] =
    useState<DisabledByPurposesValues>(DisabledByPurposeInitialValues);

  useEffect(() => {
    const purposes = state.request?.requestPurpose;
    const isActivePurposes = {
      isFabbricatiOpereMurariePurpose: false,
      isMacchinariImpiantiProduttiviPurpose: false,
      isInvestimentiImmaterialiPurpose: false,
      isAttiviFinanziariPurpose: false,
    };
    if (purposes) {
      purposes.forEach(purpose => {
        if (purpose.code === 'Fabbricati_Opere_Murarie_Ed_Assimilate') {
          isActivePurposes.isFabbricatiOpereMurariePurpose = true;
        }
        if (purpose.code === 'Macchinari_Impianti_Produttivi_E_Attrezzature') {
          isActivePurposes.isMacchinariImpiantiProduttiviPurpose = true;
        }
        if (purpose.code === 'Investimenti_Immateriali') {
          isActivePurposes.isInvestimentiImmaterialiPurpose = true;
        }
        if (purpose.code === 'Attivi_Finanziari') {
          isActivePurposes.isAttiviFinanziariPurpose = true;
        }
      });
      setDisabledByPurposesValues(isActivePurposes);
    }
  }, [state.request]);

  /**
   * Validate schema of form
   */
  const validationSchema = Yup.object().shape({
    investmentTotalAmount: Yup.number()
      .label(
        intl.formatMessage({
          id: 'investment.investmentAmount',
          defaultMessage: 'Totale programma investimento',
        }),
      )
      .required()
      .positive()
      .test(
        'sumDetails',
        "La somma dei dettagli del programma di investimento deve essere uguale all'importo programma di investimento",
        (value, textContext) => {
          if (
            !_.find(state.request?.requestPurpose, {
              code: 'Liquidita_Connessa_All_Investimento',
            })
          ) {
            return (
              textContext.parent.investmentAmount ===
              textContext.parent.otherAmount +
                textContext.parent.assetsAmount +
                textContext.parent.intangibleAmount +
                textContext.parent.equipmentAmount +
                textContext.parent.manufacturedAmount +
                textContext.parent.landAmount
            );
          }
          return true;
        },
      )
      .test(
        'lessOrEqual',
        "La somma dei dettagli del programma di investimento deve essere minore o uguale all'importo programma di investimento",
        (value, textContext) => {
          if (
            value &&
            _.find(state.request?.requestPurpose, {
              code: 'Liquidita_Connessa_All_Investimento',
            })
          )
            return textContext.parent.investmentAmount >= value;
          return true;
        },
      )
      .nullable(),
    relatedLiquidityAmount: Yup.number()
      .label(
        intl.formatMessage({
          id: 'investment.relatedLiquidityAmount',
          defaultMessage: 'Importo liquidità connessa',
        }),
      )
      .positive()
      .nullable(),
    landAmount: Yup.number()
      .label(
        intl.formatMessage({
          id: 'investment.landAmount',
          defaultMessage: 'Terreni',
        }),
      )
      .positive()
      .nullable(),
    manufacturedAmount: Yup.number()
      .label(
        intl.formatMessage({
          id: 'investment.manufacturedAmount',
          defaultMessage: 'Fabbricati, opere murarie ed assimilate',
        }),
      )
      .test(
        'test',
        'Fabbricati, opere murarie ed assimilate campo obbligatorio in base alla finalità selezionata',
        value => {
          const purposes = state.request?.requestPurpose;
          let result = false;
          if (purposes) {
            const requiredValue = purposes.some(
              purpose =>
                ['Fabbricati_Opere_Murarie_Ed_Assimilate'].indexOf(
                  purpose.code ?? '',
                ) >= 0,
            );
            result = !(!value && requiredValue);
          }
          return result;
        },
      )
      .positive()
      .nullable(),
    equipmentAmount: Yup.number()
      .label(
        intl.formatMessage({
          id: 'investment.equipmentAmount',
          defaultMessage: 'Macchinari, impianti ed attrezzature',
        }),
      )
      .test(
        'test',
        'Macchinari, impianti ed attrezzature campo obbligatorio in base alla finalità selezionata',
        value => {
          const purposes = state.request?.requestPurpose;
          let result = false;
          if (purposes) {
            const requiredValue = purposes.some(
              purpose =>
                ['Macchinari_Impianti_Produttivi_E_Attrezzature'].indexOf(
                  purpose.code ?? '',
                ) >= 0,
            );
            result = !(!value && requiredValue);
          }
          return result;
        },
      )
      .positive()
      .nullable(),
    intangibleAmount: Yup.number()
      .label(
        intl.formatMessage({
          id: 'investment.intangibleAmount',
          defaultMessage: 'Investimenti immateriali',
        }),
      )
      .test(
        'test',
        'Investimenti immateriali campo obbligatorio in base alla finalità selezionata',
        value => {
          const purposes = state.request?.requestPurpose;
          let result = false;
          if (purposes) {
            const requiredValue = purposes.some(
              purpose =>
                ['Investimenti_Immateriali'].indexOf(purpose.code ?? '') >= 0,
            );
            result = !(!value && requiredValue);
          }
          return result;
        },
      )
      .positive()
      .nullable(),
    assetsAmount: Yup.number()
      .label(
        intl.formatMessage({
          id: 'investment.assetsAmount',
          defaultMessage: 'Attivi finanziari',
        }),
      )
      .test(
        'test',
        'Attivi finanziari campo obbligatorio in base alla finalità selezionata',
        value => {
          const purposes = state.request?.requestPurpose;
          let result = false;
          if (purposes) {
            const requiredValue = purposes.some(
              purpose => ['Attivi_Finanziari'].indexOf(purpose.code ?? '') >= 0,
            );
            result = !(!value && requiredValue);
          }
          return result;
        },
      )
      .positive()
      .nullable(),
    otherAmount: Yup.number()
      .label(
        intl.formatMessage({
          id: 'investment.otherAmount',
          defaultMessage: 'Altro',
        }),
      )
      .positive()
      .nullable(),
    dateStart: Yup.mixed()
      .label(
        intl.formatMessage({
          id: 'investment.dateStart',
          defaultMessage: "Data di inizio dell'investimento",
        }),
      )
      .when('investmentStarted', {
        is: (match: boolean) => match === true,
        then: Yup.mixed()
          .required()
          .when('dateFinish', {
            is: (date: string) => {
              if (!_.isNull(date)) return true;
              return false;
            },
            then: Yup.mixed().test(
              'beforeDateFinish',
              `Il valore deve essere antecedente alla data di completamento`,
              (value, context) => {
                const formattedValue = value
                  ? value.isValid
                    ? value.format('YYYY/MM/DD')
                    : moment(value)
                  : null;
                return (
                  formattedValue &&
                  moment(context.parent.dateFinish).isAfter(formattedValue)
                );
              },
            ),
          })
          .test(
            'beforeToday',
            `Il valore deve essere antecedente a ${moment().format(
              'DD/MM/YYYY',
            )}`,
            value => {
              const formattedValue = value
                ? value.isValid
                  ? value.format('YYYY/MM/DD')
                  : moment(value)
                : null;
              const maxDate = moment().format('YYYY/MM/DD');
              return (
                formattedValue && moment(maxDate).isSameOrAfter(formattedValue)
              );
            },
          )
          .test(
            'test',
            `Il valore deve essere maggiore di ${moment()
              .subtract(6, 'months')
              .format('DD/MM/YYYY')}`,
            value => {
              const formattedValue = value
                ? value.isValid
                  ? value.format('YYYY/MM/DD')
                  : moment(value)
                : null;
              const minDate = moment()
                .subtract(6, 'months')
                .format('YYYY/MM/DD');
              return (
                formattedValue && moment(minDate).isSameOrBefore(formattedValue)
              );
            },
          ),
      }),
    dateFinish: Yup.mixed()
      .label(
        intl.formatMessage({
          id: 'investment.dateFinishYup',
          defaultMessage: 'Data di completamento',
        }),
      )
      .required()
      .when('investmentFinished', {
        is: (match: boolean) => match === true,
        then: Yup.mixed()
          .required()
          .test(
            'beforeToday',
            `Il valore deve essere antecedente a ${moment().format(
              'DD/MM/YYYY',
            )}`,
            value => {
              const formattedValue = value
                ? value.isValid
                  ? value.format('YYYY/MM/DD')
                  : moment(value)
                : null;
              const maxDate = moment().format('YYYY/MM/DD');
              return formattedValue && moment(maxDate).isAfter(formattedValue);
            },
          ),
      })
      .when('investmentFinished', {
        is: (match: boolean) => match === false,
        then: Yup.mixed().test(
          'beforeToday',
          `Il valore deve essere maggiore del ${moment().format('DD/MM/YYYY')}`,
          value => {
            const formattedValue = value
              ? value.isValid
                ? value.format('YYYY/MM/DD')
                : moment(value)
              : null;
            const today = moment().format('YYYY/MM/DD');
            return formattedValue && moment(today).isBefore(formattedValue);
          },
        ),
      })
      .when('dateStart', {
        is: (date: string) => {
          if (!_.isNull(date)) return true;
          return false;
        },
        then: Yup.mixed().test(
          'afterDateStart',
          `Il valore deve essere successivo alla data di inizio`,
          (value, context) => {
            const formattedValue = value
              ? value.isValid
                ? value.format('YYYY/MM/DD')
                : moment(value)
              : null;
            return (
              formattedValue &&
              moment(context.parent.dateStart).isBefore(formattedValue)
            );
          },
        ),
      }),
    investmentDescription: Yup.string()
      .max(50)
      .label(
        intl.formatMessage({
          id: 'investment.description',
          defaultMessage: 'Descrizione del programma di investimento',
        }),
      )
      .required(),
    otherAmountDescription: Yup.string()
      .label(
        intl.formatMessage({
          id: 'investment.otherAmountDescription',
          defaultMessage: 'Altro',
        }),
      )
      .when('otherAmount', {
        is: (match: number) => !_.isEmpty(match),
        then: Yup.string().required(),
      }),
    benefits: Yup.array()
      .label(
        intl.formatMessage({
          id: 'investment.benefits',
          defaultMessage: 'Agevolazioni',
        }),
      )
      .when('facilitatedByOther', {
        is: (match: boolean) => match === true,
        then: Yup.array().min(1).max(4),
      }),
    investmentAmount: Yup.number()
      .label(
        intl.formatMessage({
          id: 'investment.investmentTotalAmount',
          defaultMessage: 'Importo del programma investimento',
        }),
      )
      .test(
        'test',
        'L’importo (compreso di liquidità connessa) non può essere inferiore al finanziamento richiesto',
        (value, textContext) => {
          let result = false;
          if (state.request?.amountInEuro) {
            result =
              state.request?.amountInEuro <=
              value + textContext.parent.relatedLiquidityAmount;
          }
          return result;
        },
      )
      .positive()
      .nullable(),
  });

  /**
   * Submit form
   * @param values Values of form
   * @param formikHelpers Some helpers of form
   */
  const onSubmit = (
    values: InvestmentFormValues,
    formikHelpers: FormikHelpers<InvestmentFormValues>,
  ) => {
    const payload = {
      requestPurpose: state.request?.requestPurpose,
      amountInEuro: state.request?.amountInEuro,
    };

    const cleanData = _.clone(values);
    _.unset(cleanData, 'isTourismSpecialSectionEligible');
    _.unset(cleanData, 'additionalControl');
    _.unset(cleanData, 'controlSchoolsAndLanguageCourses');
    _.unset(cleanData, 'controlCelebrationsPlanning');

    updateRequest(
      {
        id: state.request?.id,
        state: States.Investimento_Regimi,
        ...cleanData,
        ...payload,
      },
      dispatch,
      formikHelpers,
    );
  };

  /** Get formatted value from value of params */
  const getCurrentValue = (value: number | string | null): number => {
    if (_.isNull(value) || _.isUndefined(value)) {
      return 0;
    }

    if (_.isString(value)) {
      return parseFloat(value);
    }

    return value;
  };

  /** Update total of investment */
  const updateTotal = (values: Values, args: Partial<FormikWatcherProps>) => {
    // Convert all necessary values
    const landAmount = getCurrentValue(values.landAmount);
    const manufacturedAmount = getCurrentValue(values.manufacturedAmount);
    const equipmentAmount = getCurrentValue(values.equipmentAmount);
    const intangibleAmount = getCurrentValue(values.intangibleAmount);
    const assetsAmount = getCurrentValue(values.assetsAmount);
    const otherAmount = getCurrentValue(values.otherAmount);

    // Define total
    const total =
      landAmount +
      manufacturedAmount +
      equipmentAmount +
      intangibleAmount +
      assetsAmount +
      otherAmount;
    if (args.setFieldValue) args.setFieldValue('investmentTotalAmount', total);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleBlur, errors, isSubmitting }) => (
        <Form className="form__Investimento" id={`form__${state.current}`}>
          <div className="investment__header">
            <h1 className="investment__header__title">
              <FormattedMessage
                id="investment.title"
                defaultMessage="Inserisci le informazioni relative al programma di "
              />
              <span className="title__bold">
                <FormattedMessage
                  id="investment.titleBold"
                  defaultMessage="investimento"
                />
              </span>
            </h1>
            <p className="investment__header__subtitle">
              <FormattedMessage
                id="investment.subTitle"
                defaultMessage="* Gli importi da inserire sono "
              />
              <span className="subtitle__bold">
                <FormattedMessage
                  id="investment.subtitleBold"
                  defaultMessage="IVA esclusa"
                />
              </span>
            </p>
          </div>
          <div className="investment__content">
            <Grid className="investment__content__grid">
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Field name="investmentAmount">
                    {({ field }) => (
                      <CurrencyInputFormik
                        field={field}
                        label={
                          <label>
                            <FormattedMessage
                              id="investment.investmentAmount"
                              defaultMessage="Importo programma di investimento"
                            />
                            *
                          </label>
                        }
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        placeholder="€ Digita importo"
                      />
                    )}
                  </Field>
                  <Tooltip
                    header={
                      <Header as="h2">
                        <FormattedMessage
                          id="investment.investmentAmount"
                          defaultMessage="Importo programma di investimento"
                        />
                      </Header>
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input
                    label={
                      <FormattedMessage
                        id="investment.started"
                        defaultMessage="L'investimento è già iniziato?"
                      />
                    }
                    name="investmentStarted"
                  >
                    <Radio
                      name="investmentStarted"
                      checked={values.investmentStarted}
                      toggle
                      onChange={(_event, { checked }) => {
                        setFieldValue('investmentStarted', checked);
                        setFieldValue('investmentFinished', false);
                        setFieldValue('dateStart', null);
                      }}
                    />
                  </Input>
                  <Tooltip
                    header={
                      <Header as="h2">
                        <FormattedMessage
                          id="investment.dates"
                          defaultMessage="Date dell'investimento"
                        />
                      </Header>
                    }
                    content={
                      <Container>
                        <p>
                          La data dell&apos;investimento non deve essere più
                          vecchia dalla data di sottoscrizione
                          dell&apos;Allegato 4. Nel caso in cui
                          l&apos;investimento non sia ancora completato,
                          specificare la data prevista di completamento.
                        </p>
                      </Container>
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  {values.investmentStarted && (
                    <>
                      <Input
                        label={
                          <label
                            className={
                              getIn(errors, 'dateStart') ? 'error' : undefined
                            }
                          >
                            <FormattedMessage
                              id="investment.dateStart"
                              defaultMessage="Data di inizio dell'investimento"
                            />
                            *
                          </label>
                        }
                        name="dateStart"
                        error={getIn(errors, 'dateStart') ? true : false}
                      >
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            error={false}
                            helperText={null}
                            clearable
                            autoOk
                            disableToolbar
                            format="DD/MM/YYYY"
                            onChange={value =>
                              setFieldValue('dateStart', value)
                            }
                            InputProps={{
                              disableUnderline: true,
                            }}
                            value={values.dateStart}
                          />
                        </MuiPickersUtilsProvider>
                      </Input>
                      <span
                        className={
                          getIn(errors, 'dateStart') ? 'error' : undefined
                        }
                      >
                        {getIn(errors, 'dateStart')}
                      </span>
                      {values.dateStart &&
                        moment
                          .duration(moment().diff(values.dateStart))
                          .asMonths() >= 5 &&
                        moment
                          .duration(
                            moment(values.dateStart).diff(
                              moment().subtract(6, 'months'),
                            ),
                          )
                          .asDays() > 0 && (
                          <p className="investment__content__grid__dateWarning">
                            Mancano ancora{' '}
                            {moment
                              .duration(
                                moment(values.dateStart).diff(
                                  moment().subtract(6, 'months'),
                                ),
                              )
                              .asDays()
                              .toFixed(0)}{' '}
                            giorni alla scadenza della richiesta
                          </p>
                        )}
                    </>
                  )}
                  {!values.investmentStarted && (
                    <>
                      <Input
                        label={
                          <label
                            className={
                              getIn(errors, 'dateFinish') ? 'error' : undefined
                            }
                          >
                            <FormattedMessage
                              id="investment.expectedDateFinish"
                              defaultMessage="Data prevista di completamento"
                            />
                            *
                          </label>
                        }
                        name="dateFinish"
                        error={getIn(errors, 'dateFinish') ? true : false}
                      >
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            error={false}
                            helperText={null}
                            clearable
                            autoOk
                            disableToolbar
                            format="DD/MM/YYYY"
                            onChange={value => {
                              setFieldValue('dateFinish', value);
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            value={values.dateFinish}
                          />
                        </MuiPickersUtilsProvider>
                      </Input>
                      {/* <ErrorMessage name="dateFinish" component="span" /> */}
                      <span
                        className={
                          getIn(errors, 'dateFinish') ? 'error' : undefined
                        }
                      >
                        {getIn(errors, 'dateFinish')}
                      </span>
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
              {values.investmentStarted && (
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Input
                      label={
                        <FormattedMessage
                          id="investment.finished"
                          defaultMessage="L'investimento è già completato?"
                        />
                      }
                      name="investmentFinished"
                    >
                      <Radio
                        name="investmentFinished"
                        checked={values.investmentFinished}
                        toggle
                        onChange={(_event, { checked }) => {
                          setFieldValue('investmentFinished', checked);
                          setFieldValue('dateFinish', null);
                        }}
                      />
                    </Input>
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label={
                        values.investmentFinished ? (
                          <label>
                            <FormattedMessage
                              id="investment.dateFinish"
                              defaultMessage="Data di fine investimento"
                            />
                            *
                          </label>
                        ) : (
                          <label
                            className={
                              getIn(errors, 'dateFinish') ? 'error' : undefined
                            }
                          >
                            <FormattedMessage
                              id="investment.expectedDateFinish"
                              defaultMessage="Data prevista di completamento"
                            />
                            *
                          </label>
                        )
                      }
                      name="dateFinish"
                      error={getIn(errors, 'dateFinish') ? true : false}
                    >
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          error={true}
                          helperText={null}
                          clearable
                          autoOk
                          disableToolbar
                          format="DD/MM/YYYY"
                          onChange={value => {
                            setFieldValue('dateFinish', value);
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          value={values.dateFinish}
                        />
                      </MuiPickersUtilsProvider>
                    </Input>
                    <span
                      className={
                        getIn(errors, 'dateFinish') ? 'error' : undefined
                      }
                    >
                      {getIn(errors, 'dateFinish')}
                    </span>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row className="investment__content__grid__textarea">
                <Input name="investmentDescription">
                  <TextArea
                    placeholder={intl.formatMessage({
                      id: 'investment.description',
                      defaultMessage:
                        'Descrizione del programma di investimento*',
                    })}
                    value={values.investmentDescription || ''}
                    onChange={(_event, { value }) =>
                      setFieldValue('investmentDescription', value)
                    }
                  />
                </Input>
                <ErrorMessage name="investmentDescription" component="span" />
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <InvestmentDetailsRow
                    color="blue"
                    type="text"
                    label="Dettagli del programma di investimento"
                  />
                </Grid.Column>
                <Grid.Column>
                  <InvestmentDetailsRow
                    color="blue"
                    type="text"
                    label="Importi del programma di investimento"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <InvestmentDetailsRow
                    color="grey"
                    type="text"
                    label="Terreni"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field name="landAmount">
                    {({ field }) => (
                      <InvestmentDetailsRow
                        field={field}
                        color="grey"
                        type="input-number"
                        placeholder="€ Digita importo"
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <InvestmentDetailsRow
                    color="grey"
                    type="text"
                    label="Fabbricati, opere murarie ed assimilate"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field name="manufacturedAmount">
                    {({ field }) => (
                      <InvestmentDetailsRow
                        field={field}
                        color="grey"
                        type="input-number"
                        placeholder="€ Digita importo"
                        setFieldValue={setFieldValue}
                        onBlur={event => {
                          handleBlur(event);
                        }}
                        disabled={
                          !disabledByPurposesValues.isFabbricatiOpereMurariePurpose
                        }
                      />
                    )}
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <InvestmentDetailsRow
                    color="grey"
                    type="text"
                    label="Macchinari, impianti ed attrezzature"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field name="equipmentAmount">
                    {({ field }) => (
                      <InvestmentDetailsRow
                        field={field}
                        color="grey"
                        type="input-number"
                        placeholder="€ Digita importo"
                        setFieldValue={setFieldValue}
                        onBlur={event => {
                          handleBlur(event);
                        }}
                        disabled={
                          !disabledByPurposesValues.isMacchinariImpiantiProduttiviPurpose
                        }
                      />
                    )}
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <InvestmentDetailsRow
                    color="grey"
                    type="text"
                    label="Investimenti immateriali"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field name="intangibleAmount">
                    {({ field }) => (
                      <InvestmentDetailsRow
                        field={field}
                        color="grey"
                        type="input-number"
                        placeholder="€ Digita importo"
                        setFieldValue={setFieldValue}
                        onBlur={event => {
                          handleBlur(event);
                        }}
                        disabled={
                          !disabledByPurposesValues.isInvestimentiImmaterialiPurpose
                        }
                      />
                    )}
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <InvestmentDetailsRow
                    color="grey"
                    type="text"
                    label="Immobilizzazioni finanziarie"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field name="assetsAmount">
                    {({ field }) => (
                      <InvestmentDetailsRow
                        field={field}
                        color="grey"
                        type="input-number"
                        placeholder="€ Digita importo"
                        setFieldValue={setFieldValue}
                        onBlur={event => {
                          handleBlur(event);
                        }}
                        disabled={
                          !disabledByPurposesValues.isAttiviFinanziariPurpose
                        }
                      />
                    )}
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Field name="otherAmountDescription">
                    {({ field }) => (
                      <InvestmentDetailsRow
                        field={field}
                        color="grey"
                        type="input-text"
                        fieldName="otherAmountDescription"
                        label="Altro"
                        placeholder="Altro: ..."
                        value={values.otherAmountDescription}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Field>
                </Grid.Column>
                <Grid.Column>
                  <Field name="otherAmount">
                    {({ field }) => (
                      <InvestmentDetailsRow
                        field={field}
                        color="grey"
                        type="input-number"
                        placeholder="€ Digita importo"
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <InvestmentDetailsRow
                    label="Totale programma investimento"
                    color="cream"
                    type="text"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field name="investmentTotalAmount">
                    {({ field }) => (
                      <InvestmentDetailsRow
                        field={field}
                        color="cream"
                        type="input-number"
                        fieldName="investmentTotalAmount"
                        placeholder="€ Totale"
                        readOnly
                      />
                    )}
                  </Field>
                </Grid.Column>
              </Grid.Row>
              {state.request?.requestPurpose.find(
                purpose =>
                  purpose.description === 'liquidità connessa all’investimento',
              ) && (
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Field name="relatedLiquidityAmount">
                      {({ field }) => (
                        <CurrencyInputFormik
                          field={field}
                          label={
                            <FormattedMessage
                              id="investment.relatedLiquidityAmount"
                              defaultMessage="Importo liquidità connessa"
                            />
                          }
                          setFieldValue={setFieldValue}
                          onBlur={handleBlur}
                          placeholder="€ Digita importo"
                        />
                      )}
                    </Field>
                    <Tooltip
                      header={
                        <Header as="h2">
                          <FormattedMessage
                            id="investment.connectedLiquidity"
                            defaultMessage="Liquidità connessa"
                          />
                        </Header>
                      }
                      content={
                        <Container>
                          <p>
                            Quota dell&apos;importo finanziato, comunque non
                            superiore al 40% dell’importo complessivo dello
                            stesso, destinabile a finanziare il fabbisogno di
                            liquidità del capitale circolante connesso alla
                            realizzazione del programma di investimento.
                          </p>
                        </Container>
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              {state.request?.additionalControl && (
                <>
                  {state.request.additionalControl ===
                    'VerificaScuoleECorsiDiLingua' && (
                    <Grid.Row>
                      <Grid.Column>
                        <Checkbox
                          className="label-on-top"
                          toggle
                          name="controlSchoolsAndLanguageCourses"
                          label={{
                            children: (
                              <FormattedMessage
                                id="stepInvestimenti.controlSchoolsAndLanguageCourses"
                                defaultMessage="L’attività svolta è limitata ai corsi di lingua organizzati nell’ambito dei viaggi di studi?"
                              />
                            ),
                          }}
                          onChange={(event, data) => {
                            setControlSchoolAndLanguageCourses(
                              data.checked ?? false,
                            );
                          }}
                        />
                        <ErrorMessage
                          name="controlSchoolsAndLanguageCourses"
                          component="span"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  {state.request.additionalControl ===
                    'VerificaOrganizzazioneFesteECerimonie' && (
                    <Grid.Row>
                      <Grid.Column>
                        <Checkbox
                          className="label-on-top"
                          toggle
                          name="controlCelebrationsPlanning"
                          label={{
                            children: (
                              <FormattedMessage
                                id="stepInvestimenti.controlCelebrationsPlanning"
                                defaultMessage="L’azienda è un’agenzia di animazione per il settore turistico?"
                              />
                            ),
                          }}
                          onChange={(event, data) => {
                            setControlCelebrationsPlanning(
                              data.checked ?? false,
                            );
                          }}
                        />
                        <ErrorMessage
                          name="controlSchoolsAndLanguageCourses"
                          component="span"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </>
              )}
              {(state.request?.isTourismSpecialSectionEligible ||
                state.request?.additionalControl) && (
                <Grid.Row>
                  <Grid.Column>
                    <Checkbox
                      className="label-on-top"
                      toggle
                      name="isTourismEnergyRenovation"
                      label={{
                        children: (
                          <FormattedMessage
                            id="stepInvestimenti.isTourismEnergyRenovation"
                            defaultMessage="Ai fini dell’ammissibilità alla sezione Turismo (vedi scheda Sezione turismo), l’investimento è finalizzato ad interventi di riqualificazione energetica?"
                          />
                        ),
                      }}
                      disabled={
                        !controlCelebrationsPlanning &&
                        !controlSchoolsAndLanguageCourses
                      }
                    />
                    <ErrorMessage
                      name="isTourismEnergyRenovation"
                      component="span"
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Input
                    label={
                      <FormattedMessage
                        id="investment.facilitatedByOther"
                        defaultMessage="Il programma di investimento è agevolato da altre misure?"
                      />
                    }
                    name="facilitatedByOther"
                  >
                    <Radio
                      name="facilitatedByOther"
                      checked={values.facilitatedByOther}
                      toggle
                      onChange={(_event, { checked }) => {
                        setFieldValue('facilitatedByOther', checked);
                        setFieldValue('benefits', []);
                      }}
                    />
                  </Input>
                </Grid.Column>
              </Grid.Row>
              {values.facilitatedByOther && (
                <Grid.Row>
                  <Grid.Column>
                    <div>
                      <b>Agevolazioni</b>
                    </div>
                    <Input name="benefits">
                      <ErrorMessage name="benefits" component="span" />
                    </Input>
                    <Benefit />
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </div>
          <FormikWatcher
            values={values}
            callback={updateTotal}
            errors={errors}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
          />
        </Form>
      )}
    </Formik>
  );
};
