import { AgentType } from '../types';
import { AgentAction, AgentActionConstants } from './actions';

export type AgentState = {
  isLoading: boolean;
  agentList: Array<AgentType>;
  reloadingAgents: boolean;
};

export const initialAgentState: AgentState = {
  isLoading: false,
  reloadingAgents: false,
  agentList: [],
};

export function agentReducer(
  state: AgentState,
  action: AgentAction,
): AgentState {
  const { type } = action;

  switch (type) {
    case AgentActionConstants.SET_SEARCH_FILTER:
      return { ...state, isLoading: action.payload.isLoading };
    case AgentActionConstants.SET_AGENT_DICTIONARY:
      return { ...state, agentList: action.payload.agentList };
    case AgentActionConstants.RELOAD_AGENT_LIST:
      return { ...state, reloadingAgents: action.payload.reloadAgents };
    default:
      return state;
  }
}
