import { Voucher } from 'services/voucher/types';

export enum VoucherActionConstants {
  SET_VOUCHER_LIST = 'Voucher/SET_VOUCHER_LIST',
  SET_IS_LOADING = 'Voucher/SET_IS_LOADING',
}

export type VoucherAction =
  | {
      type: VoucherActionConstants.SET_IS_LOADING;
      payload: { isLoading: boolean };
    }
  | {
      type: VoucherActionConstants.SET_VOUCHER_LIST;
      payload: { voucherList: Array<Voucher> };
    };
