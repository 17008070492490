import { PaginationData } from 'components/DataGrid/types';
import standardClient from 'services/client/standardRequestClient';
import { AxiosResponse } from 'axios';
import { API } from 'utils/global/backendRoutes';
import { PaginateObject } from 'utils/types/paginateObject';
import _ from 'lodash';
import qs from 'qs';
import { toast } from 'react-toastify';
import React from 'react';
import { AgentAction, AgentActionConstants } from 'pages/Agent/reducer/actions';
import { AgentFilterFormValues } from 'components/Forms/AgentFilterForm/types';
import { FormikHelpers } from 'formik';
import { StandardResponseError } from 'services/client/types';
import {
  AgentObjectResponse,
  AgentResponse,
  AgentType,
  AgentPayload,
} from 'pages/Agent/types';
import {
  DataGridAction,
  DataGridActionConstants,
} from 'components/DataGrid/reducer/actions';
import { AgentValue } from 'components/Forms/GenerateVoucherForm/types';

/**
 * Fetch all the agents.
 * @param inputValue
 * @param callback
 */
export const loadAgent = (
  inputValue: string,
  callback: (options: AgentValue[]) => void,
): void => {
  let params = {};
  params = {
    filter: [
      inputValue && {
        type: 'orx',
        conditions: [
          {
            type: 'like',
            field: 'name',
            value: `%${inputValue}%`,
            where: 'or',
          },
          {
            type: 'like',
            field: 'surname',
            value: `%${inputValue}%`,
            where: 'or',
          },
        ],
      },
    ].filter(f => f),
    pageSize: 10,
  };
  const orderByParams = {
    field: 'surname',
    type: 'field',
    direction: 'asc',
  };
  params['order-by'] = [orderByParams];

  const paramsSerializer = qs.stringify(params);

  standardClient({
    url: `${API.AGENT}?${paramsSerializer}`,
    method: 'GET',
  }).then((response: AxiosResponse<PaginateObject<AgentObjectResponse>>) => {
    const agents = response.data._embedded.agent;

    callback(
      agents.map(
        agent =>
          agent && {
            value: agent.id,
            label: `${agent.surname} ${agent.name} (${agent.email})`,
          },
      ),
    );
  });
};

/**
 * Gets agent list with filters applied if any.
 * @param values Filters values.
 * @param paginationData Pagination data.
 * @param dispatch Dispatch to manage callback results.
 */
export const getAgentList = (
  values: AgentFilterFormValues,
  paginationData: PaginationData,
  dataGridDispatch: React.Dispatch<DataGridAction>,
  dispatch: React.Dispatch<AgentAction>,
): void => {
  const params = {
    filter: [
      // searchText && {
      //   type: 'like',
      //   field: 'businessName',
      //   value: `%${searchText}%`,
      // },
      // customerType && {
      //   type: 'customer_instance_filter',
      //   field: 'customer_type',
      //   value: customerType,
      // },
      {
        type: 'eq',
        field: 'isDeleted',
        value: 0,
      },
    ].filter(o => o),
    pageSize: paginationData.page_size,
    page: _.get(paginationData, 'page', 1) === 0 ? 1 : paginationData.page,
  };
  const paramsSerializer = qs.stringify(params);

  dataGridDispatch({
    type: DataGridActionConstants.SET_IS_LOADING,
    payload: {
      isLoading: true,
    },
  });

  standardClient({
    url: `${API.AGENT}?${paramsSerializer}`,
    method: 'GET',
  })
    .then((response: AxiosResponse<PaginateObject<AgentObjectResponse>>) => {
      dispatch({
        type: AgentActionConstants.SET_AGENT_DICTIONARY,
        payload: {
          agentList: _.orderBy(
            _.get(response, 'data._embedded.agent', []),
            ['name'],
            ['desc'],
          ),
        },
        //payload: { agentList: response.data._embedded.agent },
      });
      setDataGridPaginator(response, dataGridDispatch);
      dataGridDispatch({
        type: DataGridActionConstants.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      });
    })
    .catch(() => {
      toast.error('Errore durante il caricamento degli agenti');
      dataGridDispatch({
        type: DataGridActionConstants.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      });
    });
};

/**
 * Get single agent by id.
 * @param idCustomer
 * @param customerType
 * @param dispatch
 */
export const getAgentById = (
  idAgent: string,
  dispatch: React.Dispatch<AgentAction>,
): void => {
  standardClient({
    url: `${API.AGENT}/${idAgent}`,
    method: 'GET',
  })
    .then((response: AxiosResponse<AgentResponse>) => {
      dispatch({
        type: AgentActionConstants.SET_AGENT,
        payload: { agent: response.data },
      });
    })
    .catch(() => {
      toast.error('Errore durante il caricamento dei dati agente');
    });
};

/**
 * Create a new agent.
 * @param values Agent field values.
 * @param formikHelpers Formik state helpers.
 */
export const createAgent = (
  values: AgentType,
  formikHelpers: FormikHelpers<AgentType>,
  dispatch: React.Dispatch<AgentAction>,
): void => {
  standardClient({
    url: `${API.AGENT}`,
    method: 'POST',
    data: { ...values },
  })
    .then(() => {
      dispatch({
        type: AgentActionConstants.RELOAD_AGENT_LIST,
        payload: { reloadAgents: true },
      });
      toast.success('Agente creato correttamente');
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      const errors = _.get(error, 'data.validation_messages', null);

      if (errors) {
        // Set the errors in the Formik bag.
        _.forOwn(errors, (value, key) => {
          for (const i in value) {
            formikHelpers.setFieldError(key, value[i]);
            break;
          }
        });
      } else {
        toast.error("Errore durante la creazione dell'agente");
      }
    });
};

/**
 * Update an existing agent.
 * @param values Agent field values.
 * @param id Agent id.
 * @param dispatch Agent reducer dispatch
 * @param formikHelpers Formik state helpers.
 */
export const updateAgent = (
  values: AgentType,
  id: string,
  dispatch: React.Dispatch<AgentAction>,
  formikHelpers: FormikHelpers<AgentType>,
): void => {
  standardClient({
    url: `${API.AGENT}/${id}`,
    method: 'PUT',
    data: { ...values },
  })
    .then(() => {
      dispatch({
        type: AgentActionConstants.RELOAD_AGENT_LIST,
        payload: { reloadAgents: true },
      });
      toast.success('Agente salvato correttamente');
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      const errors = _.get(error.data, 'validation_messages', null);

      if (formikHelpers && errors) {
        // Set the errors in the Formik bag.
        _.forOwn(errors, (value, key) => {
          for (const i in value) {
            formikHelpers.setFieldError(key, value[i]);
            break;
          }
        });
      } else {
        toast.error("Errore durante il salvataggio dell'agente");
      }
    });
};

/**
 * Patch an existing agent.
 * @param values Agent field values.
 * @param id Agent id.
 * @param dispatch Agent reducer dispatch
 */
export const patchAgent = (
  values: Partial<AgentPayload>,
  id: string,
  dispatch: React.Dispatch<AgentAction>,
): void => {
  standardClient({
    url: `${API.AGENT}/${id}`,
    method: 'PATCH',
    data: { ...values },
  })
    .then(() => {
      dispatch({
        type: AgentActionConstants.RELOAD_AGENT_LIST,
        payload: { reloadAgents: true },
      });
      toast.success('Agente aggiornato correttamente');
    })
    .catch(() => {
      toast.error("Errore durante l'aggiornamento dell'agente");
    });
};

/**
 * Delete an existing agent.
 * @param values Agent field values.
 * @param id Agent id.
 * @param formikHelpers Formik state helpers.
 */
export const getRidOfAgent = (
  id: string,
  dispatch: React.Dispatch<AgentAction>,
): void => {
  standardClient({
    url: `${API.AGENT}/${id}`,
    method: 'DELETE',
  })
    .then(() => {
      dispatch({
        type: AgentActionConstants.RELOAD_AGENT_LIST,
        payload: { reloadAgents: true },
      });
      toast.success('Agente eliminato correttamente');
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .catch((error: AxiosResponse<StandardResponseError>) => {
      toast.error("Errore durante l'eliminazione dell'agente");
    });
};

/**
 * Set paginator for DataGrid.
 * @param response
 * @param dataGridDispatch
 * @returns
 */
const setDataGridPaginator = (
  response: AxiosResponse<PaginateObject<AgentObjectResponse>>,
  dataGridDispatch: React.Dispatch<DataGridAction>,
) => {
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_PAGE_COUNT,
    payload: {
      page_count: response.data.page_count,
    },
  });
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
    payload: {
      pageNumber: response.data.page,
    },
  });
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_TOTAL_ITEMS,
    payload: {
      total_items: response.data.total_items,
    },
  });
  return dataGridDispatch;
};
