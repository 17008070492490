const relationTypes = [
  'Associata',
  'Collegata',
  'AssociataDiCollegata',
  'CollegataDiCollegata',
  'CollegataDiAssociata',
  'CollegataPersonaFisica',
];

/**
 * Gets relation type list.
 */
export const getRelationTypes = (): string[] => {
  return relationTypes;
};
