export enum GenerateVoucherActionConstants {
  SET_IS_LOADING = 'GenerateVoucher/SET_IS_LOADING',
  SET_MODAL_IS_OPEN = 'GenerateVoucher/SET_MODAL_IS_OPEN',
}

export type GenerateVoucherAction =
  | {
      type: GenerateVoucherActionConstants.SET_MODAL_IS_OPEN;
      payload: { modalOpen: boolean };
    }
  | {
      type: GenerateVoucherActionConstants.SET_IS_LOADING;
      payload: { isLoading: boolean };
    };
