import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Grid, Header, Image, Segment } from 'semantic-ui-react';

// Images
import mainLogo from 'assets/images/logo.svg';
import email from 'assets/images/email.svg';

// Form
import PasswordResetForm from '../../components/Forms/PasswordResetForm';

// Types
import { PasswordResetState } from './types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const PasswordReset: React.FC = () => {
  /**
   * That state keeps the information about the current registration process.
   * Basically it's empty until the registration process will be succesful.
   */
  const [response, setResponse] = useState<PasswordResetState>({
    sent: false,
  });

  return (
    <div className="password-reset">
      <Helmet>
        <title>Allegato4.it - login</title>
        <meta name="description" content="password recovery page" />
        <script>{"smartsupp('chat:hide')"}</script>
      </Helmet>
      {!response.sent && (
        <div className="password-reset__container--sent">
          <div className="password-reset__email-icon">
            <Image src={mainLogo} />
          </div>

          <div className="password-reset-form-container">
            <PasswordResetForm setResponse={setResponse} />
          </div>
        </div>
      )}
      {response.sent && (
        <Grid className="password-reset__container">
          <Grid.Row textAlign="center">
            <Grid.Column>
              <Header>
                <FormattedMessage
                  id="resetPassword.thank"
                  defaultMessage="Richiesta inviata"
                />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row textAlign="center">
            <Grid.Column>
              <Segment>
                <Header>
                  <Image src={email} />
                </Header>
                <FormattedMessage
                  id="resetPassword.succesful"
                  defaultMessage="La password è stata resettata correttamente."
                />
                <Segment.Inline>
                  <Button
                    type="button"
                    className="password-reset__button--submit"
                    content={
                      <Link to="/">
                        <FormattedMessage
                          id="toDashboard"
                          defaultMessage="Torna alla dashboard"
                        />
                      </Link>
                    }
                  />
                </Segment.Inline>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
};

export default PasswordReset;
