export enum DataGridActionConstants {
  SET_TABLE_PAGE_NUMBER = 'DataGrid/SET_TABLE_PAGE_NUMBER',
  SET_TABLE_PAGE_TOTAL_ITEMS = 'DataGrid/SET_TABLE_PAGE_TOTAL_ITEMS',
  SET_TABLE_PAGE_PAGE_COUNT = 'DataGrid/SET_TABLE_PAGE_PAGE_COUNT',
  SET_IS_LOADING = 'DataGrid/SET_IS_LOADING',
}

export type DataGridAction =
  | {
      type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER;
      payload: {
        pageNumber: number | string;
      };
    }
  | {
      type: DataGridActionConstants.SET_TABLE_PAGE_TOTAL_ITEMS;
      payload: {
        total_items: number;
      };
    }
  | {
      type: DataGridActionConstants.SET_TABLE_PAGE_PAGE_COUNT;
      payload: {
        page_count: number;
      };
    }
  | {
      type: DataGridActionConstants.SET_IS_LOADING;
      payload: {
        isLoading: boolean;
      };
    };
