import { ErrorMessage, Formik, FormikHelpers, getIn } from 'formik';
import { Checkbox, Form, Input } from 'formik-semantic-ui-react';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, Grid, Header } from 'semantic-ui-react';
import { updateRequest } from 'services/request';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import * as Yup from 'yup';
import { States } from 'services/stateMachine/types';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { StepClienteRiepilogoValues } from './types';
import { LegalRepresentative as LegalRepresentativeTable } from 'components/LegalRepresentative';
import { Tooltip } from 'components/Tooltip';
import moment from 'moment';
import { FormikWatcher } from 'components/FormikWatcher';
import { CompanyTypeConstants } from 'components/Forms/WebuserProfileForm/types';
import _ from 'lodash';

export const StepClienteRiepilogo: React.FC = () => {
  /**
   * The state machine.
   */
  const { state, dispatch } = useStateMachine();

  /**
   * The react-intl object.
   */
  const intl = useIntl();

  /**
   * The intial values of the form.
   */
  const initialValues = useMemo<StepClienteRiepilogoValues>(() => {
    if (state.request) {
      return {
        businessName: state.request.businessName,
        vatNumber: state.request.vatNumber,
        fiscalCode: state.request.fiscalCode,
        ateco: state.request.ateco ?? '',
        pec: state.request.pec,
        certifiedIncubator: state.request.certifiedIncubator,
        innovativeStartup: state.request.innovativeStartup,
        establishmentDate: state.request.establishmentDate,
        legalAdministrativeNotMatch:
          state.request.legalAdministrativeNotMatch || false,
        legalAddress: state.request.legalAddress ?? '',
        legalPostalCode: state.request.legalPostalCode ?? '',
        legalCity: state.request.legalCity ?? '',
        legalProvince: state.request.legalProvince ?? '',
        legalFraction: state.request?.legalFraction ?? '',
        legalNotInFraction: _.isNil(state.request.legalNotInFraction)
          ? false
          : state.request.legalNotInFraction,
        isLegalDamagedByFloods: _.isNil(state.request.isLegalDamagedByFloods)
          ? false
          : state.request.isLegalDamagedByFloods,
        administrativeAddress: state.request.administrativeAddress ?? '',
        administrativePostalCode: state.request.administrativePostalCode ?? '',
        administrativeCity: state.request.administrativeCity ?? '',
        administrativeProvince: state.request.administrativeProvince ?? '',
        administrativeFraction: state.request?.administrativeFraction ?? '',
        administrativeNotInFraction: _.isNil(
          state.request.administrativeNotInFraction,
        )
          ? false
          : state.request.administrativeNotInFraction,
        isAdministrativeDamagedByFloods: _.isNil(
          state.request.isAdministrativeDamagedByFloods,
        )
          ? false
          : state.request.isAdministrativeDamagedByFloods,
        legalRepresentatives: state.request.legalRepresentatives,
      };
    }

    return {
      businessName: '',
      vatNumber: '',
      fiscalCode: '',
      ateco: '',
      pec: '',
      certifiedIncubator: false,
      innovativeStartup: false,
      establishmentDate: '',
      legalAdministrativeNotMatch: false,
      legalAddress: '',
      legalPostalCode: '',
      legalCity: '',
      legalProvince: '',
      legalNotInFraction: false,
      legalFraction: '',
      isLegalDamagedByFloods: false,
      administrativeAddress: '',
      administrativePostalCode: '',
      administrativeCity: '',
      administrativeProvince: '',
      administrativeFraction: '',
      administrativeNotInFraction: false,
      isAdministrativeDamagedByFloods: false,
      legalRepresentatives: [],
    };
  }, [state.request]);

  /**
   * Go to the next state.
   * @param values The form values
   * @param formikHelpers The Formik helpers
   */
  const onSubmit = (
    values: StepClienteRiepilogoValues,
    formikHelpers: FormikHelpers<StepClienteRiepilogoValues>,
  ) => {
    const payload = { ...values };

    /**
     * Administrative fields have got the same values of the legal ones if the checkbox is not checked.
     */
    if (values.legalAdministrativeNotMatch === false) {
      payload.administrativeAddress = values.legalAddress;
      payload.administrativePostalCode = values.legalPostalCode;
      payload.administrativeCity = values.legalCity;
      payload.administrativeProvince = values.legalProvince;
      payload.administrativeFraction = values.legalFraction;
      if (payload.isAdministrativeDamagedByFloods)
        payload.isAdministrativeDamagedByFloods = false;
    }

    /**
     * After the step Cliente_IdentificativoAzienda the VAT number && fiscalCode cannot be changed.
     * Unmapped fields in BE returns a 422 errors when trying to update them, so the field has to be removed from the payload of the update request.
     */
    delete payload.vatNumber;
    delete payload.fiscalCode;

    /**
     * Update the request to the state "Finanziamento".
     */
    updateRequest(
      {
        ...payload,
        id: state.request?.id,
        state:
          state.request?.companyType === CompanyTypeConstants.FREELANCE ||
          state.request?.companyType === CompanyTypeConstants.PROFESSIONALFIRM
            ? States.ClienteCassaProfessionale
            : States.ClienteRiepilogo_Finanziamento,
      },
      dispatch,
      formikHelpers,
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        businessName: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.businessName',
              defaultMessage: 'Ragione sociale',
            }),
          )
          .nullable()
          .required(),
        ateco: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.businessName',
              defaultMessage: 'ATECO',
            }),
          )
          .required()
          .nullable()
          .length(6),
        pec: Yup.string()
          .email()
          .required()
          .label(
            intl.formatMessage({
              id: 'profile.PEC',
              defaultMessage: 'PEC',
            }),
          )
          .typeError('PEC deve essere un indirizzo e-mail valido'),
        certifiedIncubator: Yup.boolean().required(),
        innovativeStartup: Yup.boolean().required(),
        establishmentDate: Yup.date()
          .label(
            intl.formatMessage({
              id: 'request.establishmentDate',
              defaultMessage: 'Data costituzione',
            }),
          )
          .required()
          .nullable()
          .transform(v => (moment(v).isValid() ? v : null))
          .max(
            moment().set({ hours: 0, minute: 0, second: 0 }),
            intl.formatMessage({
              id: 'error.date.max.today',
              defaultMessage:
                'Data costituzione deve essere precedente ad oggi',
            }),
          ),
        legalAdministrativeNotMatch: Yup.boolean().nullable(),
        legalAddress: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.legalAddress',
              defaultMessage: 'Indirizzo sede legale',
            }),
          )
          .nullable()
          .required(),
        legalPostalCode: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.legalPostalCode',
              defaultMessage: 'CAP',
            }),
          )
          .nullable()
          .required(),
        legalCity: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.legalCity',
              defaultMessage: 'Città',
            }),
          )
          .nullable()
          .required(),
        legalProvince: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.legalProvince',
              defaultMessage: 'Provincia',
            }),
          )
          .min(2)
          .max(4)
          .nullable()
          .required(),
        legalFraction: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.fraction',
              defaultMessage: 'Frazione',
            }),
          )
          .when(['isLegalDamagedByFloods', 'legalNotInFraction'], {
            is: (match: boolean, disable: boolean) =>
              match === true && !disable,
            then: Yup.string().required(
              "La frazione è obbligatoria se l'indirizzo di questa sede rientra nei comuni colpiti dall'alluvione",
            ),
          }),
        administrativeAddress: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.administrativeAddress',
              defaultMessage: 'Indirizzo sede operativa',
            }),
          )
          .nullable()
          .when('legalAdministrativeNotMatch', {
            is: (match: boolean) => match === true,
            then: Yup.string().required().nullable(),
          }),
        administrativePostalCode: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.administrativePostalCode',
              defaultMessage: 'CAP',
            }),
          )
          .nullable()
          .when('legalAdministrativeNotMatch', {
            is: (match: boolean) => match === true,
            then: Yup.string().required().nullable(),
          }),
        administrativeCity: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.administrativeCity',
              defaultMessage: 'Città',
            }),
          )
          .nullable()
          .when('legalAdministrativeNotMatch', {
            is: (match: boolean) => match === true,
            then: Yup.string().required().nullable(),
          }),
        administrativeProvince: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.administrativeProvince',
              defaultMessage: 'Provincia',
            }),
          )
          .nullable()
          .when('legalAdministrativeNotMatch', {
            is: (match: boolean) => match === true,
            then: Yup.string().required().min(2).max(4).nullable(),
          }),
        administrativeFraction: Yup.string()
          .label(
            intl.formatMessage({
              id: 'request.fraction',
              defaultMessage: 'Frazione',
            }),
          )
          .when(
            ['isAdministrativeDamagedByFloods', 'administrativeNotInFraction'],
            {
              is: (match: boolean, disable: boolean) =>
                match === true && !disable,
              then: Yup.string().required(
                "La frazione è obbligatoria se l'indirizzo di questa sede rientra nei comuni colpiti dall'alluvione",
              ),
            },
          ),
        legalRepresentatives: Yup.array()
          .of(
            Yup.object().shape({
              firstName: Yup.string().required().nullable(),
              lastName: Yup.string().required().nullable(),
              birthCity: Yup.string().required().nullable(),
              birthDate: Yup.date().required(),
              isSelected: Yup.boolean(),
            }),
          )
          .compact(v => !v.isSelected)
          .label(
            intl.formatMessage({
              id: 'request.legalRepresentatives',
              defaultMessage: 'esponenti',
            }),
          )
          .required()
          .min(
            1,
            intl.formatMessage({
              id: 'request.minLegalRepresentatives',
              defaultMessage:
                'Devi selezionare almeno un legale rappresentante',
            }),
          ),
      })}
    >
      {({
        values,
        handleSubmit,
        errors,
        setFieldValue,
        isSubmitting,
        setFieldTouched,
      }) => (
        <Form id={`form__${state.current}`} onSubmit={handleSubmit}>
          <Grid padded>
            {/**
             * Company information
             */}
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">
                  <FormattedMessage
                    id="request.companyData"
                    defaultMessage="Dati società"
                  />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={2}>
              <Grid.Column>
                <Input
                  label={
                    <label>
                      <FormattedMessage
                        id="request.businessName"
                        defaultMessage="Ragione sociale"
                      />
                      *
                    </label>
                  }
                  name="businessName"
                  type="text"
                />
                <ErrorMessage name="businessName" component="span" />
              </Grid.Column>
              <Grid.Column>
                {state.request?.companyType ===
                CompanyTypeConstants.FREELANCE ? (
                  <>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.fiscalCode"
                            defaultMessage="Codice fiscale"
                          />
                          *
                        </label>
                      }
                      disabled
                      name="fiscalCode"
                      type="text"
                    />
                    <ErrorMessage name="fiscalCode" component="span" />
                  </>
                ) : (
                  <>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.fiscalCodeVATNo"
                            defaultMessage="P.IVA"
                          />
                          *
                        </label>
                      }
                      disabled
                      name="vatNumber"
                      type="text"
                    />
                    <ErrorMessage name="vatNumber" component="span" />
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={2}>
              <Grid.Column>
                <Input
                  label={
                    <label>
                      <FormattedMessage
                        id="request.ateco"
                        defaultMessage="ATECO"
                      />
                      *
                    </label>
                  }
                  name="ateco"
                  type="text"
                />
                <ErrorMessage name="ateco" component="span" />
              </Grid.Column>
              <Grid.Column>
                <Input
                  label={
                    <label>
                      <FormattedMessage
                        id="request.establishmentDate"
                        defaultMessage="Data costituzione"
                      />
                      *
                    </label>
                  }
                  name="establishmentDate"
                >
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      error={false}
                      helperText={null}
                      clearable
                      disableToolbar
                      format="DD/MM/YYYY"
                      autoOk
                      onChange={value =>
                        setFieldValue('establishmentDate', value)
                      }
                      InputProps={{
                        disableUnderline: true,
                      }}
                      value={values.establishmentDate}
                    />
                  </MuiPickersUtilsProvider>
                </Input>
                <ErrorMessage name="establishmentDate" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Input
                  label={
                    <label>
                      <FormattedMessage id="profile.PEC" defaultMessage="PEC" />
                      *
                    </label>
                  }
                  name="pec"
                  type="text"
                />
                <ErrorMessage name="pec" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={2}>
              <Grid.Column>
                <Checkbox
                  label={
                    <label className="tooltip">
                      <FormattedMessage
                        id="request.certifiedIncubator"
                        defaultMessage="Incubatore certificato"
                      />
                      <Tooltip
                        header={
                          <Header as="h2">
                            <FormattedMessage
                              id="request.certifiedIncubator"
                              defaultMessage="Incubatore certificato"
                            />
                          </Header>
                        }
                        content={
                          <Container>
                            <p>
                              Società iscritta nella sezione speciale del
                              registro delle imprese ai sensi dell’all’articolo
                              25, comma 8, del decreto-legge n. 179/2012 -{' '}
                              <a
                                href="https://startup.registroimprese.it/isin/static/startup/index.html?slidejump=33"
                                target="_blank"
                                rel="noreferrer"
                              >
                                https://startup.registroimprese.it/isin/static/startup/index.html?slidejump=33
                              </a>
                            </p>
                          </Container>
                        }
                      />
                    </label>
                  }
                  name="certifiedIncubator"
                />
                <ErrorMessage name="certifiedIncubator" component="span" />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  label={
                    <label className="tooltip">
                      <FormattedMessage
                        id="request.innovativeStartup"
                        defaultMessage="Startup innovativa"
                      />
                      <Tooltip
                        header={
                          <Header as="h2">
                            <FormattedMessage
                              id="request.innovativeStartup"
                              defaultMessage="Startup innovativa"
                            />
                          </Header>
                        }
                        content={
                          <Container>
                            <Header as="h3">Start-Up Innovativa</Header>
                            <p>
                              Società iscritta nella sezione speciale del
                              registro delle imprese ai sensi
                              dell&apos;all&apos;articolo 25, comma 8, del
                              decreto-legge n. 179/2012
                            </p>
                            <Header as="h3">Start-Up</Header>
                            <p>
                              Società costituita o che ha iniziato la propria
                              attività da non più di tre anni dalla richiesta
                              della garanzia al fondo e non ha due bilanci
                              approvati/depositati tra di loro valutabili.
                            </p>
                          </Container>
                        }
                      />
                    </label>
                  }
                  name="innovativeStartup"
                />
                <ErrorMessage name="innovativeStartup" component="span" />
              </Grid.Column>
            </Grid.Row>
            {/**
             * Legal information
             */}
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">
                  <FormattedMessage
                    id="request.legalData"
                    defaultMessage="Informazioni sede legale"
                  />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={2}>
              <Grid.Column width={4}>
                <Input
                  label={
                    <label>
                      <FormattedMessage
                        id="request.legalProvince"
                        defaultMessage="Provincia"
                      />
                      *
                    </label>
                  }
                  name="legalProvince"
                  type="text"
                />
                <ErrorMessage name="legalProvince" component="span" />
              </Grid.Column>
              <Grid.Column width={12}>
                <Input
                  label={
                    <label>
                      <FormattedMessage
                        id="request.legalCity"
                        defaultMessage="Città"
                      />
                      *
                    </label>
                  }
                  name="legalCity"
                  type="text"
                />
                <ErrorMessage name="legalCity" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={2}>
              <Grid.Column width={12}>
                <Input
                  label={
                    <label>
                      <FormattedMessage
                        id="request.legalAddress"
                        defaultMessage="Indirizzo sede legale"
                      />
                      *
                    </label>
                  }
                  name="legalAddress"
                  type="text"
                />
                <ErrorMessage name="legalAddress" component="span" />
              </Grid.Column>
              <Grid.Column width={4}>
                <Input
                  label={
                    <label>
                      <FormattedMessage
                        id="request.legalPostalCode"
                        defaultMessage="CAP"
                      />
                      *
                    </label>
                  }
                  name="legalPostalCode"
                  type="text"
                />
                <ErrorMessage name="legalPostalCode" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={2}>
              <Grid.Column width={12}>
                <Input
                  label={
                    <label>
                      <FormattedMessage
                        id="request.fraction"
                        defaultMessage="Frazione"
                      />
                      {values.isLegalDamagedByFloods &&
                        !values.legalNotInFraction && <>*</>}
                    </label>
                  }
                  name="legalFraction"
                  type="text"
                  disabled={values.legalNotInFraction}
                />
                <ErrorMessage name="legalFraction" component="span" />
              </Grid.Column>
              <Grid.Column width={4} className="fraction-flag">
                <Checkbox
                  label={
                    <label>
                      <FormattedMessage
                        id="request.notInFraction"
                        defaultMessage="La sede non rientra all'interno di una frazione"
                      />
                    </label>
                  }
                  name="legalNotInFraction"
                  onChange={(e, d) => {
                    if (d.checked && !_.isEmpty(values.legalFraction)) {
                      setFieldTouched('legalFraction', false);
                      setFieldValue('legalFraction', '');
                    }
                  }}
                />
                <ErrorMessage
                  name="legalNotInFraction"
                  component="span"
                  className="error"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={1}>
              <Grid.Column width={16} className="flooding-flag">
                <Checkbox
                  label={
                    <label>
                      <FormattedMessage
                        id="request.isLegalDamagedByFloods"
                        defaultMessage="La sede legale, oggetto di questa operazione, ha subito danni a causa dell'alluvione e l'indirizzo rientra all'interno di uno dei comuni colpiti riportati in gazzetta ufficiale a "
                      />
                      <a
                        href="https://www.gazzettaufficiale.it/atto/serie_generale/caricaDettaglioAtto/originario?atto.dataPubblicazioneGazzetta=2023-06-01&atto.codiceRedazionale=23G00074&elenco30giorni=false"
                        target="_blank"
                        rel="noreferrer"
                      >
                        questo link
                      </a>
                    </label>
                  }
                  name="isLegalDamagedByFloods"
                  onChange={(e, d) => {
                    if (d.checked && values.isAdministrativeDamagedByFloods)
                      setFieldValue('isAdministrativeDamagedByFloods', false);
                  }}
                />
                <ErrorMessage
                  name="isLegalDamagedByFloods"
                  component="span"
                  className="error"
                />
              </Grid.Column>
            </Grid.Row>
            {/**
             * Administrative information
             */}
            <Grid.Row>
              <Grid.Column>
                <Checkbox
                  label={
                    <label>
                      <FormattedMessage
                        id="request.legalAdministrativeNotMatch"
                        defaultMessage="La sede legale è diversa da quella operativa"
                      />
                    </label>
                  }
                  name="legalAdministrativeNotMatch"
                />
                <ErrorMessage
                  name="legalAdministrativeNotMatch"
                  component="span"
                />
              </Grid.Column>
            </Grid.Row>
            {values.legalAdministrativeNotMatch && (
              <>
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h3">
                      <FormattedMessage
                        id="request.administrativeData"
                        defaultMessage="Informazioni sede operativa"
                      />
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={2}>
                  <Grid.Column width={4}>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.administrativeProvince"
                            defaultMessage="Provincia"
                          />
                          *
                        </label>
                      }
                      name="administrativeProvince"
                      type="text"
                    />
                    <ErrorMessage
                      name="administrativeProvince"
                      component="span"
                    />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.administrativeCity"
                            defaultMessage="Città"
                          />
                          *
                        </label>
                      }
                      name="administrativeCity"
                      type="text"
                    />
                    <ErrorMessage name="administrativeCity" component="span" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={2}>
                  <Grid.Column width={12}>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.administrativeAddress"
                            defaultMessage="Indirizzo sede operativa"
                          />
                          *
                        </label>
                      }
                      name="administrativeAddress"
                      type="text"
                    />
                    <ErrorMessage
                      name="administrativeAddress"
                      component="span"
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.administrativePostalCode"
                            defaultMessage="CAP"
                          />
                          *
                        </label>
                      }
                      name="administrativePostalCode"
                      type="text"
                    />
                    <ErrorMessage
                      name="administrativePostalCode"
                      component="span"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={2}>
                  <Grid.Column width={12}>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.fraction"
                            defaultMessage="Frazione"
                          />
                          {values.isAdministrativeDamagedByFloods &&
                            !values.administrativeNotInFraction && <>*</>}
                        </label>
                      }
                      name="administrativeFraction"
                      type="text"
                      disabled={values.administrativeNotInFraction}
                    />
                    <ErrorMessage
                      name="administrativeFraction"
                      component="span"
                    />
                  </Grid.Column>
                  <Grid.Column width={4} className="fraction-flag">
                    <Checkbox
                      label={
                        <label>
                          <FormattedMessage
                            id="request.notInFraction"
                            defaultMessage="La sede non rientra all'interno di una frazione"
                          />
                        </label>
                      }
                      name="administrativeNotInFraction"
                      onChange={(e, d) => {
                        if (
                          d.checked &&
                          !_.isEmpty(values.administrativeFraction)
                        ) {
                          setFieldTouched('administrativeFraction', false);
                          setFieldValue('administrativeFraction', '');
                        }
                      }}
                    />
                    <ErrorMessage
                      name="isAdministrativeNotInFraction"
                      component="span"
                      className="error"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={1}>
                  <Grid.Column width={16} className="flooding-flag">
                    <Checkbox
                      label={
                        <label>
                          <FormattedMessage
                            id="request.isAdministrativeDamagedByFloods"
                            defaultMessage="La sede operativa, oggetto di questa operazione, ha subito danni a causa dell'alluvione e l'indirizzo rientra all'interno di uno dei comuni colpiti riportati in gazzetta ufficiale a "
                          />
                          <a
                            href="https://www.gazzettaufficiale.it/atto/serie_generale/caricaDettaglioAtto/originario?atto.dataPubblicazioneGazzetta=2023-06-01&atto.codiceRedazionale=23G00074&elenco30giorni=false"
                            target="_blank"
                            rel="noreferrer"
                          >
                            questo link
                          </a>
                        </label>
                      }
                      name="isAdministrativeDamagedByFloods"
                      onChange={(e, d) => {
                        if (d.checked && values.isLegalDamagedByFloods)
                          setFieldValue('isLegalDamagedByFloods', false);
                      }}
                    />
                    <ErrorMessage
                      name="isAdministrativeDamagedByFloods"
                      component="span"
                      className="error"
                    />
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
            {/**
             * Legal representatives
             */}
            <Grid.Row>
              <Grid.Column>
                <span className="tooltip tooltip--right">
                  <span>
                    <Header as="h3">
                      <FormattedMessage
                        id="request.legalRepresentativeTitle"
                        defaultMessage="Esponenti"
                      />
                    </Header>
                  </span>
                  <Tooltip
                    header={
                      <Header as="h2">
                        <FormattedMessage
                          id="request.legalRepresentativeTitle"
                          defaultMessage="Esponenti"
                        />
                      </Header>
                    }
                    content={
                      <Container>
                        <Header as="h3">
                          Chi è il legale rappresentante di un’azienda?
                        </Header>
                        <p>
                          Il <strong>legale rappresentante</strong> svolge la
                          funzione di rappresentare l&apos;impresa e di compiere
                          tutti quelli atti che sono previsti nell&apos;oggetto
                          sociale.
                        </p>

                        <Header as="h3">
                          Dove trovo i legali rappresentanti di un’azienda?
                        </Header>
                        <p>
                          I nomi degli amministratori di una società, di persone
                          o di capitali, sono disponibili all’interno della{' '}
                          <strong>visura amministratori</strong>, depositata
                          negli archivi della Camera di Commercio o C.C.I.A.A..
                        </p>

                        <Header as="h3">
                          Chi è il legale rappresentante in una Srl?
                        </Header>
                        <p>
                          Il <strong>legale rappresentante</strong> di una
                          s.r.l. è il socio amministratore unico o il presidente
                          del Consiglio di amministrazione, salvo che lo statuto
                          sociale disponga diversamente.
                        </p>

                        <Header as="h3">
                          Sono un libero professionista, devo specificare un
                          legale rappresentante?
                        </Header>
                        <p>
                          Sì, nel caso di libero professionista, le informazioni
                          del legale rappresentante coincidono con i dati
                          anagrafici del professionista stesso.
                        </p>
                      </Container>
                    }
                  />
                </span>
              </Grid.Column>
            </Grid.Row>
            {getIn(errors, 'legalRepresentatives') && (
              <Grid.Row>
                <Grid.Column>
                  <span className="error error-all">
                    {getIn(errors, 'legalRepresentatives')}
                  </span>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column>
                <LegalRepresentativeTable />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <FormikWatcher
            values={values}
            errors={errors}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};
