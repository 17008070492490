import React, { BaseSyntheticEvent, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  Formik,
  useFormikContext,
  getIn,
} from 'formik';
import {
  Button,
  Checkbox,
  CheckboxProps,
  Grid,
  Header,
  Modal,
} from 'semantic-ui-react';
import * as Yup from 'yup';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import DataGrid from 'components/DataGrid';
import { StepClienteRiepilogoValues } from 'components/RequestStep/StepClienteRiepilogo/types';
import { LegalRepresentativeModalProps } from './types';
import { LegalRepresentative as LegalRepresentativeEntity } from 'services/legalRepresentative/types';

/**
 * The legal representative table used in the step Cliente_Riepilogo.
 * This component manages the legal representatives array and push its changes in the main form
 * property. The legal prepresentatives will be used in the Cliente_Riepilogo as an array property.
 * @returns
 */
export const LegalRepresentative: React.FC = () => {
  /**  The Formik values from the main form. */
  const { values, setFieldValue } =
    useFormikContext<StepClienteRiepilogoValues>();

  /** Value for parent checkbox of table */
  const [allSelected, setAllSelected] = useState<boolean>(() => {
    let boolean = true;

    for (const legal of values.legalRepresentatives) {
      if (legal.isSelected != true) {
        boolean = false;
        break;
      }
    }

    return boolean;
  });

  /** The react-intl object. */
  const intl = useIntl();

  /**
   * The modal properties.
   * It stores the current legal representative index (optionally, if the modal is open and it's linked to a valid legal repr.)
   * and the open/close flag.
   */
  const [modal, setModal] = useState<LegalRepresentativeModalProps>({
    open: false,
  });

  /**
   * Open the modal to edit the selected legal representative.
   * @param data the selected row
   */
  const onEdit = data => {
    // Get the index of the the selected row.
    const newIndex = values.legalRepresentatives.indexOf(data);

    // Open the modal.
    setModal({
      open: true,
      index: newIndex,
    });
  };

  /**
   * This method performs two operations:
   * 1. Update the data of the selected legal representative
   * 2. Add a new legal representative
   *
   * @param arrayHelpers the utilities for the array property
   * @param values the formik values
   */
  const onSubmit = (arrayHelpers: FieldArrayRenderProps, values) => {
    // Check if the modal is open for a specific legal representative.
    if (modal.index != null) {
      arrayHelpers.replace(modal.index, values);
    } else {
      values.isSelected = true;
      arrayHelpers.push({
        id: uuidv4(),
        ...values,
      });
    }

    setModal({ open: false });
  };

  const initialValues = useMemo<LegalRepresentativeEntity>(() => {
    // If the modal is in "edit mode" use the value of the selected legal representative.
    if (modal.index != null) {
      return values.legalRepresentatives[modal.index];
    }

    // Otherwise create an empty object.
    return {
      firstName: '',
      lastName: '',
      birthCity: '',
      birthDate: null,
      isSelected: false,
    };
  }, [modal.index]);

  return (
    <>
      <FieldArray
        name="legalRepresentatives"
        render={arrayHelpers => (
          <>
            <Modal
              closeIcon
              open={modal.open}
              onClose={() => setModal({ open: false })}
            >
              <Modal.Content>
                {modal.open && (
                  <Formik
                    initialValues={initialValues}
                    onSubmit={values => onSubmit(arrayHelpers, values)}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string()
                        .label(
                          intl.formatMessage({
                            id: 'legalRepresentantive.firstName',
                            defaultMessage: 'Nome',
                          }),
                        )
                        .required()
                        .nullable(),
                      lastName: Yup.string()
                        .label(
                          intl.formatMessage({
                            id: 'legalRepresentantive.lastName',
                            defaultMessage: 'Cognome',
                          }),
                        )
                        .required()
                        .nullable(),
                      birthCity: Yup.string()
                        .label(
                          intl.formatMessage({
                            id: 'legalRepresentantive.birthCity',
                            defaultMessage: 'Città di nascita',
                          }),
                        )
                        .required()
                        .nullable(),
                      birthDate: Yup.mixed()
                        .label(
                          intl.formatMessage({
                            id: 'legalRepresentantive.birthDate',
                            defaultMessage: 'Data di nascita',
                          }),
                        )
                        .required()
                        .test(
                          'beforeToday',
                          `Il valore deve essere antecedente a ${moment().format(
                            'DD/MM/YYYY',
                          )}`,
                          value => {
                            const formattedValue = value
                              ? value.isValid
                                ? value.format('YYYY/MM/DD')
                                : moment(value)
                              : null;
                            const maxDate = moment().format('YYYY/MM/DD');
                            if (formattedValue) {
                              return moment(maxDate).isAfter(formattedValue);
                            }
                            return true;
                          },
                        )
                        .nullable(),
                    })}
                  >
                    {({ values, setFieldValue, errors, touched }) => (
                      <Form id="legal-representative">
                        <Grid>
                          <Grid.Row>
                            <Grid.Column>
                              <Header as="h3">
                                <FormattedMessage
                                  id="legalRepresentative.title"
                                  defaultMessage="Dati legale rappresentante"
                                />
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row centered columns={2}>
                            <Grid.Column>
                              <Input
                                label={
                                  <label>
                                    <FormattedMessage
                                      id="legalRepresentantive.firstName"
                                      defaultMessage="Nome"
                                    />
                                    *
                                  </label>
                                }
                                name="firstName"
                                type="text"
                                onChange={(_event, { value }) =>
                                  setFieldValue(
                                    'firstName',
                                    value.toLocaleUpperCase(),
                                  )
                                }
                              />
                              <ErrorMessage name="firstName" component="span" />
                            </Grid.Column>
                            <Grid.Column>
                              <Input
                                label={
                                  <label>
                                    <FormattedMessage
                                      id="legalRepresentantive.lastName"
                                      defaultMessage="Cognome"
                                    />
                                    *
                                  </label>
                                }
                                name="lastName"
                                type="text"
                                onChange={(_event, { value }) =>
                                  setFieldValue(
                                    'lastName',
                                    value.toLocaleUpperCase(),
                                  )
                                }
                              />
                              <ErrorMessage name="lastName" component="span" />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row centered columns={2}>
                            <Grid.Column>
                              <Input
                                label={
                                  <label>
                                    <FormattedMessage
                                      id="legalRepresentantive.birthCity"
                                      defaultMessage="Città di nascita"
                                    />
                                    *
                                  </label>
                                }
                                name="birthCity"
                                type="text"
                              />
                              <ErrorMessage name="birthCity" component="span" />
                            </Grid.Column>
                            <Grid.Column>
                              <Input
                                label={
                                  <label
                                    className={
                                      getIn(errors, 'birthDate') &&
                                      getIn(touched, 'birthDate')
                                        ? 'error'
                                        : undefined
                                    }
                                  >
                                    <FormattedMessage
                                      id="legalRepresentative.birthDate"
                                      defaultMessage="Data di nascita"
                                    />
                                    *
                                  </label>
                                }
                                name="birthDate"
                                error={
                                  getIn(errors, 'birthDate') &&
                                  getIn(touched, 'birthDate')
                                    ? true
                                    : false
                                }
                              >
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <KeyboardDatePicker
                                    error={false}
                                    helperText={null}
                                    clearable
                                    disableToolbar
                                    format="DD/MM/YYYY"
                                    autoOk
                                    onChange={value =>
                                      setFieldValue('birthDate', value)
                                    }
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    value={values.birthDate}
                                  />
                                </MuiPickersUtilsProvider>
                              </Input>
                              {getIn(errors, 'birthDate') &&
                                getIn(touched, 'birthDate') && (
                                  <span className="error">
                                    {getIn(errors, 'birthDate')}
                                  </span>
                                )}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                )}
              </Modal.Content>
              <Modal.Actions>
                <SubmitButton form="legal-representative">
                  {modal.index == null && (
                    <FormattedMessage
                      id="legalRepresentative.add"
                      defaultMessage="Aggiungi"
                    />
                  )}
                  {modal.index != null && (
                    <FormattedMessage
                      id="legalRepresentative.update"
                      defaultMessage="Aggiorna"
                    />
                  )}
                </SubmitButton>
              </Modal.Actions>
            </Modal>
            {/* The legal representative table */}
            <DataGrid
              isLoading={false}
              paginate={false}
              page={1}
              pageCount={1}
              pageSize={10}
              onPageSelect={() => null}
              totalItems={10}
              elements={values.legalRepresentatives.sort((a, b) =>
                a.firstName > b.firstName
                  ? 1
                  : b.firstName > a.firstName
                  ? -1
                  : 0,
              )}
              columns={[
                {
                  key: 'checkboxControlli',
                  name: (
                    <Checkbox
                      label="Tutti"
                      onChange={(
                        _event: BaseSyntheticEvent,
                        { checked }: CheckboxProps,
                      ) => {
                        values.legalRepresentatives.map(legal => {
                          legal.isSelected = checked || false;
                        });

                        setAllSelected(checked || false);

                        setFieldValue(
                          'legalRepresentatives',
                          values.legalRepresentatives,
                        );
                      }}
                      checked={allSelected || false}
                    />
                  ),
                  formatter: ({ data }) => (
                    <Checkbox
                      checked={data.isSelected || false}
                      onChange={(
                        _event: BaseSyntheticEvent,
                        { checked }: CheckboxProps,
                      ) => {
                        // Find the right legalRepresentative index
                        const currentLegalIndex =
                          values.legalRepresentatives.findIndex(
                            (item: LegalRepresentativeEntity) =>
                              item.id === data.id,
                          );

                        // If index is equal to -1 return
                        if (currentLegalIndex === -1) {
                          return;
                        }

                        // Update property isSelected of current legalRepresentative
                        values.legalRepresentatives[
                          currentLegalIndex
                        ].isSelected = checked || false;

                        // Update the parent checkbox if there is an item not checked
                        if (allSelected) {
                          for (const legal of values.legalRepresentatives) {
                            if (legal.isSelected != true) {
                              setAllSelected(false);
                              break;
                            }
                          }
                        } else {
                          // Update the parent checkbox if all item are checked
                          let flag = true;
                          for (const legal of values.legalRepresentatives) {
                            if (legal.isSelected != true) {
                              flag = false;
                              break;
                            }
                          }

                          if (flag === true) {
                            setAllSelected(true);
                          }
                        }

                        // Update values of form
                        setFieldValue(
                          'legalRepresentatives',
                          values.legalRepresentatives,
                        );
                      }}
                    />
                  ),
                },
                {
                  key: 'firstName',
                  name: (
                    <FormattedMessage
                      id="legalRepresentative.firstName"
                      defaultMessage="Nome"
                    />
                  ),
                  formatter: ({ data }) => (
                    <span style={{ textTransform: 'uppercase' }}>
                      {data.firstName}
                    </span>
                  ),
                },
                {
                  key: 'lastName',
                  name: (
                    <FormattedMessage
                      id="legalRepresentative.lastName"
                      defaultMessage="Cognome"
                    />
                  ),
                  formatter: ({ data }) => (
                    <span style={{ textTransform: 'uppercase' }}>
                      {data.lastName}
                    </span>
                  ),
                },
                {
                  key: 'birthCity',
                  name: (
                    <FormattedMessage
                      id="legalRepresentative.birthCity"
                      defaultMessage="Luogo di nascita"
                    />
                  ),
                },
                {
                  key: 'birthDate',
                  name: (
                    <FormattedMessage
                      id="legalRepresentative.birthDate"
                      defaultMessage="Data di nascita"
                    />
                  ),
                  formatter: ({ data }) =>
                    moment(data.birthDate).format('DD/MM/YYYY'),
                },
                {
                  key: 'actions',
                  name: '',
                  formatter: ({ data }) => {
                    return (
                      <>
                        <Button
                          type="button"
                          onClick={() => onEdit(data)}
                          className="button--primary"
                        >
                          <FormattedMessage
                            id="request.editLegalRepresentative"
                            defaultMessage="Modifica"
                          />
                        </Button>
                      </>
                    );
                  },
                },
              ]}
            />
            <Grid>
              <Grid.Row textAlign="center">
                <Grid.Column>
                  <Button
                    type="button"
                    onClick={() => {
                      setModal({
                        open: true,
                      });
                    }}
                  >
                    <FormattedMessage
                      id="request.addNewLegalRepresentative"
                      defaultMessage="Aggiungi legale rappresentante"
                    />
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      />
    </>
  );
};
