import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Tab } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

import { AppContext } from 'pages/App';

// Form
import LoginForm from 'components/Forms/LoginForm';
import { LoginFormValues } from 'components/Forms/LoginForm/types';
import { userLogin } from 'services/auth/userLogin';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const Login: React.FC = () => {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  const onSubmit = (values: LoginFormValues) => {
    userLogin(dispatch, values, 'password', navigate);
  };

  return (
    <div>
      <Helmet>
        <title>Allegato4.it - Accesso</title>
        <meta name="description" content="login page" />
        <script>{"smartsupp('chat:hide')"}</script>
      </Helmet>
      <div className="login">
        <Grid className="login__container">
          <Grid.Row className="access__form">
            <Tab
              renderActiveOnly={false}
              activeIndex={0}
              panes={[
                {
                  menuItem: {
                    as: Link,
                    id: 'login',
                    content: (
                      <FormattedMessage
                        id="link.login"
                        defaultMessage="Accedi"
                      />
                    ),
                    to: '/login',
                    key: 'login',
                  },
                  pane: (
                    <Tab.Pane key="login">
                      <LoginForm onSubmit={onSubmit} />
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: {
                    as: Link,
                    id: 'register',
                    content: (
                      <FormattedMessage
                        id="link.register"
                        defaultMessage="Registrati"
                      />
                    ),
                    to: '/register',
                    key: 'register',
                  },
                },
              ]}
            />
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default Login;
