import React, { useContext } from 'react';
import { StepBilancio } from 'components/RequestStep/StepBilancio';
import { StepFinalita } from 'components/RequestStep/StepFinalita';
import { StepInvestimento } from 'components/RequestStep/StepInvestimento';
import { StepPiano } from 'components/RequestStep/StepPiano';
import { StepVerifica } from 'components/RequestStep/StepVerifica';
import { Helmet } from 'react-helmet';
import { Button, Step } from 'semantic-ui-react';
import { States } from 'services/stateMachine/types';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { FormattedMessage } from 'react-intl';
import { StepClienteRichiestaFallita } from 'components/RequestStep/StepClienteRichiestaFallita';
import { StepClienteRichiestaPending } from 'components/RequestStep/StepClienteRichiestaPending';
import { StepClienteIdentificativoAzienda } from 'components/RequestStep/StepClienteIdentificativoAzienda';
import { StepClienteRiepilogo } from 'components/RequestStep/StepClienteRiepilogo';
import { StepClienteSceltaCliente } from 'components/RequestStep/StepClienteSceltaCliente';
import { StepFinanziamento } from 'components/RequestStep/StepFinanziamento';
import { StepDichiarazioni } from 'components/RequestStep/StepDichiarazioni';
import { StepBilancioCalcoloDimensionaleAzienda } from 'components/RequestStep/StepBilancioCalcoloDimensionaleAzienda';
import { updateRequest } from 'services/request';
import { StepPagamentoPending } from 'components/RequestStep/StepPagamentoPending';
import { AppContext } from 'pages/App';
import _ from 'lodash';
import { StepPagamento } from 'components/RequestStep/StepPagamento';
import { StepPrePagamento } from 'components/RequestStep/StepPrePagamento';
import { StepRegimi } from 'components/RequestStep/StepRegimi';
import { StepDichiarazioniDeMinimis } from 'components/RequestStep/StepDichiarazioniDeMinimis';
import { StepClienteCassaProfessionale } from 'components/RequestStep/StepClienteCassaProfessionale';
import { CompanyTypeConstants } from 'components/Forms/WebuserProfileForm/types';

const Request: React.FC = () => {
  /**
   * The state machine configuration.
   */
  const { state, dispatch } = useStateMachine();
  const { state: appState } = useContext(AppContext);

  /**
   * Go back to the previous state
   * @returns
   */
  const back = () => {
    let current_state;
    current_state = state.request?.state;
    if (state.request?.state === States.Regimi) {
      current_state = state.request?.onePurposeRelatedToInvestment
        ? States.Regimi
        : States.Investimento;
    } else if (current_state === States.Finanziamento) {
      current_state =
        state.request?.companyType === CompanyTypeConstants.FREELANCE ||
        state.request?.companyType === CompanyTypeConstants.PROFESSIONALFIRM
          ? States.Finanziamento
          : States.ClienteCassaProfessionale;
    } else if (state.request?.state === States.PrePagamento) {
      if (state.request.regime === 'AiutiTcf' && !state.request.isTcfEligible)
        current_state = States.Dichiarazioni;
      else if (state.request.regime !== 'AiutiTcf')
        current_state = States.DichiarazioniDeMinimis;
    } else if (state.request?.state === States.Dichiarazioni) {
      current_state = States.VerificaSceltaDocumento;
    } else if (state.request?.state === States.DichiarazioniDeMinimis) {
      current_state = States.VerificaSceltaDocumento;
    } else if (
      state.request?.state === States.VerificaSceltaDocumento ||
      state.request?.state === States.VerificaGeneraDocumento
    ) {
      if (state.request.regime === 'AiutiTcf' && !state.request.isTcfEligible)
        current_state =
          States.VerificaSceltaDocumento + '_' + States.Dichiarazioni;
      else if (state.request.regime !== 'AiutiTcf')
        current_state = States.VerificaSceltaDocumento.concat(
          `_${States.DichiarazioniDeMinimis}`,
        );
    }
    updateRequest(
      {
        id: state.request?.id,
        state: current_state + '_Back',
      },
      dispatch,
    );
  };

  return (
    <div className="request__page">
      <Helmet>
        <title>Allegato4.it - Richiesta</title>
        <meta name="description" content="Request" />
        <script>
          {`smartsupp('chat:show');smartsupp('email', '${_.get(
            appState,
            'mySelf.email',
            '',
          )}');`}
        </script>
      </Helmet>
      <div className="request__container">
        {/* Wizard enumeration steps */}
        {![
          States.PrePagamento,
          States.Pagamento,
          States.PagamentoPending,
          States.ClienteIdentificativoAzienda,
          States.ClienteSceltaCliente,
          States.ClienteRichiestaPending,
          States.Piano,
        ].includes(state.current as States) && (
          <>
            <Step.Group className="request__step-wrapper">
              <div className="step--parents">
                <Step active={state.parent === States.Cliente}>
                  <div className="request__step step__number">
                    <span>1</span>
                  </div>
                  {state.parent === States.Cliente && (
                    <span className="step__name">
                      <FormattedMessage
                        id="step.client"
                        defaultMessage="Cliente"
                      />
                    </span>
                  )}
                </Step>
                <Step active={state.parent === States.Finanziamento}>
                  <div className="request__step step__number">
                    <span>2</span>
                  </div>
                  {state.parent === States.Finanziamento && (
                    <span className="step__name">
                      <FormattedMessage
                        id="step.financing"
                        defaultMessage="Dati di finanziamento"
                      />
                    </span>
                  )}
                </Step>
                <Step active={state.parent === States.Bilancio}>
                  <div className="request__step step__number">
                    <span>3</span>
                  </div>
                  {state.parent === States.Bilancio && (
                    <span className="step__name">
                      <FormattedMessage
                        id="step.balance"
                        defaultMessage="Dati di bilancio"
                      />
                    </span>
                  )}
                </Step>
                <Step active={state.parent === States.Finalita}>
                  <div className="request__step step__number">
                    <span>4</span>
                  </div>
                  {state.parent === States.Finalita && (
                    <span className="step__name">
                      <FormattedMessage
                        id="step.purpose"
                        defaultMessage="Finalità"
                      />
                    </span>
                  )}
                </Step>
                <Step active={state.parent === States.Investimento}>
                  <div className="request__step step__number">
                    <span>5</span>
                  </div>
                  {state.parent === States.Investimento && (
                    <span className="step__name">
                      <FormattedMessage
                        id="request.investement"
                        defaultMessage="Investimenti"
                      />
                    </span>
                  )}
                </Step>
                <Step active={state.parent === States.Regimi}>
                  <div className="request__step step__number">
                    <span>6</span>
                  </div>
                  {state.parent === States.Regimi && (
                    <span className="step__name">
                      <FormattedMessage
                        id="request.regime"
                        defaultMessage="Regimi"
                      />
                    </span>
                  )}
                </Step>
                <Step active={state.parent === States.Dichiarazioni}>
                  <div className="request__step step__number">
                    <span>7</span>
                  </div>
                  {state.parent === States.Dichiarazioni && (
                    <span className="step__name">
                      <FormattedMessage
                        id="request.statements"
                        defaultMessage="Dichiarazioni"
                      />
                    </span>
                  )}
                </Step>
                <Step
                  active={
                    state.parent === States.Verifica ||
                    state.parent === States.Pagamento
                  }
                >
                  <div className="request__step step__number">
                    <span>8</span>
                  </div>
                  {(state.parent === States.Verifica ||
                    state.parent === States.Pagamento) && (
                    <span className="step__name">
                      <FormattedMessage
                        id="request.verify"
                        defaultMessage="Verifica"
                      />
                    </span>
                  )}
                </Step>
              </div>
              <div
                className={
                  state.request?.state === States.ClienteSceltaCliente
                    ? 'request--navigation wide'
                    : 'request--navigation'
                }
              >
                <Step className="step__last undo_request_step">
                  {(state.parent !== States.Cliente ||
                    state.current.includes('Professionale')) && (
                    <Button
                      loading={state.backward}
                      disabled={state.backward || state.forward}
                      className="button--success"
                      onClick={() => {
                        back();
                      }}
                    >
                      <FormattedMessage
                        id="request.back"
                        defaultMessage="Indietro"
                      />
                    </Button>
                  )}
                </Step>
                <Step>
                  <Button
                    form={`form__${state.current}`}
                    type="submit"
                    loading={state.forward}
                    className="button--success"
                    disabled={
                      state.parent === States.Verifica ||
                      state.backward ||
                      state.forward
                    }
                  >
                    <FormattedMessage
                      id="request.next"
                      defaultMessage="Prosegui"
                    />
                  </Button>
                </Step>
              </div>
            </Step.Group>
          </>
        )}
        <div
          className={
            state.request?.state === States.ClienteSceltaCliente
              ? 'request--navigation wide'
              : 'request--navigation'
          }
        >
          {/* Custom previous button for steps outside the wizard */}
          {state.current === States.PrePagamento && (
            <Step className="step__last undo_request_step">
              <Button
                loading={state.backward}
                className="button--success"
                onClick={() => {
                  back();
                }}
              >
                <FormattedMessage id="request.back" defaultMessage="Indietro" />
              </Button>
            </Step>
          )}
          {/* Custom next button for steps outside the wizard */}
          {(state.current === States.ClienteSceltaCliente ||
            state.current === States.Piano ||
            state.current === States.PrePagamento) && (
            <Step>
              <Button
                form={`form__${state.current}`}
                type="submit"
                loading={state.forward}
              >
                <FormattedMessage id="request.next" defaultMessage="Prosegui" />
              </Button>
            </Step>
          )}
        </div>
        {/* Steps content */}
        <div className="request__container__wrapper">
          {state.current === States.Piano && <StepPiano />}

          {state.current === States.ClienteSceltaCliente && (
            <StepClienteSceltaCliente />
          )}
          {state.current === States.ClienteIdentificativoAzienda && (
            <StepClienteIdentificativoAzienda />
          )}
          {state.current === States.ClienteRichiestaPending && (
            <StepClienteRichiestaPending />
          )}
          {state.current === States.ClienteRichiestaFallita && (
            <StepClienteRichiestaFallita />
          )}
          {state.current === States.ClienteRiepilogo && (
            <StepClienteRiepilogo />
          )}

          {state.current === States.ClienteCassaProfessionale && (
            <StepClienteCassaProfessionale />
          )}

          {state.current === States.Bilancio && <StepBilancio />}
          {state.current === States.BilancioCalcoloDimensionaleAzienda && (
            <StepBilancioCalcoloDimensionaleAzienda />
          )}
          {state.current === States.Finalita && <StepFinalita />}
          {state.current === States.Investimento && <StepInvestimento />}
          {state.parent === States.Verifica && <StepVerifica />}
          {state.current === States.Finanziamento && <StepFinanziamento />}
          {state.current === States.Dichiarazioni && <StepDichiarazioni />}
          {state.current === States.DichiarazioniDeMinimis && (
            <StepDichiarazioniDeMinimis />
          )}
          {state.current === States.PrePagamento && <StepPrePagamento />}
          {state.current === States.Pagamento && <StepPagamento />}
          {state.current === States.PagamentoPending && (
            <StepPagamentoPending />
          )}
          {state.current === States.Regimi && <StepRegimi />}
        </div>
      </div>
    </div>
  );
};

export default Request;
