import React, { useEffect, useReducer } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Button, Dimmer, Image, Loader, Menu } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

// Image and Icons
import mainLogo from 'assets/images/logo.svg';

// Layout
import Topbar from 'layout/Topbar';

// Pages
import { MenuItem } from 'pages/App/types';

// Utils
import { FE_ROUTES } from 'utils/global/globalCostants';
import {
  initialState,
  stateMachineReducer,
} from 'services/stateMachine/reducer';
import { StateMachineStatus, States } from 'services/stateMachine/types';
import { loadStateMachineConfig } from 'services/stateMachine';
import { StateMachineContext } from 'services/stateMachine/useStateMachine';
import {
  createRequest,
  getRequest,
  checkMaintenanceMode,
} from 'services/request';
import { Appointment } from 'pages/Appointment';
import { appReducer, initialAppState } from 'pages/App/reducer/reducer';
import { Maintenance } from 'components/Maintenance';
import { appActionConstants } from 'pages/App/reducer/actions';
import { StateMachineActionType } from 'services/stateMachine/types';

export const topBarItems: Array<MenuItem> = [
  {
    id: 'logo',
    to: FE_ROUTES.CUSTOMER,
    position: 'left',
    title: (
      <Menu.Item>
        <Image src={mainLogo} className="mainLogo" />
      </Menu.Item>
    ),
  },
  {
    id: 'terms',
    to: FE_ROUTES.CUSTOMER,
    position: 'right',
    protected: true,
    title: (
      <Menu.Item className="link__terms">
        <Button>
          <FormattedMessage
            id="link.terms"
            defaultMessage="Termini e Condizioni"
          />
        </Button>
      </Menu.Item>
    ),
  },
  {
    id: 'home',
    to: FE_ROUTES.CUSTOMER,
    position: 'right',
    protected: true,
    title: (
      <Menu.Item className="link__home">
        <Button>
          <FormattedMessage id="link.home" defaultMessage="Torna alla home" />
        </Button>
      </Menu.Item>
    ),
  },
];

const RequestLayout: React.FC = () => {
  /**
   * The reducer initialization.
   */
  const [state, dispatch] = useReducer(stateMachineReducer, initialState);
  const [appState, appDispatch] = useReducer(appReducer, initialAppState);

  /**
   * Get (eventually) the request ID.
   */
  const { id } = useParams();

  /**
   * The navigation object.
   */
  const navigate = useNavigate();

  /**
   * The initial state is lazy loaded when the component has been mounted.
   * When all configs have been loaded, it is time to get the request details
   * or create a new record if we are in /new mode.
   */
  useEffect(() => {
    if (state.status === StateMachineStatus.Empty) {
      loadStateMachineConfig('request', dispatch).then(() => {
        if (id) {
          getRequest(id, dispatch, appDispatch);
        } else {
          createRequest(
            {
              state: States.Bozza,
            },
            dispatch,
          )
            .catch(err => {
              if (err && err.data && err.data.detail === 'changing-skin') {
                appDispatch({
                  type: appActionConstants.SET_UNDER_MAINTENANCE,
                  payload: { underMaintenance: true },
                });
              } else {
                toast.error('Utente non abilitato a inviare richieste');
                navigate(FE_ROUTES.CUSTOMER);
              }
            })
            .finally(() => {
              checkMaintenanceMode(appDispatch).finally(() => {
                dispatch({
                  type: StateMachineActionType.Ready,
                });
              });
            });
        }
      });
    }
  }, []);

  /**
   * Scroll to top inside request wizard.
   */
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [state.request?.state]);

  /**
   * Navigate to the current request ID, if it's different from the original one.
   * It comes in handy when you are creating a new request, 'cause it redirects you to the right page.
   * It's a replace-type of redirect, so nothing changes except the URI on the browser.
   */
  useEffect(() => {
    if (state.request?.id && state.request?.id !== id) {
      navigate('request/' + state.request?.id, { replace: true });
    }
  }, [state.request?.id]);

  /**
   * The render method core.
   */
  return (
    <div className="request">
      <StateMachineContext.Provider value={{ state, dispatch }}>
        <Dimmer
          active={
            state.status === StateMachineStatus.Empty ||
            state.status === StateMachineStatus.Loading
          }
          inverted
        >
          <Loader size="huge">Loading</Loader>
        </Dimmer>
        <Topbar
          className={`request__topbar${
            state.current !== States.Pagamento ? '--with-sidebar' : ''
          }`}
          moduleName="Logout"
          topBarItems={topBarItems}
          logout={() => null}
          breadcrumbs={null}
        />
        {state.status === StateMachineStatus.Ready && (
          <>
            {!appState.underMaintenance ? (
              <>
                {state.current !== States.Pagamento && <Appointment />}
                <div
                  className={`request__wizard request__wizard${
                    state.current !== States.Pagamento ? '--with-sidebar' : ''
                  }`}
                >
                  <Outlet />
                </div>
              </>
            ) : (
              <Maintenance />
            )}
          </>
        )}
      </StateMachineContext.Provider>
    </div>
  );
};
export default RequestLayout;
