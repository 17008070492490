import React from 'react';
import SemanticTableGrid from 'semantic-table-grid';
import { Pagination } from 'semantic-ui-react';
// import { Input, Pagination } from 'semantic-ui-react';
import { DataGridProps } from './types';

const DataGrid: React.FC<DataGridProps> = ({
  isLoading,
  elements = [],
  // rowUniqueKey,
  columns,
  // onSort,
  paginate,
  page,
  pageCount,
  totalItems,
  onPageSelect,
  // canFilter = false,
  // onFilter,
  pageSize,
}) => {
  // const filterRow = columns.reduce((row, { key, filterType }) => {
  //   if (filterType) {
  //     return {
  //       ...row,
  //       [key]: (
  //         <Input
  //           fluid
  //           placeholder="Cerca ..."
  //           type={filterType}
  //           onChange={(event, data) => onFilter(key, data.value, filterType)}
  //         />
  //       ),
  //     };
  //   }
  //   return row;
  // }, {});

  //const pageCount = totalItems ? Math.ceil(totalItems / pageSize) : 0;

  return (
    <div className="contentGrid">
      <SemanticTableGrid
        isLoading={isLoading}
        // elements={(canFilter ? [filterRow] : []).concat(elements)}
        elements={elements}
        rowClassKey="class"
        // rowUniqueKey={rowUniqueKey}
        columns={columns}
        // rawProps={{
        //   celled: false,
        //   singleLine: true,
        //   padded: 'very',
        // }}
        // canSort={{
        //   active: true,
        //   onSort,
        // }}
        canPaginate={{
          active: paginate,
          pageCount,
          pageSize,
          totalItems,
          page,
          onSelect: onPageSelect,
          render: (
            <Pagination
              // defaultActivePage={page}
              activePage={page}
              floated="right"
              onPageChange={(event, data) => onPageSelect(data.activePage || 1)}
              totalPages={pageCount}
            />
          ),
        }}
        hiddenHeaderIfEmpty
        emptyResults={
          isLoading ? 'Caricando la lista...' : 'Nessun elemento disponibile...'
        }
      />
    </div>
  );
};

export default DataGrid;
