import { PaymentEntities } from 'services/payment/types';
import { Request, RequestEvent } from 'services/request/types';
import { Nullable } from 'utils/types';

/**
 * The state definition.
 */
export interface StateConfig {
  type: 'initial' | 'final' | 'normal';
  properties: {
    parent: string;
    index: number;
  };
}

/**
 * The transition definition.
 */
export interface TransitionConfig {
  from: string[];
  to: string;
}

/**
 * The configuration of the state machine with states, transitions, etc.
 */
export interface StateMachineConfig {
  class: string;
  states: {
    [key: string]: StateConfig;
  };
  transitions: {
    [key: string]: TransitionConfig;
  };
  parents: string[];
}

/**
 * The /state-machine API response.
 */
export type StateMachineResponse = {
  [key: string]: StateMachineConfig;
};

/**
 * The state machine status.
 */
export enum StateMachineStatus {
  Empty,
  Loading,
  Ready,
  Fail,
}

/**
 * The appointment status.
 */
export enum AppointmentStatus {
  Create,
  Update,
  Empty,
}

/**
 * The state machine settings.
 */
export interface StateMachineState {
  /**
   * The current state.
   */
  current: string;

  /**
   * The macro-state (a.k.a. the parent)
   */
  parent?: string;

  /**
   * It gets along the current property and it's useful for the tab indexing.
   */
  index?: number;

  /**
   * The state machine status.
   * It allows to understand if the initial configuration is loaded.
   */
  status: StateMachineStatus;

  /**
   * The state machine configuration.
   * It contains all the states and the allowed transitions.
   */
  config?: StateMachineConfig;

  /**
   * The stack (LIFO) of the previous states.
   */
  history: string[];

  /**
   * The flag to tell if the state machine is moving forward to a state.
   */
  forward: boolean;

  /**
   * The flag to tell if the state machine is moving backward to a state.
   */
  backward: boolean;

  /**
   * The current request entity managed from the state machine.
   */
  request?: Request;

  /**
   * A string to let the application knows if the appointment modal is open and its state.
   */
  calendarStatus: AppointmentStatus;

  /**
   * The selected calendar event.
   */
  calendarEvent: Nullable<RequestEvent>;

  /**
   * The flag to tell if request is loading.
   */
  loading: boolean;
  /**
   * Payment entity associated to the request
   */
  paymentEntity: Nullable<PaymentEntities>;
}

/**
 * The actions available for the state machine reducer.
 */
export enum StateMachineActionType {
  Init,
  Fail,
  Ready,
  To,
  Backward,
  Forward,
  Loading,
  SetRequest,
  SetCalendar,
  SetPaymentEntity,
}

/**
 * The data accepted as input in the state machine reducer.
 */
export type StateMachineAction =
  | {
      type: StateMachineActionType.Init;
      config: StateMachineConfig;
    }
  | {
      type: StateMachineActionType.To;
      to: string;
    }
  | {
      type: StateMachineActionType.Fail;
    }
  | {
      type: StateMachineActionType.Ready;
    }
  | {
      type: StateMachineActionType.Backward;
      backward: boolean;
    }
  | {
      type: StateMachineActionType.Forward;
      forward: boolean;
    }
  | {
      type: StateMachineActionType.Loading;
      loading: boolean;
    }
  | {
      type: StateMachineActionType.SetPaymentEntity;
      paymentEntity: PaymentEntities;
    }
  | {
      type: StateMachineActionType.SetRequest;
      request: Request;
    }
  | {
      type: StateMachineActionType.SetCalendar;
      calendarStatus: AppointmentStatus;
      calendarEvent?: RequestEvent;
    };

/**
 * The state machine context property.
 */
export interface StateMachineContextState {
  state: StateMachineState;
  dispatch: React.Dispatch<StateMachineAction>;
}

/**
 * The list of the states.
 */
export enum States {
  /**
   * The initial state.
   */
  Bozza = 'Bozza',

  /**
   * Cliente states.
   */
  Cliente = 'Cliente',
  ClienteIdentificativoAzienda = 'Cliente_IdentificativoAzienda',
  ClienteSceltaCliente = 'Cliente_SceltaCliente',
  ClienteRichiestaPending = 'Cliente_RichiestaPending',
  ClienteRichiestaFallita = 'Cliente_RichiestaFallita',
  ClienteRiepilogo = 'Cliente_Riepilogo',
  ClienteCassaProfessionale = 'Cliente_CassaProfessionale',
  ClienteRiepilogo_Finanziamento = 'ClienteRiepilogo_Finanziamento',
  CassaProfessionale_Finanziamento = 'CassaProfessionale_Finanziamento',

  Piano = 'Piano',
  PianoSelezionato = 'Piano_Selezionato',

  Bilancio = 'Bilancio',
  BilancioCalcoloDimensionaleAzienda = 'Bilancio_Calcolo_Dimensionale_Azienda',

  Finalita = 'Finalita',
  Investimento = 'Investimento',
  Finalita_Regimi = 'Finalita_Regimi',
  Finalita_Investimento = 'Finalita_Investimento',
  Investimento_Regimi = 'Investimento_Regimi',

  Regimi = 'Regimi',
  Finanziamento = 'Finanziamento',
  Dichiarazioni = 'Dichiarazioni',
  DichiarazioniPrePagamento = 'Dichiarazioni_Pre_Pagamento',
  DichiarazioniDeMinimis = 'Dichiarazioni_De_Minimis',
  DichiarazioniDeMinimisPrePagamento = 'Dichiarazioni_De_Minimis_Pre_Pagamento',

  /**
   * Payment states.
   */
  PrePagamento = 'Pre_Pagamento',
  Pagamento = 'Pagamento',
  PagamentoRegimi = 'Pagamento_Regimi',
  PagamentoDichiarazioni = 'Pagamento_Dichiarazioni',
  PagamentoDichiarazioniDeMinimis = 'Pagamento_Dichiarazioni_De_Minimis',
  PagamentoFatturazione = 'Pagamento_Fatturazione',
  PagamentoPending = 'Pagamento_Pending',
  PagamentoCompletato = 'Pagamento_Completato',
  PagamentoCancellato = 'Pagamento_Cancellato',

  /**
   * Verify
   */
  Verifica = 'Verifica',
  VerificaSceltaDocumento = 'Verifica_SceltaDocumento',
  VerificaGaranzie = 'Verifica_Garanzie',
  VerificaGeneraDocumento = 'Verifica_GeneraDocumento',
}
