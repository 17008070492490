import { Request } from 'services/request/types';

export enum RequestPollingActionConstants {
  POLLING_SUCCESS = 'Request/POLLING_SUCCESS',
  POLLING_TIMEOUT = 'Request/POLLING_TIMEOUT',
  POLLING_ERROR = 'Request/POLLING_ERROR',
}

export type RequestPollingAction =
  | {
      type: RequestPollingActionConstants.POLLING_SUCCESS;
      payload: { request: Request };
    }
  | {
      type: RequestPollingActionConstants.POLLING_TIMEOUT;
      payload: { isTimeout: boolean };
    }
  | {
      type: RequestPollingActionConstants.POLLING_ERROR;
      payload: { isError: boolean };
    };
