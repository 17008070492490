import {
  CustomerAction,
  CustomerActionConstants,
} from 'pages/Customer/reducer/actions';
import { CustomerResponse } from 'services/customer/types';

export type CustomerProfileState = {
  isLoading: boolean;
  customer: CustomerResponse;
};

export const initialCustomerProfileState: CustomerProfileState = {
  isLoading: false,
  customer: {
    businessName: null,
    email: null,
    fiscalCode: null,
    pec: null,
    companyType: null,
    id: null,
    subscriptionType: null,
    vatNumber: null,
    subscriptionActive: null,
    consultingEnabled: null,
    feasibilityCheckEnabled: null,
    maxRequestsAmount: null,
    remainingRequests: null,
    type: undefined,
  },
};

export function customerProfileReducer(
  state: CustomerProfileState,
  action: CustomerAction,
): CustomerProfileState {
  const { type } = action;

  switch (type) {
    case CustomerActionConstants.SET_CUSTOMER:
      return { ...state, customer: action.payload.customer };
    case CustomerActionConstants.SET_CUSTOMER_TYPE:
      return {
        ...state,
        customer: { ...state.customer, type: action.payload.customerType },
      };
    default:
      return state;
  }
}
