import { AxiosResponse } from 'axios';
import {
  DataGridAction,
  DataGridActionConstants,
} from 'components/DataGrid/reducer/actions';
import { PaginationData } from 'components/DataGrid/types';
import {
  GenerateVoucherAction,
  GenerateVoucherActionConstants,
} from 'components/Forms/GenerateVoucherForm/reducer/actions';
import { GenerateVoucherFormValues } from 'components/Forms/GenerateVoucherForm/types';
import { VoucherFilterFormValues } from 'components/Forms/VoucherFilterForm/types';
import { FormikHelpers } from 'formik';
import _ from 'lodash';
import {
  VoucherAction,
  VoucherActionConstants,
} from 'pages/Voucher/reducer/actions';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { StandardResponseError } from 'services/client/types';
import { API } from 'utils/global/backendRoutes';
import { PaginateObject } from 'utils/types/paginateObject';
import { VoucherObjectResponse } from './types';

/**
 * Gets voucher list.
 * @param paginationData Pagination data.
 * @param dispatch to manage callback results.
 */
export const getVoucherList = (
  values: VoucherFilterFormValues,
  paginationData: PaginationData,
  dataGridDispatch: React.Dispatch<DataGridAction>,
  dispatch: React.Dispatch<VoucherAction>,
): void => {
  dataGridDispatch({
    type: DataGridActionConstants.SET_IS_LOADING,
    payload: {
      isLoading: true,
    },
  });
  const params = {
    filter: [
      values.isActive &&
        values.isActive != 'all' && {
          type: 'eq',
          value: values.isActive === 'active' ? 1 : 0,
          field: 'isActive',
        },
      values.isUsed &&
        values.isUsed != 'all' && {
          type: 'eq',
          value: values.isUsed === 'used' ? 1 : 0,
          field: 'isUsed',
        },
      !_.isEmpty(values.agentFilterId) && {
        type: 'eq',
        value: values.agentFilterId,
        field: 'agent',
      },
    ].filter(o => o),
    pageSize: 10,
    page: _.get(paginationData, 'page', 1) === 0 ? 1 : paginationData.page,
  };
  const orderByParams = {
    field: 'createdAt',
    type: 'field',
    direction: 'desc',
  };
  params['order-by'] = [orderByParams];
  // Convert params to string
  const paramsSerializer = QueryString.stringify(params);

  standardClient({
    url: `${API.VOUCHER}?${paramsSerializer}`,
    method: 'GET',
  })
    .then((response: AxiosResponse<PaginateObject<VoucherObjectResponse>>) => {
      // Dispatch the notification for customer dictionary
      dispatch({
        type: VoucherActionConstants.SET_VOUCHER_LIST,
        payload: { voucherList: response.data._embedded.voucher },
      });
      setDataGridPaginator(response, dataGridDispatch);
      dataGridDispatch({
        type: DataGridActionConstants.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      });
    })
    .catch(() => {
      toast.error('Errore durante il caricamento dei voucher');
      dataGridDispatch({
        type: DataGridActionConstants.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      });
    });
};

/**
 * Set paginator for DataGrid.
 * @param response
 * @param dataGridDispatch
 * @returns
 */
const setDataGridPaginator = (
  response: AxiosResponse<PaginateObject<VoucherObjectResponse>>,
  dataGridDispatch: React.Dispatch<DataGridAction>,
) => {
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_PAGE_COUNT,
    payload: {
      page_count: response.data.page_count,
    },
  });
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
    payload: {
      pageNumber: response.data.page,
    },
  });
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_TOTAL_ITEMS,
    payload: {
      total_items: response.data.total_items,
    },
  });
  return dataGridDispatch;
};

export const generateVoucher = (
  values: GenerateVoucherFormValues,
  formikHelpers: FormikHelpers<GenerateVoucherFormValues>,
  dispatch: React.Dispatch<GenerateVoucherAction>,
): Promise<void> => {
  return standardClient({
    url: API.GENERATE_VOUCHER,
    method: 'POST',
    data: {
      agentId: values.agentId?.value,
      numberOfVoucher: values.numberOfVoucher,
      discountPercentage: parseInt(values.discountPercentage),
    },
  })
    .then(() => {
      toast.success('Voucher correttamente generati');
      dispatch({
        type: GenerateVoucherActionConstants.SET_MODAL_IS_OPEN,
        payload: {
          modalOpen: false,
        },
      });
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      const errors = error?.data?.validation_messages;

      if (errors) {
        // Set the errors in the Formik bag.
        _.forOwn(errors, (value, key) => {
          for (const i in value) {
            formikHelpers.setFieldError(key, value[i]);
            break;
          }
        });
      } else {
        toast.error('Errore durante la generazione dei voucher');
      }
    })
    .finally(() => {
      formikHelpers.setSubmitting(false);
    });
};
