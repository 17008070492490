import React, { createContext, useEffect, useReducer } from 'react';
import { Dimmer, Header, Loader } from 'semantic-ui-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'moment/locale/it';

// Components
import CheckAuth from 'components/CheckAuth';

// Internals and constants
import { AppAction, appActionConstants } from './reducer/actions';
import { appReducer, AppState, initialAppState } from './reducer/reducer';
import {
  CUSTOM_EVENT,
  FE_ROUTES,
  LOCAL_STORAGE,
} from 'utils/global/globalCostants';
import { loadMyself } from 'services/auth/loadMyself';

// Layout
import StandardLayout from 'layout/StandardLayout';
import AccessLayout from 'layout/AccessLayout';

// Pages
import Login from 'pages/Login';
import Register from 'pages/Register';
import PasswordRecovery from 'pages/PasswordRecovery';
import PasswordReset from 'pages/PasswordReset';
import AccountActivation from 'pages/AccountActivation';
import Customer from 'pages/Customer';
import CustomerProfile from 'pages/CustomerProfile';
import Dashboard from 'pages/Dashboard';
import Agent from 'pages/Agent';
import AgentProfile from 'pages/AgentProfile';

// Styles
import 'semantic-ui-less/semantic.less';
import 'react-toastify/dist/ReactToastify.css';
import './style.less';
import Request from 'pages/Request';
import RequestLayout from 'layout/RequestLayout';
import CustomerRequest from 'pages/CustomerRequest';
import CanCustomerList from 'components/CanCustomerList';
import { StripeSuccess } from 'pages/Stripe/success';
import { StripeCancel } from 'pages/Stripe/cancel';
import Voucher from 'pages/Voucher';

export const AppContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
}>({
  state: initialAppState,
  dispatch: () => null,
});

const App: React.FC = () => {
  const [state, dispatch] = useReducer(appReducer, initialAppState);

  useEffect(() => {
    // Custom event attachment for handle API errors
    window.addEventListener(CUSTOM_EVENT.LOGOUT, () => {
      dispatch({ type: appActionConstants.REVOKE_SESSION });
    });

    /**
     * Check if an accessToken is saved in the locale storage:
     * - If is not, set isAuthenticated to false and redirect to loginPage
     * - If it is, show a loading page until the myself API call has been done.
     *   This is necessary to check if the data in the local storage are already valid or not
     */
    if (!localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN)) {
      dispatch({ type: appActionConstants.REVOKE_SESSION });
    } else {
      loadMyself(dispatch);
    }

    // Remove events listener on component unmount
    return () => {
      window.removeEventListener('Logout', () =>
        dispatch({ type: appActionConstants.REVOKE_SESSION }),
      );
    };
  }, []);

  // If state is undefined (check if token are expired) a loader will be showed
  if (state.isAuthenticated === undefined) {
    return (
      <Dimmer page active>
        <Loader size="huge">
          <Header as="h2" inverted>
            Avvio applicativo in corso
            <Header.Subheader>Si prega di attendere.</Header.Subheader>
          </Header>
        </Loader>
      </Dimmer>
    );
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Routes>
        {/* Routes with Sidebar and Topbar layout (StandardLayout)*/}
        <Route element={<StandardLayout />}>
          <Route
            path={FE_ROUTES.DASHBOARD}
            element={
              <CanCustomerList>
                <Dashboard />
              </CanCustomerList>
            }
          />
          <Route
            path={FE_ROUTES.AGENT}
            element={
              <CanCustomerList>
                <Agent />
              </CanCustomerList>
            }
          />
          <Route
            path={FE_ROUTES.AGENT + '/:id'}
            element={
              <CheckAuth>
                <AgentProfile />
              </CheckAuth>
            }
          />
          <Route
            index
            element={
              <CheckAuth>
                <CanCustomerList>
                  <Customer />
                </CanCustomerList>
              </CheckAuth>
            }
          />
          <Route
            path={FE_ROUTES.CUSTOMER}
            element={
              <CheckAuth>
                <CanCustomerList>
                  <Customer />
                </CanCustomerList>
              </CheckAuth>
            }
          />
          <Route
            path={FE_ROUTES.CUSTOMER + '/:action/:id'}
            element={
              <CheckAuth>
                <CustomerProfile />
              </CheckAuth>
            }
          />
          <Route
            path={FE_ROUTES.CUSTOMER + '/:action'}
            element={
              <CheckAuth>
                <CustomerProfile />
              </CheckAuth>
            }
          />
          <Route
            path={FE_ROUTES.REQUEST}
            element={
              <CheckAuth>
                <CustomerRequest />
              </CheckAuth>
            }
          />
          <Route
            path={FE_ROUTES.STRIPE_SUCCESS + '/:idSession'}
            element={
              <CheckAuth>
                <StripeSuccess />
              </CheckAuth>
            }
          />
          <Route
            path={FE_ROUTES.STRIPE_CANCEL + '/:idSession'}
            element={
              <CheckAuth>
                <StripeCancel />
              </CheckAuth>
            }
          />
          <Route
            path={FE_ROUTES.VOUCHER}
            element={
              <CheckAuth>
                <Voucher />
              </CheckAuth>
            }
          />
        </Route>
        {/* Routes with only Footer Layout */}
        <Route element={<AccessLayout />}>
          <Route
            path={FE_ROUTES.LOGIN}
            element={
              <CheckAuth onlyNotAuth>
                <Login />
              </CheckAuth>
            }
          />
          <Route
            path={FE_ROUTES.REGISTER}
            element={
              <CheckAuth onlyNotAuth>
                <Register />
              </CheckAuth>
            }
          />
          <Route
            path={FE_ROUTES.PASSWORD_RECOVERY}
            element={
              <CheckAuth onlyNotAuth>
                <PasswordRecovery />
              </CheckAuth>
            }
          />
          <Route
            path={FE_ROUTES.PASSWORD_RESET}
            element={
              <CheckAuth onlyNotAuth>
                <PasswordReset />
              </CheckAuth>
            }
          />
          <Route
            path={FE_ROUTES.ACTIVATE}
            element={
              <CheckAuth onlyNotAuth>
                <AccountActivation />
              </CheckAuth>
            }
          />
        </Route>
        {/* Routes for the Request Layout */}
        <Route element={<RequestLayout />}>
          <Route
            path={FE_ROUTES.REQUEST + '/new'}
            element={
              <CheckAuth>
                <Request />
              </CheckAuth>
            }
          />
          <Route
            path={FE_ROUTES.REQUEST + '/:id'}
            element={
              <CheckAuth>
                <Request />
              </CheckAuth>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to={FE_ROUTES.CUSTOMER} />} />
      </Routes>
      <ToastContainer />
    </AppContext.Provider>
  );
};

export default App;
