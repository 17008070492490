import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Lottie from 'lottie-react';
import ChangingSkinAnimation from './ChangingSkinAnimation.json';
import { Consultancy } from 'components/Consultancy';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { getRequestPromise } from 'services/request';
import { StateMachineActionType } from 'services/stateMachine/types';
import { ClipLoader } from 'react-spinners';

export const Maintenance: React.FC = () => {
  const { id } = useParams();
  const {
    state: { request, loading },
    dispatch,
  } = useStateMachine();

  useEffect(() => {
    if (id) {
      dispatch({
        type: StateMachineActionType.Loading,
        loading: true,
      });
      getRequestPromise(id, 'maintenancePage')
        .then(res => {
          const request = res.data;
          dispatch({
            type: StateMachineActionType.SetRequest,
            request: request,
          });
        })
        .finally(() => {
          dispatch({
            type: StateMachineActionType.Loading,
            loading: false,
          });
        });
    }
  }, [id]);

  return (
    <>
      <div className="request__sidebar"></div>
      <div className="request__wizard request__wizard--with-sidebar">
        <div className="changing-skin">
          <div className="title">
            <h2>
              Stiamo lavorando all&apos;aggiornamento di Allegato4, prenota la
              tua sessione di consulenza per finalizzare la compilazione
            </h2>
          </div>
          <div className="changing-skin-animation">
            <Lottie
              animationData={ChangingSkinAnimation}
              loop={true}
              autoplay={true}
            />
          </div>
          {loading ? (
            <div
              style={{
                width: '100%',
                height: '200px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ClipLoader color="#8098b2"></ClipLoader>
            </div>
          ) : (
            <>
              {(id || request?.id) && (
                <Consultancy id={id ? id : request?.id} mode="maintenance" />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
