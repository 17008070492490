import React, { createContext, useEffect, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';

import { FormattedMessage } from 'react-intl';
import { Grid, Header, Icon } from 'semantic-ui-react';
import {
  customerReducer,
  CustomerState,
  initialCustomerState,
} from './reducer/reducer';
import { CustomerAction, CustomerPageActionConstants } from './reducer/actions';
import DataGrid from 'components/DataGrid';
import { getCustomerList } from 'services/customer';
import CustomerFilterForm from 'components/Forms/CustomerFilterForm';
import { CustomerFilterFormValues } from 'components/Forms/CustomerFilterForm/types';
import {
  DataGridAction,
  DataGridActionConstants,
} from 'components/DataGrid/reducer/actions';
import {
  dataGridReducer,
  DataGridState,
  initialDataGridState,
} from 'components/DataGrid/reducer/reducer';
import { Link } from 'react-router-dom';
import { CustomerFormatterParams } from 'components/DataGrid/types';
import { FE_ROUTES } from 'utils/global/globalCostants';
import { Nullable } from 'utils/types';
import _ from 'lodash';

export const CustomerContext = createContext<{
  state: CustomerState;
  dispatch: React.Dispatch<CustomerAction>;
}>({
  state: initialCustomerState,
  dispatch: () => null,
});

export const DataGridContext = createContext<{
  state: DataGridState;
  dispatch: React.Dispatch<DataGridAction>;
}>({
  state: initialDataGridState,
  dispatch: () => null,
});

const Customer: React.FC = () => {
  const [state, dispatch] = useReducer(customerReducer, initialCustomerState);
  const [dataGridState, dataGridDispatch] = useReducer(
    dataGridReducer,
    initialDataGridState,
  );
  // User defined filters
  const [filters, setFilters] = useState<CustomerFilterFormValues>({
    customerType: null,
    searchText: '',
  });

  /**
   * Gets customer list on pagination data change.
   */
  useEffect(() => {
    // Get customer collection
    getCustomerList(
      filters,
      dataGridState.paginationData,
      dataGridDispatch,
      dispatch,
    );
  }, [dataGridState.paginationData.page]);

  /**
   * Manages search submit with filters.
   * @param values
   */
  const onSubmit = (values: CustomerFilterFormValues) => {
    // Store filters locally
    setFilters(values);
    // Get customer collection
    getCustomerList(
      values,
      dataGridState.paginationData,
      dataGridDispatch,
      dispatch,
    );
  };

  /**
   * Manages page selection change.
   * @param data
   */
  const handleSelectPage = (data: string | number) => {
    dataGridDispatch({
      type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
      payload: {
        pageNumber: data,
      },
    });
  };

  return (
    <>
      <div className="customer-container">
        <Helmet>
          <body className="customer customer-list" />
          <title>Allegato4.it - Elenco clienti</title>
          <script>{"smartsupp('chat:hide')"}</script>
          <meta name="description" content="customer list page" />
        </Helmet>
        <div className="customer__content">
          <Header className="customerHeader" dividing>
            <Grid>
              <Grid.Column width={3}>
                <h1>
                  <FormattedMessage
                    id="customer.title"
                    defaultMessage="Elenco clienti"
                  />
                </h1>
              </Grid.Column>
              <Grid.Column width={13}>
                <CustomerFilterForm onSubmit={onSubmit} />
              </Grid.Column>
            </Grid>
          </Header>
          <div className="customerSectionBody">
            <DataGrid
              isLoading={dataGridState.isLoading}
              elements={state.customerList}
              columns={[
                {
                  key: 'businessName',
                  name: '',
                  formatter: ({ data }: CustomerFormatterParams) =>
                    data.businessName,
                },
                {
                  key: 'action',
                  name: '',
                  formatter: ({ data }: CustomerFormatterParams) => {
                    let customerUrl: Nullable<URL> = null;
                    if (data && data._links) {
                      customerUrl = new URL(data._links.self.href);
                    }

                    if (_.isNull(customerUrl)) {
                      return;
                    }

                    /**
                     * This is tricky: we extract the customer type and the ID from the self URL hydrated from the BE.
                     * The last two parts of this URL are:
                     * - entity type (our customer type)
                     * - entity ID
                     */
                    const urlElements = customerUrl.pathname.split('/');
                    const id = urlElements[urlElements.length - 1];
                    /** Compose the FE URL using the data just extracted */
                    const customerDetailLink = `${FE_ROUTES.CUSTOMER}/${CustomerPageActionConstants.VIEW}/${id}`;
                    return (
                      <>
                        <Link to={customerDetailLink}>
                          <FormattedMessage
                            id="customer.detail.action"
                            defaultMessage="Vedi tutte le informazioni"
                          />
                        </Link>
                        <Icon name="angle right" />
                      </>
                    );
                  },
                },
              ]}
              totalItems={state.customerList.length}
              page={dataGridState.paginationData.page}
              onPageSelect={handleSelectPage}
              paginate
              pageSize={dataGridState.paginationData.page_size}
              pageCount={dataGridState.paginationData.page_count}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
