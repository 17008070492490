import React, { useCallback, useState } from 'react';
import FullCalendar, {
  DateSpanApi,
  EventApi,
  EventInput,
} from '@fullcalendar/react';
import TimeGridPlugin from '@fullcalendar/timegrid';
import MomentPlugin from '@fullcalendar/moment';
import InteractionPlugin from '@fullcalendar/interaction';
import it from '@fullcalendar/core/locales/it';
import { useFormikContext } from 'formik';
import { AxiosResponse } from 'axios';
import { ScheduleInformation } from '@microsoft/microsoft-graph-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Dimmer, Header, Image, Loader, Popup } from 'semantic-ui-react';

import { getSchedule } from 'services/calendar';
import { AppointmentFormValues } from '../types';
import questionMark from 'assets/images/icon/question-mark.svg';
import { useRef } from 'react';
import { useAppointment } from 'pages/Appointment/context';

const AppointmentCalendar: React.FC = () => {
  /**
   * To set the form date.
   */
  const { setFieldValue, setFieldTouched } =
    useFormikContext<AppointmentFormValues>();

  const [loading, setLoading] = useState(false);

  /**
   * The appointment utility.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { event } = useAppointment();

  /**
   * Calendar reference.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const calendarRef = useRef<any>(null);

  /**
   * A function to get the schedule from the MicrosoftGraph API.
   */
  const getScheduleEvents = useCallback(
    (info, successCallback, failureCallback) => {
      getSchedule(info.startStr, info.endStr)
        .then((response: AxiosResponse<ScheduleInformation[]>) => {
          successCallback(
            (response.data[0].scheduleItems || []).map(s => ({
              start: moment(s.start?.dateTime).toISOString(),
              end: moment(s.end?.dateTime).toISOString(),
              color: '#EB5757',
            })),
          );
        })
        .catch(() => {
          toast.error('Errore nel recupero delle informazioni dal calendario');
          failureCallback([]);
        });
    },
    [],
  );

  // useEffect(() => {
  //   if (
  //     calendarRef.current &&
  //     calendarRef.current.getApi &&
  //     values.start &&
  //     values.end
  //   ) {
  //     calendarRef.current
  //       .getApi()
  //       .select(moment(values.start), moment(values.end));
  //   }
  // }, [event]);

  /**
   * Create the background events given a timeframe.
   * @param startDate the first slot time of the week
   * @param endDate the last slot of the week
   * @returns an array of slots
   */
  const createBackgroundEvents = (
    startDate: moment.Moment,
    endDate: moment.Moment,
  ): EventInput[] => {
    const events: EventInput[] = [];

    // Loop on the 5 working days.
    for (let i = 0; i < 5; i++) {
      const start = startDate.clone();
      const end = endDate.clone();

      start.add(i, 'days');
      end.add(i, 'days');

      const current = start;

      while (current < end) {
        const currentStart = current.clone();

        // The slot lasts 30 minutes.
        current.add(30, 'minute');

        const isSelectable = moment().diff(currentStart, 'days') < 0;

        const currentEnd = current.clone();
        events.push({
          start: currentStart.toISOString(),
          end: currentEnd.toISOString(),
          display: 'background',
          color: isSelectable ? 'green' : 'grey',
          costraint: isSelectable ? 'available' : 'busy',
        });
      }
    }

    return events;
  };

  /**
   * This function creates several background events for available and not available slots.
   * Basically, it takes the current week and it creates N slots (4 business days, X slots per day),
   * when the slots is after today it's meant to be available, otherwise it will be marked as busy.
   */
  const getBackgroundEvents = useCallback((info, successCallback) => {
    let backgroundEvents: EventInput[] = [];

    const startMorning = moment(info.startStr).set({
      hour: 9,
      minute: 0,
      second: 0,
    });
    const endMorning = moment(info.startStr).set({
      hour: 13,
      minute: 0,
      second: 0,
    });

    const startAfternoon = moment(info.startStr).set({
      hour: 14,
      minute: 0,
      second: 0,
    });
    const endAfternoon = moment(info.startStr).set({
      hour: 17,
      minute: 30,
      second: 0,
    });

    backgroundEvents = [
      ...createBackgroundEvents(startMorning, endMorning),
      ...createBackgroundEvents(startAfternoon, endAfternoon),
    ];

    successCallback(backgroundEvents);
  }, []);

  /**
   * Allow to select only slots that last at most 1 hours.
   * @param {DateSpanApi} span the timespan of the selection
   * @returns {Boolean}
   */
  const selectAllow = (span: DateSpanApi): boolean => {
    const duration = moment(span.endStr).diff(span.startStr, 'minutes');
    return duration >= 30 && duration <= 60;
  };

  /**
   * Allow to select only available slots.
   * @param {EventApi} event the event already on this slot.
   * @returns {Boolean}
   */
  const selectOverlap = (event: EventApi): boolean =>
    event.toPlainObject().backgroundColor === 'green';

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader>Loading</Loader>
      </Dimmer>
      <FullCalendar
        ref={calendarRef}
        loading={isLoading => setLoading(isLoading)}
        initialView="timeGridWeek"
        plugins={[TimeGridPlugin, MomentPlugin, InteractionPlugin]}
        locale={it}
        allDaySlot={false}
        displayEventTime={false}
        slotMinTime="09:00:00"
        slotMaxTime="17:30:00"
        slotDuration="00:30:00"
        expandRows
        weekends={false}
        eventConstraint="businessHours"
        businessHours={[
          {
            daysOfWeek: [1, 2, 3, 4, 5],
            startTime: '09:00',
            endTime: '13:00',
          },
          {
            daysOfWeek: [1, 2, 3, 4, 5],
            startTime: '14:00',
            endTime: '17:30',
          },
        ]}
        progressiveEventRendering
        lazyFetching
        eventSources={[getBackgroundEvents, getScheduleEvents]}
        selectable
        selectConstraint="businessHours"
        selectAllow={selectAllow}
        selectOverlap={selectOverlap}
        unselectAuto={false}
        select={info => {
          setFieldTouched('start', true);
          setFieldValue('start', info.startStr);
          setFieldValue('end', info.endStr);
        }}
        unselect={() => {
          setFieldValue('start', null);
          setFieldValue('end', null);
        }}
      />
      <div className="appointment_help">
        <Popup trigger={<Image src={questionMark} />}>
          <Header as="h2" className="appointment_title">
            Legenda
          </Header>
          <p>
            Seleziona uno slot singolo da 30 minuti oppure trascina il mouse su
            due slot adiacenti per prenotare un’unica sessione da 1 ora
          </p>
          <ul className="appointment_legend">
            <li>
              <span className="appointment_legend__box appointment_legend__box--not-available" />
              <span className="appointment_legend__color">Grigio</span>
              <span className="appointment_legend__title">
                Slot non selezionabile
              </span>
            </li>
            <li>
              <span className="appointment_legend__box appointment_legend__box--busy" />
              <span className="appointment_legend__color">Rosso</span>
              <span className="appointment_legend__title">Slot occupato</span>
            </li>
            <li>
              <span className="appointment_legend__box appointment_legend__box--available" />
              <span className="appointment_legend__color">Verde</span>
              <span className="appointment_legend__title">
                Slot disponibile
              </span>
            </li>
            <li>
              <span className="appointment_legend__box appointment_legend__box--selected" />
              <span className="appointment_legend__color">Blu</span>
              <span className="appointment_legend__title">
                Slot selezionato
              </span>
            </li>
          </ul>
        </Popup>
        Clicca sulla lente gialla per un supporto alla prenotazione della
        consulenza
      </div>
    </>
  );
};

export default AppointmentCalendar;
