// Constants
import { AxiosPromise } from 'axios';
import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const download = (id: string): AxiosPromise<any> => {
  return standardClient({
    url: `${API.ATTACHMENT_MANAGER}`,
    method: 'POST',
    responseType: 'blob',
    data: {
      method: 'download',
      params: {
        id: id,
        'attachment-type': 'allegato4',
      },
    },
  });
};

const downloadDocument = (id: string): void => {
  download(id)
    .then(res => {
      const contentDisposition = res.headers['content-disposition'];
      const match = contentDisposition.match(`filename="(.*)"`);
      const filename = match ? match[1] : '';
      const newBlob = new Blob([res.data], { type: res.data.type });
      const fileUrl = window.URL.createObjectURL(newBlob);
      const tempLink = document.createElement('a');
      tempLink.href = fileUrl;
      tempLink.setAttribute('download', filename);
      tempLink.click();
    })
    .catch(() => {
      toast.error('Errore durante il download del PDF');
    });
};
const AttachmentService = {
  download,
  downloadDocument,
};

export default AttachmentService;
