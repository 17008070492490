import { AxiosResponse } from 'axios';
import qs from 'qs';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { PaginateObject } from 'utils/types/paginateObject';
import { Plan, PlanFilterValues, PlanObjectResponse } from './types';

/**
 * Gets plans list eventually with filters applied.
 */
export const loadPlans = (
  values: PlanFilterValues | null = null,
): Promise<AxiosResponse<PaginateObject<PlanObjectResponse>>> => {
  /** Create filters array if necessary */
  const subscribable = values?.subscribable || null;
  const params = {
    filter: [
      subscribable && {
        type: 'eq',
        field: 'subscribable',
        value: subscribable,
      },
    ].filter(o => o),
  };
  // Convert params to string
  const paramsSerializer = qs.stringify(params);

  return standardClient.request({
    url: `${API.PLAN}?${paramsSerializer}`,
    method: 'GET',
  });
};

/**
 * Gets a plan given an id.
 */
export const loadPlanById = (
  id: string,
): Promise<AxiosResponse<Plan | null>> => {
  return standardClient.request({
    url: `${API.PLAN}/${id}`,
    method: 'GET',
  });
};
