import { Formik } from 'formik';
import { Form } from 'formik-semantic-ui-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { Grid, Header } from 'semantic-ui-react';
import { updateRequest } from 'services/request';
import { States } from 'services/stateMachine/types';
import { useStateMachine } from 'services/stateMachine/useStateMachine';

type StepClientValues = {
  value: string | null;
};

/**
 * This step is shown only if we are not able to fetch the customer data from the CodicePlastico API.
 * TODO: What does this step need to do? Can we re-send the request to the CodicePlastico API?
 * @returns
 */
export const StepClienteRichiestaFallita: React.FC = () => {
  /**
   * The state machine configuration.
   */
  const { state, dispatch } = useStateMachine();

  /**
   * The initial values.
   */
  const initialValues: StepClientValues = {
    value: null,
  };

  const onSubmit = () => {
    updateRequest(
      {
        id: state.request?.id,
        state: States.ClienteRiepilogo,
      },
      dispatch,
    ).then(() =>
      toast.warning(
        "Il pulsante prosegui ha forzato l'avanzamento al prossimo step anche se mancano i dati provenienti dalle API di Codice Plastico.",
      ),
    );
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form id={`form__${state.current}`}>
        <Grid textAlign="center">
          <Grid.Row>
            <Grid.Column>
              <FormattedMessage
                id="request.failed"
                defaultMessage="Ops! Qualcosa è andato storto..."
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">
                <FormattedMessage
                  id="request.failedCompanyData"
                  defaultMessage="Abbiamo trovato un problema durante il recupero dei dati della {company}"
                  values={{
                    company: (
                      <span>
                        <FormattedMessage
                          id="request.company"
                          defaultMessage="Società"
                        />
                      </span>
                    ),
                  }}
                />
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Formik>
  );
};
