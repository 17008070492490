import React, { useEffect, useState } from 'react';
import { Input } from 'formik-semantic-ui-react';
import CurrencyInput from 'react-currency-input-field';
import { ErrorMessage } from 'formik';
import { CurrencyInputProps } from './types';

export const CurrencyInputFormik: React.FC<CurrencyInputProps> = ({
  field,
  setFieldValue = undefined,
  placeholder = undefined,
  label = undefined,
  readOnly = false,
  prefix = '€ ',
  onChange = undefined,
  onBlur = undefined,
  decimalsLimit = 2,
  disabled = false,
  className = undefined,
}) => {
  /*eslint-disable-next-line @typescript-eslint/no-unused-vars*/
  const [value, setValue] = useState(field.value);

  /**
   * Update the local value when the field value is changed from outside the component.
   */
  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <span>
      <Input name={field.name} label={label} className={className}>
        <CurrencyInput
          className="currency_input_formik"
          name={field.name}
          placeholder={placeholder}
          readOnly={readOnly}
          decimalsLimit={decimalsLimit}
          prefix={prefix}
          value={field.value ? field.value : field.value == '0' ? '0' : ''}
          onValueChange={(value, name, values) => {
            setValue(values?.value || '');
            setFieldValue &&
              setFieldValue(
                field.name,
                values?.float ? values.float : values?.float === 0 ? 0 : null,
              );
            onChange && onChange(value || '', name, values);
          }}
          onBlur={event => onBlur && onBlur(event)}
          disabled={disabled}
        />
      </Input>
      <ErrorMessage name={field.name} component="span" />
    </span>
  );
};
