import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Grid, Header, Image, Segment, Tab } from 'semantic-ui-react';
import RegisterForm from '../../components/Forms/RegisterForm';

// Images
import email from 'assets/images/email.svg';

// Types
import { RegisterResponseState } from './types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FE_ROUTES } from 'utils/global/globalCostants';

const Register: React.FC = () => {
  /**
   * That state keeps the information about the current registration process.
   * Basically it's empty until the registration process will be succesful.
   */
  const [response, setResponse] = useState<RegisterResponseState>({
    sent: false,
    email: null,
  });
  return (
    <div>
      <Helmet>
        <title>Allegato4.it - Registrazione</title>
        <meta name="description" content="register page" />
        <script>{"smartsupp('chat:hide')"}</script>
      </Helmet>
      <div className="register">
        {response.sent && (
          <Grid className="register__container--sent">
            <Grid.Row textAlign="center">
              <Grid.Column>
                <Header as="h1" className="register__title--thank">
                  <FormattedMessage
                    id="register.thank"
                    defaultMessage="Grazie per esserti registrato al portale {name}"
                    values={{
                      name: (
                        <span>
                          <FormattedMessage
                            id="app.name"
                            defaultMessage="Allegato4.it"
                          />
                        </span>
                      ),
                    }}
                  />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="center">
              <Grid.Column>
                <Segment>
                  <div className="register__email-icon">
                    <Image src={email} />
                  </div>

                  <span className="register__title--check-email">
                    <FormattedMessage
                      id="register.succesful"
                      defaultMessage="La registrazione è avvenuta correttamente! controlla tua la casella mail {email}."
                      values={{
                        email: <span>{response.email}</span>,
                      }}
                    />
                  </span>
                  <Segment.Inline>
                    <Button
                      type="button"
                      className="register__button--to-dashboard"
                      content={
                        <Link to={FE_ROUTES.CUSTOMER}>
                          <FormattedMessage
                            id="link.homepage"
                            defaultMessage="Torna alla dashboard"
                          />
                        </Link>
                      }
                    />
                  </Segment.Inline>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        {!response.sent && (
          <>
            <Grid className="register_container">
              <Grid.Row className="access__form">
                <Tab
                  renderActiveOnly={false}
                  activeIndex={1}
                  panes={[
                    {
                      menuItem: {
                        as: Link,
                        id: 'login',
                        content: (
                          <FormattedMessage
                            id="link.login"
                            defaultMessage="Accedi"
                          />
                        ),
                        to: '/login',
                        key: 'login',
                      },
                    },
                    {
                      menuItem: {
                        as: Link,
                        id: 'register',
                        content: (
                          <FormattedMessage
                            id="link.register"
                            defaultMessage="Registrati"
                          />
                        ),
                        to: '/register',
                        key: 'register',
                      },
                      pane: (
                        <Tab.Pane key="register">
                          <RegisterForm setResponse={setResponse} />
                        </Tab.Pane>
                      ),
                    },
                  ]}
                />
              </Grid.Row>
            </Grid>
          </>
        )}
      </div>
    </div>
  );
};

export default Register;
