import React, { useEffect, useState } from 'react';
import { IntlProvider, useIntl } from 'react-intl';

import { LangProviderProps, LangProviderState } from './types';
import defaultMessages from '../../assets/lang/it.json';
import { DEFAULT_LOCALE } from 'utils/global/globalCostants';
import { setLocale } from 'yup';

/**
 * The YupLangProvider inject the translation of the Yup validation messages
 * and bind them to the Yup component.
 */
const YupLangProvider = ({ children }) => {
  const intl = useIntl();

  setLocale({
    mixed: {
      required: intl.formatMessage({
        id: 'error.mixed.required',
        defaultMessage: '${path} è richiesto',
      }),
      default: intl.formatMessage({
        id: 'error.mixed.default',
        defaultMessage: '${path} non è valido',
      }),
      defined: intl.formatMessage({
        id: 'error.mixed.defined',
        defaultMessage: '${path} deve essere definito',
      }),
      oneOf: intl.formatMessage({
        id: 'error.mixed.oneOf',
        defaultMessage:
          '${path} deve essere uno dei seguenti valori: ${values}',
      }),
      notOneOf: intl.formatMessage({
        id: 'error.mixed.notOneOf',
        defaultMessage:
          '${path} non deve essere uno dei seguenti valori: ${values}',
      }),
    },
    string: {
      length: intl.formatMessage({
        id: 'error.string.length',
        defaultMessage: '${path} deve essere lungo ${length} caratteri',
      }),
      min: intl.formatMessage({
        id: 'error.string.min',
        defaultMessage: '${path} deve essere lungo almeno ${min} caratteri',
      }),
      max: intl.formatMessage({
        id: 'error.string.max',
        defaultMessage: '${path} deve essere lungo al massimo ${max} caratteri',
      }),
      matches: intl.formatMessage({
        id: 'error.string.matches',
        defaultMessage:
          "${path} deve soddisfare la seguente regola: '${regex}'",
      }),
      email: intl.formatMessage({
        id: 'error.string.email',
        defaultMessage: '${path} deve essere un indirizzo e-mail valido',
      }),
      url: intl.formatMessage({
        id: 'error.string.url',
        defaultMessage: '${path} deve essere un URL valido',
      }),
      uuid: intl.formatMessage({
        id: 'error.string.uuid',
        defaultMessage: '${path} deve essere un UUID valido',
      }),
      trim: intl.formatMessage({
        id: 'error.string.trim',
        defaultMessage: '${path} deve essere una stringa rifilata',
      }),
      lowercase: intl.formatMessage({
        id: 'error.string.lowercase',
        defaultMessage: '${path} deve essere scritta in minuscolo',
      }),
      uppercase: intl.formatMessage({
        id: 'error.string.uppercase',
        defaultMessage: '${path} deve essere scritta in maiuscolo',
      }),
    },
    number: {
      min: intl.formatMessage({
        id: 'error.number.min',
        defaultMessage: '${path} deve essere maggiore o uguale a ${min}',
      }),
      max: intl.formatMessage({
        id: 'error.number.max',
        defaultMessage: '${path} deve essere minore o uguale a ${max}',
      }),
      lessThan: intl.formatMessage({
        id: 'error.number.lessThan',
        defaultMessage: '${path} deve essere minore di ${less}',
      }),
      moreThan: intl.formatMessage({
        id: 'error.number.moreThan',
        defaultMessage: '${path} deve essere maggiore di ${more}',
      }),
      positive: intl.formatMessage({
        id: 'error.number.positive',
        defaultMessage: '${path} deve essere un numero positivo',
      }),
      negative: intl.formatMessage({
        id: 'error.number.negative',
        defaultMessage: '${path} deve essere un numero negativo',
      }),
      integer: intl.formatMessage({
        id: 'error.number.integer',
        defaultMessage: '${path} deve essere un numero intero',
      }),
    },
    date: {
      min: intl.formatMessage({
        id: 'error.date.min',
        defaultMessage: '${path} deve essere successiva a ${min}',
      }),
      max: intl.formatMessage({
        id: 'error.date.max',
        defaultMessage: '${path} deve essere precedente a ${max}',
      }),
    },
    boolean: {
      isValue: intl.formatMessage({
        id: 'error.boolean.isValue',
        defaultMessage: '${path} deve essere ${value}',
      }),
    },
    object: {
      noUnknown: intl.formatMessage({
        id: 'error.object.noUnknown',
        defaultMessage: '${path} ha delle proprietà non conosciute: ${unknown}',
      }),
    },
    array: {
      min: intl.formatMessage({
        id: 'error.array.min',
        defaultMessage: '${path} deve avere almeno ${min} elementi',
      }),
      max: intl.formatMessage({
        id: 'error.array.max',
        defaultMessage: '${path} deve avere al massimo ${max} elementi',
      }),
      length: intl.formatMessage({
        id: 'error.array.length',
        defaultMessage: '${path} deve essere lungo ${length} elementi',
      }),
    },
  });

  return children;
};

/**
 * The LangProvider is meant to inject the application messages based on the current language.
 * In the first versione, the language comes from the browser settings, but it will be replaced
 * from the one saved in the logged user profile.
 *
 * There are several issues to deal on the scalability of the {lang}.json file.
 * Here some ideas:
 * - Add a loader (the website may not be ready until all the assets are loaded)
 * - Render the App component without translation (it takes the default language, that could be loaded statically)
 * @param param0
 * @returns
 */
const LangProvider: React.FC<LangProviderProps> = ({ children }) => {
  const [state, setState] = useState<LangProviderState>({
    language: navigator.language,
  });

  /*
   * Load asynchronously the current language messages from the compiled file.
   * TODO: It could be NOT production-ready since loading external assets in production could lead to some issues.
   */
  const loadLocale = async () => {
    const messageByLanguage = require.context(
      '../../assets/lang/',
      false,
      /\.json$/,
    );

    let messages = defaultMessages;

    if (state.language !== DEFAULT_LOCALE) {
      try {
        messages = messageByLanguage(`./${state.language}.json`);
      } catch (err) {}
    }

    setState({
      ...state,
      messages,
    });
  };

  /**
   * This effect is called at the first rendering.
   */
  useEffect(() => {
    loadLocale();
  }, []);

  /*
   * Load the localizated messages every time the navigator language changes.
   * TODO: Bind the user profile too.
   */
  useEffect(() => {
    if (navigator.language !== state.language) {
      setState({
        ...state,
        language: navigator.language,
      });

      loadLocale();
    }
  }, [navigator.language]);

  if (!state.messages) {
    return null;
  }

  return (
    <IntlProvider
      locale={state.language}
      messages={state.messages}
      defaultLocale={DEFAULT_LOCALE}
    >
      <YupLangProvider>{children}</YupLangProvider>
    </IntlProvider>
  );
};

export default LangProvider;
