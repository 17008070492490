import { AppContext } from 'pages/App';
import { appActionConstants } from 'pages/App/reducer/actions';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Breadcrumb, Button } from 'semantic-ui-react';
import { FE_ROUTES } from 'utils/global/globalCostants';
import { TopbarProps } from './types';

const Topbar: React.FC<TopbarProps> = ({
  className,
  topBarItems,
  breadcrumbs,
}) => {
  const { state, dispatch } = useContext(AppContext);

  return (
    <>
      <Menu
        className={`topbar ${className}`}
        inverted
        size="massive"
        style={{ position: 'fixed' }}
      >
        {topBarItems
          .filter(
            topBarItem =>
              !topBarItem.position || topBarItem.position === 'left',
          )
          .map(topBarItem => (
            <Menu.Item
              as={NavLink}
              style={{ textAlign: 'left' }}
              key={topBarItem.id}
              name={topBarItem.id}
              to={topBarItem.to}
            >
              {topBarItem.title}
            </Menu.Item>
          ))}
        {breadcrumbs && (
          <Menu.Item>
            <Breadcrumb divider="/" sections={breadcrumbs} />
          </Menu.Item>
        )}

        <Menu.Menu position="right">
          {topBarItems
            .filter(
              topBarItem =>
                topBarItem.position && topBarItem.position === 'right',
            )
            .filter(
              topBarItem =>
                (topBarItem.protected ?? false) === state.isAuthenticated,
            )
            .map(topBarItem => (
              <>
                {topBarItem.id === 'WPHome' ? (
                  <Menu.Item
                    style={{ textAlign: 'left' }}
                    key={topBarItem.id}
                    name={topBarItem.id}
                    href={topBarItem.to}
                    target="_blank"
                  >
                    {topBarItem.title}
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    as={NavLink}
                    style={{ textAlign: 'left' }}
                    key={topBarItem.id}
                    name={topBarItem.id}
                    to={topBarItem.to}
                  >
                    {topBarItem.title}
                  </Menu.Item>
                )}
              </>
            ))}
          {state.isAuthenticated && (
            <Menu.Item
              className="link__logout"
              as={NavLink}
              key="logout"
              name="logout"
              to={FE_ROUTES.LOGIN}
              style={{ textAlign: 'left' }}
              onClick={() => {
                localStorage.clear();
                dispatch({ type: appActionConstants.REVOKE_SESSION });
              }}
            >
              <Button
                onClick={() => {
                  localStorage.clear();
                  dispatch({ type: appActionConstants.REVOKE_SESSION });
                }}
              >
                Logout
              </Button>
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
    </>
  );
};

export default Topbar;
