import { ErrorMessage, Formik, FormikHelpers, getIn } from 'formik';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox, Form } from 'formik-semantic-ui-react';
import { Grid, Header, Button, Icon } from 'semantic-ui-react';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { updateRequest } from 'services/request';
import { States } from 'services/stateMachine/types';
import { StepDichiarazioniValues } from './types';
import * as Yup from 'yup';
import _ from 'lodash';
import { FormikWatcher } from 'components/FormikWatcher';

export const StepDichiarazioni: React.FC = () => {
  const { state, dispatch } = useStateMachine();

  const initialValues = useMemo<StepDichiarazioniValues>(() => {
    if (state.request) {
      return {
        hasExperiencedProvisionDisruptions: _.isNull(
          state.request.hasExperiencedProvisionDisruptions,
        )
          ? false
          : state.request.hasExperiencedProvisionDisruptions,
        isIncomeDecreasedDueToConflict: _.isNull(
          state.request.isIncomeDecreasedDueToConflict,
        )
          ? false
          : state.request.isIncomeDecreasedDueToConflict,
        hasOutstandingPaymentsFromWarCountries: _.isNull(
          state.request.hasOutstandingPaymentsFromWarCountries,
        )
          ? false
          : state.request.hasOutstandingPaymentsFromWarCountries,
        isExposedToCyberAttacks: _.isNull(state.request.isExposedToCyberAttacks)
          ? false
          : state.request.isExposedToCyberAttacks,
        higherEnergyCosts: _.isNull(state.request.higherEnergyCosts)
          ? false
          : state.request.higherEnergyCosts,
        error: undefined,
      };
    }

    return {
      hasExperiencedProvisionDisruptions: false,
      isIncomeDecreasedDueToConflict: false,
      hasOutstandingPaymentsFromWarCountries: false,
      isExposedToCyberAttacks: false,
      higherEnergyCosts: false,
      error: undefined,
    };
  }, [state.request]);

  /**
   * Go to the next state.
   * @param values
   * @param formikHelpers
   */
  const onSubmit = (
    values: StepDichiarazioniValues,
    formikHelpers: FormikHelpers<StepDichiarazioniValues>,
  ) => {
    _.unset(values, 'error');
    updateRequest(
      {
        ...values,
        id: state.request?.id,
        state: States.DichiarazioniPrePagamento,
      },
      dispatch,
      formikHelpers,
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => {
        if (_.keys(_.pickBy(values)).length === 0) {
          formikHelpers.setFieldError(
            'error',
            'Seleziona una dichiarazione per passare allo step successivo.',
          );
        } else onSubmit(values, formikHelpers);
      }}
      validationSchema={Yup.object().shape({})}
    >
      {({ values, errors, isSubmitting }) => (
        <Form id={`form__${state.current}`}>
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <Header as="h2">
                  <FormattedMessage
                    id="stepDichiarazioni.flag"
                    defaultMessage="Flagga una delle seguenti "
                  />
                  <strong>
                    <FormattedMessage
                      id="stepDichiarazioni.options"
                      defaultMessage="opzioni "
                    />
                  </strong>
                  <br />
                  <FormattedMessage
                    id="stepDichiarazioni.case"
                    defaultMessage="se la società rientra nella casistiche esposta."
                  />
                </Header>
                <Header as="h4">
                  <FormattedMessage
                    id="stepDichiarazioni.information"
                    defaultMessage="Inserisci almeno una dichiarazione per passare allo step successivo."
                  />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <Checkbox
                  toggle
                  disabled={
                    _.keys(_.pickBy(values)).length > 0 &&
                    !values.hasExperiencedProvisionDisruptions
                  }
                  name="hasExperiencedProvisionDisruptions"
                  label={{
                    children: (
                      <FormattedMessage
                        id="stepDichiarazioni.hasExperiencedProvisionDisruptions"
                        defaultMessage="Il richiedente ha registrato interruzioni nelle catene di approvvigionamento"
                      />
                    ),
                  }}
                />
                <ErrorMessage
                  name="hasExperiencedProvisionDisruptions"
                  component="span"
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Checkbox
                  toggle
                  disabled={
                    _.keys(_.pickBy(values)).length > 0 &&
                    !values.higherEnergyCosts
                  }
                  name="higherEnergyCosts"
                  label={{
                    children: (
                      <FormattedMessage
                        id="stepDichiarazioni.higherEnergyCosts"
                        defaultMessage="Il richiedente ha registrato forti incrementi nei prezzi dell’energia, delle materie prime e/o semilavorati per effetto del conflitto"
                      />
                    ),
                  }}
                />
                <ErrorMessage name="higherEnergyCosts" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <Checkbox
                  disabled={
                    _.keys(_.pickBy(values)).length > 0 &&
                    !values.isIncomeDecreasedDueToConflict
                  }
                  toggle
                  name="isIncomeDecreasedDueToConflict"
                  label={{
                    children: (
                      <FormattedMessage
                        id="stepDichiarazioni.isIncomeDecreasedDueToConflict"
                        defaultMessage="Il richiedente ha subito un forte calo del fatturato poiché molto esposto in quei mercati"
                      />
                    ),
                  }}
                />
                <ErrorMessage
                  name="isIncomeDecreasedDueToConflict"
                  component="span"
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Checkbox
                  toggle
                  disabled={
                    _.keys(_.pickBy(values)).length > 0 &&
                    !values.hasOutstandingPaymentsFromWarCountries
                  }
                  name="hasOutstandingPaymentsFromWarCountries"
                  label={{
                    children: (
                      <FormattedMessage
                        id="stepDichiarazioni.hasOutstandingPaymentsFromWarCountries"
                        defaultMessage="Il richiedente ha pagamenti in sospeso dalla Russia o dall'Ucraina"
                      />
                    ),
                  }}
                />
                <ErrorMessage
                  name="hasOutstandingPaymentsFromWarCountries"
                  component="span"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <Checkbox
                  disabled={
                    _.keys(_.pickBy(values)).length > 0 &&
                    !values.isExposedToCyberAttacks
                  }
                  toggle
                  name="isExposedToCyberAttacks"
                  label={{
                    children: (
                      <FormattedMessage
                        id="stepDichiarazioni.isExposedToCyberAttacks"
                        defaultMessage="Il richiedente ha registrato un aumento dei costi per la sicurezza informatica o è fortemente esposto a rischi di attacchi informatici"
                      />
                    ),
                  }}
                />
                <ErrorMessage name="isExposedToCyberAttacks" component="span" />
              </Grid.Column>
            </Grid.Row>
            {getIn(errors, 'error') && (
              <Grid.Row>
                <Grid.Column>
                  <span className="error-all error">
                    Per passare allo step successivo devi selezionare una tra le
                    dichiarazioni di cui sopra.
                    <br />
                    Se nessuna delle voci elencate corrisponde alla tua
                    situazione, clicca sul pulsante qui sotto per tornare alla
                    scelta del regime da applicare.
                  </span>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column>
                <Button
                  type="button"
                  icon
                  onClick={() =>
                    updateRequest(
                      {
                        id: state.request?.id,
                        state: States.VerificaSceltaDocumento + '_Back',
                      },
                      dispatch,
                    )
                  }
                  className="button--underline"
                >
                  <Icon name="arrow left" />
                  <FormattedMessage
                    id="profile.goBackToList"
                    defaultMessage="Torna alla scelta dei regimi"
                  />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <FormikWatcher
            values={values}
            errors={errors}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};
