import DataGrid from 'components/DataGrid';
import React, { createContext, useEffect, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Grid, Header, Icon } from 'semantic-ui-react';
import {
  dataGridReducer,
  DataGridState,
  initialDataGridState,
} from 'components/DataGrid/reducer/reducer';
import {
  DataGridAction,
  DataGridActionConstants,
} from 'components/DataGrid/reducer/actions';
import {
  initialVoucherState,
  voucherReducer,
  VoucherState,
} from './reducer/reducer';
import { getVoucherList } from 'services/voucher/';
import { VoucherAction } from './reducer/actions';
import moment from 'moment';
import { VoucherFormatterParams } from 'components/DataGrid/types';
import _ from 'lodash';
import VoucherFilterForm from 'components/Forms/VoucherFilterForm';
import { VoucherFilterFormValues } from 'components/Forms/VoucherFilterForm/types';

export const VoucherContext = createContext<{
  state: VoucherState;
  dispatch: React.Dispatch<VoucherAction>;
  dataGridState: DataGridState;
  dataGridDispatch: React.Dispatch<DataGridAction>;
}>({
  state: initialVoucherState,
  dispatch: () => null,
  dataGridState: initialDataGridState,
  dataGridDispatch: () => null,
});

const Voucher: React.FC = () => {
  const [state, dispatch] = useReducer(voucherReducer, initialVoucherState);
  const [dataGridState, dataGridDispatch] = useReducer(
    dataGridReducer,
    initialDataGridState,
  );

  /**
   * Get voucher list on pagination data change.
   */
  useEffect(() => {
    // Get voucher collection
    getVoucherList(
      filters,
      dataGridState.paginationData,
      dataGridDispatch,
      dispatch,
    );
  }, [dataGridState.paginationData.page]);

  /**
   * Manages page selection change.
   * @param data
   */
  const handleSelectPage = (data: string | number) => {
    dataGridDispatch({
      type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
      payload: {
        pageNumber: data,
      },
    });
  };

  const [filters, setFilters] = useState<VoucherFilterFormValues>({
    isActive: null,
    isUsed: null,
    agentFilter: null,
    agentFilterId: null,
    agentId: null,
  });

  /**
   * Manages search submit with filters.
   * @param values
   */
  const onSubmit = (values: VoucherFilterFormValues) => {
    const cloneValues = _.clone(values);
    let filterAgentId = '';
    if (values.agentFilter && values.agentFilter.value) {
      filterAgentId = values.agentFilter.value;
      cloneValues.agentFilterId = filterAgentId;
    } else cloneValues.agentFilterId = null;
    // Store filters locally
    setFilters(cloneValues);
    // Get request collection
    if (dataGridState.paginationData.page != 1) {
      dataGridDispatch({
        type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
        payload: {
          pageNumber: 1,
        },
      });
    } else {
      getVoucherList(
        cloneValues,
        dataGridState.paginationData,
        dataGridDispatch,
        dispatch,
      );
    }
  };

  return (
    <VoucherContext.Provider
      value={{ state, dispatch, dataGridState, dataGridDispatch }}
    >
      <div className="voucher-container">
        <Helmet>
          <body className="voucher customer-requests" />
          <title>Allegato4.it - Elenco Voucher</title>
          <script>{"smartsupp('chat:hide')"}</script>
          <meta name="description" content="voucher list page" />
        </Helmet>
        <div className="voucher__content">
          <Header className="voucherHeader" dividing>
            <Grid>
              <Grid.Column width={3}>
                <h1>
                  <FormattedMessage
                    id="voucher.title"
                    defaultMessage="Elenco voucher"
                  />
                </h1>
              </Grid.Column>
              <Grid.Column width={13}>
                <VoucherFilterForm
                  onSubmit={onSubmit}
                  voucherDispatch={dispatch}
                />
              </Grid.Column>
            </Grid>
          </Header>
          <div className="voucherSectionBody">
            <DataGrid
              isLoading={dataGridState.isLoading}
              elements={state.voucherList}
              columns={[
                {
                  key: 'code',
                  name: (
                    <span>
                      <FormattedMessage
                        id="voucher.code"
                        defaultMessage="Codice voucher"
                      />
                    </span>
                  ),
                },
                {
                  key: 'isActive',
                  name: (
                    <span>
                      <FormattedMessage
                        id="voucher.isActive"
                        defaultMessage="Attivo/Non attivo"
                      />
                    </span>
                  ),
                  formatter: ({ data }: VoucherFormatterParams) => {
                    return data.isActive ? (
                      <Icon name="check" />
                    ) : (
                      <Icon name="close" />
                    );
                  },
                },
                {
                  key: 'discountPercentage',
                  name: (
                    <span>
                      <FormattedMessage
                        id="voucher.discountPercentage"
                        defaultMessage="Percentuale di sconto"
                      />
                    </span>
                  ),
                },
                {
                  key: 'releaseDate',
                  name: (
                    <span>
                      <FormattedMessage
                        id="voucher.releaseDate"
                        defaultMessage="Data di generazione"
                      />
                    </span>
                  ),
                  formatter: ({ data }: VoucherFormatterParams) => {
                    return data.releaseDate
                      ? moment(data.releaseDate).format('DD/MM/YYYY')
                      : '-';
                  },
                },
                {
                  key: 'agent.name',
                  name: (
                    <span>
                      <FormattedMessage
                        id="voucher.agent"
                        defaultMessage="Agente"
                      />
                    </span>
                  ),
                  formatter: ({ data }: VoucherFormatterParams) => {
                    const agent = _.get(data, 'agent', null);
                    return agent ? agent.name + ' ' + agent.surname : '-';
                  },
                },
                {
                  key: 'isUsed',
                  name: (
                    <span>
                      <FormattedMessage
                        id="voucher.isUsed"
                        defaultMessage="Utilizzato/Non utilizzato"
                      />
                    </span>
                  ),
                  formatter: ({ data }: VoucherFormatterParams) => {
                    return data.isUsed ? (
                      <Icon name="check" />
                    ) : (
                      <Icon name="close" />
                    );
                  },
                },
                {
                  key: 'usedAt',
                  name: (
                    <span>
                      <FormattedMessage
                        id="voucher.usedAt"
                        defaultMessage="Usato il"
                      />
                    </span>
                  ),
                  formatter: ({ data }: VoucherFormatterParams) => {
                    return data.usedAt
                      ? moment(data.usedAt).format('DD/MM/YYYY')
                      : '-';
                  },
                },
                {
                  key: 'customer.businessName',
                  name: (
                    <span>
                      <FormattedMessage
                        id="voucher.usedBy"
                        defaultMessage="Utilizzato da"
                      />
                    </span>
                  ),
                  formatter: ({ data }: VoucherFormatterParams) => {
                    const request = _.get(data, 'request', null);
                    return request ? request.customer : '-';
                  },
                },
              ]}
              totalItems={state.voucherList.length}
              page={dataGridState.paginationData.page}
              onPageSelect={handleSelectPage}
              paginate
              pageSize={dataGridState.paginationData.page_size}
              pageCount={dataGridState.paginationData.page_count}
            />
          </div>
        </div>
      </div>
    </VoucherContext.Provider>
  );
};

export default Voucher;
