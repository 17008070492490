import { Customer } from 'services/customer/types';
import { CustomerAction, CustomerActionConstants } from './actions';

export type CustomerState = {
  isLoading: boolean;
  customerList: Array<Customer>;
};

export const initialCustomerState: CustomerState = {
  isLoading: false,
  customerList: [],
};

export function customerReducer(
  state: CustomerState,
  action: CustomerAction,
): CustomerState {
  const { type } = action;

  switch (type) {
    case CustomerActionConstants.SET_SEARCH_FILTER:
      return { ...state, isLoading: action.payload.isLoading };
    case CustomerActionConstants.SET_CUSTOMER_DICTIONARY:
      return { ...state, customerList: action.payload.customerList };
    default:
      return state;
  }
}
