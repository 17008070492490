export type PaymentRequest = {
  id: string;
  customer: string;
  request: string;
  plan: string;
  grossAmount: number;
  netAmount: number;
  vat: number;
  type: string;
  source: string;
};

export type PaymentFilterValues = {
  request: string | null;
  types: string[] | null;
};

export enum PaymentTypes {
  GOLD = 'Gold',
  SILVER = 'Silver',
  SILVER_CONSULTING = 'SilverConsulenza',
  CONSULTING = 'Consulenza',
}

export enum PaymentEntities {
  PLAN = 'plan',
  CONSULTING = 'consulting',
}
