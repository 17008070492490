import React from 'react';
import { FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import _ from 'lodash';
import qs from 'qs';
import { AxiosPromise, AxiosResponse } from 'axios';
import {
  CustomerRequestAction,
  CustomerRequestActionConstants,
  RequestCompletionType,
} from 'pages/CustomerRequest/reducer/actions';
import { API } from 'utils/global/backendRoutes';
import {
  Condition,
  Request,
  RequestObjectResponse,
  RequestRequest,
  MaintenanceModeRes,
} from './types';
import { PaginateObject } from 'utils/types/paginateObject';
import {
  DataGridAction,
  DataGridActionConstants,
} from 'components/DataGrid/reducer/actions';
import { CustomerRequestFilterFormValues } from 'components/Forms/CustomerRequestFilterForm/types';
import { StepClienteRiepilogoValues } from 'components/RequestStep/StepClienteRiepilogo/types';
import { StepClienteIdentificavoAziendaValues } from 'components/RequestStep/StepClienteIdentificativoAzienda/types';
import { StepSceltaClienteValues } from 'components/RequestStep/StepClienteSceltaCliente/types';
import { StepPianoValues } from 'components/RequestStep/StepPiano/types';
import { InvoiceFormValues } from 'components/Forms/InvoiceForm/types';
import { StepClienteRichiestaPendingValues } from 'components/RequestStep/StepClienteRichiestaPending/types';
import { StepFinanziamentoValues } from 'components/RequestStep/StepFinanziamento/types';
import { StepFinalitaValues } from 'components/RequestStep/StepFinalita/types';
import { StepBilancioCalcoloDimensionaleAziendaValues } from 'components/RequestStep/StepBilancioCalcoloDimensionaleAzienda/types';
import { StepDichiarazioniValues } from 'components/RequestStep/StepDichiarazioni/types';
import { StepDichiarazioniDeMinimisValues } from 'components/RequestStep/StepDichiarazioniDeMinimis/types';
import { StepVerificaValues } from 'components/RequestStep/StepVerifica/types';
import { BalanceFormValues } from 'components/RequestStep/StepBilancio/types';
import { PaginationData } from 'components/DataGrid/types';
import { InvestmentFormValues } from 'components/RequestStep/StepInvestimento/types';
import { RegimeFormValues } from 'components/RequestStep/StepRegimi/types';
import { StandardResponseError } from 'services/client/types';
import standardClient from 'services/client/standardRequestClient';
import {
  StateMachineAction,
  StateMachineActionType,
} from 'services/stateMachine/types';
import {
  RequestPollingAction,
  RequestPollingActionConstants,
} from 'components/RequestPolling/reducer/actions';
import { AppAction, appActionConstants } from 'pages/App/reducer/actions';
import { StepCassaProfessionaleValues } from 'components/RequestStep/StepClienteCassaProfessionale/types';

/**
 * Load the request by its ID.
 * @param id the request ID
 * @param dispatch the dispatcher
 * @param appDispatch the app dispatcher
 */
export const getRequest = (
  id: string,
  dispatch: React.Dispatch<StateMachineAction>,
  appDispatch: React.Dispatch<AppAction>,
): void => {
  standardClient({
    url: `${API.REQUEST}/${id}`,
    method: 'GET',
  })
    .then((response: AxiosResponse<Request>) => {
      dispatch({
        type: StateMachineActionType.SetRequest,
        request: response.data,
      });
    })
    .catch(err => {
      if (
        err &&
        ((err.data && err.data.detail === 'changing-skin') ||
          err.detail === 'changing-skin')
      ) {
        appDispatch({
          type: appActionConstants.SET_UNDER_MAINTENANCE,
          payload: { underMaintenance: true },
        });
      } else toast.error('Errore durante il caricamento della richiesta');
    })
    .finally(() => {
      dispatch({
        type: StateMachineActionType.Ready,
      });
    });
};

/**
 * Load the request by its ID.
 * @param id the request ID
 */
export const getRequestPromise = (
  id: string,
  param?: string,
): Promise<AxiosResponse<Request>> => {
  const params = {};
  if (!_.isEmpty(param)) params['step'] = param;
  const paramsSerializer = qs.stringify(params);

  return standardClient.request({
    url: `${API.REQUEST}/${id}${
      !_.isEmpty(param) ? '?' + paramsSerializer : ''
    }`,
    method: 'GET',
  });
};

/**
 * Gets request list with filters applied if any.
 * @param values Filters values.
 * @param paginationData Pagination data.
 * @param dispatch Dispatch to manage callback results.
 */
export const getRequestList = (
  values: CustomerRequestFilterFormValues,
  paginationData: PaginationData,
  dataGridDispatch: React.Dispatch<DataGridAction>,
  dispatch: React.Dispatch<CustomerRequestAction>,
): void => {
  const requestType = values.requestType || null;

  const params = {
    filter: [
      requestType &&
        requestType != RequestCompletionType.ALL && {
          type:
            requestType == RequestCompletionType.COMPLETED
              ? 'isnotnull'
              : 'isnull',
          field: 'endDate',
        },
    ].filter(o => o),
    pageSize: paginationData.page_size,
    page: _.get(paginationData, 'page', 1) === 0 ? 1 : paginationData.page,
  };
  // Convert params to string
  const paramsSerializer = qs.stringify(params);

  dataGridDispatch({
    type: DataGridActionConstants.SET_IS_LOADING,
    payload: {
      isLoading: true,
    },
  });

  standardClient({
    url: `${API.REQUEST}?${paramsSerializer}`,
    method: 'GET',
  })
    .then((response: AxiosResponse<PaginateObject<RequestObjectResponse>>) => {
      // Dispatch the notification for request dictionary
      dispatch({
        type: CustomerRequestActionConstants.SET_REQUEST_DICTIONARY,
        payload: { requestList: response.data._embedded.request },
      });
      setDataGridPaginator(response, dataGridDispatch);
      dataGridDispatch({
        type: DataGridActionConstants.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      });
    })
    .catch(() => {
      toast.error('Errore durante il caricamento delle richieste');
      dataGridDispatch({
        type: DataGridActionConstants.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      });
    });
};

/**
 * Set paginator for DataGrid.
 * @param response
 * @param dataGridDispatch
 * @returns
 */
const setDataGridPaginator = (
  response: AxiosResponse<PaginateObject<RequestObjectResponse>>,
  dataGridDispatch: React.Dispatch<DataGridAction>,
) => {
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_PAGE_COUNT,
    payload: {
      page_count: response.data.page_count,
    },
  });
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
    payload: {
      pageNumber: response.data.page,
    },
  });
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_TOTAL_ITEMS,
    payload: {
      total_items: response.data.total_items,
    },
  });
  return dataGridDispatch;
};

/**
 * Updates a request.
 * @param content
 * @param dispatch
 * @param formikHelpers
 * @returns
 */
export const updateRequest = (
  content: Partial<RequestRequest>,
  dispatch: React.Dispatch<StateMachineAction>,
  formikHelpers?:
    | FormikHelpers<StepClienteIdentificavoAziendaValues>
    | FormikHelpers<StepClienteRiepilogoValues>
    | FormikHelpers<StepSceltaClienteValues>
    | FormikHelpers<StepPianoValues>
    | FormikHelpers<StepFinalitaValues>
    | FormikHelpers<StepClienteRichiestaPendingValues>
    | FormikHelpers<StepFinanziamentoValues>
    | FormikHelpers<StepBilancioCalcoloDimensionaleAziendaValues>
    | FormikHelpers<StepDichiarazioniValues>
    | FormikHelpers<StepDichiarazioniDeMinimisValues>
    | FormikHelpers<StepVerificaValues>
    | FormikHelpers<BalanceFormValues>
    | FormikHelpers<InvestmentFormValues>
    | FormikHelpers<InvoiceFormValues>
    | FormikHelpers<RegimeFormValues>
    | FormikHelpers<StepCassaProfessionaleValues>,
): Promise<boolean> => {
  const { id, ...rest } = content;
  const { state } = content;

  if (!state?.endsWith('Back')) {
    dispatch({
      type: StateMachineActionType.Forward,
      forward: true,
    });
  } else {
    dispatch({
      type: StateMachineActionType.Backward,
      backward: true,
    });
  }

  return standardClient({
    url: `${API.REQUEST}/${id}`,
    method: 'PUT',
    data: {
      ...rest,
    },
  })
    .then((response: AxiosResponse<Request>) => {
      dispatch({
        type: StateMachineActionType.SetRequest,
        request: response.data,
      });
      return true;
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      const errors = _.get(error, 'data.validation_messages', null);

      if (formikHelpers && errors) {
        // Set the errors in the Formik bag.
        _.forOwn(errors, (value, key) => {
          for (const i in value) {
            formikHelpers.setFieldError(key, value[i]);
            break;
          }
        });
      } else {
        toast.error("Errore durante l'aggiornamento della richiesta");
      }
      return false;
    })
    .finally(() => {
      // Stop formik submission.
      formikHelpers?.setSubmitting(false);

      // Tell the "Prosegui" button to stop the submission as well.
      dispatch({
        type: StateMachineActionType.Forward,
        forward: false,
      });

      dispatch({
        type: StateMachineActionType.Backward,
        backward: false,
      });
    });
};

/**
 * Create a requests.
 * @param content
 * @param dispatch
 * @returns
 */
export const createRequest = (
  content: Partial<Request>,
  dispatch: React.Dispatch<StateMachineAction>,
): Promise<void> =>
  standardClient({
    url: `${API.REQUEST}`,
    method: 'POST',
    data: {
      ...content,
    },
  }).then((response: AxiosResponse<Request>) => {
    dispatch({
      type: StateMachineActionType.SetRequest,
      request: response.data,
    });
  });

/**
 * Updates a request without dispatch and formik helpers.
 * @param content
 * @param dispatch
 * @param formikHelpers
 * @returns
 */
export const updateRequestPromise = (
  content: Partial<RequestRequest>,
): Promise<void> => {
  const { id, ...rest } = content;

  return standardClient.request({
    url: `${API.REQUEST}/${id}`,
    method: 'PUT',
    data: {
      ...rest,
    },
  });
};

/**
 * Methods which executes a polling on a request until the given conditions are met or a timeout is reached.
 * @param id
 * @param conditions
 * @returns
 */
export const getRequestUntil = (
  id: string,
  conditions: Condition[],
  timeout: number,
  startTime: Date,
  dispatch: React.Dispatch<RequestPollingAction>,
): void => {
  // If there are no conditions to meet, immediately dispatch a polling success event and return
  if (conditions.length === 0) {
    dispatch({
      type: RequestPollingActionConstants.POLLING_ERROR,
      payload: { isError: true },
    });
    return;
  }
  // If we reached the timeout, dispatch a polling timeout event and return
  const elapsedTime = new Date().getTime() - startTime.getTime();
  if (elapsedTime > timeout) {
    dispatch({
      type: RequestPollingActionConstants.POLLING_TIMEOUT,
      payload: { isTimeout: true },
    });
    return;
  }

  // Get the specified request from the backend services
  standardClient({
    url: `${API.REQUEST}/${id}`,
    method: 'GET',
  })
    .then((response: AxiosResponse<Request>) => {
      // Check if the request meets the required conditions
      let isConditionsMet = true;
      conditions.forEach(c => {
        switch (c.type) {
          case 'eq':
            if (response.data[c.field] !== c.value) {
              isConditionsMet = false;
            }
            break;
          case 'neq':
            if (response.data[c.field] === c.value) {
              isConditionsMet = false;
            }
            break;
          case 'notNull':
            if (!response.data[c.field]) {
              isConditionsMet = false;
            }
            break;
          case 'notEmpty':
            if (response.data[c.field].length === 0) {
              isConditionsMet = false;
            }
            break;
        }
      });
      // If the request doesn't meet the conditions, iterate over this method until we reach a timeout or a correct answer
      // otherwise dispatch a polling success event and return.
      if (!isConditionsMet) {
        setTimeout(
          () => getRequestUntil(id, conditions, timeout, startTime, dispatch),
          5000,
        );
      } else {
        dispatch({
          type: RequestPollingActionConstants.POLLING_SUCCESS,
          payload: { request: response.data },
        });
        return;
      }
    })
    .catch(() => {
      dispatch({
        type: RequestPollingActionConstants.POLLING_ERROR,
        payload: { isError: true },
      });
      return;
    });
};

/**
 * Updates a request.
 * @param content
 * @param dispatch
 * @param formikHelpers
 * @returns
 */
export const updateFinalStepRequest = (
  content: Partial<RequestRequest>,
  dispatch: React.Dispatch<StateMachineAction>,
): AxiosPromise<void> => {
  const { id, ...rest } = content;
  const { state } = content;
  if (!state?.endsWith('Back')) {
    dispatch({
      type: StateMachineActionType.Forward,
      forward: true,
    });
  } else {
    dispatch({
      type: StateMachineActionType.Backward,
      backward: true,
    });
  }
  return standardClient({
    url: `${API.REQUEST}/${id}`,
    method: 'PUT',
    data: {
      ...rest,
    },
  });
};

export const forceRequestToNextStep = (
  id: string | undefined,
  state: string | undefined,
): AxiosPromise => {
  return standardClient({
    url: `${API.REQUEST}/${id}`,
    method: 'PATCH',
    data: {
      state,
    },
  });
};

/**
 * Check application maintenance mode
 * @param appDispatch the app dispatcher
 */
export const checkMaintenanceMode = (
  appDispatch: React.Dispatch<AppAction>,
): Promise<void> => {
  return standardClient({
    url: `${API.MAINTENANCE_MODE}`,
    method: 'GET',
  })
    .then((res: AxiosResponse<MaintenanceModeRes>) => {
      if (_.has(res.data, 'changingSkin') || _.has(res, 'changingSkin')) {
        appDispatch({
          type: appActionConstants.SET_UNDER_MAINTENANCE,
          payload: { underMaintenance: res.data.changingSkin },
        });
      }
    })
    .catch(() => {
      toast.error("Errore durante il caricamento dell'applicazione");
    });
};
