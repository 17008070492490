import { Nullable } from 'utils/types';
import { Dispatch } from 'react';
import { SetStateAction } from 'react';

export interface AppointmentFormType {
  open: boolean;
}

export interface AppointmentFormValues {
  id?: string;
  type: Nullable<string>;
  note: Nullable<string>;
  attachments: Nullable<File[]>;
  email: Nullable<string>;
  start: Nullable<string>;
  end: Nullable<string>;
  request?: Nullable<string>;
}

export interface AppointmentUpdateValues {
  id?: string;
  type: Nullable<string>;
  note: Nullable<string>;
  attachments: Nullable<File[]>;
  email: Nullable<string>;
  start: Nullable<string>;
  end: Nullable<string>;
  request?: Nullable<string>;
}

export enum AppointmentStates {
  Calendar,
  Info,
  End,
}

export type ForwardButtonProps = {
  index: number;
  setIndex: Dispatch<SetStateAction<AppointmentStates>>;
  setSubmittingForm: Dispatch<SetStateAction<boolean>>;
  submittingForm: boolean;
};
