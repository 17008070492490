import React from 'react';
import { Image, Modal } from 'semantic-ui-react';

import questionMark from 'assets/images/icon/question-mark.svg';

import { TooltipProps } from './types';

/**
 * Tooltip component for general use cases.
 * @param props The semantic-ui popup props except the content
 * @returns a question mark that triggers a tooltip message
 */
export const Tooltip = (props: TooltipProps): JSX.Element => {
  const { header, content, ...rest } = props;

  return (
    <Modal
      {...rest}
      closeIcon
      dimmer="inverted"
      trigger={<Image src={questionMark} style={{ cursor: 'pointer' }} />}
    >
      <>
        <Modal.Header>
          <span className="tooltip">
            <span className="tooltip tooltip--left">
              <Image src={questionMark} />
              <span>{header}</span>
            </span>
          </span>
        </Modal.Header>
        <Modal.Content>{content}</Modal.Content>
      </>
    </Modal>
  );
};
