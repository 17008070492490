import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { Checkbox, Form, SubmitButton, Select } from 'formik-semantic-ui-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Header } from 'semantic-ui-react';
import * as Yup from 'yup';
import { updateFinalStepRequest, updateRequest } from 'services/request';
import { verificaDocumentOptions } from './verificaTypes';
import { StateMachineActionType, States } from 'services/stateMachine/types';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { DocumentType, StepVerificaValues } from './types';
import AttachmentService from 'services/attachment';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';

export const StepVerifica: React.FC = () => {
  /**
   * The state machine configuration.
   */
  const { state, dispatch } = useStateMachine();

  /**
   * The initial values.
   */
  const initialValues: StepVerificaValues = {
    documentType: state.request?.documentType
      ? state.request?.documentType
      : DocumentType.DomandaDiAgevolazione,
    warrantyEgf: false,
    hasAlreadyEgfWarranty: false,
    ineligibilityEgfWarranty: false,
    expirationDate: state.request?.expirationDate,
  };

  /**
   * Returns the wizard back to the first step.
   * It helps to review the data before submitting them.
   */
  const onReview = () => {
    updateRequest(
      {
        id: state.request?.id,
        state: States.ClienteRiepilogo,
      },
      dispatch,
    );
  };

  /**
   * Update the request and generate the document.
   * In case the document type is "Garanzia Diretta" another step could be involved in case there is a warranty check to perform on the ATECO code of the
   * customer.
   * @param values the picked document type,
   * @param formikHelpers the formik helpers
   */
  const onSubmit = (
    values: StepVerificaValues,
    formikHelpers: FormikHelpers<StepVerificaValues>,
  ) => {
    formikHelpers.setSubmitting(true);
    dispatch({
      type: StateMachineActionType.Forward,
      forward: true,
    });
    updateFinalStepRequest(
      {
        id: state.request?.id,
        state: States.VerificaGeneraDocumento,
        documentType: values.documentType
          ? values.documentType
          : DocumentType.GaranziaDiretta,
        expirationDate: values.expirationDate,
      },
      dispatch,
    )
      .then(response => {
        AttachmentService.downloadDocument(
          _.get(response, 'data.attachment.id'),
        );
      })
      .catch(() => {
        toast.error("Errore durante l'aggiornamento della richiesta");
      })
      .finally(() => {
        // Stop formik submission.
        formikHelpers?.setSubmitting(false);

        // Tell the "Prosegui" button to stop the submission as well.
        dispatch({
          type: StateMachineActionType.Forward,
          forward: false,
        });

        dispatch({
          type: StateMachineActionType.Backward,
          backward: false,
        });
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        documentType: Yup.string()
          .required('Il tipo di documento è richiesto')
          .oneOf([DocumentType.DomandaDiAgevolazione]),
        warrantyEgf: Yup.boolean().nullable(),
        hasAlreadyEgfWarranty: Yup.boolean()
          .when('warrantyEgf', {
            is: (match: boolean) => match === true,
            then: Yup.boolean().required(),
          })
          .when('warrantyEgf', {
            is: (match: boolean) => !match,
            then: Yup.boolean().nullable(),
          }),
        ineligibilityEgfWarranty: Yup.boolean()
          .when('warrantyEgf', {
            is: (match: boolean) => match === true,
            then: Yup.boolean().required(),
          })
          .when('warrantyEgf', {
            is: (match: boolean) => !match,
            then: Yup.boolean().nullable(),
          }),
      })}
    >
      {({ values }) => (
        <Form id={`form__${States.VerificaGeneraDocumento}`}>
          <Grid textAlign="center">
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">
                  <strong>
                    <FormattedMessage
                      id="request.title.verifica"
                      defaultMessage="Hai terminato la compilazione"
                    />
                  </strong>
                </Header>
                <Header as="h4">
                  <FormattedMessage
                    id="stepVerifica.verify"
                    defaultMessage="Verifica i dati inseriti e procedi alla generazione del documento."
                  />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={2}>
              <Grid.Column className="document-column">
                <Select
                  label={
                    <FormattedMessage
                      id="common.message"
                      tagName="p"
                      defaultMessage="Seleziona tipo di documento da generare:"
                    />
                  }
                  name="documentType"
                  options={verificaDocumentOptions}
                  disabled
                />
                <ErrorMessage name="documentType" component="span" />
              </Grid.Column>
            </Grid.Row>
            {values.documentType === DocumentType.GaranziaDiretta &&
              values.warrantyEgf === true && (
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Checkbox
                      label={
                        <label>
                          <FormattedMessage
                            id="request.hasAlreadyEgfWarranty"
                            defaultMessage="L’azienda ha già beneficiato a livello di gruppo di ulteriori Garanzie EGF?"
                          />
                        </label>
                      }
                      name="hasAlreadyEgfWarranty"
                    />
                    <ErrorMessage
                      name="hasAlreadyEgfWarranty"
                      component="span"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      label={
                        <label>
                          <FormattedMessage
                            id="request.ineligibilityEgfWarranty"
                            defaultMessage="L’azienda si trova in una condizione di non ammissibilità alla Garanzia EGF?"
                          />
                        </label>
                      }
                      name="ineligibilityEgfWarranty"
                    />
                    <ErrorMessage
                      name="ineligibilityEgfWarranty"
                      component="span"
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
            <Grid.Row>
              <Grid.Column>
                <SubmitButton className="button--primary">
                  <FormattedMessage
                    id="request.generateDocument"
                    defaultMessage="Genera documento"
                  />
                </SubmitButton>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <a
                  onClick={onReview}
                  className="ui button--underline--negative"
                  style={{ cursor: 'pointer' }}
                >
                  <FormattedMessage
                    id="request.rewiewData"
                    defaultMessage="Visualizza riepilogo dati"
                  />
                </a>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <span style={{ color: 'red' }}>
                  <FormattedMessage
                    id="request.requestEditableUpTo"
                    defaultMessage="La richiesta resterà modificabile fino al "
                  />
                  {values.expirationDate
                    ? moment(values.expirationDate).format(
                        'DD/MM/YYYY  HH:mm:ss',
                      )
                    : ''}
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
