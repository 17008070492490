import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { Form, SubmitButton, Select } from 'formik-semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';

// Types
import {
  CustomerTypeSelectionFormProps,
  CustomerTypeSelectionValues,
} from './types';
import { CustomerType } from 'pages/Customer/reducer/actions';
import { customerTypeOptions } from '../CustomerFilterForm/const';

const CustomerTypeSelectionForm: React.FC<CustomerTypeSelectionFormProps> = ({
  onSubmit,
}) => {
  /**
   * The react-intl object.
   */
  const intl = useIntl();

  /**
   * The initial modal values.
   */
  const initialModalValues: CustomerTypeSelectionValues = {
    customerType: CustomerType.WEBUSER,
  };

  return (
    <Formik
      initialValues={initialModalValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        customerType: Yup.string()
          .label(
            intl.formatMessage({
              id: 'customerFilter.customerType',
              defaultMessage: 'Tipo cliente',
            }),
          )
          .required()
          .nullable(),
      })}
    >
      {values => (
        <Form>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">
                  <FormattedMessage
                    id="customerType.title"
                    defaultMessage="Seleziona tipo cliente"
                  />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Select
                  name="customerType"
                  options={customerTypeOptions(false)}
                />
                <ErrorMessage name="customerType" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="right" columns={1}>
              <Grid.Column>
                {values.values.customerType != null && (
                  <SubmitButton>
                    <FormattedMessage
                      id="customerType.select"
                      defaultMessage="Seleziona"
                    />
                  </SubmitButton>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerTypeSelectionForm;
