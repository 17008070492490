import React, { useContext } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import {
  Form,
  SubmitButton,
  Field,
  Input,
  Select,
} from 'formik-semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ErrorMessage, Formik, getIn } from 'formik';
import * as Yup from 'yup';
import AsyncSelect from 'react-select/async';
import {
  GenerateVoucherFormProps,
  GenerateVoucherFormValues,
  AgentValue,
  discountPercentageOptions,
} from './types';
import { generateVoucher, getVoucherList } from 'services/voucher';
import { loadAgent } from 'services/agent';
import _ from 'lodash';
import { VoucherContext } from 'pages/Voucher';

const GenerateVoucherForm: React.FC<GenerateVoucherFormProps> = ({
  voucherDispatch,
  generateVoucherDispatch,
  voucherFilterValues,
}) => {
  /**
   * The react-intl object.
   */
  const intl = useIntl();

  /**
   * The initial modal values.
   */
  const initialModalValues: GenerateVoucherFormValues = {
    numberOfVoucher: 0,
    agentId: null,
    discountPercentage: '',
  };

  const { dataGridState, dataGridDispatch } = useContext(VoucherContext);

  return (
    <Formik
      initialValues={initialModalValues}
      onSubmit={(values, formikHelpers) => {
        generateVoucher(values, formikHelpers, generateVoucherDispatch).then(
          () => {
            getVoucherList(
              voucherFilterValues,
              dataGridState.paginationData,
              dataGridDispatch,
              voucherDispatch,
            );
          },
        );
      }}
      validationSchema={Yup.object().shape({
        agentId: Yup.mixed()
          .label(
            intl.formatMessage({
              id: 'generateVoucher.agent',
              defaultMessage: 'Agente',
            }),
          )
          .required()
          .nullable(false),
        numberOfVoucher: Yup.number()
          .label(
            intl.formatMessage({
              id: 'generateVoucher.numberOfVoucher',
              defaultMessage: 'Numero di voucher',
            }),
          )
          .positive()
          .required()
          .min(1)
          .max(50)
          .nullable(),
        discountPercentage: Yup.string()
          .required()
          .label(
            intl.formatMessage({
              id: 'generateVoucher.discountPercentage',
              defaultMessage: 'Percentuale di sconto',
            }),
          ),
      })}
    >
      {({ values, errors, touched }) => (
        <Form id="form__generateVoucher">
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">
                  <FormattedMessage
                    id="generateVoucher.title"
                    defaultMessage="Genera voucher"
                  />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <label>
                  <FormattedMessage
                    id="generateVoucher.agent"
                    defaultMessage="Agente"
                  />
                  *
                </label>
                <br />
                <Field name="agentId">
                  {({ field, form }) => (
                    <>
                      <AsyncSelect
                        isClearable={true}
                        value={field.value}
                        loadOptions={(
                          inputValue: string,
                          callback: (options: AgentValue[]) => void,
                        ) => {
                          loadAgent(inputValue, callback);
                        }}
                        placeholder="Digitare il nome di un agente"
                        noOptionsMessage={() => 'Nessun risultato trovato'}
                        loadingMessage={() => 'Caricamento...'}
                        cacheOptions
                        defaultOptions
                        onChange={value =>
                          form.setFieldValue(field.name, value)
                        }
                      />
                    </>
                  )}
                </Field>
                <ErrorMessage
                  className="error"
                  name="agentId"
                  component="span"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Input
                  label={
                    <label>
                      <FormattedMessage
                        id="generateVoucher.numberOfVoucher"
                        defaultMessage="Numero di voucher"
                      />
                      *
                    </label>
                  }
                  name="numberOfVoucher"
                  type="number"
                  step="1"
                  min={0}
                />
                <ErrorMessage name="numberOfVoucher" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Select
                  label={
                    <FormattedMessage
                      id="generateVoucher.discountPercentage"
                      defaultMessage="Seleziona la percentuale di sconto:"
                    />
                  }
                  name="discountPercentage"
                  options={discountPercentageOptions}
                  clearable
                  className={
                    getIn(errors, 'discountPercentage') &&
                    getIn(touched, 'discountPercentage')
                      ? !_.isEmpty(getIn(values, 'discountPercentage'))
                        ? 'discountPercentage error filled'
                        : 'discountPercentage error'
                      : !_.isEmpty(getIn(values, 'discountPercentage'))
                      ? 'discountPercentage filled'
                      : 'discountPercentage'
                  }
                />
                <ErrorMessage
                  name="discountPercentage"
                  component="span"
                  className={
                    getIn(errors, 'discountPercentage') &&
                    getIn(touched, 'discountPercentage')
                      ? 'error'
                      : undefined
                  }
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="right" columns={1}>
              <Grid.Column>
                <SubmitButton>
                  <FormattedMessage
                    id="generateVoucher.generate"
                    defaultMessage="Genera"
                  />
                </SubmitButton>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default GenerateVoucherForm;
