import React, { useEffect, useState } from 'react';
import { Input } from 'formik-semantic-ui-react';
import CurrencyInput from 'react-currency-input-field';
import { ErrorMessage } from 'formik';
import { PercentageInputProps } from './types';

export const PercentageInputFormik: React.FC<PercentageInputProps> = ({
  field,
  setFieldValue = undefined,
  placeholder = undefined,
  label = undefined,
  readOnly = false,
  suffix = '%',
  onChange = undefined,
  onBlur = undefined,
}) => {
  const [value, setValue] = useState(field.value);

  /**
   * Update the local value when the field value is changed from outside the component.
   */
  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <span>
      <Input name={field.name} label={label}>
        <CurrencyInput
          className="currency_input_formik"
          name={field.name}
          placeholder={placeholder}
          readOnly={readOnly}
          decimalsLimit={2}
          suffix={suffix}
          value={value || undefined}
          onValueChange={(value, name, values) => {
            setValue(values?.value || '');
            setFieldValue && setFieldValue(field.name, values?.float || null);
            onChange && onChange(value || '', name, values);
          }}
          onBlur={event => onBlur && onBlur(event)}
        />
      </Input>
      <ErrorMessage name={field.name} component="span" />
    </span>
  );
};
