export const getMonthsArray = (
  curMonth: number,
  curYear: number,
): Array<string> => {
  const calendarMonths = [
    'Gen',
    'Feb',
    'Mar',
    'Apr',
    'Mag',
    'Giu',
    'Lug',
    'Ago',
    'Set',
    'Ott',
    'Nov',
    'Dic',
  ];

  const actualMonths: Array<string> = [];
  let changeYear = false;

  for (let i = curMonth + 12; i > curMonth; i--) {
    const adjustedIndex = i % 12;
    actualMonths.push(
      calendarMonths[adjustedIndex] +
        ' ' +
        `${changeYear ? (curYear - 1).toString() : curYear.toString()}`,
    );
    if (adjustedIndex === 0) changeYear = true;
  }

  return actualMonths;
};
