import React from 'react';
import { AxiosResponse } from 'axios';
import standardClient from 'services/client/standardRequestClient';
import {
  StateMachineAction,
  StateMachineActionType,
  StateMachineResponse,
} from './types';
import { API } from 'utils/global/backendRoutes';

/**
 * Load the state machine configuration.
 * @param module the entity name
 * @param dispatch a state machine dispatcher
 */
export const loadStateMachineConfig = (
  module: string,
  dispatch: React.Dispatch<StateMachineAction>,
): Promise<void> => {
  return standardClient
    .request({
      url: API.STATE_MACHINE,
      method: 'POST',
      data: {
        method: 'get-config',
        params: {
          module,
        },
      },
    })
    .then((response: AxiosResponse<StateMachineResponse>) => {
      dispatch({
        type: StateMachineActionType.Init,
        config: response.data['request-state'],
      });
    })
    .catch(() => {
      dispatch({ type: StateMachineActionType.Fail });
    });
};
