import React from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Input, Select, TextArea } from 'formik-semantic-ui-react';
import { DropdownItemProps, Grid } from 'semantic-ui-react';
import { AppointmentFormValues } from '../types';
import { FormattedMessage } from 'react-intl';

/**
 * List of the default options for the event type.
 */
const EventTypeOptions: Array<DropdownItemProps> = [
  {
    text: (
      <FormattedMessage
        id="event.type1"
        defaultMessage="Difficoltà nel reperire i dati delle aziende collegate"
      />
    ),
    value: 'Difficoltà nel reperire i dati delle aziende collegate',
  },
  {
    text: (
      <FormattedMessage
        id="event.type2"
        defaultMessage="Difficoltà nel reperire i dati di bilancio"
      />
    ),
    value: 'Difficoltà nel reperire i dati di bilancio',
  },
  {
    text: (
      <FormattedMessage
        id="event.type3"
        defaultMessage="Difficoltà nel compilare i dati relativi al programma di Investimento"
      />
    ),
    value:
      'Difficoltà nel compilare i dati relativi al programma di Investimento',
  },
  {
    text: (
      <FormattedMessage
        id="event.type4"
        defaultMessage="Difficoltà nella selezione delle finalità"
      />
    ),
    value: 'Difficoltà nella selezione delle finalità',
  },
  {
    text: <FormattedMessage id="event.type5" defaultMessage="Altro" />,
    value: 'Altro',
  },
];

export const AppointmentInfo: React.FC<{ readOnly?: boolean }> = ({
  readOnly = false,
}) => {
  /**
   * The current formik context.
   */
  const { setFieldValue, values } = useFormikContext<AppointmentFormValues>();

  // /**
  //  * The multi file input reference.
  //  */
  // const fileRef = useRef<HTMLInputElement>(null);

  // /**
  //  * This method handles the return of the import file windows.
  //  */
  // const handleUploadChange = event => {
  //   // Get all the files.
  //   const attachments = [...event.target.files];

  //   // Change the Formik attachments value.
  //   setFieldValue('attachments', attachments);
  // };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Input
            label={
              <label>
                <FormattedMessage id="event.start" defaultMessage="Data" />*
              </label>
            }
            name="start"
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                error={false}
                helperText={null}
                clearable
                disableToolbar
                format="DD/MM/YYYY HH:mm:ss"
                autoOk
                disabled
                onChange={value => setFieldValue('start', value)}
                InputProps={{
                  disableUnderline: true,
                }}
                readOnly
                value={values.start}
              />
            </MuiPickersUtilsProvider>
          </Input>
          <ErrorMessage name="start" component="span" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Select
            label={
              <label>
                <FormattedMessage
                  id="appointment.type"
                  defaultMessage="Motivazione"
                />
                *
              </label>
            }
            name="type"
            options={EventTypeOptions}
            clearable
          />
          <ErrorMessage name="type" component="span" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Input
            name="email"
            label={
              <label>
                <FormattedMessage
                  id="appointment.email"
                  defaultMessage="E-mail"
                />
                *
              </label>
            }
            disabled={readOnly}
          />
          <ErrorMessage name="email" component="span" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <TextArea
            name="note"
            label={
              <label>
                <FormattedMessage id="appointment.note" defaultMessage="Note" />
                *
              </label>
            }
          />
          <ErrorMessage name="note" component="span" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {/* <Field name="attachments">
              <input
                ref={fileRef}
                name="attachments"
                onChange={handleUploadChange}
                type="file"
                multiple
              />
            </Field> */}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
