import { AppContext } from 'pages/App';
import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Types
import { CanCustomerListProps } from './types';
import { isThatRole } from 'utils/function/acl';
import { FE_ROUTES, ROLES } from 'utils/global/globalCostants';
import { CustomerPageActionConstants } from 'pages/Customer/reducer/actions';

const CanCustomerList: React.FC<CanCustomerListProps> = ({ children }) => {
  const { state } = useContext(AppContext);

  const location = useLocation();

  /**
   * If the user is not allowed to access customer list,
   * redirect him to its profile page
   */
  if (
    !isThatRole(ROLES.ADMIN) &&
    !isThatRole(ROLES.ACCOUNTANT) &&
    !isThatRole(ROLES.BANK)
  ) {
    const customerId = state.mySelf?.customer || '';
    const myRoute = state.mySelf?.customer
      ? `${CustomerPageActionConstants.VIEW}/${customerId}`
      : '';
    /** Compose the navigation URL to the current user detail area */
    const customerDetailLink = customerId
      ? `${FE_ROUTES.CUSTOMER}/${myRoute}`
      : `${FE_ROUTES.CUSTOMER}`;
    return (
      <Navigate to={customerDetailLink} state={{ from: location }} replace />
    );
  }

  return children;
};

export default CanCustomerList;
