import React, { useMemo, useState } from 'react';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { Form, Select, Field, Checkbox } from 'formik-semantic-ui-react';
import { Grid, Header, DropdownItemProps } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import _, { forEach } from 'lodash';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { RegimeFormValues } from './types';
import { getRegimeDescription } from 'utils/function/getRegimeDescription';
import { updateRequest } from 'services/request';
import { getIn } from 'formik';
import { States } from 'services/stateMachine/types';

export const StepRegimi: React.FC = () => {
  const { state, dispatch } = useStateMachine();
  const [regimeOptions, setRegimeOptions] = useState<Array<DropdownItemProps>>([
    { text: 'Non ci sono regimi disponibili', value: 'default' },
  ]);

  const initialValues = useMemo<RegimeFormValues>(() => {
    const suitableRegimesFromCP: DropdownItemProps[] = [];
    let isTcfAnOption = false;
    if (state.request && state.request.suitableRegimes) {
      const suitableRegimesArray = state.request.suitableRegimes.split(',');
      forEach(suitableRegimesArray, regime => {
        const option = {
          text: getRegimeDescription(regime),
          value: regime,
        };
        suitableRegimesFromCP.push(option);
        if (regime === 'AiutiTcf') isTcfAnOption = true;
      });
      setRegimeOptions(suitableRegimesFromCP);
    }
    let initialSubsidyValue: boolean | null = null;
    if (
      state.request &&
      !_.isUndefined(state.request.subsidyAimedAtEnergyRenovation)
    ) {
      if (
        state.request?.isTourismSpecialSectionEligible === false &&
        _.isNull(state.request.subsidyAimedAtEnergyRenovation)
      )
        initialSubsidyValue = false;
      else initialSubsidyValue = state.request.subsidyAimedAtEnergyRenovation;
    }
    return {
      regime: state.request?.regime
        ? state.request?.regime
        : isTcfAnOption
        ? 'AiutiTcf'
        : null,
      subsidyAimedAtEnergyRenovation: initialSubsidyValue,
    };
  }, [state.request?.regime, state.request?.suitableRegimes]);

  const onSubmit = (
    values: RegimeFormValues,
    formikHelpers: FormikHelpers<RegimeFormValues>,
  ) => {
    let nextState = States.PrePagamento;
    switch (values.regime) {
      case 'AiutiTcf':
        nextState = States.Dichiarazioni;
        break;
      default:
        nextState = States.DichiarazioniDeMinimis;
    }
    updateRequest(
      {
        ...values,
        state: nextState,
        id: state.request?.id,
      },
      dispatch,
      formikHelpers,
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        regime: Yup.string()
          .nullable()
          .required('Devi selezionare almeno un regime')
          .test(
            'Tcf not applicable',
            'Attenzione: Il Regime TCF non è applicabile',
            value => {
              if (value) {
                if (value === 'AiutiTcf') {
                  if (!state.request?.connectedToConflict) return false;
                }
              }
              return true;
            },
          ),
      })}
    >
      {({ errors, setFieldValue }) => (
        <Form id={`form__${state.current}`}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">
                  <FormattedMessage
                    id="stepRegimi.formTitle"
                    defaultMessage="Quale regime vuoi applicare?"
                  />
                </Header>
                <Header.Subheader as="h5">
                  <FormattedMessage
                    id="stepRegimi.formSubTitle"
                    defaultMessage="Seleziona un regime tra quelli disponibili"
                  />
                </Header.Subheader>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Select
                  label={
                    <FormattedMessage
                      id="regime.selectRegime"
                      defaultMessage="Seleziona il regime da applicare:"
                    />
                  }
                  name="regime"
                  options={regimeOptions}
                  clearable
                  className={getIn(errors, 'regime') ? 'error' : undefined}
                />
                <ErrorMessage
                  name="regime"
                  component="span"
                  className={getIn(errors, 'regime') ? 'error' : undefined}
                />
              </Grid.Column>
            </Grid.Row>
            {state.request?.isTourismSpecialSectionEligible === false && (
              <Grid.Row>
                <Grid.Column width={12}>
                  <Field name="subsidyAimedAtEnergyRenovation">
                    {({ field }) => (
                      <Checkbox
                        toggle
                        name="subsidyAimedAtEnergyRenovation"
                        label={{
                          children: (
                            <FormattedMessage
                              id="request.subsidyAimedAtEnergyRenovation"
                              defaultMessage="Il finanziamento è finalizzato alla realizzazione di obiettivi di efficientamento o diversificazione della produzione o del consumo energetici?"
                            />
                          ),
                        }}
                        onChange={(event, data) => {
                          setFieldValue(field.name, data.checked ?? false);
                        }}
                      />
                    )}
                  </Field>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
