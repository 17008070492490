import { RequestCompletionType } from 'pages/CustomerRequest/reducer/actions';

export const requestCompletionTypeOptions = [
  {
    text: 'Tutte le richieste',
    value: RequestCompletionType.ALL,
  },
  {
    text: 'Concluse',
    value: RequestCompletionType.COMPLETED,
  },
  {
    text: 'In corso',
    value: RequestCompletionType.IN_PROGRESS,
  },
];
