export const isActiveVoucherOptions = [
  {
    text: 'Tutti i voucher',
    value: 'all',
  },
  {
    text: 'Attivi',
    value: 'active',
  },
  {
    text: 'Non attivi',
    value: 'nonActive',
  },
];

export const isUsedVoucherOptions = [
  {
    text: 'Tutti i voucher',
    value: 'all',
  },
  {
    text: 'Utilizzati',
    value: 'used',
  },
  {
    text: 'Non utilizzati',
    value: 'unused',
  },
];
