import { Request } from 'services/request/types';

export enum CustomerRequestActionConstants {
  SET_SEARCH_FILTER = 'Request/SET_SEARCH_FILTER',
  SET_REQUEST_DICTIONARY = 'Request/SET_CUSTOMER_DICTIONARY',
}

export enum RequestCompletionType {
  ALL = 'all',
  IN_PROGRESS = 'progress',
  COMPLETED = 'completed',
}

export enum RequestPageActionConstants {
  VIEW = 'view',
  MODIFY = 'edit',
  NEW = 'new',
}

export type CustomerRequestAction =
  | {
      type: CustomerRequestActionConstants.SET_SEARCH_FILTER;
      payload: { isLoading: boolean };
    }
  | {
      type: CustomerRequestActionConstants.SET_REQUEST_DICTIONARY;
      payload: { requestList: Array<Request> };
    };
