import React from 'react';
import { CurrencyInputFormik } from 'components/CurrencyInputFormik';
import { InvestmentDetailsRowProps } from './types';
import { ErrorMessage } from 'formik';
import { Input } from 'formik-semantic-ui-react';

export const InvestmentDetailsRow: React.FC<InvestmentDetailsRowProps> = ({
  color,
  type,
  field = undefined,
  value = undefined,
  label = undefined,
  readOnly = false,
  disabled = false,
  placeholder = undefined,
  setFieldValue = undefined,
  onChange = undefined,
  onBlur = undefined,
}) => {
  return (
    <span className={`investment__detail__${color}`}>
      {/** Show a simple bold text */}
      {type === 'text' && (
        <p className={`investment__detail__${color}__label`}>
          <b>{label}</b>
        </p>
      )}

      {/** Show a currency input */}
      {type === 'input-number' && field && (
        <span className={`investment__detail__${color}__input`}>
          <CurrencyInputFormik
            field={field}
            setFieldValue={setFieldValue}
            placeholder={placeholder}
            readOnly={readOnly}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
        </span>
      )}

      {/** Show an input type text */}
      {type === 'input-text' && field && (
        <span className={`investment__detail__${color}__input`}>
          <Input
            name={field.name}
            type="text"
            placeholder={placeholder}
            value={value}
          />
          <ErrorMessage name={field.name} component="span" />
        </span>
      )}
    </span>
  );
};
