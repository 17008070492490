import { AccountActivationState } from '../types';
import {
  AccountActivationAction,
  AccountActivationActionConstants,
} from './actions';

export const initialAppState: AccountActivationState = {
  status: null,
};

export function AccountActivationReducer(
  state: AccountActivationState,
  action: AccountActivationAction,
): AccountActivationState {
  const { type } = action;

  switch (type) {
    case AccountActivationActionConstants.SET_STATUS:
      return { ...state, status: action.payload.status };
    default:
      return state;
  }
}
