import React from 'react';
import { Form, Input } from 'formik-semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import { Grid, Header, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

// Types
import { CustomerProfileFormProps, CompanyTypeConstants } from './types';
import { CustomerPageActionConstants } from 'pages/Customer/reducer/actions';

const WebUserProfileForm: React.FC<CustomerProfileFormProps> = ({
  onSubmit,
  initialValues,
  mode,
}) => {
  /**
   * The react-intl component.
   */
  const intl = useIntl();

  /**
   * The form validation schema.
   */
  const validationSchema = Yup.object().shape({
    businessName: Yup.string()
      .required()
      .label(intl.formatMessage({ id: 'profile.businessName' }))
      .nullable(),
    email: Yup.string().required().email().nullable(),
    fiscalCode: Yup.string()
      .required()
      .label(intl.formatMessage({ id: 'profile.fiscalCodeVAT' }))
      .nullable(),
    pec: Yup.string().nullable(),
    companyType: Yup.string()
      .required()
      .label(intl.formatMessage({ id: 'profile.companyType' }))
      .nullable(),
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, values }) => (
          <Form id="customerProfileForm">
            <Grid padded>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3">
                    <FormattedMessage
                      id="profile.companyData"
                      defaultMessage="Dati società"
                    />
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Input
                    name="businessName"
                    placeholder="Ragione Sociale"
                    readOnly={mode === CustomerPageActionConstants.VIEW}
                    label={
                      <FormattedMessage
                        id="profile.businessName"
                        defaultMessage="Ragione Sociale"
                      />
                    }
                  />
                  <ErrorMessage name="businessName" component="span" />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Input
                    name="email"
                    placeholder="Email"
                    readOnly={mode === CustomerPageActionConstants.VIEW}
                    label={
                      <FormattedMessage
                        id="profile.email"
                        defaultMessage="Mail di contatto"
                      />
                    }
                  />
                  <ErrorMessage name="email" component="span" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Input
                    name="fiscalCode"
                    placeholder="Codice fiscale/P.IVA"
                    readOnly={mode === CustomerPageActionConstants.VIEW}
                    label={
                      <FormattedMessage
                        id="profile.fiscalCodeVAT"
                        defaultMessage="Codice fiscale/P.IVA"
                      />
                    }
                  />
                  <ErrorMessage name="fiscalCode" component="span" />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Input
                    name="pec"
                    placeholder="PEC"
                    readOnly={mode === CustomerPageActionConstants.VIEW}
                    label={
                      <FormattedMessage id="profile.PEC" defaultMessage="PEC" />
                    }
                  />
                  <ErrorMessage name="pec" component="span" />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid padded className="company-type">
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3">
                    <FormattedMessage
                      id="profile.companyType"
                      defaultMessage="Tipo società"
                    />
                  </Header>
                  <div className="error field" />
                  <ErrorMessage name="companyType" component="span" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Button.Group className="company-type-profile">
                    <Button
                      disabled={mode === CustomerPageActionConstants.VIEW}
                      type="button"
                      primary={
                        values.companyType === CompanyTypeConstants.FREELANCE
                      }
                      checked={
                        values.companyType === CompanyTypeConstants.FREELANCE
                      }
                      onClick={() => {
                        if (mode !== CustomerPageActionConstants.VIEW) {
                          setFieldValue(
                            'companyType',
                            CompanyTypeConstants.FREELANCE,
                          );
                        }
                      }}
                    >
                      <Icon name="suitcase" />
                      <FormattedMessage
                        id="profile.freelancer"
                        defaultMessage="Libero professionista"
                      />
                    </Button>
                    <Button
                      disabled={mode === CustomerPageActionConstants.VIEW}
                      type="button"
                      primary={
                        values.companyType === CompanyTypeConstants.PARTNERSHIP
                      }
                      checked={
                        values.companyType === CompanyTypeConstants.PARTNERSHIP
                      }
                      onClick={() => {
                        if (mode !== CustomerPageActionConstants.VIEW) {
                          setFieldValue(
                            'companyType',
                            CompanyTypeConstants.PARTNERSHIP,
                          );
                        }
                      }}
                    >
                      <Icon name="users" />
                      <FormattedMessage
                        id="profile.partnership"
                        defaultMessage="Società di persone"
                      />
                    </Button>
                    <Button
                      disabled={mode === CustomerPageActionConstants.VIEW}
                      type="button"
                      primary={
                        values.companyType ===
                        CompanyTypeConstants.LIMITEDCOMPANY
                      }
                      checked={
                        values.companyType ===
                        CompanyTypeConstants.LIMITEDCOMPANY
                      }
                      onClick={() => {
                        if (mode !== CustomerPageActionConstants.VIEW) {
                          setFieldValue(
                            'companyType',
                            CompanyTypeConstants.LIMITEDCOMPANY,
                          );
                        }
                      }}
                    >
                      <Icon name="building" />
                      <FormattedMessage
                        id="profile.limitedCompany"
                        defaultMessage="Società di capitali"
                      />
                    </Button>
                    <Button
                      disabled={mode === CustomerPageActionConstants.VIEW}
                      type="button"
                      primary={
                        values.companyType ===
                        CompanyTypeConstants.PROFESSIONALFIRM
                      }
                      checked={
                        values.companyType ===
                        CompanyTypeConstants.PROFESSIONALFIRM
                      }
                      onClick={() => {
                        if (mode !== CustomerPageActionConstants.VIEW) {
                          setFieldValue(
                            'companyType',
                            CompanyTypeConstants.PROFESSIONALFIRM,
                          );
                        }
                      }}
                    >
                      <Icon name="file alternate" />
                      <FormattedMessage
                        id="profile.professionalFirm"
                        defaultMessage="Studio professionale"
                      />
                    </Button>
                  </Button.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WebUserProfileForm;
