import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownItemProps } from 'semantic-ui-react';

export const balanceSheetTypesOptions: Array<DropdownItemProps> = [
  {
    text: (
      <FormattedMessage
        id="BilancioDepositatoCCIAA"
        defaultMessage="Bilancio depositato CCIAA"
      />
    ),
    value: 'BilancioDepositatoCCIAA',
  },
];

export const incomeStatementTypesOptions: Array<DropdownItemProps> = [
  {
    text: (
      <FormattedMessage
        id="DichiarazioneRedditiTrasmessaAgenziaEntrate"
        defaultMessage="Dichiarazione redditi trasmessa all'Agenzia dell'Entrate"
      />
    ),
    value: 'DichiarazioneRedditiTrasmessaAgenziaEntrate',
  },
];
