import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  Formik,
  useFormikContext,
} from 'formik';
import { Field, Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { Button, Grid, Header, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import _ from 'lodash';

import { InvestmentFormValues } from 'components/RequestStep/StepInvestimento/types';
import { BenefitEntity } from './types';
import DataGrid from 'components/DataGrid';
import { PercentageInputFormik } from 'components/PercentageInputFormik';

export const Benefit: React.FC = () => {
  /**  The Formik values from the main form. */
  const { values } = useFormikContext<InvestmentFormValues>();

  /** The react-intl object. */
  const intl = useIntl();

  /**
   * The modal properties.
   * It stores the current legal representative index (optionally, if the modal is open and it's linked to a valid legal repr.)
   * and the open/close flag.
   */
  const [modal, setModal] = useState<{
    open: boolean;
    index?: number;
  }>({
    open: false,
  });

  /**
   *
   * @param arrayHelpers the utilities for the array property
   * @param data the selected row
   */
  const onRemove = (arrayHelpers: FieldArrayRenderProps, data) => {
    // Get the index of the the selected row.
    const index = values.benefits.indexOf(data);
    // Remote the legal representative from the array (and the main form).
    arrayHelpers.remove(index);
  };

  /**
   * Open the modal to edit the selected legal representative.
   * @param data the selected row
   */
  const onEdit = data => {
    // Get the index of the the selected row.
    const newIndex = values.benefits.indexOf(data);

    // Open the modal.
    setModal({
      open: true,
      index: newIndex,
    });
  };

  /**
   * This method performs two operations:
   * 1. Update the data of the selected legal representative
   * 2. Add a new legal representative
   *
   * @param arrayHelpers the utilities for the array property
   * @param values the formik values
   */
  const onSubmit = (arrayHelpers: FieldArrayRenderProps, values) => {
    // Check if the modal is open for a specific legal representative.
    if (modal.index != null) {
      arrayHelpers.replace(modal.index, values);
    } else {
      arrayHelpers.push(values);
    }

    setModal({ open: false });
  };

  const initialValues = useMemo<BenefitEntity>(() => {
    // If the modal is in "edit mode" use the value of the selected legal representative.
    if (modal.index != null) {
      return values.benefits[modal.index];
    }

    // Otherwise create an empty object.
    return {
      concessionDate: null,
      eslPercentage: '',
      grantor: '',
      type: '',
    };
  }, [modal.index]);

  return (
    <>
      <FieldArray
        name="benefits"
        render={arrayHelpers => (
          <>
            <Modal open={modal.open} onClose={() => setModal({ open: false })}>
              <Modal.Content>
                {modal.open && (
                  <Formik
                    initialValues={initialValues}
                    onSubmit={values => onSubmit(arrayHelpers, values)}
                    validationSchema={Yup.object().shape({
                      grantor: Yup.string()
                        .required()
                        .label(
                          intl.formatMessage({
                            id: 'benefit.tableGrantor',
                            defaultMessage: 'Riferimento normativo',
                          }),
                        ),
                      type: Yup.string()
                        .required()
                        .label(
                          intl.formatMessage({
                            id: 'benefit.tableType',
                            defaultMessage: 'Tipo di contributi',
                          }),
                        ),
                      eslPercentage: Yup.number()
                        .when('concessionDate', {
                          is: (match: unknown) => !_.isEmpty(match),
                          then: Yup.number().required(),
                        })
                        .label(
                          intl.formatMessage({
                            id: 'benefit.eslPercentage',
                            defaultMessage: 'ESL Ottenuto',
                          }),
                        )
                        .positive()
                        .min(0.0)
                        .max(100.0),
                      concessionDate: Yup.mixed()
                        .label(
                          intl.formatMessage({
                            id: 'investment.concessionDate',
                            defaultMessage: 'Data di concessione',
                          }),
                        )
                        .required()
                        .test(
                          'beforeToday',
                          `La concessione deve essere antecedente al ${moment().format(
                            'DD/MM/YYYY',
                          )}`,
                          value => {
                            const formattedValue = value
                              ? value.isValid
                                ? value.format('YYYY/MM/DD')
                                : moment(value)
                              : null;
                            return (
                              formattedValue &&
                              moment(formattedValue).isBefore(moment())
                            );
                          },
                        ),
                    })}
                  >
                    {({ values, setFieldValue, handleBlur }) => (
                      <Form id="form-benefit">
                        <Grid>
                          <Grid.Row>
                            <Grid.Column>
                              <Header as="h3">
                                <FormattedMessage
                                  id="benefit.title"
                                  defaultMessage="Dati agevolazione"
                                />
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row centered columns={2}>
                            <Grid.Column>
                              <Input
                                label={
                                  <label>
                                    <FormattedMessage
                                      id="benefit.grantor"
                                      defaultMessage="Riferimento normativo e amministrazione concedente"
                                    />
                                    *
                                  </label>
                                }
                                name="grantor"
                                type="text"
                              />
                              <ErrorMessage name="grantor" component="span" />
                            </Grid.Column>
                            <Grid.Column>
                              <Input
                                label={
                                  <label>
                                    <FormattedMessage
                                      id="benefit.type"
                                      defaultMessage="Tipo di contributi"
                                    />
                                    *
                                  </label>
                                }
                                name="type"
                                type="text"
                              />
                              <ErrorMessage name="type" component="span" />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row centered columns={2}>
                            <Grid.Column>
                              <Input
                                label={
                                  <label>
                                    <FormattedMessage
                                      id="benefit.concessionDate"
                                      defaultMessage="Data di concessione dell'agevolazione"
                                    />
                                    *
                                  </label>
                                }
                                name="concessionDate"
                              >
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <KeyboardDatePicker
                                    error={false}
                                    helperText={null}
                                    clearable
                                    disableToolbar
                                    format="DD/MM/YYYY"
                                    autoOk
                                    onChange={value =>
                                      setFieldValue('concessionDate', value)
                                    }
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    value={values.concessionDate}
                                  />
                                </MuiPickersUtilsProvider>
                              </Input>
                              <ErrorMessage
                                name="concessionDate"
                                component="span"
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Field name="eslPercentage">
                                {({ field }) => (
                                  <PercentageInputFormik
                                    field={field}
                                    label={
                                      <label>
                                        <FormattedMessage
                                          id="benefit.eslPercentage"
                                          defaultMessage="ESL Ottenuto"
                                        />
                                      </label>
                                    }
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                  />
                                )}
                              </Field>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                )}
              </Modal.Content>
              <Modal.Actions>
                <SubmitButton form="form-benefit">
                  {modal.index == null && (
                    <FormattedMessage
                      id="benefit.add"
                      defaultMessage="Aggiungi"
                    />
                  )}
                  {modal.index != null && (
                    <FormattedMessage
                      id="benefit.update"
                      defaultMessage="Aggiorna"
                    />
                  )}
                </SubmitButton>
              </Modal.Actions>
            </Modal>
            {/* The benefit table */}
            <DataGrid
              isLoading={false}
              paginate={false}
              page={1}
              pageCount={1}
              pageSize={10}
              onPageSelect={() => null}
              totalItems={10}
              elements={values.benefits}
              columns={[
                {
                  key: 'grantor',
                  name: (
                    <FormattedMessage
                      id="benefit.tableGrantor"
                      defaultMessage="Riferimento normativo"
                    />
                  ),
                },
                {
                  key: 'type',
                  name: (
                    <FormattedMessage
                      id="benefit.tableType"
                      defaultMessage="Tipo di contributi"
                    />
                  ),
                },
                {
                  key: 'concessionDate',
                  name: (
                    <FormattedMessage
                      id="benefit.tableConcessionDate"
                      defaultMessage="Data di concessione"
                    />
                  ),
                  formatter: ({ data }) =>
                    data.concessionDate
                      ? moment(data.concessionDate).format('DD/MM/YYYY')
                      : null,
                },
                {
                  key: 'eslPercentage',
                  name: (
                    <FormattedMessage
                      id="benefit.tableEslPercentage"
                      defaultMessage="ESL Ottenuto"
                    />
                  ),
                },
                {
                  key: 'actions',
                  name: '',
                  formatter: ({ data }) => {
                    return (
                      <>
                        <Button
                          type="button"
                          onClick={() => onEdit(data)}
                          className="button--primary"
                          style={{ marginBottom: '10px' }}
                        >
                          <FormattedMessage
                            id="request.editBenefit"
                            defaultMessage="Modifica"
                          />
                        </Button>
                        <Button
                          type="button"
                          onClick={() => onRemove(arrayHelpers, data)}
                          className="button--primary--negative"
                        >
                          <FormattedMessage
                            id="request.removeBenefit"
                            defaultMessage="Rimuovi"
                          />
                        </Button>
                      </>
                    );
                  },
                },
              ]}
            />
            {values.benefits.length < 4 && (
              <Grid>
                <Grid.Row textAlign="center">
                  <Grid.Column>
                    <Button
                      type="button"
                      onClick={() => {
                        setModal({
                          open: true,
                        });
                      }}
                    >
                      <FormattedMessage
                        id="request.addNewBenefit"
                        defaultMessage="Aggiungi agevolazione"
                      />
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </>
        )}
      />
    </>
  );
};
