import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import {
  StripeCreateSessionResponse,
  StripeCreateSessionValues,
  StripeGetSessionResponse,
} from './types';

/**
 * Creates a new stripe checkout session.
 * @param values
 * @param callback
 * @returns
 */
export const stripeCreateSession = (
  values: Partial<StripeCreateSessionValues>,
  callback: (response: StripeCreateSessionResponse) => void,
): Promise<void> => {
  return standardClient
    .request({
      baseURL: API.BASE_URL,
      url: API.STRIPE_MANAGER,
      method: 'POST',
      data: {
        method: 'create-session',
        params: {
          ...values,
        },
      },
    })
    .then((response: AxiosResponse<StripeCreateSessionResponse>) =>
      // The response is okay, so back to the response page.
      callback({
        url: response.data.url ?? '',
        isAlreadyPaid: response.data.isAlreadyPaid ?? false,
      }),
    )
    .catch(() => {
      toast.error('Errore durante la creazione della sessione di pagamento');
    });
};

/**
 * Gets an existing stripe checkout session by id.
 * @param id
 * @returns
 */
export const stripeGetSession = (
  id: string,
): Promise<AxiosResponse<StripeGetSessionResponse>> => {
  return standardClient.request({
    baseURL: API.BASE_URL,
    url: API.STRIPE_MANAGER,
    method: 'POST',
    data: {
      method: 'get-session',
      params: {
        idSession: id,
      },
    },
  });
};
