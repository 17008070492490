export enum DocumentType {
  GaranziaDiretta = 'G',
  Riassicurazione = 'R',
  DomandaDiAgevolazione = 'D',
}

export interface StepVerificaValues {
  /**
   * The type of document to generate.
   */
  documentType: DocumentType | null;
  warrantyEgf: boolean | null;
  hasAlreadyEgfWarranty: boolean | null;
  ineligibilityEgfWarranty: boolean | null;
  expirationDate: Date | undefined;
}
