import { createContext, useContext } from 'react';
import { AppointmentContextType } from '../types';

export const AppointmentContext = createContext({} as AppointmentContextType);

AppointmentContext.displayName = 'AppointmentContext';

export function useAppointment(): AppointmentContextType {
  const appointmentContext =
    useContext<AppointmentContextType>(AppointmentContext);

  return appointmentContext;
}
