import React, { useEffect } from 'react';
import { Dimmer, Grid, Header, Loader } from 'semantic-ui-react';

import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { updateRequestPromise } from 'services/request';
import { toast } from 'react-toastify';
import { stripeGetSession } from 'services/stripe';
import { FE_ROUTES } from 'utils/global/globalCostants';
import { States } from 'services/stateMachine/types';

export const StripeCancel: React.FC = () => {
  /** Get the stripe id session */
  const { idSession } = useParams();

  /**
   * The navigation object.
   */
  const navigate = useNavigate();

  /**
   * The state machine configuration.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state, dispatch } = useStateMachine();

  /**
   * The react-intl object.
   */
  const intl = useIntl();

  /**
   * This effect is called at the first rendering.
   */
  useEffect(() => {
    // Get the session information
    stripeGetSession(idSession ?? '').then(result => {
      // Update the request state restoring the previous state
      updateRequestPromise({
        id: result.data.requestId,
        state: States.PagamentoCancellato,
        stateBeforePayment: result.data.previousState,
      })
        .then(() => {
          // Navigate to the request, which at this point should be in Pagamento state
          navigate(FE_ROUTES.REQUEST + '/' + result.data.requestId);
        })
        .catch(() => {
          toast.error("Errore durante l'aggiornamento della richiesta");
        });
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {'Allegato4.it - ' +
            intl.formatMessage({
              id: `stripeCheckout.title`,
              defaultMessage: 'Pagamento',
            })}
        </title>
        <meta name="description" content="stripe checkout" />
        <script>{"smartsupp('chat:hide')"}</script>
      </Helmet>
      <div className="stripe-checkout-container">
        <>
          <div>
            <Header className="stripeCheckoutHeader" dividing>
              <Grid>
                <Grid.Column width={4}>
                  <Header>
                    <h1>
                      <FormattedMessage
                        id="stripeCheckout.title"
                        defaultMessage="Pagamento"
                      />
                    </h1>
                  </Header>
                </Grid.Column>
              </Grid>
            </Header>
            <div className="stripeCheckoutSectionBody">
              <div className="stripeCheckoutSectionResponse">
                <Dimmer active inverted>
                  <Loader inverted>Attendi</Loader>
                </Dimmer>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};
