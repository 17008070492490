import React, { useEffect, useMemo } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { StepPagamentoValues } from './types';
import { FE_ROUTES } from 'utils/global/globalCostants';
import { stripeCreateSession } from 'services/stripe';
import { StripeCreateSessionResponse } from 'services/stripe/types';
import { CircleLoader } from 'react-spinners';
import { PaymentTypes } from 'services/payment/types';
import { getPayments } from 'services/payment';
import { useParams } from 'react-router-dom';

export const StepPagamento: React.FC = () => {
  /**
   * The state machine configuration.
   */
  const { state } = useStateMachine();
  const { id } = useParams();

  /**
   * The react-intl object.
   */
  const intl = useIntl();

  const initialValues = useMemo<StepPagamentoValues>(() => {
    const { request } = state;
    if (request) {
      return {
        plan: request.plan ?? null,
      };
    }

    return {
      plan: null,
    };
  }, [state.request]);

  /**
   * This effect is called on initialValues changes.
   */
  useEffect(() => {
    const filterRequest =
      state.request && state.request.id ? state.request.id : id ? id : null;
    const filterTypes = [
      PaymentTypes.GOLD,
      PaymentTypes.CONSULTING,
      PaymentTypes.SILVER_CONSULTING,
    ];
    /** Gets payments list for the current request */
    getPayments({ request: filterRequest, types: filterTypes }).then(res => {
      /**
       * If no payments were executed for this request, directly navigate to the stripe session,
       * otherwise open the modal and let the user books the appointment
       */
      if (res.data.total_items === 0) {
        if (initialValues.plan && state.paymentEntity) {
          // Create stripe checkout session
          const data = {
            routeSuccess: FE_ROUTES.STRIPE_SUCCESS,
            routeCancel: FE_ROUTES.STRIPE_CANCEL,
            paymentEntity: state.paymentEntity,
            paymentId: initialValues.plan.id,
            requestId: state.request?.id,
          };
          stripeCreateSession(data, onSessionCreated);
        }
      }
    });
  }, [initialValues]);

  /**
   * Callback of a created stripe session.
   * @param state
   */
  const onSessionCreated = (state: StripeCreateSessionResponse) => {
    window.location.href = state.url;
  };

  return (
    <div>
      <Helmet>
        <title>
          {'Allegato4.it - ' +
            intl.formatMessage({
              id: `stripeCheckout.title`,
              defaultMessage: 'Pagamento',
            })}
        </title>
        <meta name="description" content="stripe checkout" />
      </Helmet>
      <div className="stripe-checkout-container">
        <>
          <div>
            <Grid textAlign="center">
              <Grid.Row>
                <Grid.Column>
                  <FormattedMessage
                    id="request.wait"
                    defaultMessage="Un attimo di pazienza..."
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3">
                    <FormattedMessage
                      id="request.waitingStripeCheckout"
                      defaultMessage="Stiamo creando la sessione di checkout"
                    />
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div className="waiting">
                    <CircleLoader color="#FFC107" />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </>
      </div>
    </div>
  );
};
