import { Field, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Dimmer,
  Grid,
  Icon,
  Image,
  List,
  Loader,
} from 'semantic-ui-react';
import { Form } from 'formik-semantic-ui-react';
import { AxiosResponse } from 'axios';

import silverLogo from 'assets/images/icon/silver-plan.svg';
import goldLogo from 'assets/images/icon/gold-plan.svg';
import platinumLogo from 'assets/images/icon/platinum-plan.svg';

import { loadPlans } from 'services/plan';
import { Plan, PlanObjectResponse } from 'services/plan/types';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { PaginateObject } from 'utils/types/paginateObject';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { StepPianoValues } from './types';
import { updateRequest } from 'services/request';
import { States } from 'services/stateMachine/types';

export const StepPiano: React.FC = () => {
  /**
   * The state machine configuration.
   */
  const { state, dispatch } = useStateMachine();

  /**
   * The list of the available plans.
   */
  const [plans, setPlans] = useState<Plan[]>();

  /**
   * Initial values for this step.
   */
  const initialValues = useMemo<StepPianoValues>(() => {
    const { request } = state;
    if (request) {
      return {
        plan: request.plan ?? null,
      };
    }

    return {
      plan: null,
    };
  }, [state.request]);

  /**
   * This effect is called at the first rendering.
   */
  useEffect(() => {
    loadPlans()
      .then((response: AxiosResponse<PaginateObject<PlanObjectResponse>>) =>
        setPlans(response.data._embedded.plan),
      )
      .catch(() => toast.error('Impossibile caricare i dati di pagamento'));
  }, []);

  /**
   * Go to the next state.
   * @param values
   * @param formikHelpers
   */
  const onSubmit = (
    values: StepPianoValues,
    formikHelpers: FormikHelpers<StepPianoValues>,
  ) => {
    if (values && values.plan) {
      // Update the request with the invoice fields
      const payload = {
        plan: values.plan.id,
      };
      updateRequest(
        {
          ...payload,
          id: state.request?.id,
          state: States.PianoSelezionato,
        },
        dispatch,
        formikHelpers,
      );
    } else {
      toast.error('Selezionare un piano di pagamento');
    }
  };

  /**
   * If plans are not loaded yet, show a loader.
   */
  if (!plans) {
    return (
      <>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form id={`form__${state.current}`} className="request__form">
          <Grid>
            <Grid.Row centered columns={1}>
              {/* Plan selection */}
              {plans && (
                <Field
                  name="plan"
                  render={() => (
                    <Card.Group centered>
                      {plans
                        .filter(plan => plan.saleable)
                        .map(plan => (
                          <Card
                            className={
                              plan === values.plan
                                ? 'request__plan__card request__plan_card_selected'
                                : 'request__plan__card'
                            }
                            key={plan.id}
                          >
                            <Card.Content className="content__header">
                              <Card.Header>{plan.description}</Card.Header>
                              <div className="content__image-wrapper">
                                {plan?.code === 'S' && (
                                  <Image src={silverLogo}></Image>
                                )}
                                {plan?.code === 'G' && (
                                  <Image src={goldLogo}></Image>
                                )}
                                {plan?.code === 'P' && (
                                  <Image src={platinumLogo}></Image>
                                )}
                              </div>
                            </Card.Content>
                            <Card.Content className="content__price">
                              <FormattedMessage
                                id="plan.price"
                                defaultMessage="{price} /singola compilazione + iva se dovuta"
                                values={{
                                  price: (
                                    <strong>
                                      <FormattedNumber
                                        value={plan.price}
                                        style="currency"
                                        currency="EUR"
                                      />
                                    </strong>
                                  ),
                                }}
                              />
                            </Card.Content>
                            <Card.Content className="content__features">
                              <List>
                                <List.Item>
                                  <Icon name="check" color="green" />
                                  <List.Content>
                                    Pre-compilazione automatica dei dati di
                                    bilancio (*)
                                  </List.Content>
                                </List.Item>
                                <List.Item>
                                  <Icon name="check" color="green" />
                                  <List.Content>
                                    Pre-compilazione automatica dei dati
                                    recuperati dall’ultima visura storica (*)
                                  </List.Content>
                                </List.Item>
                                <List.Item>
                                  <Icon name="check" color="green" />
                                  <List.Content>
                                    Generazione automatica del documento
                                    aggiornato alle ultime disposizioni di MCC
                                  </List.Content>
                                </List.Item>
                                <List.Item>
                                  <Icon name="check" color="green" />
                                  <List.Content>
                                    Consulenza via chat con un nostro esperto
                                  </List.Content>
                                </List.Item>
                                <List.Item>
                                  <Icon
                                    name={
                                      plan.code === 'S' ? 'cancel' : 'check'
                                    }
                                    color={plan.code === 'S' ? 'red' : 'green'}
                                  />
                                  <List.Content>
                                    1H di consulenza video
                                  </List.Content>
                                </List.Item>
                              </List>
                            </Card.Content>
                            <Card.Content
                              className="content__buttons"
                              textAlign="center"
                            >
                              <Button
                                //onClick={() => onSelect(plan)}
                                onClick={() => setFieldValue('plan', plan)}
                                className="button--primary"
                                type="button"
                              >
                                Seleziona il piano
                              </Button>
                            </Card.Content>
                          </Card>
                        ))}
                    </Card.Group>
                  )}
                />
              )}
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
