import { PurposeOption } from 'services/purpose/types';
import { PurposeOptionActionContants, PurposeOptionAction } from './actions';

export type PurposeOptionState = {
  isLoading: boolean;
  purposeOptionList: Array<PurposeOption>;
  requestPurpose: Array<PurposeOption>;
};

export const initialPurposeOptionState: PurposeOptionState = {
  isLoading: false,
  purposeOptionList: [],
  requestPurpose: [],
};

export function purposeOptionReducer(
  state: PurposeOptionState,
  action: PurposeOptionAction,
): PurposeOptionState {
  const { type } = action;

  switch (type) {
    case PurposeOptionActionContants.SET_PURPOSE_SET_PURPOSE_OPTIONS_LIST:
      return {
        ...state,
        purposeOptionList: action.payload.purposeOptionList,
      };
    case PurposeOptionActionContants.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case PurposeOptionActionContants.SET_REQUEST_PURPOSE:
      return {
        ...state,
        requestPurpose: action.payload.requestPurpose,
      };
    default:
      return state;
  }
}
