import { ErrorMessage, Field, Formik, getIn, FormikHelpers } from 'formik';
import React, { useMemo, useEffect } from 'react';
import { Grid, Select } from 'semantic-ui-react';
import { Form, Input } from 'formik-semantic-ui-react';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { FormattedMessage, useIntl } from 'react-intl';
import { sexTypeOptions, InvoiceFormValues, InvoiceFormProps } from './types';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import * as Yup from 'yup';
import { updateRequest } from 'services/request';
import { States } from 'services/stateMachine/types';
import { RequestRequest } from 'services/request/types';
import { StateMachineActionType } from 'services/stateMachine/types';
import { PaymentEntities } from 'services/payment/types';
import { FormikWatcher } from 'components/FormikWatcher';
import { useAppointment } from 'pages/Appointment/context';

export const InvoiceForm: React.FC<InvoiceFormProps> = ({ mode }) => {
  /**
   * The state machine configuration.
   */
  const { state, dispatch } = useStateMachine();
  const { onCreateConsultancy, onCloseInvoiceModal } = useAppointment();

  /**
   * The react-intl object.
   */
  const intl = useIntl();

  useEffect(() => {
    dispatch({
      type: StateMachineActionType.SetPaymentEntity,
      paymentEntity: mode,
    });
  }, []);

  /**
   * Initial values for this form.
   */
  const initialValues = useMemo<InvoiceFormValues>(() => {
    const { request } = state;
    if (request) {
      return {
        businessName: request.businessName,
        legalAddress: request.legalAddress,
        legalCity: request.legalCity,
        legalPostalCode: request.legalPostalCode,
        legalProvince: request.legalProvince,
        legalRepresentatives: request.legalRepresentatives ?? [],
        pec: request.pec ?? '',
        vatNumber: request.vatNumber,
        billingFirstName: request.billingFirstName,
        billingLastName: request.billingLastName,
        billingSex: request.billingSex,
        billingBirthCity: request.billingBirthCity,
        billingBirthCountry: request.billingBirthCountry,
        billingBirthDate: request.billingBirthDate,
        voucherCode: request.voucherCode,
      };
    }

    return {
      businessName: '',
      legalAddress: '',
      legalCity: '',
      legalPostalCode: '',
      legalProvince: '',
      legalRepresentatives: [],
      pec: '',
      vatNumber: '',
      billingFirstName: '',
      billingLastName: '',
      billingSex: '',
      billingBirthCity: '',
      billingBirthCountry: '',
      billingBirthDate: '',
      voucherCode: '',
    };
  }, [state.request]);

  /**
   * Save data.
   * @param values
   * @param formikHelpers
   */
  const onSubmit = (
    values: InvoiceFormValues,
    formikHelpers: FormikHelpers<InvoiceFormValues>,
  ) => {
    if (values) {
      // Update the request with the invoice fields
      const payload: Partial<RequestRequest> = {
        businessName: values.businessName,
        vatNumber: values.vatNumber,
        pec: values.pec,
        legalAddress: values.legalAddress,
        legalPostalCode: values.legalPostalCode,
        legalCity: values.legalCity,
        legalProvince: values.legalProvince,
        billingFirstName: values.billingFirstName,
        billingLastName: values.billingLastName,
        billingSex: values.billingSex,
        billingBirthCity: values.billingBirthCity,
        billingBirthCountry: values.billingBirthCountry,
        billingBirthDate: values.billingBirthDate,
        voucherCode: values.voucherCode,
        paymentEntity: mode,
        stateBeforePayment:
          mode === PaymentEntities.CONSULTING
            ? state.current
            : state.request?.stateBeforePayment,
      };
      updateRequest(
        {
          ...payload,
          id: state.request?.id,
          state: States.PagamentoFatturazione,
        },
        dispatch,
        formikHelpers,
      ).then(res => {
        if (res && mode === PaymentEntities.CONSULTING) {
          onCloseInvoiceModal();
          onCreateConsultancy();
        }
      });
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
        validationSchema={Yup.object().shape({
          voucherCode: Yup.string()
            .nullable()
            .matches(
              /^[a-zA-Z0-9]+$/,
              'Il codice non può contenere caratteri speciali',
            )
            .min(10, 'Il codice deve avere 10 caratteri')
            .max(10, 'Il codice deve avere 10 caratteri'),
          isInvoiceRequired: Yup.bool().nullable(),
          businessName: Yup.string()
            .nullable()
            .label(
              intl.formatMessage({
                id: 'request.businessName',
                defaultMessage: 'Ragione sociale',
              }),
            )
            .required(),
          vatNumber: Yup.string()
            .nullable()
            .label(
              intl.formatMessage({
                id: 'request.vatNumber',
                defaultMessage: 'P.IVA',
              }),
            )
            .required(),
          legalAddress: Yup.string()
            .label(
              intl.formatMessage({
                id: 'request.legalAddress',
                defaultMessage: 'Indirizzo sede legale',
              }),
            )
            .nullable()
            .required(),
          legalPostalCode: Yup.string()
            .nullable()
            .label(
              intl.formatMessage({
                id: 'request.legalPostalCode',
                defaultMessage: 'CAP',
              }),
            )
            .required(),
          legalCity: Yup.string()
            .nullable()
            .label(
              intl.formatMessage({
                id: 'request.legalCity',
                defaultMessage: 'Città',
              }),
            )
            .required(),
          legalProvince: Yup.string()
            .nullable()
            .label(
              intl.formatMessage({
                id: 'request.legalProvince',
                defaultMessage: 'Provincia',
              }),
            )
            .min(2)
            .max(4)
            .required(),
          pec: Yup.string()
            .nullable()
            .label(
              intl.formatMessage({
                id: 'request.pec',
                defaultMessage: 'PEC',
              }),
            )
            .required()
            .email(),
          billingFirstName: Yup.string()
            .nullable()
            .label(
              intl.formatMessage({
                id: 'request.billingFirstName',
                defaultMessage: 'Nome',
              }),
            )
            .required(),
          billingLastName: Yup.string()
            .nullable()
            .label(
              intl.formatMessage({
                id: 'request.billingLastName',
                defaultMessage: 'Cognome',
              }),
            )
            .required(),
          billingSex: Yup.string()
            .nullable()
            .label(
              intl.formatMessage({
                id: 'request.billingSex',
                defaultMessage: 'Sesso',
              }),
            ),
          billingBirthCity: Yup.string()
            .nullable()
            .label(
              intl.formatMessage({
                id: 'request.billingBirthCity',
                defaultMessage: 'Comune di nascita',
              }),
            )
            .required(),
          billingBirthCountry: Yup.string()
            .nullable()
            .label(
              intl.formatMessage({
                id: 'request.billingBirthCountry',
                defaultMessage: 'Stato di nascita',
              }),
            )
            .required(),
          billingBirthDate: Yup.date()
            .nullable()
            .typeError('Data di nascita formato non valido')
            .label(
              intl.formatMessage({
                id: 'request.billingBirthDate',
                defaultMessage: 'Data di nascita',
              }),
            )
            .required(),
        })}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          setFieldTouched,
          isSubmitting,
        }) => (
          <Form
            id={
              mode === PaymentEntities.CONSULTING
                ? 'form__consultancyInvoice'
                : `form__${state.current}`
            }
          >
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <h1 className="payment__header__title">
                    <FormattedMessage
                      id="payment.informationof"
                      defaultMessage="Informazioni di "
                    />
                    <strong>
                      <FormattedMessage
                        id="payment.payment"
                        defaultMessage="pagamento"
                      />
                    </strong>
                  </h1>
                  <h4 className="payment__header__subtitle">
                    <FormattedMessage
                      id="payment.subtitle"
                      defaultMessage="Inserisci un codice di sconto se ne sei in possesso"
                    />
                  </h4>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns={2}>
                <Grid.Column
                  textAlign="center"
                  className="request__voucher-code"
                >
                  <Input
                    label={
                      <label>
                        <FormattedMessage
                          id="request.voucherCode"
                          defaultMessage="Codice voucher"
                        />
                      </label>
                    }
                    name="voucherCode"
                    onChange={setFieldValue}
                  />
                  <ErrorMessage name="voucherCode" component="span" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns={1}>
                <Grid.Column
                  textAlign="center"
                  className="request__invoice"
                  style={{ marginTop: '35px' }}
                >
                  <h1 className="payment__header__title">
                    <FormattedMessage
                      id="payment.invoiceData"
                      defaultMessage="Dati di "
                    />
                    <strong>
                      <FormattedMessage
                        id="payment.invoice"
                        defaultMessage="fatturazione"
                      />
                    </strong>
                  </h1>
                </Grid.Column>
              </Grid.Row>
              {/* Invoice fields */}
              <>
                <Grid.Row columns={1}>
                  <Grid.Column width={9}>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.businessName"
                            defaultMessage="Ragione sociale"
                          />
                          *
                        </label>
                      }
                      name="businessName"
                      type="text"
                    />
                    <ErrorMessage name="businessName" component="span" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.vatNumber"
                            defaultMessage="Codice fiscale/P.IVA"
                          />
                          *
                        </label>
                      }
                      name="vatNumber"
                      type="text"
                    />
                    <ErrorMessage name="vatNumber" component="span" />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.legalAddress"
                            defaultMessage="Indirizzo sede legale"
                          />
                          *
                        </label>
                      }
                      name="legalAddress"
                      type="text"
                    />
                    <ErrorMessage name="legalAddress" component="span" />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.legalPostalCode"
                            defaultMessage="CAP sede legale"
                          />
                          *
                        </label>
                      }
                      name="legalPostalCode"
                      type="text"
                    />
                    <ErrorMessage name="legalPostalCode" component="span" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.legalCity"
                            defaultMessage="Comune sede legale"
                          />
                          *
                        </label>
                      }
                      name="legalCity"
                      type="text"
                    />
                    <ErrorMessage name="legalCity" component="span" />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.legalProvince"
                            defaultMessage="Provincia sede legale"
                          />
                          *
                        </label>
                      }
                      name="legalProvince"
                      type="text"
                    />
                    <ErrorMessage name="legalProvince" component="span" />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.pec"
                            defaultMessage="PEC"
                          />
                          *
                        </label>
                      }
                      name="pec"
                      type="text"
                    />
                    <ErrorMessage name="pec" component="span" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.billingFirstName"
                            defaultMessage="Nome"
                          />
                          *
                        </label>
                      }
                      name="billingFirstName"
                      type="text"
                    />
                    <ErrorMessage name="billingFirstName" component="span" />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.billingLastName"
                            defaultMessage="Cognome"
                          />
                          *
                        </label>
                      }
                      name="billingLastName"
                      type="text"
                    />
                    <ErrorMessage name="billingLastName" component="span" />
                  </Grid.Column>
                  <Grid.Column className="field">
                    <Field name="billingSex">
                      {({ field }) => (
                        <>
                          <label>
                            <FormattedMessage
                              id="request.billingSex"
                              defaultMessage="Sesso"
                            />
                          </label>
                          <Select
                            options={sexTypeOptions}
                            onChange={(_event, data) =>
                              setFieldValue(field.name, data.value)
                            }
                            value={values.billingSex}
                          />
                        </>
                      )}
                    </Field>
                    {getIn(errors, 'billingSex') &&
                      getIn(touched, 'billingSex') && (
                        <span className="error">
                          {getIn(errors, 'billingSex')}
                        </span>
                      )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.billingBirthCity"
                            defaultMessage="Comune di nascita"
                          />
                          *
                        </label>
                      }
                      name="billingBirthCity"
                      type="text"
                    />
                    <ErrorMessage name="billingBirthCity" component="span" />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.billingBirthCountry"
                            defaultMessage="Stato di nascita"
                          />
                          *
                        </label>
                      }
                      name="billingBirthCountry"
                      type="text"
                    />
                    <ErrorMessage name="billingBirthCountry" component="span" />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label={
                        <label>
                          <FormattedMessage
                            id="request.billingBirthDate"
                            defaultMessage="Data di nascita"
                          />
                          *
                        </label>
                      }
                      name="billingBirthDate"
                      onBlur={() => {
                        setFieldTouched('billingBirthDate', true);
                      }}
                    >
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          error={false}
                          helperText={null}
                          clearable
                          disableToolbar
                          format="DD/MM/YYYY"
                          autoOk
                          onChange={value =>
                            setFieldValue('billingBirthDate', value)
                          }
                          InputProps={{
                            disableUnderline: true,
                          }}
                          value={values.billingBirthDate}
                          onBlur={() => {
                            setFieldTouched('billingBirthDate', true);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Input>
                    <ErrorMessage name="billingBirthDate" component="span" />
                  </Grid.Column>
                </Grid.Row>
              </>
            </Grid>
            <FormikWatcher
              values={values}
              errors={errors}
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
