import { AuthObject } from 'services/client/types';
import { LOCAL_STORAGE } from 'utils/global/globalCostants';

/**
 * Save the authentication object returned form API in the local storage
 * @param authObject Current user data
 */
export const updateAuthObject = (authObject: AuthObject): void => {
  localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, authObject.accessTokken);
  localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, authObject.refreshToken);
  localStorage.setItem(LOCAL_STORAGE.SCOPE, authObject.scope);
  localStorage.setItem(LOCAL_STORAGE.EXPIRES_IN, authObject.expiresIn);
  localStorage.setItem(LOCAL_STORAGE.TOKEN_TYPE, authObject.tokenType);
};

/** Clear all the data about the current user in the local storage */
export const clearAuthObject = (): void => {
  localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.REFRESH_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.SCOPE);
  localStorage.removeItem(LOCAL_STORAGE.EXPIRES_IN);
  localStorage.removeItem(LOCAL_STORAGE.TOKEN_TYPE);
};

/** Remove from the local storage refresh and access tokens */
export const clearTokens = (): void => {
  localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.REFRESH_TOKEN);
};

/** Set in the local storage the new access token */
export const setAuthToken = (newAccessToken: string): void => {
  localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, newAccessToken);
};
