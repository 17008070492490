import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { Checkbox, Field, Form, Input } from 'formik-semantic-ui-react';
import { Container, Grid, Header } from 'semantic-ui-react';
import * as Yup from 'yup';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { updateRequest } from 'services/request';
import { States } from 'services/stateMachine/types';
import { StepFinanziamentoValues } from './types';
import { CurrencyInputFormik } from 'components/CurrencyInputFormik';
import { Tooltip } from 'components/Tooltip';
import _ from 'lodash';

export const StepFinanziamento: React.FC = () => {
  /**
   * The state machine configuration
   */
  const { state, dispatch } = useStateMachine();

  /**
   * The react-intl imperative component.
   */
  const intl = useIntl();

  /**
   * Set the initial values from the request state.
   * It helps when I'm going to travel across the steps.
   */
  const initialValues = useMemo<StepFinanziamentoValues>(() => {
    if (state.request) {
      return {
        amountInEuro: state.request.amountInEuro,
        durationInMonths: state.request.durationInMonths,
        operationRelatedToOperationalHeadquarter: state.request
          .isAdministrativeDamagedByFloods
          ? true
          : state.request.isLegalDamagedByFloods
          ? false
          : !state.request.legalAdministrativeNotMatch
          ? false
          : !_.isNull(state.request.operationRelatedToOperationalHeadquarter)
          ? state.request.operationRelatedToOperationalHeadquarter
          : false,
      };
    }

    return {
      amountInEuro: null,
      durationInMonths: null,
      operationRelatedToOperationalHeadquarter: false,
    };
  }, [state.request]);

  const onSubmit = (
    values: StepFinanziamentoValues,
    formikHelpers: FormikHelpers<StepFinanziamentoValues>,
  ) => {
    updateRequest(
      {
        ...values,
        id: state.request?.id,
        state: States.Bilancio,
      },
      dispatch,
      formikHelpers,
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        amountInEuro: Yup.string()
          .label(
            intl.formatMessage({
              id: 'stepFinanziamento.amountInEuro',
              defaultMessage: 'Importo in euro',
            }),
          )
          .required()
          .nullable(),
        durationInMonths: Yup.number()
          .label(
            intl.formatMessage({
              id: 'stepFinanziamento.durationInMonths',
              defaultMessage: 'Durata in mesi',
            }),
          )
          .required()
          .nullable()
          .min(1),
        operationRelatedToOperationalHeadquarter: Yup.boolean().nullable(true),
      })}
    >
      {({ setFieldValue, handleBlur }) => (
        <Form id={`form__${state.current}`}>
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <div className="finanziamento__header">
                  <h1 className="finanziamento__header__title">
                    <FormattedMessage
                      id="stepFinanziamento.insertInformationOf"
                      defaultMessage="Inserisci le informazioni del "
                    />
                    <strong className="title__bold">
                      <FormattedMessage
                        id="loan"
                        defaultMessage="finanziamento"
                      />
                      <Tooltip
                        header={
                          <Header as="h2" className="finanziamento">
                            <FormattedMessage
                              id="loan"
                              defaultMessage="finanziamento"
                            />
                          </Header>
                        }
                        content={
                          <Container>
                            <p>
                              La durata del finanziamento deve essere
                              comprensiva dell’eventuale preammortamento – ad
                              esempio per un’operazione di 96 mesi di cui 12
                              mesi di preammortamento va indicato 96 mesi.
                            </p>
                          </Container>
                        }
                      />
                    </strong>
                  </h1>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row width={16}>
              <Grid.Column width={8}>
                <Field name="amountInEuro">
                  {({ field }) => (
                    <CurrencyInputFormik
                      field={field}
                      label={
                        <label>
                          <FormattedMessage
                            id="stepFinanziamento.amountInEuro"
                            defaultMessage="Importo in Euro"
                          />
                          *
                        </label>
                      }
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      placeholder="€ Importo in Euro"
                    />
                  )}
                </Field>
              </Grid.Column>
              <Grid.Column width={8}>
                <Input
                  required
                  label={
                    <label>
                      <FormattedMessage
                        id="stepFinanziamento.durationInMonths"
                        defaultMessage="Durata in mesi"
                      />
                      *
                    </label>
                  }
                  type="number"
                  min="0"
                  name="durationInMonths"
                  placeholder="Durata in mesi"
                />
                <ErrorMessage name="durationInMonths" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row width={8}>
              <Grid.Column width={8}>
                <Checkbox
                  toggle
                  name="operationRelatedToOperationalHeadquarter"
                  label={{
                    children: (
                      <FormattedMessage
                        id="profile.operationRelatedToOperationalHeadquarter"
                        defaultMessage="L'operazione è legata alla sede operativa?"
                      />
                    ),
                  }}
                  disabled={
                    state.request?.isAdministrativeDamagedByFloods ||
                    state.request?.isLegalDamagedByFloods ||
                    false
                  }
                />
                <ErrorMessage
                  name="operationRelatedToOperationalHeadquarter"
                  component="span"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
