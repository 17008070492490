import { AxiosResponse } from 'axios';
import { AppAction, appActionConstants } from 'pages/App/reducer/actions';
import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';
import { MySelf } from 'utils/types/mySelf';
import { checkMaintenanceMode } from 'services/request';

export const loadMyself = (
  dispatch: React.Dispatch<AppAction>,
): Promise<void> => {
  return standardClient
    .request({
      baseURL: API.BASE_URL,
      url: API.MYSELF,
      method: 'GET',
    })
    .then((response: AxiosResponse<MySelf>) => {
      dispatch({ type: appActionConstants.SET_SESSION });
      dispatch({
        type: appActionConstants.SET_MYSELF,
        payload: { mySelf: response.data },
      });
      checkMaintenanceMode(dispatch);
    })
    .catch(() => {
      dispatch({ type: appActionConstants.REVOKE_SESSION });
    });
};
