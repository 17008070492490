import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownItemProps } from 'semantic-ui-react';

export const verificaDocumentOptions: Array<DropdownItemProps> = [
  // {
  //   text: (
  //     <FormattedMessage
  //       id="request.warranty"
  //       defaultMessage="Garanzia diretta"
  //     />
  //   ),
  //   value: 'G',
  // },
  // {
  //   text: (
  //     <FormattedMessage
  //       id="request.reinsurance"
  //       defaultMessage="Riassicutrazione"
  //     />
  //   ),
  //   value: 'R',
  // },
  {
    text: (
      <FormattedMessage
        id="request.financialRequest"
        defaultMessage="Domanda di agevolazione"
      />
    ),
    value: 'D',
  },
];
