import standardClient from 'services/client/standardRequestClient';
import { API } from 'utils/global/backendRoutes';

/**
 * Get the schedule information for the calendar owner.
 * @param startTime the start time
 * @param endTime the end time
 * @returns the list of the schedule information
 */
export const getSchedule = (startTime: string, endTime: string) =>
  standardClient({
    url: `${API.GET_SCHEDULE}`,
    method: 'POST',
    data: {
      startTime,
      endTime,
      availabilityViewInterval: 60,
    },
  });

export const createEvent = data =>
  standardClient({
    url: `${API.CREATE_EVENT}`,
    method: 'POST',
    data,
  });

export const getEvent = (id: string) =>
  standardClient({
    url: `${API.GET_EVENT}`,
    method: 'POST',
    data: {
      id,
    },
  });

export const updateEvent = data =>
  standardClient({
    url: `${API.UPDATE_EVENT}`,
    method: 'PATCH',
    data,
  });
