import { Nullable } from 'utils/types';
import { PaymentEntities } from 'services/payment/types';

export type InvoiceFormProps = {
  mode: PaymentEntities;
};

export interface InvoiceFormValues {
  /**
   * The business name.
   */
  businessName: string;

  /**
   * The fiscal code or the vat number.
   */
  vatNumber: string;

  /**
   * The pec address.
   */
  pec: string;

  /**
   * Legal information.
   */
  legalAddress: string;
  legalPostalCode: string;
  legalCity: string;
  legalProvince: string;

  /**
   * Billing information.
   */
  billingFirstName: string;
  billingLastName: string;
  billingSex: string;
  billingBirthCity: string;
  billingBirthCountry: string;
  billingBirthDate: string;
  /**
   * Voucher code.
   */
  voucherCode: Nullable<string>;
}

export const sexTypeOptions = [
  {
    text: 'M',
    value: 'M',
  },
  {
    text: 'F',
    value: 'F',
  },
];
