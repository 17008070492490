import React, { createRef } from 'react';
import SearchLoader from './SearchLoader.json';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Lottie, { useLottie } from 'lottie-react';

export const Loader: React.FC = () => {
  const animationContainer = createRef<HTMLDivElement>();

  // const options = {
  //   animationData: SearchLoader,
  //   loop: true,
  //   autoplay: true,
  // };

  // const { View } = useLottie(options);

  // return View;

  return (
    <div className="animation-container" ref={animationContainer}>
      <Lottie animationData={SearchLoader} loop={true} autoplay={true} />
    </div>
  );
};
