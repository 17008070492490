import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Image, Menu } from 'semantic-ui-react';
import { AppContext } from 'pages/App';

// Image and Icons
import mainLogo from 'assets/images/logo.svg';

// Layout
import Topbar from 'layout/Topbar';
import SideBar from 'layout/SideBar';

// Pages
import { MenuItem } from 'pages/App/types';

// Utils
import { FE_ROUTES, ROLES } from 'utils/global/globalCostants';

// Images
import { Link } from 'react-router-dom';
import { isThatRole } from 'utils/function/acl';
import { CustomerPageActionConstants } from 'pages/Customer/reducer/actions';

export const topBarItems: Array<MenuItem> = [
  {
    id: 'logo',
    to: FE_ROUTES.CUSTOMER,
    position: 'left',
    title: (
      <Menu.Item>
        <Image src={mainLogo} className="mainLogo" />
      </Menu.Item>
    ),
  },
  {
    id: 'home',
    to: FE_ROUTES.CUSTOMER,
    position: 'right',
    title: (
      <Menu.Item>
        <Button>
          <FormattedMessage id="link.home" defaultMessage="Torna alla home" />
        </Button>
      </Menu.Item>
    ),
  },
  {
    id: 'terms',
    to: FE_ROUTES.CUSTOMER,
    position: 'right',
    protected: true,
    title: (
      <Menu.Item className="link__terms">
        <Button className="button--underline">
          <FormattedMessage
            id="link.terms"
            defaultMessage="Termini e Condizioni"
          />
        </Button>
      </Menu.Item>
    ),
  },
  {
    id: 'request',
    to: FE_ROUTES.REQUEST_NEW,
    position: 'right',
    protected: true,
    title: (
      <Menu.Item className="link__request">
        <Link to={FE_ROUTES.REQUEST_NEW}>
          <Button>
            <FormattedMessage
              id="link.newRequest"
              defaultMessage="Nuova compilazione"
            />
          </Button>
        </Link>
      </Menu.Item>
    ),
  },
];

const StandardLayout: React.FC = () => {
  const { state } = useContext(AppContext);
  const logout = (): void => {
    localStorage.clear();
  };
  const sideBarItems: Array<MenuItem | null> = [
    isThatRole(ROLES.ADMIN) ||
    isThatRole(ROLES.BANK) ||
    isThatRole(ROLES.ACCOUNTANT)
      ? {
          id: 'customers',
          to: FE_ROUTES.CUSTOMER,
          title: (
            <div
              className="ui icon"
              data-toggle="tooltip"
              title="Elenco clienti"
            >
              <Icon name="user" />
            </div>
          ),
        }
      : null,
    {
      id: 'elenco-richieste',
      to: FE_ROUTES.REQUEST,
      title: (
        <div className="ui icon" data-toggle="tooltip" title="Elenco richieste">
          <Icon name="clipboard list" />
        </div>
      ),
    },
    {
      id: 'new-allegato4-request',
      to: FE_ROUTES.REQUEST_NEW,
      title: (
        <div
          className="ui icon"
          data-toggle="tooltip"
          title="Nuova compilazione"
        >
          <Icon name="copy" />
        </div>
      ),
    },
    !isThatRole(ROLES.ADMIN)
      ? {
          id: 'customer-profile',
          to:
            FE_ROUTES.CUSTOMER +
            `/${CustomerPageActionConstants.VIEW}/${state.mySelf?.customer}`,
          title: (
            <div
              className="ui icon"
              data-toggle="tooltip"
              title="Profilo cliente"
            >
              <Icon name="address book" />
            </div>
          ),
        }
      : null,
    isThatRole(ROLES.ADMIN)
      ? {
          id: 'stats',
          to: FE_ROUTES.DASHBOARD,
          title: (
            <div className="ui icon" data-toggle="tooltip" title="Statistiche">
              <Icon name="chart bar" />
            </div>
          ),
        }
      : null,
    isThatRole(ROLES.ADMIN)
      ? {
          id: 'agents',
          to: FE_ROUTES.AGENT,
          title: (
            <div
              className="ui icon"
              data-toggle="tooltip"
              title="Elenco agenti"
            >
              <Icon name="address card" />
            </div>
          ),
        }
      : null,
    isThatRole(ROLES.ADMIN)
      ? {
          id: 'vouchers',
          to: FE_ROUTES.VOUCHER,
          title: (
            <div className="ui icon" data-toggle="tooltip" title="Voucher">
              <Icon name="ticket alternate" />
            </div>
          ),
        }
      : null,
  ];

  return (
    <>
      <SideBar
        className="sidebar--left"
        sideBarItems={sideBarItems}
        moduleName="Gestione"
      />
      <Topbar
        moduleName="Logout"
        topBarItems={topBarItems}
        logout={logout}
        breadcrumbs={null}
      />
      <div className="standardlayout__container">
        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default StandardLayout;
