import React, { useEffect } from 'react';
import { FormikWatcherProps } from './types';

export const FormikWatcher = (props: FormikWatcherProps): JSX.Element => {
  const { values, callback, ...args } = props;

  useEffect(() => {
    if (callback) callback(values, { ...args });
  }, [values]);

  useEffect(() => {
    if (args.isSubmitting && args.errors) {
      const firstError = document.getElementsByClassName('error')[0];
      firstError?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [args.errors, args.isSubmitting]);

  return (
    <div
      className="formik-watcher"
      style={{ opacity: 0, display: 'none' }}
    ></div>
  );
};
