import standardClient from 'services/client/standardRequestClient';
import qs from 'qs';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import React from 'react';
import { PaginateObject } from 'utils/types/paginateObject';

// Constants
import { API } from 'utils/global/backendRoutes';

import {
  PurposeOptionAction,
  PurposeOptionActionContants,
} from 'components/RequestStep/StepFinalita/reducer/actions';
import { PurposeOptionObjectResponse } from './types';
import { PurposeOption } from 'services/purpose/types';

/**
 * Gets purpose options list.
 * @param values Filters values.
 * @param dispatch Dispatch to manage callback results.
 */
export const getPurposeOptionsList = (
  purposeOptionDispatch: React.Dispatch<PurposeOptionAction>,
): void => {
  purposeOptionDispatch({
    type: PurposeOptionActionContants.SET_IS_LOADING,
    payload: {
      isLoading: true,
    },
  });

  const params = {
    filter: [
      {
        type: 'eq',
        field: 'active',
        value: `true`,
      },
    ].filter(o => o),
  };
  // Convert params to string
  const paramsSerializer = qs.stringify(params);

  standardClient({
    url: `${API.PURPOSE_OPTION}?${paramsSerializer}`,
    method: 'GET',
  })
    .then(
      (
        response: AxiosResponse<PaginateObject<PurposeOptionObjectResponse>>,
      ) => {
        const purposeOptions = orderPurposeOptions(
          response.data._embedded.purpose_option,
        );

        purposeOptionDispatch({
          type: PurposeOptionActionContants.SET_PURPOSE_SET_PURPOSE_OPTIONS_LIST,
          payload: {
            purposeOptionList: purposeOptions,
          },
        });
        purposeOptionDispatch({
          type: PurposeOptionActionContants.SET_IS_LOADING,
          payload: {
            isLoading: false,
          },
        });
      },
    )
    .catch(() => {
      purposeOptionDispatch({
        type: PurposeOptionActionContants.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      });
      toast.error('Errore durante il caricamento dei clienti');
    });
};

const orderPurposeOptions = (purposeOptions: Array<PurposeOption>) => {
  const liquidityPurposes: PurposeOption[] = [];
  const investimentPurposes: PurposeOption[] = [];
  const relatedToInvestimentPurpose: PurposeOption[] = [];
  const lineeABrevePurpose: PurposeOption[] = [];
  const additionalPurpose: PurposeOption[] = [];

  purposeOptions.forEach(purposeOption => {
    if (
      purposeOption?.code?.includes('Liquidita') &&
      purposeOption?.code !== 'Liquidita_Connessa_All_Investimento' &&
      purposeOption?.code !== 'Linee_A_Breve'
    ) {
      liquidityPurposes.push(purposeOption);
    } else if (
      purposeOption.relatedToInvestment &&
      purposeOption?.code !== 'Liquidita_Connessa_All_Investimento' &&
      purposeOption?.code !== 'Linee_A_Breve'
    ) {
      investimentPurposes.push(purposeOption);
    } else if (purposeOption?.code === 'Liquidita_Connessa_All_Investimento') {
      relatedToInvestimentPurpose.push(purposeOption);
    } else if (purposeOption?.code === 'Linee_A_Breve') {
      lineeABrevePurpose.push(purposeOption);
    } else additionalPurpose.push(purposeOption);
  });

  const orderPurposes: PurposeOption[] = [];
  return orderPurposes.concat(
    liquidityPurposes,
    lineeABrevePurpose,
    investimentPurposes,
    relatedToInvestimentPurpose,
    additionalPurpose,
  );
};
