import { AgentType } from '../types';

export enum AgentActionConstants {
  SET_SEARCH_FILTER = 'Agent/SET_SEARCH_FILTER',
  SET_AGENT_DICTIONARY = 'Agent/SET_CUSTOMER_DICTIONARY',
  SET_AGENT = 'Agent/SET_AGENT',
  RELOAD_AGENT_LIST = 'Agent/RELOAD_AGENT_LIST',
}

export type AgentAction =
  | {
      type: AgentActionConstants.SET_SEARCH_FILTER;
      payload: { isLoading: boolean };
    }
  | {
      type: AgentActionConstants.RELOAD_AGENT_LIST;
      payload: { reloadAgents: boolean };
    }
  | {
      type: AgentActionConstants.SET_AGENT_DICTIONARY;
      payload: { agentList: Array<AgentType> };
    }
  | {
      type: AgentActionConstants.SET_AGENT;
      payload: { agent: AgentType };
    };
