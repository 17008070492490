import _ from 'lodash';
import standardClient from 'services/client/standardRequestClient';
import qs from 'qs';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import React from 'react';
import {
  CustomerAction,
  CustomerActionConstants,
  CustomerType,
} from 'pages/Customer/reducer/actions';
import { PaginateObject } from 'utils/types/paginateObject';
import { CustomerFilterFormValues } from 'components/Forms/CustomerFilterForm/types';
import { FormikHelpers } from 'formik';

// Constants
import { API } from 'utils/global/backendRoutes';

// Types
import { StandardResponseError } from 'services/client/types';
import { Customer, CustomerResponse } from 'services/customer/types';
import { CustomerObjectResponse } from './types';
import {
  DataGridAction,
  DataGridActionConstants,
} from 'components/DataGrid/reducer/actions';
import { PaginationData } from 'components/DataGrid/types';

/**
 * Get single customer by id.
 * @param idCustomer
 * @param customerType
 * @param dispatch
 */
export const getCustomerById = (
  idCustomer: string,
  dispatch: React.Dispatch<CustomerAction>,
): Promise<void> => {
  return standardClient({
    url: `${API.CUSTOMER}/${idCustomer}`,
    method: 'GET',
  })
    .then((response: AxiosResponse<CustomerResponse>) => {
      const splitUrl = response.data._links?.self.href.split('/');
      if (splitUrl) response.data.type = splitUrl[splitUrl.length - 2];
      dispatch({
        type: CustomerActionConstants.SET_CUSTOMER,
        payload: { customer: response.data },
      });
    })
    .catch(() => {
      toast.error('Errore durante il caricamento dei clienti');
    });
};

/**
 * Gets customer list with filters applied if any.
 * @param values Filters values.
 * @param paginationData Pagination data.
 * @param dispatch Dispatch to manage callback results.
 */
export const getCustomerList = (
  values: CustomerFilterFormValues,
  paginationData: PaginationData,
  dataGridDispatch: React.Dispatch<DataGridAction>,
  dispatch: React.Dispatch<CustomerAction>,
): void => {
  const searchText = values.searchText || null;
  const customerType = values.customerType || null;

  const params = {
    filter: [
      searchText && {
        type: 'like',
        field: 'businessName',
        value: `%${searchText}%`,
      },
      customerType && {
        type: 'customer_instance_filter',
        field: 'customer_type',
        value: customerType,
      },
    ].filter(o => o),
    pageSize: paginationData.page_size,
    page: _.get(paginationData, 'page', 1) === 0 ? 1 : paginationData.page,
  };
  // Convert params to string
  const paramsSerializer = qs.stringify(params);

  dataGridDispatch({
    type: DataGridActionConstants.SET_IS_LOADING,
    payload: {
      isLoading: true,
    },
  });

  standardClient({
    url: `${API.CUSTOMER}?${paramsSerializer}`,
    method: 'GET',
  })
    .then((response: AxiosResponse<PaginateObject<CustomerObjectResponse>>) => {
      // Dispatch the notification for customer dictionary
      dispatch({
        type: CustomerActionConstants.SET_CUSTOMER_DICTIONARY,
        payload: { customerList: response.data._embedded.customer },
      });
      setDataGridPaginator(response, dataGridDispatch);
      dataGridDispatch({
        type: DataGridActionConstants.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      });
    })
    .catch(() => {
      toast.error('Errore durante il caricamento dei clienti');
      dataGridDispatch({
        type: DataGridActionConstants.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      });
    });
};

/**
 * Create a new customer.
 * @param values Customer or AccountantClient field values.
 * @param customerType Customer or AccountantClient type.
 * @param formikHelpers Formik state helpers.
 */
export const createCustomer = (
  values: Customer,
  customerType: string,
  formikHelpers: FormikHelpers<Customer>,
): void => {
  const api = customerTypeToApi(customerType);

  standardClient({
    url: `${api}`,
    method: 'POST',
    data: { ...values },
  })
    .then(() => {
      toast.success('Cliente creato correttamente');
      // The response is okay, so back to the response page.
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      const errors = _.get(error, 'data.validation_messages', null);

      if (errors) {
        // Set the errors in the Formik bag.
        _.forOwn(errors, (value, key) => {
          for (const i in value) {
            formikHelpers.setFieldError(key, value[i]);
            break;
          }
        });
      } else {
        toast.error('Errore durante il salvataggio del cliente');
      }
    });
};

/**
 * Update an existing customer.
 * @param values Customer or AccountantClient field values.
 * @param id Customer or AccountantClient id.
 * @param customerType Customer or AccountantClient type.
 * @param formikHelpers Formik state helpers.
 */
export const updateCustomer = (
  values: Customer,
  id: string,
  customerType: string,
  formikHelpers: FormikHelpers<Customer>,
): void => {
  const api = customerTypeToApi(customerType);

  standardClient({
    url: `${api}/${id}`,
    method: 'PUT',
    data: { ...values },
  })
    .then(() => {
      toast.success('Cliente salvato correttamente');
      // The response is okay, so back to the response page.
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      const errors = _.get(error.data, 'validation_messages', null);

      if (errors) {
        // Set the errors in the Formik bag.
        _.forOwn(errors, (value, key) => {
          for (const i in value) {
            formikHelpers.setFieldError(key, value[i]);
            break;
          }
        });
      } else {
        toast.error('Errore durante il salvataggio del cliente');
      }
    });
};

/**
 * Return the API related to the given customer type.
 * @param customerType Customer or AccountantClient type.
 * @returns API.
 */
const customerTypeToApi = (customerType: string) => {
  let api: string;
  switch (customerType) {
    case CustomerType.ACCOUNTANT:
      api = API.ACCOUNTANT;
      break;
    case CustomerType.ACCOUNTANTCLIENT:
      api = API.ACCOUNTANT_CLIENT;
      break;
    case CustomerType.BANK:
      api = API.BANK;
      break;
    case CustomerType.BANKCLIENT:
      api = API.BANK_CLIENT;
      break;
    case CustomerType.WEBUSER:
      api = API.WEB_USER;
      break;
    default:
      api = API.CUSTOMER;
      break;
  }
  return api;
};

/**
 * Set paginator for DataGrid.
 * @param response
 * @param dataGridDispatch
 * @returns
 */
const setDataGridPaginator = (
  response: AxiosResponse<PaginateObject<CustomerObjectResponse>>,
  dataGridDispatch: React.Dispatch<DataGridAction>,
) => {
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_PAGE_COUNT,
    payload: {
      page_count: response.data.page_count,
    },
  });
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
    payload: {
      pageNumber: response.data.page,
    },
  });
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_TOTAL_ITEMS,
    payload: {
      total_items: response.data.total_items,
    },
  });
  return dataGridDispatch;
};
