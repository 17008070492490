import React, { useEffect, useState, useContext } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getRequestPromise, updateRequestPromise } from 'services/request';
import { States } from 'services/stateMachine/types';
import { toast } from 'react-toastify';
import { stripeGetSession } from 'services/stripe';
import { FE_ROUTES } from 'utils/global/globalCostants';
import { createPayment } from 'services/payment';
import { StripeGetSessionResponse } from 'services/stripe/types';
import { Request } from 'services/request/types';
import { PaymentEntities } from 'services/payment/types';
import { AppContext } from 'pages/App';
import { appActionConstants } from 'pages/App/reducer/actions';

export const StripeSuccess: React.FC = () => {
  /** Get the stripe id session */
  const { idSession } = useParams();
  const { dispatch } = useContext(AppContext);

  /**
   * The navigation object.
   */
  const navigate = useNavigate();

  /**
   * The customer name.
   */
  const [customerName, setCustomerName] = useState<string>();

  /**
   * True if request is being processed, otherwise false.
   */
  const [isProcessing, setIsProcessing] = useState(false);

  /**
   * True if the post stripe checkout procedure failed for some reason, otherwise false.
   */
  const [isFailed, setIsFailed] = useState(false);

  /**
   * The react-intl object.
   */
  const intl = useIntl();

  /**
   * This effect is called at the first rendering.
   */
  useEffect(() => {
    // Sanity checks
    if (!idSession) {
      toast.error('Impossibile recuperare la sessione di checkout');
      return;
    }

    setIsProcessing(true);

    // Get the session information
    stripeGetSession(idSession ?? '')
      .then(result => {
        const stripeSession = result.data;
        // Update the shown customer name
        setCustomerName(stripeSession.customerName);
        // Get the request entity linked to the stripe session
        getRequestPromise(result.data.requestId, 'afterPayment')
          .then(res => {
            const request = res.data;
            // Create the new payment
            if (stripeSession.paymentEntity === PaymentEntities.CONSULTING)
              dispatch({
                type: appActionConstants.SET_OPEN_APPOINTMENT_MODAL,
                payload: { isOpen: true },
              });
            insertPayment(stripeSession, request).then(() => {
              // Update the request state to complete the payment
              updateRequestPromise({
                id: result.data.requestId,
                state: States.PagamentoCompletato,
              })
                .then(() => {
                  setIsProcessing(false);
                  // Navigate to the request, which at this point should be in the correct state
                  navigate(FE_ROUTES.REQUEST + '/' + result.data.requestId);
                })
                .catch(() => {
                  toast.error("Errore durante l'aggiornamento della richiesta");
                  setIsProcessing(false);
                  setIsFailed(true);
                });
            });
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(() => {
        toast.error('Errore durante la sessione di checkout');
        setIsProcessing(false);
        setIsFailed(true);
      });
  }, []);

  /**
   * This method create a new payments from a succesful stripe session.
   * @param stripeSession
   */
  const insertPayment = (
    stripeSession: StripeGetSessionResponse,
    request: Request,
  ): Promise<void> => {
    // Build the payload for the new payment to create
    const payload = {
      customer: request.customer.id || '',
      request: request.id,
      type: stripeSession.paymentType,
      grossAmount: stripeSession.grossAmount,
      netAmount: stripeSession.netAmount,
      vat: stripeSession.vat,
      source: stripeSession.paymentEntity,
    };
    // Create the new payment
    return createPayment(payload);
  };

  return (
    <div>
      <Helmet>
        <title>
          {'Allegato4.it - ' +
            intl.formatMessage({
              id: `stripeCheckout.title`,
              defaultMessage: 'Pagamento',
            })}
        </title>
        <meta name="description" content="stripe checkout" />
        <script>{"smartsupp('chat:hide')"}</script>
      </Helmet>
      <div className="stripe-checkout-container">
        <>
          <div>
            <Grid textAlign="center">
              {customerName && isProcessing && (
                <>
                  <Grid.Row>
                    <Grid.Column>
                      <FormattedMessage
                        id="request.pending"
                        defaultMessage="Un attimo di pazienza {customerName}..."
                        values={{
                          customerName: <span>customerName</span>,
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3">
                        <FormattedMessage
                          id="request.waitingStripeCheckout"
                          defaultMessage="Stiamo recuperando i dati della sessione di checkout"
                        />
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
              {!isProcessing && isFailed && (
                <>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3">
                        <FormattedMessage
                          id="request.stripeCheckoutFailure"
                          defaultMessage="Qualcosa non è andato a buon fine, ricarica la pagina"
                        />
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Button
                      type="button"
                      className="button--primary"
                      icon="refresh"
                      onClick={() => window.location.reload()}
                    ></Button>
                  </Grid.Row>
                </>
              )}
            </Grid>
          </div>
        </>
      </div>
    </div>
  );
};
