import React from 'react';
import { FormattedMessage } from 'react-intl';

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className="footer-text">
        <FormattedMessage
          id="copyright"
          defaultMessage="© Copyright 2022 Planet Finance"
        />
      </div>
    </div>
  );
};

export default Footer;
