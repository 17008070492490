import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Header, Tab } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { getAgentById, updateAgent } from 'services/agent/index';
import {
  agentProfileReducer,
  AgentProfileState,
  initialAgentProfileState,
} from './reducer/reducer';
import { AgentAction } from 'pages/Agent/reducer/actions';
import { FormikHelpers } from 'formik';
import AgentProfileForm from 'components/Forms/AgentProfileForm';
import { AgentType } from 'pages/Agent/types';
import { useNavigate } from 'react-router-dom';
import { FE_ROUTES } from 'utils/global/globalCostants';
import { agentReducer, initialAgentState } from 'pages/Agent/reducer/reducer';

export const CustomerProfileContext = createContext<{
  state: AgentProfileState;
  dispatch: React.Dispatch<AgentAction>;
}>({
  state: initialAgentProfileState,
  dispatch: () => null,
});

const AgentProfile: React.FC = () => {
  /** Get params */
  const { id } = useParams();

  /**
   * The react-intl object.
   */
  const intl = useIntl();

  const navigate = useNavigate();

  /** Gets the reducer to receive GET request result */
  const [state, dispatch] = useReducer(
    agentProfileReducer,
    initialAgentProfileState,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [stateAgent, dispatchAgent] = useReducer(
    agentReducer,
    initialAgentState,
  );

  const getAgentValues = () => {
    if (id) {
      getAgentById(id, dispatch);
    }
  };

  useEffect(() => {
    getAgentValues();
  }, []);

  const initialValues = useMemo<AgentType>(() => {
    const transformData = {
      id: state.agent.id,
      name: state.agent.name,
      surname: state.agent.surname,
      email: state.agent.email,
      type: state.agent.type,
      vouchers: state.agent.vouchers ? state.agent.vouchers.length : 0,
    };
    return transformData;
  }, [state.agent]);

  /**
   * Method used to manage the submit action from CustomerProfileForm component.
   * @param values Customer data.
   */
  const onSubmit = (
    values: AgentType,
    formikHelpers: FormikHelpers<AgentType>,
  ) => {
    updateAgent(values, id ?? '', dispatchAgent, formikHelpers);
    navigate(`${FE_ROUTES.AGENT}`);
  };

  return (
    <div>
      <Helmet>
        <title>
          {'Allegato4.it - ' +
            intl.formatMessage({
              id: 'agent.title',
              defaultMessage: 'Profilo agente',
            })}
        </title>
        <meta name="description" content="agent profile page" />
        <script>{"smartsupp('chat:hide')"}</script>
      </Helmet>
      <div className="agent-container">
        <>
          <div>
            <Header className="agentHeader" dividing>
              <Grid>
                <Grid.Row>
                  <Grid.Column className="columnProfile">
                    <Header>
                      <h1>
                        <FormattedMessage
                          id="agent.title"
                          defaultMessage="Profilo agente"
                        />
                      </h1>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header>
            <div className="agentSectionBody">
              <div className="agentSectionForm">
                <Tab
                  renderActiveOnly={false}
                  activeIndex={0}
                  panes={[
                    {
                      menuItem: {
                        id: 'agentInfo',
                        content: (
                          <FormattedMessage
                            id="agentInfo"
                            defaultMessage="Dati agente"
                          />
                        ),
                        key: 'agentInfo',
                      },
                      pane: (
                        <Tab.Pane>
                          <AgentProfileForm
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            mode="modify"
                          />
                        </Tab.Pane>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default AgentProfile;
