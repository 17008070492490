import { createContext, useContext } from 'react';
import { StateMachineContextState } from './types';

export const StateMachineContext = createContext<StateMachineContextState>(
  {} as StateMachineContextState,
);

export function useStateMachine(): StateMachineContextState {
  return useContext(StateMachineContext);
}
