import React from 'react';
import { Modal, Icon, Grid } from 'semantic-ui-react';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { InvoiceForm } from 'components/Forms/InvoiceForm';
import { Button } from 'semantic-ui-react';
import { States } from 'services/stateMachine/types';
import { FormattedMessage } from 'react-intl';
import { useAppointment } from 'pages/Appointment/context';
import { PaymentEntities } from 'services/payment/types';

export const ConsultancyInvoiceModal: React.FC = () => {
  /**
   * The state machine object.
   */
  const { state } = useStateMachine();
  const { openInvoiceModal, onCloseInvoiceModal } = useAppointment();

  return (
    <Modal
      open={openInvoiceModal}
      closeOnEscape
      onclose={onCloseInvoiceModal}
      className="consultancy-invoice-modal"
    >
      <Icon name="close" onClick={onCloseInvoiceModal}></Icon>
      <InvoiceForm mode={PaymentEntities.CONSULTING} />
      <Grid.Row>
        <Grid.Column>
          <Button
            form="form__consultancyInvoice"
            type="submit"
            loading={state.forward}
            className="button--success"
            disabled={state.parent === States.Verifica}
          >
            <FormattedMessage
              id="request.goToPayment"
              defaultMessage="Prosegui al pagamento"
            />
            <Icon name="angle double right" />
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Modal>
  );
};
