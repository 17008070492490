import React from 'react';
import { Menu } from 'semantic-ui-react';
import { SideBarProps } from './types';
import { useLocation, useNavigate } from 'react-router-dom';

const SideBar: React.FC<SideBarProps> = ({ className, sideBarItems }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <Menu className={`sidebar ${className}`} inverted size="massive">
        {sideBarItems.map(
          (sideBarItem, index) =>
            sideBarItem && (
              <>
                <Menu.Item
                  className="sidebar__item"
                  //as={NavLink}
                  style={{ textAlign: 'center' }}
                  key={`${sideBarItem.id}_${index}`}
                  name={sideBarItem.id}
                  to={sideBarItem.to}
                  onClick={() => navigate(sideBarItem.to)}
                  selected={location.pathname === sideBarItem.to}
                  active={location.pathname === sideBarItem.to}
                >
                  {sideBarItem.title}
                </Menu.Item>
              </>
            ),
        )}
      </Menu>
    </>
  );
};

export default SideBar;
