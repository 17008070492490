import { Customer, CustomerResponse } from 'services/customer/types';

export enum CustomerActionConstants {
  SET_SEARCH_FILTER = 'Customer/SET_SEARCH_FILTER',
  SET_CUSTOMER_DICTIONARY = 'Customer/SET_CUSTOMER_DICTIONARY',
  SET_CUSTOMER = 'Customer/SET_CUSTOMER',
  SET_CUSTOMER_TYPE = 'Customer/SET_CUSTOMER_TYPE',
}

export enum CustomerType {
  ACCOUNTANT = 'accountant',
  ACCOUNTANTCLIENT = 'accountant-client',
  BANK = 'bank',
  BANKCLIENT = 'bank-client',
  WEBUSER = 'web-user',
  CUSTOMER = 'customer',
}

export enum CustomerPageActionConstants {
  VIEW = 'view',
  MODIFY = 'edit',
  NEW = 'new',
}

export type CustomerAction =
  | {
      type: CustomerActionConstants.SET_SEARCH_FILTER;
      payload: { isLoading: boolean };
    }
  | {
      type: CustomerActionConstants.SET_CUSTOMER_DICTIONARY;
      payload: { customerList: Array<Customer> };
    }
  | {
      type: CustomerActionConstants.SET_CUSTOMER;
      payload: { customer: CustomerResponse };
    }
  | {
      type: CustomerActionConstants.SET_CUSTOMER_TYPE;
      payload: { customerType: string };
    };
