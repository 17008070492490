import React, { useState } from 'react';
import {
  Card,
  Header,
  //Embed,
  Image,
  Modal,
  Placeholder,
} from 'semantic-ui-react';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import silverLogo from 'assets/images/icon/silver-plan.svg';
import goldLogo from 'assets/images/icon/gold-plan.svg';
import platinumLogo from 'assets/images/icon/platinum-plan.svg';
import ReactPlayer from 'react-player';
import { Consultancy } from 'components/Consultancy';

/**
 * A custom hook to deal with the modal.
 * @param initialValue The initial value
 * @returns the hook
 */
const useModal = initialValue => {
  const [open, setOpen] = useState(initialValue);

  function onOpen() {
    setOpen(true);
  }

  function onClose() {
    setOpen(false);
  }

  return [open, onOpen, onClose];
};

/**
 * The appointment page.
 * @returns
 */
export const Appointment: React.FC = () => {
  /**
   * Take from the state machine context the calendar flag and the current request.
   */
  const {
    state: { request },
  } = useStateMachine();

  /**
   * A flag to open the tutorial modal.
   */
  const [tutorial, openTutorial, closeTutorial] = useModal(false);

  return (
    <>
      <div className="request__sidebar">
        <Header style={{ backgroundColor: 'transparent' }} as="h2" image>
          {
            {
              S: <Image src={silverLogo} />,
              G: <Image src={goldLogo} />,
              P: <Image src={platinumLogo} />,

              // Default
              D: <Image src={silverLogo} />,
            }[request?.plan?.code || 'D']
          }

          <Header.Content>{request?.plan?.description}</Header.Content>
        </Header>
        <Consultancy id={request?.id} mode="sidebar" />
        <Card className="request__sidebar__card" onClick={openTutorial}>
          <Card.Content className="request__sidebar__card__content--tutorial">
            <div>Guarda il video tutorial!</div>
            <div>
              <Placeholder>
                <Placeholder.Image />
              </Placeholder>
            </div>
          </Card.Content>
        </Card>
        <Modal open={tutorial} onClose={closeTutorial} closeIcon>
          <Modal.Content>
            {/* <Embed
              icon="right circle arrow"
              url="http://www.allegato4.it/wp-content/uploads/2022/11/video_tutorial.webm"
            /> */}
            <ReactPlayer
              url="http://www.allegato4.it/wp-content/uploads/2022/11/video_tutorial.webm"
              className="react-player"
              playing
              width="100%"
              height="100%"
              controls={true}
            />
          </Modal.Content>
        </Modal>
      </div>
    </>
  );
};
