import { useContext } from 'react';
import _ from 'lodash';

import { AppContext } from 'pages/App';
import { ROLES } from 'utils/global/globalCostants';
import { AppState } from 'pages/App/reducer/reducer';

// Verify if the current user role in my self object have the searched role.
export const isThatRole = (
  searchedRole: ROLES,
  state: AppState | null = null,
): boolean => {
  if (!state) {
    ({ state } = useContext(AppContext));
  }
  if (!state.mySelf || _.isEmpty(state.mySelf.roles)) {
    return false;
  }
  if (_.includes(state.mySelf.roles, searchedRole)) {
    return true;
  }
  return false;
};
