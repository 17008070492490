import {
  GenerateVoucherAction,
  GenerateVoucherActionConstants,
} from './actions';

export type GenerateVoucherState = {
  isLoading: boolean;
  modalOpen: boolean;
};

export const initialGenerateVoucherState: GenerateVoucherState = {
  isLoading: false,
  modalOpen: false,
};

export function generateVoucherReducer(
  state: GenerateVoucherState,
  action: GenerateVoucherAction,
): GenerateVoucherState {
  const { type } = action;

  switch (type) {
    case GenerateVoucherActionConstants.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading };
    case GenerateVoucherActionConstants.SET_MODAL_IS_OPEN:
      return { ...state, modalOpen: action.payload.modalOpen };
    default:
      return state;
  }
}
