import React from 'react';
import { PaymentEntities } from 'services/payment/types';

import { InvoiceForm } from 'components/Forms/InvoiceForm';

export const StepPrePagamento: React.FC = () => {
  return (
    <div>
      <InvoiceForm mode={PaymentEntities.PLAN} />
    </div>
  );
};
