import { FormikHelpers } from 'formik';
import { AgentType } from 'pages/Agent/types';

export type AgentProfileFormProps = {
  initialValues: AgentType;
  onSubmit: (
    values: AgentType,
    formikHelpers: FormikHelpers<AgentType>,
  ) => void;
  mode: string | undefined;
};

export enum AgentTypeConstants {
  AREA_MANAGER = 'area-manager',
  CONSULTANT = 'consultant',
  BACK_OFFICE = 'back-office',
}
