import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  Formik,
  useFormikContext,
} from 'formik';
import { Button, Grid, Header, Modal, Select } from 'semantic-ui-react';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import DataGrid from 'components/DataGrid';
import { LinkedCompanyModalProps } from './types';
import { LinkedCompany as LinkedCompanyEntity } from 'services/linkedCompany/types';
import { StepBilancioCalcoloDimensionaleAziendaValues } from 'components/RequestStep/StepBilancioCalcoloDimensionaleAzienda/types';
import { CurrencyInputFormik } from 'components/CurrencyInputFormik';
import { PercentageInputFormik } from 'components/PercentageInputFormik';
import { getRelationTypes } from 'services/linkedCompany';
import _ from 'lodash';

/**
 * The linked company table used in the step BilancioCalcoloDimensionaleAziendale.
 * This component manages the linked company array and push its changes in the main form
 * property. The linked company will be used in the BilancioCalcoloDimensionaleAziendale as an array property.
 * @returns
 */
export const LinkedCompany: React.FC = () => {
  /**  The Formik values from the main form. */
  const { values } =
    useFormikContext<StepBilancioCalcoloDimensionaleAziendaValues>();

  /** The react-intl object. */
  const intl = useIntl();

  /**
   * The list of the available relations.
   */
  const [relationTypes, setRelationTypes] = useState<
    { value: string; text: string }[]
  >([]);

  /**
   * Fetch all the relation types and map them as valid options.
   */
  const loadRelationOptions = () => {
    const types = getRelationTypes();
    // Fill the react-select component with the relation types options.
    setRelationTypes(
      types.map(c => {
        return {
          value: c,
          text: intl.formatMessage({
            id: `linkedCompany.${c}`,
            defaultMessage: c,
          }),
        };
      }),
    );
  };

  /**
   * This effect is called at the first rendering.
   */
  useEffect(() => {
    loadRelationOptions();
  }, []);

  /**
   * The modal properties.
   * It stores the current linked company index (optionally, if the modal is open and it's linked to a valid linked company)
   * and the open/close flag.
   */
  const [modal, setModal] = useState<LinkedCompanyModalProps>({
    open: false,
  });

  /**
   * Open the modal to edit the selected linked company.
   * @param data the selected row
   */
  const onEdit = data => {
    // Get the index of the the selected row.
    const newIndex = values.linkedCompanies.indexOf(data);

    // Open the modal.
    setModal({
      open: true,
      index: newIndex,
    });
  };

  /**
   * Delete the selected linked company and reload the list.
   * @param arrayHelpers the utilities for the array property
   * @param data the selected row
   */
  const onDelete = (arrayHelpers: FieldArrayRenderProps, data) => {
    // Get the index of the the selected row.
    const newIndex = values.linkedCompanies.indexOf(data);

    arrayHelpers.remove(newIndex);
  };

  /**
   * This method performs two operations:
   * 1. Update the data of the selected linked company
   * 2. Add a new linked company
   *
   * @param arrayHelpers the utilities for the array property
   * @param values the formik values
   */
  const onSubmit = (arrayHelpers: FieldArrayRenderProps, values) => {
    // Check if the modal is open for a specific linked company.
    if (modal.index != null) {
      arrayHelpers.replace(modal.index, values);
    } else {
      arrayHelpers.push({
        id: uuidv4(),
        ...values,
      });
    }

    setModal({ open: false });
  };

  const initialValues = useMemo<LinkedCompanyEntity>(() => {
    // If the modal is in "edit mode" use the value of the selected linked company.
    if (modal.index != null) {
      return values.linkedCompanies[modal.index];
    }

    // Otherwise create an empty object.
    return {
      businessName: '',
      fiscalCode: '',
      incomingAmount: 0,
      totalAssetsAmount: 0,
      employeeNumber: 0.0,
      relationType: '',
      sharePercentage: 0.0,
    };
  }, [modal.index]);

  return (
    <>
      <FieldArray
        name="linkedCompanies"
        render={arrayHelpers => (
          <>
            <Modal
              closeIcon
              open={modal.open}
              onClose={() => setModal({ open: false })}
            >
              <Modal.Content>
                {modal.open && (
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values, formikHelpers) => {
                      let canSubmit = true;
                      if (
                        _.startsWith(
                          _.lowerCase(values.relationType),
                          'associata',
                        ) &&
                        values.sharePercentage &&
                        values.sharePercentage > 50
                      ) {
                        formikHelpers.setFieldError(
                          'sharePercentage',
                          'La percentuale di collegamento delle aziende associate non può superare il 50%',
                        );
                        canSubmit = false;
                      } else if (
                        _.startsWith(
                          _.lowerCase(values.relationType),
                          'collegata',
                        ) &&
                        values.sharePercentage &&
                        values.sharePercentage < 50.01
                      ) {
                        formikHelpers.setFieldError(
                          'sharePercentage',
                          'La percentuale di collegamento delle aziende collegate non può essere inferiore a 50,01%',
                        );
                        canSubmit = false;
                      }
                      if (canSubmit) onSubmit(arrayHelpers, values);
                    }}
                    validationSchema={Yup.object().shape({
                      businessName: Yup.string()
                        .label(
                          intl.formatMessage({
                            id: 'linkedCompany.businessName',
                            defaultMessage: 'Ragione sociale',
                          }),
                        )
                        .required()
                        .nullable(),
                      fiscalCode: Yup.string()
                        .label(
                          intl.formatMessage({
                            id: 'linkedCompany.vatNumber',
                            defaultMessage: 'Partita Iva',
                          }),
                        )
                        .required()
                        .nullable()
                        .length(11),
                      incomingAmount: Yup.number()
                        .label(
                          intl.formatMessage({
                            id: 'linkedCompany.incomingAmount',
                            defaultMessage: 'Ricavi (migliaia di euro)',
                          }),
                        )
                        .required()
                        .nullable(),
                      totalAssetsAmount: Yup.number()
                        .label(
                          intl.formatMessage({
                            id: 'linkedCompany.totalAssetsAmount',
                            defaultMessage: 'Totale attivo (migliaia di euro)',
                          }),
                        )
                        .required()
                        .nullable(),
                      employeeNumber: Yup.number()
                        .label(
                          intl.formatMessage({
                            id: 'linkedCompany.employeeNumber',
                            defaultMessage: 'Numero medio dipendenti',
                          }),
                        )
                        .required()
                        .nullable(),
                      relationType: Yup.string()
                        .label(
                          intl.formatMessage({
                            id: 'linkedCompany.relationType',
                            defaultMessage: 'Tipo relazione',
                          }),
                        )
                        .required()
                        .nullable(),
                      sharePercentage: Yup.number()
                        .label(
                          intl.formatMessage({
                            id: 'linkedCompany.sharePercentage',
                            defaultMessage: 'Percentuale di collegamento',
                          }),
                        )
                        .min(25)
                        .max(100)
                        .required()
                        .nullable()
                        .typeError(
                          'Percentuale di collegamento deve essere un numero',
                        ),
                    })}
                  >
                    {({ values, setFieldValue, handleBlur }) => (
                      <Form id="group-structure">
                        <Grid>
                          <Grid.Row>
                            <Grid.Column>
                              <Header as="h3">
                                <FormattedMessage
                                  id="linkedCompany.title"
                                  defaultMessage="Dati azienda collegata"
                                />
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row centered columns={2}>
                            <Grid.Column>
                              <Input
                                label={
                                  <label>
                                    <FormattedMessage
                                      id="linkedCompany.businessName"
                                      defaultMessage="Ragione sociale"
                                    />
                                    *
                                  </label>
                                }
                                name="businessName"
                                type="text"
                              />
                              <ErrorMessage
                                name="businessName"
                                component="span"
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Input
                                label={
                                  <label>
                                    <FormattedMessage
                                      id="linkedCompany.vatNumber"
                                      defaultMessage="Partita Iva"
                                    />
                                    *
                                  </label>
                                }
                                name="fiscalCode"
                                type="text"
                                onKeyPress={event => {
                                  if (
                                    !/[0-9]/.test(event.key) ||
                                    values.fiscalCode.length === 11
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="fiscalCode"
                                component="span"
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row centered columns={2}>
                            <Grid.Column>
                              <Field name="incomingAmount">
                                {({ field }) => (
                                  <CurrencyInputFormik
                                    field={field}
                                    label={
                                      <label>
                                        <FormattedMessage
                                          id="linkedCompany.incomingAmount"
                                          defaultMessage="Ricavi"
                                        />
                                        *
                                      </label>
                                    }
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                    placeholder="€ Importo in Euro"
                                  />
                                )}
                              </Field>
                            </Grid.Column>
                            <Grid.Column>
                              <Field name="totalAssetsAmount">
                                {({ field }) => (
                                  <CurrencyInputFormik
                                    field={field}
                                    label={
                                      <label>
                                        <FormattedMessage
                                          id="linkedCompany.totalAssetsAmount"
                                          defaultMessage="Totale attivo"
                                        />
                                        *
                                      </label>
                                    }
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                    placeholder="€ Importo in Euro"
                                    decimalsLimit={0}
                                  />
                                )}
                              </Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row centered columns={2}>
                            <Grid.Column>
                              <Input
                                label={
                                  <label>
                                    <FormattedMessage
                                      id="linkedCompany.employeeNumber"
                                      defaultMessage="Numero medio dipendenti"
                                    />
                                    *
                                  </label>
                                }
                                name="employeeNumber"
                                type="text"
                              />
                              <ErrorMessage
                                name="employeeNumber"
                                component="span"
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Field name="sharePercentage">
                                {({ field }) => (
                                  <PercentageInputFormik
                                    field={field}
                                    label={
                                      <label>
                                        <FormattedMessage
                                          id="linkedCompany.sharePercentage"
                                          defaultMessage="Percentuale di collegamento"
                                        />
                                      </label>
                                    }
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                  />
                                )}
                              </Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <Field name="relationType">
                                {({ field }) => (
                                  <>
                                    <label>
                                      <FormattedMessage
                                        id="linkedCompany.relationType"
                                        defaultMessage="Tipo di relazione"
                                      />
                                      *
                                    </label>
                                    <br />
                                    <Select
                                      options={relationTypes}
                                      onChange={(_event, data) =>
                                        setFieldValue(field.name, data.value)
                                      }
                                      value={values.relationType}
                                      style={{ minWidth: '100%' }}
                                    />
                                  </>
                                )}
                              </Field>
                              <ErrorMessage
                                name="subscriptionType"
                                component="span"
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                )}
              </Modal.Content>
              <Modal.Actions>
                <SubmitButton form="group-structure">
                  {modal.index == null && (
                    <FormattedMessage
                      id="linkedCompany.add"
                      defaultMessage="Aggiungi"
                    />
                  )}
                  {modal.index != null && (
                    <FormattedMessage
                      id="linkedCompany.update"
                      defaultMessage="Aggiorna"
                    />
                  )}
                </SubmitButton>
              </Modal.Actions>
            </Modal>
            {/* The linked company table */}
            <DataGrid
              isLoading={false}
              paginate={false}
              page={1}
              pageCount={1}
              pageSize={10}
              onPageSelect={() => null}
              totalItems={10}
              elements={values.linkedCompanies.sort((a, b) =>
                a.businessName > b.businessName
                  ? 1
                  : b.businessName > a.businessName
                  ? -1
                  : 0,
              )}
              columns={[
                {
                  key: 'businessName',
                  name: (
                    <FormattedMessage
                      id="linkedCompany.businessName"
                      defaultMessage="Ragione sociale"
                    />
                  ),
                  formatter: ({ data }) => (
                    <span style={{ textTransform: 'uppercase' }}>
                      {data.businessName}
                    </span>
                  ),
                },
                {
                  key: 'fiscalCode',
                  name: (
                    <FormattedMessage
                      id="linkedCompany.vatNumber"
                      defaultMessage="Partita Iva"
                    />
                  ),
                  formatter: ({ data }) => (
                    <span style={{ textTransform: 'uppercase' }}>
                      {data.fiscalCode}
                    </span>
                  ),
                },
                {
                  key: 'relationType',
                  name: (
                    <FormattedMessage
                      id="linkedCompany.relationType"
                      defaultMessage="Tipo relazione"
                    />
                  ),
                },
                {
                  key: 'sharePercentage',
                  name: (
                    <FormattedMessage
                      id="linkedCompany.sharePercentage"
                      defaultMessage="Percentuale collegamento"
                    />
                  ),
                  formatter: ({ data }) => <span>{data.sharePercentage}%</span>,
                },
                {
                  key: 'actions',
                  name: '',
                  formatter: ({ data }) => {
                    return (
                      <>
                        <Button
                          type="button"
                          onClick={() => onEdit(data)}
                          className="button--primary"
                        >
                          <FormattedMessage
                            id="request.editLinkedCompany"
                            defaultMessage="Modifica"
                          />
                        </Button>
                        <Button
                          type="button"
                          onClick={() => onDelete(arrayHelpers, data)}
                          className="button--primary"
                        >
                          <FormattedMessage
                            id="request.deleteLinkedCompany"
                            defaultMessage="Cancella"
                          />
                        </Button>
                      </>
                    );
                  },
                },
              ]}
            />
            <Grid>
              <Grid.Row textAlign="center">
                <Grid.Column>
                  <Button
                    type="button"
                    onClick={() => {
                      setModal({
                        open: true,
                      });
                    }}
                  >
                    <FormattedMessage
                      id="request.addNewLinkedCompany"
                      defaultMessage="Aggiungi azienda collegata"
                    />
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      />
    </>
  );
};
