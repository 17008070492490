import React, { useContext, useState } from 'react';
import { Grid, Button, Icon, Modal, Header } from 'semantic-ui-react';
import { Form } from 'formik-semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isThatRole } from 'utils/function/acl';
import { ROLES } from 'utils/global/globalCostants';
import { AppContext } from 'pages/App';
import { toast } from 'react-toastify';
import AgentProfileForm from '../AgentProfileForm';
import { AgentType } from 'pages/Agent/types';
import { createAgent } from 'services/agent/index';
import _ from 'lodash';
import { AgentFilterFormTypes } from './types';

const AgentFilterForm: React.FC<AgentFilterFormTypes> = ({ dispatch }) => {
  const initialValues: AgentType = {
    id: null,
    name: null,
    surname: null,
    type: null,
    email: null,
    vouchers: 0,
  };

  const context = useContext(AppContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onCreateAgent = () => {
    if (isThatRole(ROLES.ADMIN, context.state)) {
      setModalOpen(true);
    } else {
      toast.error('Impossibile generare agente - Accesso negato');
      return;
    }
  };

  const newAgent = async (values, formikHelpers) => {
    _.unset(values, 'id');
    createAgent(values, formikHelpers, dispatch);
    setModalOpen(false);
  };

  return (
    <>
      <Modal closeIcon open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h2">
                  <FormattedMessage
                    id="createAgent.title"
                    defaultMessage="Nuovo agente"
                  />
                </Header>
                <AgentProfileForm
                  initialValues={initialValues}
                  onSubmit={newAgent}
                  mode="create"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
      <Formik
        initialValues={initialValues}
        onSubmit={newAgent}
        validationSchema={Yup.object().shape({
          requestType: Yup.object().nullable(),
        })}
      >
        {/*eslint-disable-next-line @typescript-eslint/no-unused-vars*/}
        {({ resetForm }) => (
          <Form>
            <Grid padded>
              <Grid.Row>
                <Grid.Column className="add-agent">
                  <Button
                    type="button"
                    className="button--primary"
                    onClick={onCreateAgent}
                  >
                    <div>
                      <FormattedMessage
                        id="agent.createAgent"
                        defaultMessage="Aggiungi agente"
                      />
                      <Icon name="plus" />
                    </div>
                  </Button>
                  {/* <SubmitButton
                    className="button--primary--negative"
                    content={
                      <FormattedMessage
                        id="voucherFilter.apply"
                        defaultMessage="Applica"
                      />
                    }
                    loading={generateVoucherState.isLoading}
                  />
                  <Button
                    content="Reset"
                    type="button"
                    className="button--underline"
                    onClick={() => resetForm()}
                  /> */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AgentFilterForm;
