import standardClient from 'services/client/standardRequestClient';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { API } from 'utils/global/backendRoutes';
import { IndicatorType } from 'services/dashboard/types';
import _ from 'lodash';

/**
 * Gets latest indicator's record.
 */

export const getLatestIndicator = (
  setIndicators: (indicators: IndicatorType) => void,
  setIsLoading: (loading: boolean) => void,
): Promise<void> => {
  setIsLoading(true);

  return standardClient({
    url: `${API.INDICATOR}`,
    method: 'GET',
  })
    .then((response: AxiosResponse<IndicatorType>) => {
      if (!_.isEmpty(response.data)) setIndicators(response.data);
      else {
        const indicator = {
          id: null,
          date: null,
          customersGrandTotal: 'n.d.',
          requestsGrandTotal: 'n.d.',
          ongoingRequestsTotal: 'n.d.',
          completedRequestsTotal: 'n.d.',
          expiredRequestsTotal: 'n.d.',
          appointmentsGrandTotal: 'n.d.',
          reqFreelancer: null,
          reqProfessionalFirm: null,
          reqLimitedCompany: null,
          reqPartnership: null,
          reqGold: null,
          reqSilver: null,
          reqGaranziaDiretta: null,
          reqRiassicurazione: null,
          reqStarted: null,
          reqCompleted: null,
          reqFailed: null,
          reqAvgCompletionTime: null,
          reqMonthlyTrend: [],
          regMonthlyTrend: [],
        };
        setIndicators({ ...indicator });
      }
      setIsLoading(false);
    })
    .catch(() => {
      const indicator = {
        id: null,
        date: null,
        customersGrandTotal: 'n.d.',
        requestsGrandTotal: 'n.d.',
        ongoingRequestsTotal: 'n.d.',
        completedRequestsTotal: 'n.d.',
        expiredRequestsTotal: 'n.d.',
        appointmentsGrandTotal: 'n.d.',
        reqFreelancer: null,
        reqProfessionalFirm: null,
        reqLimitedCompany: null,
        reqPartnership: null,
        reqGold: null,
        reqSilver: null,
        reqGaranziaDiretta: null,
        reqRiassicurazione: null,
        reqStarted: null,
        reqCompleted: null,
        reqFailed: null,
        reqAvgCompletionTime: null,
        reqMonthlyTrend: [],
        regMonthlyTrend: [],
      };
      setIndicators({ ...indicator });
      toast.error('Errore durante il caricamento dei dati');
      setIsLoading(false);
    });
};
