import React, { useMemo } from 'react';
import { Formik, FormikHelpers, Field, ErrorMessage } from 'formik';
import { Form } from 'formik-semantic-ui-react';
import { Grid, Header, Container, Checkbox } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Tooltip } from 'components/Tooltip';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { updateRequest } from 'services/request';
import { StepCassaProfessionaleValues } from './types';
import { States } from 'services/stateMachine/types';
import { FormControlLabel } from '@material-ui/core';
import {
  CasseProfessionali,
  CasseProfessionaliTooltips,
} from 'utils/global/globalCostants';
import * as Yup from 'yup';

export const StepClienteCassaProfessionale: React.FC = () => {
  const { state, dispatch } = useStateMachine();

  const initialValues = useMemo<StepCassaProfessionaleValues>(() => {
    if (state.request && state.request.cassaProfessionale) {
      return {
        cassaProfessionale: state.request.cassaProfessionale.split('|'),
      };
    }
    return {
      cassaProfessionale: [],
    };
  }, [state.request?.requestPurpose]);

  const onSubmit = (
    values: StepCassaProfessionaleValues,
    formikHelpers: FormikHelpers<StepCassaProfessionaleValues>,
  ) => {
    const payload = {
      cassaProfessionale: !_.isEmpty(values.cassaProfessionale)
        ? values.cassaProfessionale.join('|')
        : null,
    };

    updateRequest(
      {
        ...payload,
        id: state.request?.id,
        state: States.CassaProfessionale_Finanziamento,
      },
      dispatch,
      formikHelpers,
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        cassaProfessionale: Yup.array().label('Cassa professionale').max(1),
      })}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Form id={`form__${state.current}`} onSubmit={handleSubmit}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">
                  <FormattedMessage
                    id="stepCP.doesItBelong"
                    defaultMessage="Il soggetto beneficiario finale appartiene ad una di queste "
                  />
                  <strong className="title__bold">
                    <FormattedMessage
                      id="stepCP.CP"
                      defaultMessage="Casse Professionali "
                    />
                  </strong>
                  ?
                  <Tooltip
                    header={
                      <Header as="h2">
                        <FormattedMessage
                          id="stepCP.welfareFund"
                          defaultMessage="Sezione CDP - Casse Professionali"
                        />
                      </Header>
                    }
                    content={
                      <Container>
                        <p>
                          Ai fini dell’ammissibilità della presente agevolazione
                          alla Sezione CDP – Casse Professionali, il soggetto
                          beneficiario finale può dichiarare di appartenere ad
                          una delle Casse Professionali elencate in questa
                          pagina.
                        </p>
                        <p>
                          Sarà anche necessario attestare di essere in regola
                          con il pagamento dei contributi verso l’ente. Per
                          maggiori informazioni, si vedano i messaggi di aiuto a
                          fianco di ogni ente.
                        </p>
                      </Container>
                    }
                  />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <div className="step__cp__options">
              <div role="group" aria-labelledby="checkbox-group">
                {_.keys(CasseProfessionali).map((cp, i) => (
                  <Grid.Row className="step__cp__option" key={`${cp}_${i}`}>
                    <Grid.Column>
                      <label className="step__cp__label">
                        <Field
                          className="step__cp__input"
                          type="checkbox"
                          name="cassaProfessionale"
                          value={cp}
                          as={FormControlLabel}
                          control={
                            <Checkbox
                              checked={values.cassaProfessionale.includes(cp)}
                              onChange={(e, d) => {
                                const cpClone = _.clone(
                                  values.cassaProfessionale,
                                );
                                if (
                                  d.checked &&
                                  !values.cassaProfessionale.includes(cp)
                                ) {
                                  cpClone.push(cp);
                                  setFieldValue('cassaProfessionale', cpClone);
                                } else if (
                                  !d.checked &&
                                  values.cassaProfessionale.includes(cp)
                                )
                                  cpClone.splice(
                                    values.cassaProfessionale.indexOf(cp),
                                    1,
                                  );
                                setFieldValue('cassaProfessionale', cpClone);
                              }}
                              disabled={
                                values.cassaProfessionale.length >= 1 &&
                                !values.cassaProfessionale.includes(cp)
                              }
                            />
                          }
                        />
                        {CasseProfessionali[cp]}
                      </label>
                    </Grid.Column>
                    <Grid.Column className="step__cp__tooltip">
                      {' '}
                      <Tooltip
                        header={CasseProfessionali[cp]}
                        content={
                          <Container
                            dangerouslySetInnerHTML={{
                              __html: CasseProfessionaliTooltips[cp],
                            }}
                          />
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </div>
            </div>
            <Grid.Row>
              <Grid.Column>
                {' '}
                <ErrorMessage
                  name="cassaProfessionale"
                  component="span"
                  className="error error-all"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
