import { ErrorMessage, Formik, FormikHelpers, getIn } from 'formik';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox, Form, Input, Field } from 'formik-semantic-ui-react';
import { Grid, Header, Button, Icon } from 'semantic-ui-react';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { updateRequest } from 'services/request';
import { States } from 'services/stateMachine/types';
import { StepDichiarazioniDeMinimisValues } from './types';
import _ from 'lodash';
import { CurrencyInputFormik } from 'components/CurrencyInputFormik';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import * as Yup from 'yup';
import { FormikWatcher } from 'components/FormikWatcher';

export const StepDichiarazioniDeMinimis: React.FC = () => {
  const { state, dispatch } = useStateMachine();

  const initialValues = useMemo<StepDichiarazioniDeMinimisValues>(() => {
    if (state.request) {
      return {
        hasNotReceivedIncompatibleSupport: _.isNull(
          state.request.hasNotReceivedIncompatibleSupport,
        )
          ? false
          : state.request.hasNotReceivedIncompatibleSupport,
        hasReceivedIncompatibleSupport: _.isNull(
          state.request.hasReceivedIncompatibleSupport,
        )
          ? false
          : state.request.hasReceivedIncompatibleSupport,
        amountOfIncompatibleSupport:
          state.request.amountOfIncompatibleSupport ?? null,
        hasReimbursedIncompatibleAmounts: _.isNull(
          state.request.hasReimbursedIncompatibleAmounts,
        )
          ? false
          : state.request.hasReimbursedIncompatibleAmounts,
        reimbursedAmount: state.request.reimbursedAmount ?? null,
        reimbursementDate: state.request.reimbursementDate ?? null,
        reimbursementProcedure: state.request?.reimbursementProcedure ?? null,
        reimbursementDecreeLetter:
          state.request?.reimbursementDecreeLetter ?? null,
        hasDepositedRefund: _.isNull(state.request.hasDepositedRefund)
          ? false
          : state.request.hasDepositedRefund,
        depositedAmount: state.request.depositedAmount ?? null,
        depositDecreeLetter: state.request?.depositDecreeLetter,
        error: null,
      };
    }
    return {
      hasNotReceivedIncompatibleSupport: false,
      hasReceivedIncompatibleSupport: false,
      amountOfIncompatibleSupport: null,
      hasReimbursedIncompatibleAmounts: false,
      reimbursedAmount: null,
      reimbursementDate: null,
      reimbursementProcedure: null,
      reimbursementDecreeLetter: null,
      hasDepositedRefund: false,
      depositedAmount: null,
      depositDecreeLetter: null,
      error: null,
    };
  }, [state.request]);

  /**
   * Go to the next state.
   * @param values
   * @param formikHelpers
   */
  const onSubmit = (
    values: StepDichiarazioniDeMinimisValues,
    formikHelpers: FormikHelpers<StepDichiarazioniDeMinimisValues>,
  ) => {
    const payloadData = cleanData(values);
    updateRequest(
      {
        ...payloadData,
        id: state.request?.id,
        state: States.DichiarazioniDeMinimisPrePagamento,
      },
      dispatch,
      formikHelpers,
    );
  };

  const cleanData = (
    values: StepDichiarazioniDeMinimisValues,
  ): StepDichiarazioniDeMinimisValues => {
    _.unset(values, 'error');
    const payload = _.clone(values);
    if (
      _.isString(payload.reimbursementDecreeLetter) &&
      payload.reimbursementDecreeLetter.length === 0
    )
      payload.reimbursementDecreeLetter = null;
    if (
      _.isString(payload.reimbursementProcedure) &&
      payload.reimbursementProcedure.length === 0
    )
      payload.reimbursementProcedure = null;
    if (
      _.isString(payload.depositDecreeLetter) &&
      payload.depositDecreeLetter.length === 0
    )
      payload.depositDecreeLetter = null;
    return payload;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => {
        if (_.keys(_.pickBy(values, value => value === true)).length === 0) {
          formikHelpers.setFieldError(
            'error',
            'Seleziona una dichiarazione per passare allo step successivo.',
          );
        } else {
          let formError = false;
          const boolKey = _.keys(_.pickBy(values, value => value === true))[0];
          if (boolKey) {
            switch (boolKey) {
              case 'hasReceivedIncompatibleSupport':
                if (!values.amountOfIncompatibleSupport) {
                  formikHelpers.setFieldError(
                    boolKey,
                    "Inserire l'ammontare ricevuto",
                  );
                  formError = true;
                }
                break;
              case 'hasReimbursedIncompatibleAmounts':
                if (
                  !values.reimbursementDate ||
                  !values.reimbursementProcedure ||
                  !values.reimbursedAmount ||
                  !values.reimbursementDecreeLetter
                ) {
                  formikHelpers.setFieldError(
                    boolKey,
                    'Compilare tutti i campi correlati al rimborso',
                  );
                  formError = true;
                }
                break;
              case 'hasDepositedRefund':
                if (!values.depositedAmount || !values.depositDecreeLetter) {
                  formikHelpers.setFieldError(
                    boolKey,
                    'Compilare tutti i campi correlati al deposito',
                  );
                  formError = true;
                } else if (
                  !_.isEmpty(values.depositDecreeLetter) &&
                  !['a', 'b', 'c', 'd'].includes(
                    _.toLower(values.depositDecreeLetter),
                  )
                ) {
                  formikHelpers.setFieldError(
                    'depositDecreeLetter',
                    'Lettera non valida',
                  );
                  formError = true;
                }
                break;
            }
            if (!formError) onSubmit(values, formikHelpers);
          }
        }
      }}
      validationSchema={Yup.object().shape({
        reimbursementDate: Yup.mixed()
          .when('hasReimbursedIncompatibleAmounts', {
            is: (match: boolean) => match === true,
            then: Yup.mixed().test(
              'beforeToday',
              `La data deve essere antecedente a ${moment().format(
                'DD/MM/YYYY',
              )}`,
              value => {
                const formattedValue = value
                  ? value.isValid
                    ? value.format('YYYY/MM/DD')
                    : moment(value)
                  : null;
                const maxDate = moment().format('YYYY/MM/DD');
                return (
                  formattedValue && moment(maxDate).isAfter(formattedValue)
                );
              },
            ),
          })
          .nullable(),
        reimbursementDecreeLetter: Yup.string()
          .when('hasReimbursedIncompatibleAmounts', {
            is: (match: boolean) => match === true,
            then: Yup.string().test(
              'invalidLetter',
              'La lettera non è valida',
              value => {
                if (value)
                  return (
                    !_.isEmpty(value) &&
                    ['a', 'b', 'c', 'd'].includes(_.toLower(value))
                  );
                return true;
              },
            ),
          })
          .nullable(),
        depositDecreeLetter: Yup.string()
          .when('hasDepositedRefund', {
            is: (match: boolean) => match === true,
            then: Yup.string().test(
              'invalidLetter',
              'La lettera non è valida',
              value => {
                if (value)
                  return (
                    !_.isEmpty(value) &&
                    ['a', 'b', 'c', 'd'].includes(_.toLower(value))
                  );
                return true;
              },
            ),
          })
          .nullable(),
      })}
    >
      {({ values, errors, setFieldValue, handleBlur, isSubmitting }) => (
        <Form id={`form__${state.current}`}>
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <Header as="h2">
                  <FormattedMessage
                    id="stepDichiarazioni.flag"
                    defaultMessage="Flagga una delle seguenti "
                  />
                  <strong>
                    <FormattedMessage
                      id="stepDichiarazioni.options"
                      defaultMessage="opzioni "
                    />
                  </strong>
                  <br />
                  <FormattedMessage
                    id="stepDichiarazioni.case"
                    defaultMessage="se la società rientra in una delle casistiche elencate."
                  />
                </Header>
                <Header as="h4">
                  <FormattedMessage
                    id="stepDichiarazioni.information"
                    defaultMessage="Inserisci almeno una dichiarazione per passare allo step successivo."
                  />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className="decl--checkbox">
                <Checkbox
                  toggle
                  disabled={
                    _.keys(_.pickBy(values, value => value === true)).length >
                      0 && !values.hasNotReceivedIncompatibleSupport
                  }
                  name="hasNotReceivedIncompatibleSupport"
                />
              </Grid.Column>
              <Grid.Column
                className={
                  _.keys(_.pickBy(values, value => value === true)).length >
                    0 && !values.hasNotReceivedIncompatibleSupport
                    ? 'decl--specifications disabled'
                    : 'decl--specifications'
                }
              >
                <p>
                  Dichiara di non rientrare fra coloro che hanno ricevuto,
                  neanche secondo la regola “de minimis”, aiuti dichiarati
                  incompatibili con le decisioni della Commissione Europea
                  indicate nell’art. 4 del d.P.C.M. 23.5.2007, adottato ai sensi
                  dell’art. 1, comma 1223, della legge 27 dicembre 2006, n. 296,
                  pubblicato nella Gazzetta Ufficiale della Repubblica Italiana,
                  Serie generale, n. 160 del 12.7.2007&nbsp;
                </p>
                <ErrorMessage
                  name="hasNotReceivedIncompatibleSupport"
                  component="span"
                  className="error-all error"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className="decl--checkbox">
                <Checkbox
                  toggle
                  disabled={
                    _.keys(_.pickBy(values, value => value === true)).length >
                      0 && !values.hasReceivedIncompatibleSupport
                  }
                  name="hasReceivedIncompatibleSupport"
                  onChange={(event, data) => {
                    if (!data.checked) {
                      setFieldValue('amountOfIncompatibleSupport', null);
                    }
                  }}
                />
              </Grid.Column>
              <Grid.Column
                className={
                  _.keys(_.pickBy(values, value => value === true)).length >
                    0 && !values.hasReceivedIncompatibleSupport
                    ? 'decl--specifications disabled'
                    : 'decl--specifications'
                }
              >
                <p>
                  Dichiara di rientrare fra i soggetti che hanno ricevuto
                  secondo la regola “de minimis” gli aiuti dichiarati
                  incompatibili con le decisioni della Commissione Europea
                  indicate nell’art. 4 del d.P.C.M. 23.5.2007, adottato ai sensi
                  dell’art. 1, comma 1223, della legge 27 dicembre 2006, n. 296,
                  pubblicato nella Gazzetta Ufficiale della Repubblica Italiana,
                  Serie generale, n. 160 del 12.7.2007, per un ammontare totale
                  di euro&nbsp;
                  <Field name="amountOfIncompatibleSupport">
                    {({ field }) => (
                      <CurrencyInputFormik
                        field={field}
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        className={
                          getIn(errors, 'hasReceivedIncompatibleSupport')
                            ? 'error'
                            : undefined
                        }
                        disabled={
                          _.keys(_.pickBy(values, value => value === true))
                            .length > 0 &&
                          !values.hasReceivedIncompatibleSupport
                        }
                      />
                    )}
                  </Field>
                  &nbsp;e di non essere pertanto tenuto all’obbligo di
                  restituzione delle somme fruite&nbsp;
                </p>
                <ErrorMessage
                  name="hasReceivedIncompatibleSupport"
                  component="span"
                  className="error-all error"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className="decl--checkbox">
                <Checkbox
                  toggle
                  disabled={
                    _.keys(_.pickBy(values, value => value === true)).length >
                      0 && !values.hasReimbursedIncompatibleAmounts
                  }
                  name="hasReimbursedIncompatibleAmounts"
                  onChange={(event, data) => {
                    if (!data.checked) {
                      setFieldValue('reimbursementDate', null);
                      setFieldValue('reimbursementProcedure', '');
                      setFieldValue('reimbursedAmount', null);
                      setFieldValue('reimbursementDecreeLetter', '');
                    }
                  }}
                />
              </Grid.Column>
              <Grid.Column
                className={
                  _.keys(_.pickBy(values, value => value === true)).length >
                    0 && !values.hasReimbursedIncompatibleAmounts
                    ? 'decl--specifications disabled'
                    : 'decl--specifications'
                }
              >
                <p>
                  Dichiara di aver rimborsato in data (giorno, mese, anno)&nbsp;
                  <Field name="reimbursementDate">
                    {({ field }) => (
                      <Input
                        name="reimbursementDate"
                        error={
                          getIn(errors, 'hasReimbursedIncompatibleAmounts') &&
                          !field.value
                        }
                        className="reimbursementDate"
                      >
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            error={false}
                            helperText={null}
                            clearable
                            disableToolbar
                            format="DD/MM/YYYY"
                            autoOk
                            onChange={value =>
                              setFieldValue('reimbursementDate', value)
                            }
                            InputProps={{
                              disableUnderline: true,
                            }}
                            value={values.reimbursementDate}
                            disabled={
                              _.keys(_.pickBy(values, value => value === true))
                                .length > 0 &&
                              !values.hasReimbursedIncompatibleAmounts
                            }
                            cancelLabel="Cancella"
                            clearLabel="Reset"
                          />
                        </MuiPickersUtilsProvider>
                      </Input>
                    )}
                  </Field>
                  &nbsp;mediante&nbsp;
                  <Field name="reimbursementProcedure">
                    {({ field }) => (
                      <Input
                        name={field.name}
                        onChange={(event, data) =>
                          setFieldValue(field.name, data.value)
                        }
                        className={
                          getIn(errors, 'hasReimbursedIncompatibleAmounts') &&
                          !field.value
                            ? 'error'
                            : undefined
                        }
                        disabled={
                          _.keys(_.pickBy(values, value => value === true))
                            .length > 0 &&
                          !values.hasReimbursedIncompatibleAmounts
                        }
                      />
                    )}
                  </Field>
                  &nbsp;(indicare il mezzo utilizzato: modello F24, cartella di
                  pagamento ecc con cui si è proceduto al rimborso) la somma di
                  euro&nbsp;
                  <Field name="reimbursedAmount">
                    {({ field }) => (
                      <CurrencyInputFormik
                        field={field}
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        className={
                          getIn(errors, 'hasReimbursedIncompatibleAmounts') &&
                          !field.value
                            ? 'error'
                            : undefined
                        }
                        disabled={
                          _.keys(_.pickBy(values, value => value === true))
                            .length > 0 &&
                          !values.hasReimbursedIncompatibleAmounts
                        }
                      />
                    )}
                  </Field>
                  &nbsp;comprensiva degli interessi calcolati ai sensi del Capo
                  V del Regolamento (CE) 21.4.2004 n. 794/2004 relativa
                  all’aiuto di Stato soggetto al recupero e dichiarato
                  incompatibile con la decisione della Commissione europea
                  indicata nell’art. 4, comma 1, lettera&nbsp;
                  <Field name="reimbursementDecreeLetter">
                    {({ field }) => (
                      <Input
                        name={field.name}
                        onChange={(event, data) =>
                          setFieldValue(field.name, data.value)
                        }
                        className={
                          getIn(errors, 'hasReimbursedIncompatibleAmounts') &&
                          !field.value
                            ? 'error decree-letter'
                            : 'decree-letter'
                        }
                        maxLength="1"
                        disabled={
                          _.keys(_.pickBy(values, value => value === true))
                            .length > 0 &&
                          !values.hasReimbursedIncompatibleAmounts
                        }
                      />
                    )}
                  </Field>
                  &nbsp;(specificare a quali delle lettere a, b, c, o d)(1) del
                  d.P.C.M. adottato ai sensi dell’art. 1, comma 1223, della
                  legge 27 dicembre 2006, n. 296, pubblicato nella Gazzetta
                  Ufficiale della Repubblica Italiana, Serie generale, n. 160
                  del 12.7.2007
                </p>
                <ErrorMessage
                  name="hasReimbursedIncompatibleAmounts"
                  component="span"
                  className="error-all error"
                />
                <ErrorMessage
                  name="reimbursementDecreeLetter"
                  component="span"
                  className="error-all error"
                />
                <ErrorMessage
                  name="reimbursementDate"
                  component="span"
                  className="error-all error"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className="decl--checkbox">
                <Checkbox
                  disabled={
                    _.keys(_.pickBy(values, value => value === true)).length >
                      0 && !values.hasDepositedRefund
                  }
                  toggle
                  name="hasDepositedRefund"
                  onChange={(event, data) => {
                    if (!data.checked) {
                      setFieldValue('depositedAmount', null);
                      setFieldValue('depositDecreeLetter', '');
                    }
                  }}
                />
              </Grid.Column>
              <Grid.Column
                className={
                  _.keys(_.pickBy(values, value => value === true)).length >
                    0 && !values.hasDepositedRefund
                    ? 'decl--specifications disabled'
                    : 'decl--specifications'
                }
              >
                <p>
                  Dichiara di aver depositato nel conto di contabilità speciale
                  acceso presso la Banca d’Italia la somma di euro&nbsp;
                  <Field name="depositedAmount">
                    {({ field }) => (
                      <CurrencyInputFormik
                        field={field}
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        className={
                          getIn(errors, 'hasDepositedRefund') && !field.value
                            ? 'error'
                            : undefined
                        }
                        disabled={
                          _.keys(_.pickBy(values, value => value === true))
                            .length > 0 && !values.hasDepositedRefund
                        }
                      />
                    )}
                  </Field>
                  &nbsp;comprensiva degli interessi calcolati ai sensi del Capo
                  V del Regolamento (CE) 21.4.2004 n. 794/2004 relativa
                  all’aiuto di stato soggetto al recupero e dichiarato
                  incompatibile con la decisione della Commissione europea
                  indicata indicate nell’art. 4, comma 1, lettera&nbsp;
                  <Field name="depositDecreeLetter">
                    {({ field }) => (
                      <Input
                        name={field.name}
                        onChange={(event, data) =>
                          setFieldValue(field.name, data.value)
                        }
                        className={
                          getIn(errors, 'hasDepositedRefund') && !field.value
                            ? 'error decree-letter'
                            : 'decree-letter'
                        }
                        maxLength="1"
                        disabled={
                          _.keys(_.pickBy(values, value => value === true))
                            .length > 0 && !values.hasDepositedRefund
                        }
                        value={field.value}
                      />
                    )}
                  </Field>
                  &nbsp;(specificare a quali delle lettere a, b, c, o d) del
                  d.P.C.M. adottato ai sensi dell’art. 1, comma 1223, della L.
                  n. 296/2006&nbsp;
                </p>
                <ErrorMessage
                  name="hasDepositedRefund"
                  component="span"
                  className="error-all error"
                />
                <ErrorMessage
                  name="depositDecreeLetter"
                  component="span"
                  className="error-all error"
                />
              </Grid.Column>
            </Grid.Row>
            {getIn(errors, 'error') && (
              <Grid.Row>
                <Grid.Column>
                  <span className="error-all error">
                    Per passare allo step successivo devi selezionare una tra le
                    dichiarazioni di cui sopra.
                    <br />
                    Se nessuna delle voci elencate corrisponde alla tua
                    situazione, clicca sul pulsante qui sotto per tornare alla
                    scelta del regime da applicare.
                  </span>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column>
                <Button
                  type="button"
                  icon
                  onClick={() =>
                    updateRequest(
                      {
                        id: state.request?.id,
                        state: States.VerificaSceltaDocumento + '_Back',
                      },
                      dispatch,
                    )
                  }
                  className="button--underline"
                >
                  <Icon name="arrow left" />
                  <FormattedMessage
                    id="profile.goBackToList"
                    defaultMessage="Torna alla scelta dei regimi"
                  />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <FormikWatcher
            values={values}
            errors={errors}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};
