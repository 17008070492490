import React, { useMemo, useState } from 'react';
import { Form, Input, Select, Checkbox, Field } from 'formik-semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage, FieldArray, Formik, FormikHelpers, getIn } from 'formik';
import { Grid, Header, Container, Button } from 'semantic-ui-react';
import _, { forEach } from 'lodash';
import * as Yup from 'yup';
import { Tooltip } from 'components/Tooltip';
import { Values, FormikWatcherProps } from 'components/FormikWatcher/types';
import moment from 'moment';

// Constants, Utils
import {
  balanceSheetTypesOptions,
  incomeStatementTypesOptions,
} from './balanceTypes';
import { CompanyTypeConstants } from 'components/Forms/WebuserProfileForm/types';
import { Balance } from './types';

// Services
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { BalanceFormValues } from './types';
import { updateRequest } from 'services/request';
import { States } from 'services/stateMachine/types';
import { CurrencyInputFormik } from 'components/CurrencyInputFormik';
import { FormikWatcher } from 'components/FormikWatcher';

export const StepBilancio: React.FC = () => {
  const { state, dispatch } = useStateMachine();

  const [leastThreeDeposited, setLeastThreeDeposited] = useState<boolean>(true);
  const [connectedToConflict, setConnectedToConflict] =
    useState<boolean>(false);
  const [balanceOrIncome, setBalanceOrIncome] = useState<string>();
  const [leastThreeDisabled, setLeastThreeDisabled] = useState<boolean>(false);

  /**
   * Set the initial values from the request state.
   * It helps when I'm going to travel across the steps.
   */
  const initialValues = useMemo<BalanceFormValues>(() => {
    state.request?.companyType === CompanyTypeConstants.LIMITEDCOMPANY ||
    state.request?.companyType === CompanyTypeConstants.PROFESSIONALFIRM
      ? setBalanceOrIncome('balance')
      : setBalanceOrIncome('income');

    let leastThreeDepositedLocal = true;

    if (state.request && !_.isNil(state.request?.connectedToConflict))
      setConnectedToConflict(state.request.connectedToConflict);
    const establishmentDate = moment(state.request?.establishmentDate);
    establishmentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const lastTwelveMonths = moment().subtract(1, 'year');
    lastTwelveMonths.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    if (establishmentDate.isAfter(lastTwelveMonths)) {
      setLeastThreeDeposited(false);
      leastThreeDepositedLocal = false;
    }
    setLeastThreeDisabled(true);

    let orderedBalances = state.request?.balances;
    if (orderedBalances)
      orderedBalances = _.orderBy(orderedBalances, ['year'], ['desc']);

    const arrayBalance: Array<Balance> = [];

    if (state.request?.balances && state.request.balances.length > 0) {
      [...Array(state.request.balances.length)].map((n, i) => {
        let balance: Balance | undefined = undefined;
        if (orderedBalances && orderedBalances[i]) balance = orderedBalances[i];
        if (balance) {
          arrayBalance.push({
            id: balance.id,
            revenueAmount: _.isNumber(balance.revenueAmount)
              ? balance.revenueAmount
              : null,
            staffCost: balance.staffCost || null,
            type: balance.type || null,
            year: balance.year || '',
            numberOfEmployee: balance.numberOfEmployee,
            totalActiveAmount: balance.totalActiveAmount,
          });
        }
      });
    }

    if (arrayBalance.length === 0) {
      arrayBalance.push({
        id: null,
        revenueAmount: null,
        staffCost: null,
        type: null,
        year: null,
        numberOfEmployee: null,
        totalActiveAmount: null,
      });
    }

    return {
      leastThreeDeposited: leastThreeDepositedLocal,
      revenueSinceEstablishment:
        state.request && _.isNumber(state.request?.revenueSinceEstablishment)
          ? state.request.revenueSinceEstablishment
          : null,
      connectedToConflict:
        state.request && !_.isNil(state.request?.connectedToConflict)
          ? state.request?.connectedToConflict
          : connectedToConflict,
      lastYearEnergyCosts: state.request?.lastYearEnergyCosts ?? null,
      balances: arrayBalance,
    };
  }, [state.request?.balances]);

  const handleOnChange = (
    values: Values,
    args: Partial<FormikWatcherProps>,
  ) => {
    if (!values.leastThreeDeposited) {
      const filteredBalances = values.balances.filter(balance => {
        if (_.isNumber(balance.revenueAmount) || balance.type || balance.year) {
          return balance;
        }
      });
      if (filteredBalances.length > 0) {
        if (args.setFieldValue) {
          args.setFieldValue('balances', [
            {
              id: null,
              revenueAmount: null,
              staffCost: null,
              type: null,
              year: null,
              numberOfEmployee: null,
              totalActiveAmount: null,
            },
          ]);
        }
      }
    }
    if (!values.connectedToConflict) {
      if (args.setFieldTouched)
        args.setFieldTouched('lastYearEnergyCosts', false);
      if (args.setFieldValue) args.setFieldValue('lastYearEnergyCosts', null);
    }
    if (values.leastThreeDeposited) {
      if (args.setFieldTouched)
        args.setFieldTouched('revenueSinceEstablishment', false);
      if (args.setFieldValue)
        args.setFieldValue('revenueSinceEstablishment', null);
    }
  };

  const onSubmit = (
    values: BalanceFormValues,
    formikHelpers: FormikHelpers<BalanceFormValues>,
  ) => {
    const balances = values.balances.filter(balance => {
      if (!_.isEmpty(balance.revenueAmount) || !_.isEmpty(balance.type)) {
        return balance;
      }
    });

    updateRequest(
      {
        balances: balances,
        leastThreeDeposited: leastThreeDeposited,
        revenueSinceEstablishment: values.revenueSinceEstablishment,
        connectedToConflict: connectedToConflict,
        lastYearEnergyCosts: values.lastYearEnergyCosts,
        id: state.request?.id,
        state: States.BilancioCalcoloDimensionaleAzienda,
      },
      dispatch,
      formikHelpers,
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        balances: Yup.array().of(
          Yup.object().shape(
            {
              revenueAmount: Yup.mixed()
                .label('Ricavi')
                .when(['year', 'type'], {
                  is: (year: string, type: string) => {
                    if (!year && _.isEmpty(type)) {
                      return false;
                    }
                    return true;
                  },
                  then: Yup.mixed()
                    .required()
                    .test(
                      'Is positive?',
                      'La quantità deve essere maggiore o uguale a zero',
                      value => {
                        if (value) {
                          if (value >= 0) return true;
                          else return false;
                        }
                        return true;
                      },
                    )
                    .nullable(),
                  otherwise: Yup.mixed().notRequired().nullable(),
                }),
              type: Yup.mixed().label('Tipo'),
              // .when(['revenueAmount', 'year'], {
              //   is: (revenueAmount: string, year: string) => {
              //     if (!year && !revenueAmount) {
              //       return false;
              //     }
              //     return true;
              //   },
              //   then: Yup.mixed().required().nullable(),
              //   otherwise: Yup.mixed().notRequired().nullable(),
              //}),
              year: Yup.string()
                .nullable()
                .label('Anno')
                .min(4, "L'anno deve essere lungo 4 caratteri")
                .max(4, "L'anno deve essere lungo 4 caratteri")
                .when(['revenueAmount', 'type'], {
                  is: (revenueAmount: string, type: string) => {
                    if (_.isEmpty(type) && !revenueAmount) {
                      return false;
                    }
                    return true;
                  },
                  then: Yup.string().required().nullable(),
                  otherwise: Yup.string().notRequired().nullable(),
                }),
            },
            [
              ['year', 'type'],
              ['revenueAmount', 'year'],
              ['revenueAmount', 'type'],
            ],
          ),
        ),
        leastThreeDeposited: Yup.boolean().notRequired(),
        connectedToConflict: Yup.boolean().notRequired(),
        lastYearEnergyCosts: Yup.number()
          .label('Costi energetici degli ultimi 12 mesi')
          .when('connectedToConflict', {
            is: (match: boolean) => match === true,
            then: Yup.number().required().nullable(),
            otherwise: Yup.number().notRequired().nullable(),
          }),
        revenueSinceEstablishment: Yup.number()
          .label('Ricavi dalla data di costituzione')
          .when('leastThreeDeposited', {
            is: (match: boolean) => match === false,
            then: Yup.number()
              .required()
              .test(
                'Is positive?',
                'La quantità deve essere maggiore o uguale a zero',
                value => {
                  if (value) {
                    if (value >= 0) return true;
                    else return false;
                  }
                  return true;
                },
              )
              .nullable(),
            otherwise: Yup.number().notRequired().nullable(),
          }),
      })}
      onSubmit={(values, formikHelpers) => {
        let isValid = true;
        const payloadBalances = values.balances.filter(balance => {
          if (
            _.isNumber(balance.revenueAmount) &&
            balance.type &&
            balance.year
          ) {
            return balance;
          }
        });
        if (leastThreeDeposited) {
          let prevBalanceYear = '';
          forEach(values.balances, (balance, index) => {
            if (index > 0 && balance.year) {
              if (balance.year >= prevBalanceYear) {
                formikHelpers.setFieldError(
                  `balances[${index}].year`,
                  `Inserire un anno antecedente a ${prevBalanceYear}`,
                );
                isValid = false;
              }
            }
            if (balance.year) prevBalanceYear = balance.year;
            if (balance.revenueAmount || balance.year) {
              if (!balance.type) {
                formikHelpers.setFieldError(
                  `balances[${index}].type`,
                  'Tipo è richiesto',
                );
                isValid = false;
              }
            }
          });
          if (payloadBalances.length === 0) {
            formikHelpers.setFieldError(
              'leastThreeDeposited',
              'Inserire i dati di almeno un bilancio depositato',
            );
            isValid = false;
          }
        }
        if (isValid) onSubmit(values, formikHelpers);
        else formikHelpers.setSubmitting(false);
      }}
    >
      {({
        values,
        setFieldValue,
        handleBlur,
        errors,
        setFieldTouched,
        isSubmitting,
      }) => (
        <Form className="form__Bilancio" id={`form__${state.current}`}>
          <div className="balance__header">
            <h1 className="balance__header__title">
              <FormattedMessage
                id="balance.compileDataOf"
                defaultMessage="Compila i dati di "
              />
              <span className="title__bold">
                <FormattedMessage id="balance" defaultMessage="bilancio" />
              </span>
            </h1>
            <h4 className="balance__header__subtitle">
              <FormattedMessage
                id="balance.balanceSubtitle"
                defaultMessage="Inserisci le informazioni relative ai bilanci più recenti"
              />
            </h4>
          </div>
          <Grid.Row className="checkbox">
            <Grid.Column>
              <Checkbox
                toggle
                name="connectedToConflict"
                label={{
                  children: (
                    <FormattedMessage
                      id="request.connectedToConflict"
                      defaultMessage="La richiesta di agevolazione è collegata alle esigenze di liquidità connesse al grave turbamento dell’economia causato dall’aggressione della Russia contro l’Ucraina?"
                    />
                  ),
                }}
                onChange={(event, data) => {
                  setConnectedToConflict(data.checked ?? false);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          {connectedToConflict && (
            <Grid.Row className="single-input">
              <Grid.Column>
                <Field name="lastYearEnergyCosts">
                  {({ field }) => (
                    <>
                      <CurrencyInputFormik
                        field={field}
                        label={
                          <>
                            <FormattedMessage
                              id="balance.lastYearEnergyCosts"
                              defaultMessage="Costi sostenuti per l’energia nei dodici mesi precedenti alla sottoscrizione della presente richiesta"
                            />
                            <Tooltip
                              header={
                                <Header as="h2">
                                  Costi energetici sostenuti nei dodici mesi
                                  precedenti
                                </Header>
                              }
                              content={
                                <Container>
                                  <p>
                                    Sono da considerare le spese per l’acquisito
                                    di energia elettrica, gas, carburanti, ecc.
                                  </p>
                                </Container>
                              }
                            />
                          </>
                        }
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                      />
                    </>
                  )}
                </Field>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row className="checkbox">
            <Grid.Column>
              <Checkbox
                toggle
                name="leastThreeDeposited"
                label={{
                  children: (
                    <FormattedMessage
                      id="request.leastThreeDeposited"
                      defaultMessage="È presente almeno un bilancio depositato?"
                    />
                  ),
                }}
                checked={leastThreeDeposited}
                onChange={(event, data) => {
                  setLeastThreeDeposited(data.checked ?? false);
                }}
                disabled={leastThreeDisabled}
              />
            </Grid.Column>
          </Grid.Row>
          {leastThreeDeposited ? (
            <>
              {getIn(errors, 'leastThreeDeposited') && (
                <div className="balance-required">
                  <span className="error error-all">
                    {getIn(errors, 'leastThreeDeposited')}
                  </span>
                </div>
              )}
              <FieldArray
                name="balances"
                render={() =>
                  values.balances.map((balance, index) => (
                    <div className="balance__content" key={`balance__${index}`}>
                      <Grid.Row className="checkbox">
                        <Grid.Column>
                          <h3>
                            {`Inserire i dati ${
                              index !== 0
                                ? index === 1
                                  ? 'del penultimo'
                                  : 'del terzultimo'
                                : "dell'ultimo"
                            } bilancio depositato`}
                          </h3>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Field name={`balances.${index}.year`}>
                              {({ field }) => (
                                <>
                                  <Input
                                    label={
                                      <FormattedMessage
                                        id="balance.year"
                                        defaultMessage="Anno di riferimento bilancio"
                                      />
                                    }
                                    className="balance__content__label"
                                    name={`balances.${index}.year`}
                                    onChange={(event, data) => {
                                      setFieldValue(field.name, data.value);
                                    }}
                                  />
                                  <Tooltip
                                    header={
                                      <Header as="h2">
                                        {`${
                                          index !== 0
                                            ? index === 1
                                              ? 'Penultimo'
                                              : 'Terzultimo'
                                            : 'Ultimo'
                                        } bilancio depositato`}
                                      </Header>
                                    }
                                    content={
                                      <Container>
                                        <p>
                                          {`Inserisci o conferma le informazioni
                                          legate al${
                                            index !== 0
                                              ? index === 1
                                                ? ' penultimo'
                                                : ' terzultimo'
                                              : "l'ultimo"
                                          } esercizio contabile se il rispettivo bilancio è
                                          stato approvato e/o depositato, in
                                          caso di società di capitali, o la
                                          dichiarazione dei redditi trasmessa
                                          all\'Agenzia delle Entrate, in
                                          caso di società di persone, libero
                                          professionista o studio associato.`}
                                        </p>
                                      </Container>
                                    }
                                  />
                                  <ErrorMessage
                                    name={`balances.${index}.year`}
                                    component="span"
                                    className={
                                      getIn(errors, `balances[${index}].year`)
                                        ? 'error'
                                        : undefined
                                    }
                                  />
                                </>
                              )}
                            </Field>
                          </Grid.Column>
                          <Grid.Column>
                            <Field name={`balances[${index}].revenueAmount`}>
                              {({ field }) => (
                                <CurrencyInputFormik
                                  field={field}
                                  label={
                                    <FormattedMessage
                                      id="balance.revenueAmount"
                                      defaultMessage="Ricavi"
                                    />
                                  }
                                  setFieldValue={setFieldValue}
                                  onBlur={handleBlur}
                                />
                              )}
                            </Field>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                          <Grid.Column>
                            <Select
                              label={
                                <FormattedMessage
                                  id="balance.type"
                                  defaultMessage="Tipo"
                                />
                              }
                              name={`balances.${index}.type`}
                              options={
                                balanceOrIncome === 'balance'
                                  ? balanceSheetTypesOptions
                                  : incomeStatementTypesOptions
                              }
                              clearable
                              error={
                                getIn(errors, `balances.${index}.type`)
                                  ? true
                                  : false
                              }
                            />
                            <ErrorMessage
                              name={`balances.${index}.type`}
                              component="span"
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                  ))
                }
              />
              <Grid.Row className="modify--balances">
                <Grid.Column>
                  {values.balances.length > 1 && (
                    <Button
                      onClick={event => {
                        event.preventDefault();
                        const balancesMinusOne = _.clone(values.balances);
                        balancesMinusOne.pop();
                        setFieldValue('balances', balancesMinusOne);
                      }}
                      className="button--primary"
                    >
                      Rimuovi un bilancio
                    </Button>
                  )}
                  {values.balances.length < 3 && (
                    <Button
                      onClick={event => {
                        event.preventDefault();
                        const balancesPlusOne = _.clone(values.balances);
                        balancesPlusOne.push({
                          id: null,
                          revenueAmount: null,
                          staffCost: null,
                          type: null,
                          year: null,
                          numberOfEmployee: null,
                          totalActiveAmount: null,
                        });
                        setFieldValue('balances', balancesPlusOne);
                      }}
                      className="button--primary"
                    >
                      Aggiungi un bilancio
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
            </>
          ) : (
            <Grid.Row className="single-input">
              <Grid.Column>
                <Field name="revenueSinceEstablishment">
                  {({ field }) => (
                    <CurrencyInputFormik
                      field={field}
                      label={
                        <FormattedMessage
                          id="balance.revenueSinceEstablishment"
                          defaultMessage="Somma dei ricavi dalla data di costituzione ad oggi"
                        />
                      }
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                    />
                  )}
                </Field>
              </Grid.Column>
            </Grid.Row>
          )}
          <FormikWatcher
            values={values}
            callback={handleOnChange}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            errors={errors}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};
