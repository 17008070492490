import { CustomerType } from 'pages/Customer/reducer/actions';

export const customerTypeOptions = (
  includeAllCustomer = true,
): Array<{
  text: string;
  value: string;
}> => [
  ...(includeAllCustomer
    ? [
        {
          text: 'Tutti i clienti',
          value: CustomerType.CUSTOMER,
        },
      ]
    : []),
  {
    text: 'Banche',
    value: CustomerType.BANK,
  },
  {
    text: 'Clienti banche',
    value: CustomerType.BANKCLIENT,
  },
  {
    text: 'Commercialisti',
    value: CustomerType.ACCOUNTANT,
  },
  {
    text: 'Clienti commercialisti',
    value: CustomerType.ACCOUNTANTCLIENT,
  },
  {
    text: 'Clienti dal web',
    value: CustomerType.WEBUSER,
  },
];
