import React, { createContext, useEffect, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Grid, Header, Button } from 'semantic-ui-react';
import { agentReducer, AgentState, initialAgentState } from './reducer/reducer';
import { AgentAction, AgentActionConstants } from './reducer/actions';
import DataGrid from 'components/DataGrid';
import { getAgentList, patchAgent } from 'services/agent';
import AgentFilterForm from 'components/Forms/AgentFilterForm';
import { AgentFilterFormValues } from 'components/Forms/AgentFilterForm/types';
import {
  DataGridAction,
  DataGridActionConstants,
} from 'components/DataGrid/reducer/actions';
import {
  dataGridReducer,
  DataGridState,
  initialDataGridState,
} from 'components/DataGrid/reducer/reducer';
import { Link } from 'react-router-dom';
import { AgentFormatterParams } from 'components/DataGrid/types';

export const AgentContext = createContext<{
  state: AgentState;
  dispatch: React.Dispatch<AgentAction>;
}>({
  state: initialAgentState,
  dispatch: () => null,
});

export const DataGridContext = createContext<{
  state: DataGridState;
  dispatch: React.Dispatch<DataGridAction>;
}>({
  state: initialDataGridState,
  dispatch: () => null,
});

const Agent: React.FC = () => {
  const [state, dispatch] = useReducer(agentReducer, initialAgentState);
  const [dataGridState, dataGridDispatch] = useReducer(
    dataGridReducer,
    initialDataGridState,
  );
  /*eslint-disable-next-line @typescript-eslint/no-unused-vars*/
  const [filters, setFilters] = useState<AgentFilterFormValues>({
    agentType: null,
    searchText: '',
  });

  useEffect(() => {
    getAgentList(
      filters,
      dataGridState.paginationData,
      dataGridDispatch,
      dispatch,
    );
  }, [dataGridState.paginationData.page]);

  const deleteAgent = id => {
    patchAgent({ isDeleted: true }, id, dispatch);
  };

  useEffect(() => {
    if (state.reloadingAgents) {
      getAgentList(
        filters,
        dataGridState.paginationData,
        dataGridDispatch,
        dispatch,
      );
      dispatch({
        type: AgentActionConstants.RELOAD_AGENT_LIST,
        payload: { reloadAgents: false },
      });
    }
  }, [state.reloadingAgents]);

  /**
   * Manages page selection change.
   * @param data
   */
  const handleSelectPage = (data: string | number) => {
    dataGridDispatch({
      type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
      payload: {
        pageNumber: data,
      },
    });
  };

  return (
    <>
      <div className="agent-container">
        <Helmet>
          <body className="agent agent-list" />
          <title>Allegato4.it - Elenco agenti</title>
          <script>{"smartsupp('chat:hide')"}</script>
          <meta name="description" content="agent list page" />
        </Helmet>
        <div className="agent__content">
          <Header className="agentHeader" dividing>
            <Grid>
              <Grid.Row>
                <Grid.Column width={3}>
                  <h1>
                    <FormattedMessage
                      id="agent.title"
                      defaultMessage="Elenco agenti"
                    />
                  </h1>
                </Grid.Column>
                <Grid.Column>
                  <AgentFilterForm dispatch={dispatch} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
          <div className="agentSectionBody">
            <DataGrid
              isLoading={dataGridState.isLoading}
              elements={state.agentList}
              columns={[
                {
                  key: 'name',
                  name: `Agente`,
                  formatter: ({ data }: AgentFormatterParams) =>
                    `${data.name} ${data.surname} (${data.email})`,
                },
                {
                  key: 'action',
                  name: 'Azioni',
                  formatter: ({ data }: AgentFormatterParams) => {
                    const agentUrl = `/agent/${data.id}`;
                    return (
                      <div className="ctas">
                        {/* <Link to={agentUrl}>
                          <Button circular small icon={'eye'} />
                        </Link> */}
                        <Link to={agentUrl}>
                          <Button circular small icon={'edit'} />
                        </Link>
                        <Button
                          circular
                          small
                          icon={'delete'}
                          onClick={() => deleteAgent(data.id)}
                        />
                      </div>
                    );
                  },
                },
              ]}
              totalItems={state.agentList.length}
              page={dataGridState.paginationData.page}
              onPageSelect={handleSelectPage}
              paginate
              pageSize={dataGridState.paginationData.page_size}
              pageCount={dataGridState.paginationData.page_count}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Agent;
