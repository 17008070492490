import React, { useEffect, useState } from 'react';
import { Field, Form, Input } from 'formik-semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import { Checkbox, Grid, Header, Select } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import _ from 'lodash';

// Types
import { CustomerPageActionConstants } from 'pages/Customer/reducer/actions';
import { CustomerProfileFormProps } from '../WebuserProfileForm/types';
import { loadPlans } from 'services/plan';

const AccountantProfileForm: React.FC<CustomerProfileFormProps> = ({
  onSubmit,
  initialValues,
  mode,
}) => {
  /**
   * The react-intl object.
   */
  const intl = useIntl();

  /**
   * The list of the available plans.
   */
  const [subscriptionTypes, setSubscriptionTypes] = useState<
    { value: string; text: string }[]
  >([]);

  /**
   * Fetch all the plans and map them as valid options.
   */
  const loadPlansOptions = () => {
    loadPlans({ subscribable: true }).then(res => {
      const plans = res.data._embedded.plan;
      // Fill the react-select component with the subscription types options.
      setSubscriptionTypes(
        plans.map(
          c =>
            c && {
              value: c.id,
              text: c.description,
            },
        ),
      );
    });
  };

  /**
   * This effect is called at the first rendering.
   */
  useEffect(() => {
    loadPlansOptions();
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
        validationSchema={Yup.object().shape({
          businessName: Yup.string()
            .label(
              intl.formatMessage({
                id: 'profile.businessName',
                defaultMessage: 'Ragione sociale',
              }),
            )
            .required()
            .nullable(),
          email: Yup.string()
            .label(
              intl.formatMessage({
                id: 'profile.email',
                defaultMessage: 'Email',
              }),
            )
            .required()
            .email()
            .nullable(),
          fiscalCode: Yup.string()
            .label(
              intl.formatMessage({
                id: 'profile.fiscalCode',
                defaultMessage: 'Codice fiscale',
              }),
            )
            .required()
            .nullable(),
          vatNumber: Yup.string()
            .label(
              intl.formatMessage({
                id: 'profile.vatNumber',
                defaultMessage: 'P.IVA',
              }),
            )
            .required()
            .nullable(),
          subscriptionType: Yup.string()
            .label(
              intl.formatMessage({
                id: 'profile.subscriptionType',
                defaultMessage: 'Tipo abbonamento',
              }),
            )
            .required()
            .nullable(),
          // subscriptionActive: Yup.string()
          //   .label(
          //     intl.formatMessage({
          //       id: 'profile.subscriptionActive',
          //       defaultMessage: 'Abbonamento attivo',
          //     }),
          //   )
          //   .required()
          //   .nullable(),
          // consultingEnabled: Yup.string()
          //   .label(
          //     intl.formatMessage({
          //       id: 'profile.consultingEnabled',
          //       defaultMessage: 'Consulenza',
          //     }),
          //   )
          //   .required()
          //   .nullable(),
          // feasibilityCheckEnabled: Yup.string()
          //   .label(
          //     intl.formatMessage({
          //       id: 'profile.feasibilityCheckEnabled',
          //       defaultMessage: 'Verifica di fattibilità',
          //     }),
          //   )
          //   .required()
          //   .nullable(),
          maxRequestsAmount: Yup.number()
            .typeError('Numero massimo richieste deve essere un numero')
            .label(
              intl.formatMessage({
                id: 'profile.maxRequestsAmount',
                defaultMessage: 'Numero massimo richieste',
              }),
            )
            .required()
            .nullable()
            .test(
              'Is positive?',
              'La quantità deve essere maggiore o uguale a zero',
              value => {
                if (value) {
                  if (value >= 0) return true;
                  else return false;
                }
                return true;
              },
            ),
          remainigRequests: Yup.number()
            .typeError('Numero richieste rimanenti deve essere un numero')
            .label(
              intl.formatMessage({
                id: 'profile.remainingRequests',
                defaultMessage: 'Numero richieste rimanenti',
              }),
            )
            .notRequired()
            .nullable(),
        })}
      >
        {({ setFieldValue, values }) => (
          <Form id="customerProfileForm">
            <Grid padded>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3">{values.businessName}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input
                    name="businessName"
                    placeholder="Ragione sociale"
                    readOnly={mode === CustomerPageActionConstants.VIEW}
                    label={
                      <label>
                        <FormattedMessage
                          id="profile.businessName"
                          defaultMessage="Ragione sociale"
                        />
                        *
                      </label>
                    }
                  />
                  <ErrorMessage name="businessName" component="span" />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    name="vatNumber"
                    placeholder="P.IVA"
                    readOnly={mode === CustomerPageActionConstants.VIEW}
                    label={
                      <label>
                        <FormattedMessage
                          id="profile.vatNumber"
                          defaultMessage="P.IVA"
                        />
                        *
                      </label>
                    }
                  />
                  <ErrorMessage name="vatNumber" component="span" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input
                    name="fiscalCode"
                    placeholder="Codice fiscale"
                    readOnly={mode === CustomerPageActionConstants.VIEW}
                    label={
                      <label>
                        <FormattedMessage
                          id="profile.fiscalCode"
                          defaultMessage="Codice fiscale"
                        />
                        *
                      </label>
                    }
                  />
                  <ErrorMessage name="fiscalCode" component="span" />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    name="email"
                    placeholder="Mail di contatto"
                    readOnly={mode === CustomerPageActionConstants.VIEW}
                    label={
                      <label>
                        <FormattedMessage
                          id="profile.email"
                          defaultMessage="Mail di contatto"
                        />
                        *
                      </label>
                    }
                  />
                  <ErrorMessage name="email" component="span" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input
                    name="maxRequestsAmount"
                    placeholder="Numero massimo richieste"
                    readOnly={mode === CustomerPageActionConstants.VIEW}
                    label={
                      <label>
                        <FormattedMessage
                          id="profile.maxRequestsAmount"
                          defaultMessage="Numero massimo richieste"
                        />
                        *
                      </label>
                    }
                    onChange={(event, data) => {
                      if (!_.isNull(initialValues.maxRequestsAmount)) {
                        const difference =
                          data.value - initialValues.maxRequestsAmount;
                        if (!_.isNull(initialValues.remainingRequests)) {
                          if (
                            initialValues.remainingRequests + difference >=
                            0
                          ) {
                            setFieldValue(
                              'remainingRequests',
                              initialValues.remainingRequests + difference,
                            );
                          } else {
                            setFieldValue('remainingRequests', 0);
                          }
                        }
                      }
                    }}
                  />
                  <ErrorMessage name="maxRequestsAmount" component="span" />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    name="remainingRequests"
                    placeholder="Numero richieste rimanenti"
                    readOnly
                    label={
                      <label>
                        <FormattedMessage
                          id="profile.remainingRequests"
                          defaultMessage="Numero richieste rimanenti"
                        />
                      </label>
                    }
                  />
                  <ErrorMessage name="remainingRequests" component="span" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Field name="subscriptionType">
                    {({ field }) => (
                      <>
                        <label>
                          <FormattedMessage
                            id="profile.subscriptionType"
                            defaultMessage="Tipo abbonamento"
                          />
                          *
                        </label>
                        <br />
                        <Select
                          disabled={mode === CustomerPageActionConstants.VIEW}
                          options={subscriptionTypes}
                          onChange={(_event, data) =>
                            setFieldValue(field.name, data.value)
                          }
                          value={values.subscriptionType}
                        />
                      </>
                    )}
                  </Field>
                  <ErrorMessage name="subscriptionType" component="span" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  {/* <Grid>
                    <Grid.Row columns={1}>
                      <label>
                        <FormattedMessage
                          id="profile.additionalServices"
                          defaultMessage="Servizi aggiuntivi"
                        />
                      </label>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Field name="consultingEnabled">
                          {({ field }) => (
                            <>
                              <Checkbox
                                readOnly={
                                  mode === CustomerPageActionConstants.VIEW
                                }
                                label={{
                                  children: (
                                    <FormattedMessage
                                      id="profile.consultingEnabled"
                                      defaultMessage="Consulenza"
                                    />
                                  ),
                                }}
                                onChange={(_event, data) =>
                                  setFieldValue(field.name, data.checked)
                                }
                                checked={values.consultingEnabled}
                              />
                            </>
                          )}
                        </Field>
                        <ErrorMessage
                          name="consultingEnabled"
                          component="span"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field name="feasibilityCheckEnabled">
                          {({ field }) => (
                            <>
                              <Checkbox
                                readOnly={
                                  mode === CustomerPageActionConstants.VIEW
                                }
                                label={{
                                  children: (
                                    <FormattedMessage
                                      id="profile.feasibilityCheck"
                                      defaultMessage="Verifica di fattibilità"
                                    />
                                  ),
                                }}
                                onChange={(_event, data) =>
                                  setFieldValue(field.name, data.checked)
                                }
                                checked={values.feasibilityCheckEnabled}
                              />
                            </>
                          )}
                        </Field>
                        <ErrorMessage
                          name="feasibilityCheckEnabled"
                          component="span"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid> */}
                </Grid.Column>
                <Grid.Column>
                  <Field name="subscriptionActive">
                    {({ field }) => (
                      <>
                        <Checkbox
                          toggle
                          readOnly={mode === CustomerPageActionConstants.VIEW}
                          label={{
                            children: (
                              <FormattedMessage
                                id="profile.subscriptionActive"
                                defaultMessage="Abbonamento attivo"
                              />
                            ),
                          }}
                          onChange={(_event, data) =>
                            setFieldValue(field.name, data.checked)
                          }
                          checked={values.subscriptionActive}
                        />
                      </>
                    )}
                  </Field>
                  <ErrorMessage name="subscriptionActive" component="span" />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AccountantProfileForm;
