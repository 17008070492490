import React, { useContext, useState } from 'react';
import { Grid, Button, Icon, Modal } from 'semantic-ui-react';
import { Form, Input, SubmitButton, Select } from 'formik-semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { customerTypeOptions } from './const';
import * as Yup from 'yup';

// Types
import {
  CustomerFilterFormProps,
  CustomerFilterFormValues,
  CustomerTypeModalProps,
} from './types';
import { CustomerContext } from 'pages/Customer';
import { useNavigate } from 'react-router-dom';
import {
  CustomerPageActionConstants,
  CustomerType,
} from 'pages/Customer/reducer/actions';
import { FE_ROUTES, ROLES } from 'utils/global/globalCostants';
import { isThatRole } from 'utils/function/acl';
import { AppContext } from 'pages/App';
import { toast } from 'react-toastify';
import CustomerTypeSelectionForm from '../CustomerTypeSelectionForm';
import { CustomerTypeSelectionValues } from '../CustomerTypeSelectionForm/types';

const CustomerFilterForm: React.FC<CustomerFilterFormProps> = ({
  onSubmit,
}) => {
  /**
   * The hook to fetch the customer context.
   */
  const { state } = useContext(CustomerContext);

  /**
   * The hook to fetch the app context.
   */
  const context = useContext(AppContext);

  /**
   * The hook to navigate thorught the application.
   */
  const navigate = useNavigate();

  /**
   * The modal properties.
   * It stores the current legal representative index (optionally, if the modal is open and it's linked to a valid legal repr.)
   * and the open/close flag.
   */
  const [modal, setModal] = useState<CustomerTypeModalProps>({
    open: false,
  });

  /**
   * The initial values.
   */
  const initialFormValues: CustomerFilterFormValues = {
    searchText: '',
    customerType: null,
  };

  /**
   * Method used to manage the new action for the Customer.
   * It contains the logic to force the customer type or to display a modal for the selection.
   */
  const onCreateCustomer = () => {
    /** The creation of a customer depends on the current user role:
     * - BANK can only create BANKCLIENT
     * - ACCOUNTANT can only create ACCOUNTANTCLIENT
     * - ADMIN can select and create any customer type
     * - others cannot create customers
     */
    let newCustomerType: CustomerType;

    if (isThatRole(ROLES.BANK, context.state)) {
      newCustomerType = CustomerType.BANKCLIENT;
    } else if (isThatRole(ROLES.ACCOUNTANT, context.state)) {
      newCustomerType = CustomerType.ACCOUNTANTCLIENT;
    } else if (isThatRole(ROLES.ADMIN, context.state)) {
      setModal({ open: true });
      return;
    } else {
      toast.error('Impossibile creare un nuovo cliente');
      return;
    }

    /** Navigate to the creation page */
    navigateToCustomerCreate(newCustomerType);
  };

  /**
   * Method used to manage the selection of a customer type from the modal.
   * @param values
   */
  const onSelectCustomerType = (values: CustomerTypeSelectionValues) => {
    navigateToCustomerCreate(values.customerType);
  };

  /**
   * Method used to manage the navigation to the new customer form, given the specified type.
   * @param customerType
   */
  const navigateToCustomerCreate = customerType => {
    /** Navigate to the creation page */
    navigate(
      `${FE_ROUTES.CUSTOMER}/${CustomerPageActionConstants.NEW}/${customerType}`,
    );
  };

  return (
    <>
      {/* Modal for customer type selection */}
      <Modal
        closeIcon
        open={modal.open}
        onClose={() => setModal({ open: false })}
      >
        <Modal.Content>
          {modal.open && (
            <CustomerTypeSelectionForm
              onSubmit={values => onSelectCustomerType(values)}
            />
          )}
        </Modal.Content>
      </Modal>
      {/* Customer filter form */}
      <Formik
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          searchText: Yup.string().nullable(),
          customerType: Yup.object().nullable(),
        })}
      >
        {({ resetForm }) => (
          <Form>
            <Grid padded>
              <Grid.Row width={12}>
                <Grid.Column width={4}>
                  <Input name="searchText" placeholder="Cerca" />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Select name="customerType" options={customerTypeOptions()} />
                </Grid.Column>
                <Grid.Column width={8} className="customer__buttons">
                  <Button
                    type="button"
                    className="button--primary"
                    onClick={onCreateCustomer}
                  >
                    <div>
                      <FormattedMessage
                        id="add.new.customer"
                        defaultMessage="Aggiungi nuovo cliente"
                      />
                      <Icon name="plus" />
                    </div>
                  </Button>
                  <SubmitButton
                    className="button--primary--negative"
                    content={
                      <FormattedMessage
                        id="customerfilter.apply"
                        defaultMessage="Applica"
                      />
                    }
                    loading={state.isLoading}
                  />
                  <Button
                    content="Reset"
                    type="button"
                    className="button--underline"
                    onClick={() => resetForm()}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CustomerFilterForm;
