import { RequestPollingAction } from 'components/RequestPolling/reducer/actions';
import {
  initialRequestPollingState,
  requestPollingReducer,
  RequestPollingState,
} from 'components/RequestPolling/reducer/reducer';
import { Formik, FormikHelpers } from 'formik';
import { Form } from 'formik-semantic-ui-react';
import React, {
  createContext,
  useEffect,
  useReducer,
  useState,
  useLayoutEffect,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Grid, Header } from 'semantic-ui-react';
import {
  getRequestUntil,
  updateRequest,
  forceRequestToNextStep,
} from 'services/request';
import { Condition } from 'services/request/types';
import { StateMachineActionType, States } from 'services/stateMachine/types';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { StepClienteRichiestaPendingValues } from './types';
import { Loader } from '../../Loader';

export const RequestPollingContext = createContext<{
  state: RequestPollingState;
  dispatch: React.Dispatch<RequestPollingAction>;
}>({
  state: initialRequestPollingState,
  dispatch: () => null,
});

/**
 * The step shows up when the server is waiting for the CodicePlastico response about the customer data.
 * TODO: Implement the web socket stuff and subscribe to the server changes.
 * @returns
 */
export const StepClienteRichiestaPending: React.FC = () => {
  const { state, dispatch } = useStateMachine();

  const initialValues: StepClienteRichiestaPendingValues = {
    businessName: state.request?.businessName || '',
    vatNumber: state?.request?.vatNumber || '',
  };

  const [requestPollingState, requestPollingDispatch] = useReducer(
    requestPollingReducer,
    initialRequestPollingState,
  );

  const [lineWidth, setLineWidth] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
  const [delta, setDelta] = useState<number>();
  const [maxLineWidth, setMaxLineWidth] = useState<number>(0);
  const [nextStepLoading, setNextStepLoading] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (document.querySelector('.loadingLineContainer')) {
      const loadingLineContainer = document.querySelector(
        '.loadingLineContainer',
      );
      if (loadingLineContainer) {
        setMaxLineWidth(
          parseFloat(
            loadingLineContainer?.getBoundingClientRect().right.toFixed(2),
          ) -
            parseFloat(
              loadingLineContainer?.getBoundingClientRect().x.toFixed(2),
            ) -
            6,
        );
        if (maxLineWidth) setDelta(maxLineWidth / 600);
      }
    }
  });

  /**
   * This effect is called at the first rendering.
   */
  useEffect(() => {
    const conditions: Condition[] = [
      { type: 'neq', field: 'state', value: States.ClienteRichiestaPending },
    ];

    getRequestUntil(
      state.request?.id ?? '',
      conditions,
      60000,
      new Date(),
      requestPollingDispatch,
    );
  }, []);

  useEffect(() => {
    if (intervalId) clearInterval(intervalId);
    if (!requestPollingState.isTimeout) {
      setIntervalId(() => {
        return setInterval(() => {
          setLineWidth(prevWidth => {
            if (prevWidth + (delta ?? 0) <= (maxLineWidth ?? 0))
              return prevWidth + (delta ?? 0);
            else return prevWidth;
          });
        }, 100);
      });
    }
  }, [delta]);

  useEffect(() => {
    if (intervalId && requestPollingState.isTimeout) {
      setLineWidth(maxLineWidth);
      clearInterval(intervalId);
    }
  }, [lineWidth]);

  /**
   * This effect is called when the polling ends succesfully.
   */
  useEffect(() => {
    if (intervalId) clearInterval(intervalId);
    setLineWidth(maxLineWidth);
    setTimeout(() => {
      if (requestPollingState.request) {
        dispatch({
          type: StateMachineActionType.SetRequest,
          request: requestPollingState.request,
        });
      }
    }, 1000);
  }, [requestPollingState.request]);

  /**
   * This method MUST NOT BE used in production because it going to bypass the pending state.
   * TODO: Change it when the ws will be implemented.
   * @param values The form values.
   * @param formikHelpers The formik helpers.
   */
  const onSubmit = (
    values: StepClienteRichiestaPendingValues,
    formikHelpers: FormikHelpers<StepClienteRichiestaPendingValues>,
  ) => {
    updateRequest(
      {
        ...values,
        id: state.request?.id,
        state: States.ClienteRiepilogo,
      },
      dispatch,
      formikHelpers,
    );
  };

  const goToNextStep = () => {
    setNextStepLoading(true);
    forceRequestToNextStep(state.request?.id, States.ClienteRiepilogo).then(
      res => {
        dispatch({
          type: StateMachineActionType.SetRequest,
          request: res.data,
        }),
          setNextStepLoading(false);
      },
    );
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form id={`form__${state.current}`}>
        <Grid textAlign="center">
          {!requestPollingState.isTimeout && (
            <>
              <Grid.Row>
                <Grid.Column>
                  <FormattedMessage
                    id="request.pending"
                    defaultMessage="Un attimo di pazienza..."
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3">
                    <FormattedMessage
                      id="request.waitingCompanyData"
                      defaultMessage="Stiamo recuperando i dati della {company}"
                      values={{
                        company: (
                          <span>
                            <FormattedMessage
                              id="request.company"
                              defaultMessage="Società"
                            />
                          </span>
                        ),
                      }}
                    />
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Loader />
              <div className="loadingLineContainer">
                <div className="loadingLine" style={{ width: lineWidth }} />
              </div>
            </>
          )}
          {requestPollingState.isTimeout && (
            <>
              <Grid.Row>
                <Grid.Column>
                  <Header>
                    <FormattedMessage
                      id="request.errorWhenGetCompanyData"
                      defaultMessage="Non siamo riusciti a recuperare i dati della {company}"
                      values={{
                        company: (
                          <span>
                            <FormattedMessage
                              id="request.company"
                              defaultMessage="Società"
                            />
                          </span>
                        ),
                      }}
                    />
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <FormattedMessage
                    id="request.continueManualEntry"
                    defaultMessage="Prosegui la compilazione manuale della richiesta nello step successivo"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Button
                  type="button"
                  onClick={() => goToNextStep()}
                  loading={nextStepLoading}
                  content={
                    <FormattedMessage
                      id="request.toRequests"
                      defaultMessage="Vai allo step successivo"
                    />
                  }
                />
              </Grid.Row>
            </>
          )}
        </Grid>
      </Form>
    </Formik>
  );
};
