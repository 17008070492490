import React, { useContext } from 'react';
import { Grid, Button, ButtonGroup } from 'semantic-ui-react';
import { Form, SubmitButton, Select } from 'formik-semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { requestCompletionTypeOptions } from './const';
import * as Yup from 'yup';

// Types
import {
  CustomerRequestFilterFormProps,
  CustomerRequestFilterFormValues,
} from './types';
import { CustomerContext } from 'pages/Customer';
import { RequestCompletionType } from 'pages/CustomerRequest/reducer/actions';

const CustomerRequestFilterForm: React.FC<CustomerRequestFilterFormProps> = ({
  onSubmit,
}) => {
  const { state } = useContext(CustomerContext);

  const initialFormValues: CustomerRequestFilterFormValues = {
    requestType: RequestCompletionType.ALL,
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        requestType: Yup.object().nullable(),
      })}
    >
      {({ resetForm }) => (
        <Form>
          <Grid padded>
            <Grid.Row width={12}>
              <Grid.Column width={5}>
                <Select
                  name="requestType"
                  options={requestCompletionTypeOptions}
                />
              </Grid.Column>
              <Grid.Column width={6} className="buttons customerReq">
                <ButtonGroup>
                  <SubmitButton
                    className="button--primary--negative"
                    content={
                      <FormattedMessage
                        id="requestfilter.apply"
                        defaultMessage="Applica"
                      />
                    }
                    loading={state.isLoading}
                  />
                  <Button
                    content="Reset"
                    type="button"
                    className="button--underline"
                    onClick={() => resetForm()}
                  />
                </ButtonGroup>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerRequestFilterForm;
