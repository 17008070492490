import React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Grid, Image, Menu } from 'semantic-ui-react';
import { Outlet } from 'react-router';
import Footer from 'layout/Footer';
import Topbar from 'layout/Topbar';

// Image and Icons
import logo from 'assets/images/logo.svg';

// Constants
import { FE_ROUTES } from 'utils/global/globalCostants';

// Types
import { MenuItem } from 'pages/App/types';
import { FormattedMessage } from 'react-intl';

export const topBarItems: Array<MenuItem> = [
  {
    id: 'logo',
    to: FE_ROUTES.CUSTOMER,
    position: 'left',
    title: (
      <Menu.Item>
        <Image src={logo} className="mainLogo" />
      </Menu.Item>
    ),
  },
  {
    id: 'WPHome',
    to: 'https://www.allegato4.it/',
    position: 'right',
    title: (
      <Menu.Item>
        <Button>
          <FormattedMessage id="link.home" defaultMessage="Torna alla home" />
        </Button>
      </Menu.Item>
    ),
  },
];
const AccessLayout: React.FC = () => {
  const logout = (): void => {
    localStorage.clear();
  };

  return (
    <>
      <div className="access">
        <Helmet>
          <title>Allegato4.it - Login</title>
          <meta name="description" content="access page" />
        </Helmet>
        <Topbar
          moduleName="Logout"
          topBarItems={topBarItems}
          logout={logout}
          breadcrumbs={null}
        />
        <Grid stackable centered className="access__container">
          <Grid.Column width={8} verticalAlign="middle">
            <Outlet />
          </Grid.Column>
        </Grid>
        <Footer />
      </div>
    </>
  );
};

export default AccessLayout;
