import standardClient from 'services/client/standardRequestClient';
import { toast } from 'react-toastify';

// Constants
import { API } from 'utils/global/backendRoutes';

// Types
import { PaymentFilterValues, PaymentRequest } from './types';
import qs from 'qs';
import { AxiosResponse } from 'axios';
import { PaginateObject } from 'utils/types/paginateObject';

/**
 * Create a new payment.
 * @param values Payment field values.
 */
export const createPayment = (
  values: Partial<PaymentRequest>,
): Promise<void> => {
  return standardClient({
    url: API.PAYMENT,
    method: 'POST',
    data: { ...values },
  })
    .then(() => {
      toast.success('Pagamento creato correttamente');
      // The response is okay, so back to the response page.
    })
    .catch(() => {
      toast.error('Errore durante il salvataggio del pagamento');
    });
};

/**
 * Gets payments list eventually with filters applied.
 */
export const getPayments = (
  values: PaymentFilterValues | null = null,
): Promise<AxiosResponse<PaginateObject<PaymentRequest>>> => {
  /** Create filters array if necessary */
  const request = values?.request || null;
  const types = values?.types || null;

  const params = {
    filter: [
      request && {
        type: 'eq',
        field: 'request',
        value: request,
      },
      types && {
        type: 'in',
        field: 'type',
        values: types,
      },
    ].filter(o => o),
  };
  // Convert params to string
  const paramsSerializer = qs.stringify(params);

  return standardClient.request({
    url: `${API.PAYMENT}?${paramsSerializer}`,
    method: 'GET',
  });
};
