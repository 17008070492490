import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Card, Button } from 'semantic-ui-react';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { PaymentTypes } from 'services/payment/types';
import { getPayments } from 'services/payment';
import { States } from 'services/stateMachine/types';
import { AppointmentCreateForm } from 'components/Forms/AppointmentCreateForm';
import { ConsultancyProps } from './types';
import { Nullable } from 'utils/types';
import { RequestEvent } from 'services/request/types';
import moment from 'moment';
import { getEvent } from 'services/calendar';
import { AxiosResponse } from 'axios';
import { Event as graphEvent } from '@microsoft/microsoft-graph-types';
import { toast } from 'react-toastify';
import { AppointmentContext } from 'pages/Appointment/context';
import { ConsultancyInvoiceModal } from 'components/ConsultancyInvoiceModal';
import { AppContext } from 'pages/App';
import { appActionConstants } from 'pages/App/reducer/actions';

export const Consultancy: React.FC<ConsultancyProps> = ({
  id,
  mode = 'sidebar',
}) => {
  const {
    state: { request },
  } = useStateMachine();

  const { state: appState, dispatch: appDispatch } = useContext(AppContext);
  /**
   * The flag to open/close the modals.
   */
  const [open, setOpen] = useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  /**
   * The isLoading props defined the button loading when elaborate
   */
  const [isLoading, setIsLoading] = useState(false);
  /**
   * The current open.
   */
  const [event, setEvent] = useState<Nullable<RequestEvent>>(null);

  useEffect(() => {
    if (appState.openAppointmentModal) setOpen(true);
  }, []);

  /**
   * Open the modal in the update mode.
   * @param {RequestEvent} e the event to update.
   */
  const onUpdate = (e: RequestEvent) => {
    setEvent(e);
    setIsLoading(true);
    getEvent(e.eventId)
      .then((response: AxiosResponse<graphEvent>) => {
        if (moment().isSameOrAfter(moment(response?.data?.start?.dateTime))) {
          toast.error('Impossibile modificare un appuntamento passato.');
        } else {
          setOpen(true);
        }
      })
      .catch(() =>
        toast.error("Errore durante l'aggiornamento dell'appuntamento"),
      )
      .finally(() => setIsLoading(false));
  };

  /**
   * Open consultancy modal if user has already paid, otherwise set payment up.
   * @returns
   */
  const onCreateConsultancy = () => {
    const filterRequest = id || null;
    const filterTypes = [
      PaymentTypes.GOLD,
      PaymentTypes.CONSULTING,
      PaymentTypes.SILVER_CONSULTING,
    ];
    /** Gets payments list for the current request */
    if (
      request?.plan?.code === 'P' &&
      appState.mySelf?.email !== 'admin@novigo-consulting.it'
    ) {
      setOpen(true);
    } else {
      getPayments({ request: filterRequest, types: filterTypes }).then(res => {
        if (res.data.total_items === 0) {
          setOpenInvoiceModal(true);
        } else {
          setOpen(true);
        }
      });
    }
  };

  /**
   * Close the current modal and erase the selected event.
   */
  const onClose = (): void => {
    setOpen(false);
    appDispatch({
      type: appActionConstants.SET_OPEN_APPOINTMENT_MODAL,
      payload: { isOpen: false },
    });
    setEvent(null);
  };

  const onCloseInvoiceModal = (): void => {
    setOpenInvoiceModal(false);
  };

  /**
   * Check if the user can create an event, that happens in two cases:
   * - No events have been already created
   * - The sum of the created events duration is less than 60 minutes.
   * - The state of the request is neither set to Piano, nor to ClienteSceltaCliente if mode is not maintenance
   */
  const canCreateEvent = useMemo<boolean>(() => {
    if (!request?.events) {
      return true;
    }

    if (
      (request?.state === States.Piano && mode !== 'maintenance') ||
      (request?.state === States.ClienteSceltaCliente && mode !== 'maintenance')
    )
      return false;

    const sumDuration = request.events.reduce(
      (sum, event) => sum + (event.duration || 0),
      0,
    );

    return sumDuration < 60;
  }, [request?.events, request?.id]);

  return (
    <AppointmentContext.Provider
      value={{
        open,
        event,
        onClose,
        openInvoiceModal,
        onCloseInvoiceModal,
        onCreateConsultancy,
      }}
    >
      {(request?.events || []).map(event => (
        <Card key={event.id} className={`request__${mode}__card`}>
          <Card.Content className={`request__${mode}__card__content`}>
            <div>Sessione di consulenza a video prenotata</div>
            <div>
              <Button
                icon="pencil alternate"
                className={`request__${mode}__card__button`}
                onClick={() => onUpdate(event)}
                loading={isLoading}
              />
            </div>
          </Card.Content>
        </Card>
      ))}
      {canCreateEvent && (
        <Card className={`request__${mode}__card`}>
          <Card.Content className={`request__${mode}__card__content`}>
            <div>
              {(request?.events || []).length === 0
                ? 'Prenota una sessione di consulenza video'
                : 'Prenota la seconda sessione di consulenza'}
            </div>
            <div>
              <Button
                icon="plus"
                className={`request__${mode}__card__button`}
                onClick={onCreateConsultancy}
              />
            </div>
          </Card.Content>
        </Card>
      )}
      {open && <AppointmentCreateForm />}
      {openInvoiceModal && <ConsultancyInvoiceModal />}
    </AppointmentContext.Provider>
  );
};
