import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { loadMyself } from './loadMyself';

// Internals and constants
import { API } from 'utils/global/backendRoutes';
import { AppAction, appActionConstants } from 'pages/App/reducer/actions';
import authClient from 'services/client/authRequestClient';
import { INVALID_USER_DATA } from 'utils/global/standardError';
import { updateAuthObject } from 'utils/function/localStorage';

// Types
import { AuthObject, StandardResponseError } from 'services/client/types';
import { LoginFormValues } from 'components/Forms/LoginForm/types';
import { NavigateFunction } from 'react-router-dom';
import { UserLoginResponse } from './types';
import { FE_ROUTES } from 'utils/global/globalCostants';

export const userLogin = (
  dispatch: React.Dispatch<AppAction>,
  userData: LoginFormValues,
  grant: string,
  navigate: NavigateFunction,
): void | boolean => {
  // Add to request data also the grant type (password in this case)
  const requestData = { ...userData, grant_type: grant };
  dispatch({
    type: appActionConstants.SET_USER_LOADING,
    payload: { isLoading: true },
  });

  authClient
    .request({
      url: API.OAUTH_GRANT_PASSWORD,
      method: 'POST',
      data: requestData,
    })
    .then((response: AxiosResponse<UserLoginResponse>) => {
      const responseData = response.data;

      const authObject: AuthObject = {
        accessTokken: responseData.access_token,
        expiresIn: responseData.expires_in.toString(),
        refreshToken: responseData.refresh_token,
        scope: responseData.scope,
        tokenType: responseData.token_type,
      };

      updateAuthObject(authObject);

      loadMyself(dispatch).then(() => {
        dispatch({ type: appActionConstants.SET_SESSION });
        dispatch({
          type: appActionConstants.SET_USER_LOADING,
          payload: { isLoading: false },
        });
        navigate(FE_ROUTES.CUSTOMER);
      });
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      if (error.statusText === INVALID_USER_DATA) {
        toast.error('Password o username non validi');
      }

      dispatch({
        type: appActionConstants.SET_USER_LOADING,
        payload: { isLoading: false },
      });
    });
};
