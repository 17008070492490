import { FormikHelpers } from 'formik';
import { toast } from 'react-toastify';

// Client
import standardClient from 'services/client/standardRequestClient';

// Constants
import { API } from 'utils/global/backendRoutes';

// Types
import { PasswordRecoveryRequest, PasswordResetRequest } from './types';
import { PasswordRecoveryState } from 'pages/PasswordRecovery/types';
import { PasswordResetState } from 'pages/PasswordReset/types';

export const requestRecovery = (
  values: PasswordRecoveryRequest,
  formikHelpers: FormikHelpers<PasswordRecoveryRequest>,
  callback: (response: PasswordRecoveryState) => void,
): void => {
  standardClient({
    url: API.PASSWORD_RECOVERY,
    method: 'POST',
    data: {
      method: 'request_recovery',
      params: {
        ...values,
      },
    },
  })
    .then(() =>
      // The response is okay, so back to the response page.
      callback({
        email: values.username,
        sent: true,
      }),
    )
    .catch(() => {
      toast.error('Errore durante il recupero della password');
    })
    .finally(() => formikHelpers.setSubmitting(false));
};

export const resetPassword = (
  values: PasswordResetRequest,
  formikHelpers: FormikHelpers<PasswordResetRequest>,
  callback: (response: PasswordResetState) => void,
): void => {
  standardClient({
    url: API.PASSWORD_RECOVERY,
    method: 'POST',
    data: {
      method: 'reset_password',
      params: {
        ...values,
      },
    },
  })
    .then(() =>
      // The response is okay, so back to the response page.
      callback({
        sent: true,
      }),
    )
    .catch(() => {
      toast.error('Errore durante il recupero della password');
    })
    .finally(() => formikHelpers.setSubmitting(false));
};
