import { FormikHelpers } from 'formik';
import { Customer } from 'services/customer/types';

export type CustomerProfileFormProps = {
  customerType: string;
  initialValues: Customer;
  onSubmit: (values: Customer, formikHelpers: FormikHelpers<Customer>) => void;
  mode: string | undefined;
};

export enum CompanyTypeConstants {
  FREELANCE = 'Freelancer',
  PARTNERSHIP = 'Partnership',
  LIMITEDCOMPANY = 'LimitedCompany',
  PROFESSIONALFIRM = 'ProfessionalFirm',
}
