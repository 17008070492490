import { VoucherFilterFormValues } from 'components/Forms/VoucherFilterForm/types';
import { VoucherAction } from 'pages/Voucher/reducer/actions';
import { GenerateVoucherAction } from '../reducer/actions';

export type GenerateVoucherFormProps = {
  voucherDispatch: React.Dispatch<VoucherAction>;
  generateVoucherDispatch: React.Dispatch<GenerateVoucherAction>;
  voucherFilterValues: VoucherFilterFormValues;
};

export type GenerateVoucherFormValues = {
  agentId: AgentValue | null;
  numberOfVoucher: number;
  discountPercentage: string;
};

export type AgentValue = {
  value: string | null;
  label: string | null;
};

export const discountPercentageOptions = [
  {
    text: '10',
    value: '10',
  },
  {
    text: '15',
    value: '15',
  },
  {
    text: '25',
    value: '25',
  },
  {
    text: '50',
    value: '50',
  },
  {
    text: '75',
    value: '75',
  },
  {
    text: '99',
    value: '99',
  },
  {
    text: '100',
    value: '100',
  },
];
