import React from 'react';
import { Grid, Header, Button } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { RequestDetails } from './types';
import { States } from 'services/stateMachine/types';
import { updateRequest } from 'services/request';
import { RequestRequest } from 'services/request/types';

export const StepPagamentoPending: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state, dispatch } = useStateMachine();

  const requestDetails: RequestDetails = {
    id: (state.request?.id || window.location.href.split('/').pop()) ?? '',
    state: state.request?.state,
    stateBeforePayment: state.request?.stateBeforePayment ?? '',
  };
  /**
   * The react-intl object.
   */
  const intl = useIntl();

  const cancelPendingPayment = () => {
    const payload: Partial<RequestRequest> = {};
    payload['id'] = requestDetails.id;
    payload['state'] = States.PagamentoCancellato;
    payload['stateBeforePayment'] = requestDetails.stateBeforePayment;

    updateRequest(payload, dispatch);
  };

  return (
    <div>
      <Helmet>
        <title>
          {'Allegato4.it - ' +
            intl.formatMessage({
              id: `stripeCheckout.title`,
              defaultMessage: 'Pagamento',
            })}
        </title>
        <meta name="description" content="stripe checkout" />
      </Helmet>
      <div className="stripe-checkout-container">
        <>
          <Header className="stripeCheckoutHeader" dividing>
            <Grid>
              <Grid.Column>
                <Header>
                  <h1>
                    <FormattedMessage
                      id="stripeCheckout.title"
                      defaultMessage="Pagamento"
                    />
                  </h1>
                </Header>
              </Grid.Column>
            </Grid>
          </Header>
          <Grid.Row>
            <Grid.Column>
              <h3>
                <FormattedMessage
                  id="stripeCheckout.pending"
                  defaultMessage="Ci risulta una sessione di pagamento in corso"
                />
              </h3>
              <Button onClick={cancelPendingPayment}>
                Torna alla richiesta
              </Button>
            </Grid.Column>
          </Grid.Row>
        </>
      </div>
    </div>
  );
};
