import { Request } from 'services/request/types';
import {
  CustomerRequestAction,
  CustomerRequestActionConstants,
} from './actions';

export type CustomerRequestState = {
  isLoading: boolean;
  requestList: Array<Request>;
};

export const initialCustomerState: CustomerRequestState = {
  isLoading: false,
  requestList: [],
};

export function customerReducer(
  state: CustomerRequestState,
  action: CustomerRequestAction,
): CustomerRequestState {
  const { type } = action;

  switch (type) {
    case CustomerRequestActionConstants.SET_SEARCH_FILTER:
      return { ...state, isLoading: action.payload.isLoading };
    case CustomerRequestActionConstants.SET_REQUEST_DICTIONARY:
      return { ...state, requestList: action.payload.requestList };
    default:
      return state;
  }
}
