import React from 'react';
import { Form, Input } from 'formik-semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import { Grid, Header, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { AgentProfileFormProps } from './types';
import { AgentTypeConstants } from './types';

const AgentProfileForm: React.FC<AgentProfileFormProps> = ({
  onSubmit,
  initialValues,
  mode,
}) => {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Nome è richiesto').nullable(),
          surname: Yup.string().required('Cognome è richiesto').nullable(),
          email: Yup.string().required().email().nullable(),
          vouchers: Yup.number(),
          type: Yup.string()
            .required('Tipologia di agente è richiesto')
            .nullable(),
        })}
      >
        {({ setFieldValue, values, isSubmitting }) => (
          <Form id="agentProfileForm">
            <Grid padded>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3">
                    <FormattedMessage
                      id="agent.agentDetails"
                      defaultMessage="Anagrafica"
                    />
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Input
                    name="name"
                    placeholder="Nome"
                    label={
                      <FormattedMessage id="agent.name" defaultMessage="Nome" />
                    }
                  />
                  <ErrorMessage name="name" component="span" />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Input
                    name="surname"
                    placeholder="Cognome"
                    label={
                      <FormattedMessage
                        id="agent.surname"
                        defaultMessage="Cognome"
                      />
                    }
                  />
                  <ErrorMessage name="surname" component="span" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Input
                    name="email"
                    placeholder="Email"
                    label={
                      <FormattedMessage
                        id="agent.email"
                        defaultMessage="Indirizzo di posta elettronica"
                      />
                    }
                  />
                  <ErrorMessage name="email" component="span" />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Input
                    name="vouchers"
                    placeholder="N° voucher"
                    label={
                      <FormattedMessage
                        id="agent.vouchers"
                        defaultMessage="Voucher a disposizione"
                      />
                    }
                    disabled={true}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid padded className="company-type">
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3">
                    <FormattedMessage
                      id="agent.type"
                      defaultMessage="Tipologia di agente"
                    />
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Button.Group
                    className={mode === 'create' ? 'create' : 'modify'}
                  >
                    <Button
                      type="button"
                      primary={values.type === AgentTypeConstants.AREA_MANAGER}
                      checked={values.type === AgentTypeConstants.AREA_MANAGER}
                      onClick={() => {
                        setFieldValue('type', AgentTypeConstants.AREA_MANAGER);
                      }}
                    >
                      <Icon name="id badge" />
                      <FormattedMessage
                        id="agent.area_manager"
                        defaultMessage="Area Manager"
                      />
                    </Button>
                    <Button
                      type="button"
                      primary={values.type === AgentTypeConstants.CONSULTANT}
                      checked={values.type === AgentTypeConstants.CONSULTANT}
                      onClick={() => {
                        setFieldValue('type', AgentTypeConstants.CONSULTANT);
                      }}
                    >
                      <Icon name="handshake" />
                      <FormattedMessage
                        id="agent.consultant"
                        defaultMessage="Consultant"
                      />
                    </Button>
                    <Button
                      type="button"
                      primary={values.type === AgentTypeConstants.BACK_OFFICE}
                      checked={values.type === AgentTypeConstants.BACK_OFFICE}
                      onClick={() => {
                        setFieldValue('type', AgentTypeConstants.BACK_OFFICE);
                      }}
                    >
                      <Icon name="desktop" />
                      <FormattedMessage
                        id="agent.back_office"
                        defaultMessage="Back-Office"
                      />
                    </Button>
                  </Button.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Button
                    type="submit"
                    loading={isSubmitting}
                    className="button--primary"
                  >
                    {mode === 'create' && (
                      <div>
                        <FormattedMessage
                          id="agent.new"
                          defaultMessage="Crea"
                        />
                      </div>
                    )}
                    {mode === 'modify' && (
                      <div>
                        <FormattedMessage
                          id="agent.existent"
                          defaultMessage="Salva"
                        />
                      </div>
                    )}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AgentProfileForm;
