import React, { useMemo, useState } from 'react';
import { Checkbox, Field, Form, Input } from 'formik-semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { Container, Grid, Header } from 'semantic-ui-react';
import * as Yup from 'yup';
import moment from 'moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { LinkedCompany as LinkedCompanyTable } from 'components/LinkedCompany';
import { Tooltip } from 'components/Tooltip';
import _, { forEach } from 'lodash';
import { getIn } from 'formik';

// Services
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { States } from 'services/stateMachine/types';
import { updateRequest } from 'services/request';
import { StepBilancioCalcoloDimensionaleAziendaValues } from './types';
import { Nullable } from 'utils/types';
import { CurrencyInputFormik } from 'components/CurrencyInputFormik';

export const StepBilancioCalcoloDimensionaleAzienda: React.FC = () => {
  const { state, dispatch } = useStateMachine();
  const [minDate, setMinDate] = useState<Nullable<string>>(null);
  const [maxDate, setMaxDate] = useState<Nullable<string>>(null);

  const intl = useIntl();

  const onSubmit = (
    values: StepBilancioCalcoloDimensionaleAziendaValues,
    formikHelpers: FormikHelpers<StepBilancioCalcoloDimensionaleAziendaValues>,
  ) => {
    const payload = { ...values };
    updateRequest(
      {
        ...payload,
        id: state.request?.id,
        state: States.Finalita,
      },
      dispatch,
      formikHelpers,
    );
  };

  /**
   * The initial values from the form.
   * Since it's a memorized property it's going to be recalculated every time the request changes.
   */
  const initialValues =
    useMemo<StepBilancioCalcoloDimensionaleAziendaValues>(() => {
      if (state.request) {
        const {
          totalActiveAmount = '',
          numberOfEmployees = '',
          relatedCompanies = false,
          revenueAmount = '',
          linkedCompanies = [],
        } = state.request;

        // Get the last balance year from the balances array.
        // The cast is required since year seems to be a string...
        const maxBalanceYear = Math.max(
          ...state.request.balances.map(b =>
            b.type && b.year ? parseInt(b.year) : 0,
          ),
          0,
        );

        // Compose the default reference date.
        const defaultReferenceDate = maxBalanceYear
          ? moment().set({
              date: 31,
              month: 11,
              year: maxBalanceYear,
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
          : moment()
              .utc()
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        if (maxBalanceYear) {
          setMaxDate(
            moment()
              .set({
                date: 31,
                month: 11,
                year: maxBalanceYear,
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .toString(),
          );
          setMinDate(
            moment()
              .set({
                date: 1,
                month: 0,
                year: maxBalanceYear,
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .toString(),
          );
        }

        const strDefaultRerenceDate =
          defaultReferenceDate && defaultReferenceDate.toString();

        const balance =
          maxBalanceYear &&
          state.request.balances.find(balance =>
            balance.year
              ? parseInt(balance.year) === maxBalanceYear
              : undefined,
          );

        let strTotalActiveAmount: string | null = '';
        let strNumberOfEmployees: string | null = '';
        let strRevenueAmount: string | null = '';

        if (balance) {
          strTotalActiveAmount = balance.totalActiveAmount || null;
          strNumberOfEmployees = balance.numberOfEmployee || null;
          strRevenueAmount = balance.revenueAmount;
        } else if (state.request.revenueSinceEstablishment) {
          strRevenueAmount = state.request.revenueSinceEstablishment.toString();
        }

        return {
          balanceReferenceDate: strDefaultRerenceDate,
          totalActiveAmount: totalActiveAmount || strTotalActiveAmount,
          numberOfEmployees: numberOfEmployees || strNumberOfEmployees,
          relatedCompanies,
          revenueAmount: revenueAmount || strRevenueAmount,
          linkedCompanies: linkedCompanies || [],
        };
      }

      // Otherwise returns an empty object.
      return {
        balanceReferenceDate: null,
        totalActiveAmount: '',
        numberOfEmployees: '',
        relatedCompanies: false,
        revenueAmount: '',
        linkedCompanies: [],
      };
    }, [state.request]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => {
        let error = false;
        if (values.relatedCompanies && values.linkedCompanies.length === 0) {
          formikHelpers.setFieldError(
            'linkedCompanies',
            "Inserire almeno un'azienda collegata.",
          );
          error = true;
        }
        if (values.linkedCompanies.length > 0) {
          forEach(values.linkedCompanies, linked => {
            if (_.isEmpty(linked.fiscalCode)) {
              formikHelpers.setFieldError(
                'linkedCompanies',
                'La partita IVA delle aziende associate/collegate è obbligatoria',
              );
              error = true;
            }
          });
        }
        if (!error) onSubmit(values, formikHelpers);
      }}
      validationSchema={Yup.object().shape({
        balanceReferenceDate: Yup.date()
          .label(
            intl.formatMessage({
              id: 'request.balanceReferenceDate',
              defaultMessage: 'Periodo di riferimento',
            }),
          )
          .typeError('Periodo di riferimento deve essere una data valida')
          .required()
          .nullable(),
        totalActiveAmount: Yup.number()
          .label(
            intl.formatMessage({
              id: 'request.totalActiveAmount',
              defaultMessage: 'Totale attivo',
            }),
          )
          .required()
          .test(
            'Is positive?',
            'Totale attivo deve essere maggiore o uguale a zero',
            value => {
              if (value) {
                if (value >= 0) return true;
                else return false;
              }
              return true;
            },
          )
          .nullable(),
        revenueAmount: Yup.number()
          .label(
            intl.formatMessage({
              id: 'request.revenueAmount',
              defaultMessage: 'Ricavi',
            }),
          )
          .required()
          .test(
            'Is positive?',
            'Ricavi deve essere maggiore o uguale a zero',
            value => {
              if (value) {
                if (value >= 0) return true;
                else return false;
              }
              return true;
            },
          )
          .nullable(),
        numberOfEmployees: Yup.number()
          .label(
            intl.formatMessage({
              id: 'request.numberOfEmployees',
              defaultMessage: 'Numero Dipendenti',
            }),
          )
          .required()
          .test(
            'Is positive?',
            'Numero Dipendenti deve essere maggiore o uguale a zero',
            value => {
              if (value) {
                if (value >= 0) return true;
                else return false;
              }
              return true;
            },
          )
          .nullable(),
        relatedCompanies: Yup.boolean().nullable(),
        linkedCompanies: Yup.array()
          .of(
            Yup.object().shape({
              businessName: Yup.string().required().nullable(),
              fiscalCode: Yup.string().nullable(),
              incomingAmount: Yup.number().required().nullable(),
              totalAssetsAmount: Yup.number().required().nullable(),
              employeeNumber: Yup.number().required().nullable(),
              relationType: Yup.string().required().nullable(),
              sharePercentage: Yup.number().required().nullable(),
            }),
          )
          .label(
            intl.formatMessage({
              id: 'request.linkedCompanies',
              defaultMessage: 'Aziende collegate/associate',
            }),
          )
          .required()
          .nullable(),
      })}
    >
      {({ values, setFieldValue, handleBlur, errors }) => (
        <Form
          className="form__BalanceCalcoloDimensionaleAzienda"
          id={`form__${state.current}`}
        >
          <div className="balance_calcolo_dimesionale_azienda__header">
            <h1 className="balance_calcolo_dimesionale_azienda__header__title">
              <FormattedMessage
                id="balanceCalcoloDimensionale.insertDataOf"
                defaultMessage="Inserisci i "
              />
              <strong className="title__bold">
                <FormattedMessage
                  id="balanceCalcoloDimensionale.parametriDimensionali"
                  defaultMessage="parametri dimensionali "
                />
              </strong>
              <FormattedMessage
                id="balanceCalcoloDimensionale.ofBusiness"
                defaultMessage="dell'azienda"
              />
            </h1>
            <h4 className="balance_calcolo_dimesionale_azienda__header__subtitle">
              <FormattedMessage
                id="balance.business_dimensional_params_support"
                defaultMessage="Inserisci o conferma i dati relativi all'ultimo bilancio disponibile di "
              />
              <b>{state.request?.businessName}</b>
            </h4>
          </div>
          <div className="balance_calcolo_dimesionale_azienda__content">
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Input
                    label={
                      <label>
                        <FormattedMessage
                          id="request.balanceReferenceDate"
                          defaultMessage="Periodo di riferimento"
                        />
                        *
                      </label>
                    }
                    name="balanceReferenceDate"
                  >
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        error={false}
                        helperText={null}
                        clearable
                        disableToolbar
                        format="DD/MM/YYYY"
                        autoOk
                        onChange={value =>
                          setFieldValue('balanceReferenceDate', value)
                        }
                        InputProps={{
                          disableUnderline: true,
                        }}
                        value={values.balanceReferenceDate}
                        minDate={moment(minDate)}
                        maxDate={moment(maxDate)}
                      />
                    </MuiPickersUtilsProvider>
                  </Input>
                  <ErrorMessage name="balanceReferenceDate" component="span" />
                  <Tooltip
                    header={
                      <Header as="h2">
                        <FormattedMessage
                          id="request.balanceReferenceDate"
                          defaultMessage="Periodo di riferimento"
                        />
                      </Header>
                    }
                    content={
                      <Container>
                        <p>
                          Inserire la data di chiusura dell&apos;esericzio
                          contabile dell&apos;ultimo bilancio approvato e/o
                          depositato o dichiarazione dei redditi trasmessa
                          all&apos;Agenzia dell&apos;Entrate disponibile.
                        </p>
                      </Container>
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row width={12}>
                <Grid.Column width={4}>
                  <Field name="totalActiveAmount">
                    {({ field }) => (
                      <CurrencyInputFormik
                        field={field}
                        label={
                          <label>
                            <FormattedMessage
                              id="request.totalActiveAmount"
                              defaultMessage="Totale attivo"
                            />
                            *
                          </label>
                        }
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        placeholder="€ Importo in Euro"
                      />
                    )}
                  </Field>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Field name="revenueAmount">
                    {({ field }) => (
                      <CurrencyInputFormik
                        field={field}
                        label={
                          <label>
                            <FormattedMessage
                              id="request.revenueAmount"
                              defaultMessage="Ricavi"
                            />
                            *
                          </label>
                        }
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        placeholder="€ Importo in Euro"
                      />
                    )}
                  </Field>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Input
                    label={
                      <label>
                        <FormattedMessage
                          id="request.numberOfEmployees"
                          defaultMessage="Numero Dipendenti"
                        />
                        *
                      </label>
                    }
                    name="numberOfEmployees"
                    type="number"
                    step=".1"
                    min={0}
                  />
                  <ErrorMessage name="numberOfEmployees" component="span" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Checkbox
                    label={
                      <label>
                        <FormattedMessage
                          id="request.relatedCompanies"
                          defaultMessage="Sono presenti aziende collegate?"
                        />
                      </label>
                    }
                    name="relatedCompanies"
                  />
                </Grid.Column>
              </Grid.Row>
              {/**
               * Group structures
               */}
              {values.relatedCompanies && (
                <>
                  <Grid.Row>
                    <Grid.Column>
                      <span className="tooltip tooltip--right">
                        <span>
                          <Header as="h3">
                            <FormattedMessage
                              id="request.linkedCompanyTitle"
                              defaultMessage="Aziende collegate/associate"
                            />
                          </Header>
                        </span>
                        <Tooltip
                          header={
                            <Header as="h2">
                              <FormattedMessage
                                id="request.linkedCompanyTitle"
                                defaultMessage="Aziende collegate/associate"
                              />
                            </Header>
                          }
                          content={
                            <Container>
                              <p>
                                <strong>IMPRESA AUTONOMA:</strong> è
                                completamente indipendente o ha una o più
                                partecipazioni di minoranza (ciascuna inferiore
                                al 25%) con altre imprese.
                                <p /> <strong>IMPRESA ASSOCIATA:</strong> se la
                                partecipazione con altre imprese arriva almeno
                                al 25%, ma non supera il 50%.
                                <p /> <strong>IMPRESA COLLEGATA:</strong> se la
                                partecipazione con altre imprese supera il tetto
                                del 50%.
                                <p />{' '}
                                <strong>
                                  IMPRESA COLLEGATA TRAMITE PERSONE FISICHE:
                                </strong>{' '}
                                i soci da soli o di concerto detengono più del
                                50% del capitale di altre imprese: 1) facenti
                                parte del medesimo settore attività (codice
                                ATECO/ISTAT); oppure 2) l&apos;una fattura
                                all&apos;altra (non necessariamente in maniera
                                reciproca) almeno il 25% del fatturato annuo.
                              </p>
                            </Container>
                          }
                        />
                      </span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid.Column>
                      <span
                        className={
                          getIn(errors, 'linkedCompanies')
                            ? 'error-all error'
                            : undefined
                        }
                      >
                        {getIn(errors, 'linkedCompanies')}
                      </span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <LinkedCompanyTable />
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
            </Grid>
          </div>
        </Form>
      )}
    </Formik>
  );
};
