import { AxiosResponse } from 'axios';
import { ErrorMessage, Field, Formik, FormikHelpers } from 'formik';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Grid, Header, Modal } from 'semantic-ui-react';
import AsyncSelect from 'react-select/async';
import * as Yup from 'yup';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { Form } from 'formik-semantic-ui-react';

import standardClient from 'services/client/standardRequestClient';
import { CustomerObjectResponse } from 'services/customer/types';
import { States } from 'services/stateMachine/types';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { API } from 'utils/global/backendRoutes';
import { PaginateObject } from 'utils/types/paginateObject';
import { updateRequest } from 'services/request';
import { StepSceltaClienteValues } from './types';
import {
  CustomerPageActionConstants,
  CustomerType,
} from 'pages/Customer/reducer/actions';
import { CustomerTypeModalProps } from 'components/Forms/CustomerFilterForm/types';
import { FE_ROUTES, ROLES } from 'utils/global/globalCostants';
import { isThatRole } from 'utils/function/acl';
import { AppContext } from 'pages/App';
import { toast } from 'react-toastify';
import CustomerTypeSelectionForm from 'components/Forms/CustomerTypeSelectionForm';
import { CustomerTypeSelectionValues } from 'components/Forms/CustomerTypeSelectionForm/types';

export const StepClienteSceltaCliente: React.FC = () => {
  /**
   * The state machine configuration.
   */
  const { state, dispatch } = useStateMachine();

  /**
   * The hook to fetch the app context.
   */
  const context = useContext(AppContext);

  /**
   * The hook to navigate thorught the application.
   */
  const navigate = useNavigate();

  /**
   * The react-intl locate componente.
   */
  const intl = useIntl();

  /**
   * The modal properties.
   * It stores the current legal representative index (optionally, if the modal is open and it's linked to a valid legal repr.)
   * and the open/close flag.
   */
  const [modal, setModal] = useState<CustomerTypeModalProps>({
    open: false,
  });

  /**
   * The initial values.
   */
  const initialValues: StepSceltaClienteValues = {
    customer: null,
  };

  /**
   * Fetch all the customers.
   * The query providers server-side will provide only the available customers for the logged user.
   * @param inputValue
   * @param callback
   */
  const loadCustomers = (inputValue, callback) => {
    standardClient({
      url: API.CUSTOMER,
      method: 'GET',
      params: {
        filter: [
          inputValue && {
            type: 'like',
            field: 'businessName',
            value: `%${inputValue}%`,
          },
        ].filter(f => f),
      },
      paramsSerializer: qs.stringify,
    }).then(
      (response: AxiosResponse<PaginateObject<CustomerObjectResponse>>) => {
        const customers = response.data._embedded.customer;

        // Fill the react-select component with the customer options.
        callback(
          customers.map(
            c =>
              c && {
                value: c.id,
                label: c.businessName,
              },
          ),
        );
      },
    );
  };

  /**
   * Method used to manage the new action for the Customer.
   * It contains the logic to force the customer type or to display a modal for the selection.
   */
  const onCreateCustomer = () => {
    /** The creation of a customer depends on the current user role:
     * - BANK can only create BANKCLIENT
     * - ACCOUNTANT can only create ACCOUNTANTCLIENT
     * - ADMIN can select and create any customer type
     * - others cannot create customers
     */
    let newCustomerType: CustomerType;

    if (isThatRole(ROLES.BANK, context.state)) {
      newCustomerType = CustomerType.BANKCLIENT;
    } else if (isThatRole(ROLES.ACCOUNTANT, context.state)) {
      newCustomerType = CustomerType.ACCOUNTANTCLIENT;
    } else if (isThatRole(ROLES.ADMIN, context.state)) {
      setModal({ open: true });
      return;
    } else {
      toast.error('Impossibile creare un nuovo cliente');
      return;
    }

    /** Navigate to the creation page */
    navigateToCustomerCreate(newCustomerType);
  };

  /**
   * Choose the customer for the current request.
   * @param values the form values
   * @param formikHelpers the Formik helpers
   */
  const onSubmit = (
    values: StepSceltaClienteValues,
    formikHelpers: FormikHelpers<StepSceltaClienteValues>,
  ) => {
    updateRequest(
      {
        id: state.request?.id,
        state: States.ClienteIdentificativoAzienda,
        customer: values.customer?.value,
      },
      dispatch,
      formikHelpers,
    );
  };

  /**
   * Method used to manage the selection of a customer type from the modal.
   * @param values
   */
  const onSelectCustomerType = (values: CustomerTypeSelectionValues) => {
    navigateToCustomerCreate(values.customerType);
  };

  /**
   * Method used to manage the navigation to the new customer form, given the specified type.
   * @param customerType
   */
  const navigateToCustomerCreate = customerType => {
    /** Navigate to the creation page */
    navigate(
      `${FE_ROUTES.CUSTOMER}/${CustomerPageActionConstants.NEW}/${customerType}`,
    );
  };

  return (
    <>
      {/* Modal for customer type selection */}
      <Modal
        closeIcon
        open={modal.open}
        onClose={() => setModal({ open: false })}
      >
        <Modal.Content>
          {modal.open && (
            <CustomerTypeSelectionForm
              onSubmit={values => onSelectCustomerType(values)}
            />
          )}
        </Modal.Content>
      </Modal>
      {/* Step form */}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          customer: Yup.object()
            .shape({
              value: Yup.string().nullable().required(),
              label: Yup.string().nullable().required(),
            })
            .label(
              intl.formatMessage({
                id: 'request.customer',
                defaultMessage: 'Cliente',
              }),
            )
            .required()
            .nullable(),
        })}
      >
        <Form id={`form__${state.current}`}>
          <Grid padded textAlign="center">
            <Grid.Row>
              <Grid.Column>
                <Header as="h2" className="login__title--h3">
                  <FormattedMessage
                    id="request.title--scelta_cliente"
                    defaultMessage="Per chi vorresti compilare Allegato4?"
                  />
                </Header>
                <Header.Subheader as="h3">
                  <FormattedMessage
                    id="request.subtitle--scelta_cliente"
                    defaultMessage="Seleziona uno dei clienti presenti nella tua anagrafica"
                  />
                </Header.Subheader>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field name="customer">
                  {({ field, form }) => (
                    <>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadCustomers}
                        defaultOptions
                        isClearable
                        onChange={value =>
                          form.setFieldValue(field.name, value)
                        }
                      />
                    </>
                  )}
                </Field>
                <ErrorMessage name="customer" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button type="button" onClick={onCreateCustomer}>
                  <FormattedMessage
                    id="request.addNewCustomer"
                    defaultMessage="Aggiungi nuovo cliente"
                  />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
