import React, { createContext, useEffect, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';

import { FormattedMessage } from 'react-intl';
import { Button, Grid, Header } from 'semantic-ui-react';
import {
  customerReducer,
  CustomerRequestState,
  initialCustomerState,
} from './reducer/reducer';
import {
  CustomerRequestAction,
  RequestCompletionType,
} from './reducer/actions';
import DataGrid from 'components/DataGrid';
import { getRequestList } from 'services/request';
import CustomerRequestFilterForm from 'components/Forms/CustomerRequestFilterForm';
import { CustomerRequestFilterFormValues } from 'components/Forms/CustomerRequestFilterForm/types';
import {
  DataGridAction,
  DataGridActionConstants,
} from 'components/DataGrid/reducer/actions';
import {
  dataGridReducer,
  DataGridState,
  initialDataGridState,
} from 'components/DataGrid/reducer/reducer';
import { Link } from 'react-router-dom';
import { RequestFormatterParams } from 'components/DataGrid/types';
import _ from 'lodash';
import AttachmentService from 'services/attachment';
import moment from 'moment';
import { appReducer, initialAppState } from 'pages/App/reducer/reducer';

export const CustomerContext = createContext<{
  state: CustomerRequestState;
  dispatch: React.Dispatch<CustomerRequestAction>;
}>({
  state: initialCustomerState,
  dispatch: () => null,
});

export const DataGridContext = createContext<{
  state: DataGridState;
  dispatch: React.Dispatch<DataGridAction>;
}>({
  state: initialDataGridState,
  dispatch: () => null,
});

const CustomerRequest: React.FC = () => {
  const [state, dispatch] = useReducer(customerReducer, initialCustomerState);
  /*eslint-disable-next-line @typescript-eslint/no-unused-vars*/
  const [appState, appDispatch] = useReducer(appReducer, initialAppState);
  const [dataGridState, dataGridDispatch] = useReducer(
    dataGridReducer,
    initialDataGridState,
  );
  // User defined filters
  const [filters, setFilters] = useState<CustomerRequestFilterFormValues>({
    requestType: RequestCompletionType.ALL,
  });

  /**
   * Gets customer list on pagination data change.
   */
  useEffect(() => {
    // Get request collection
    getRequestList(
      filters,
      dataGridState.paginationData,
      dataGridDispatch,
      dispatch,
    );
  }, [dataGridState.paginationData.page]);

  /**
   * Manages search submit with filters.
   * @param values
   */
  const onSubmit = (values: CustomerRequestFilterFormValues) => {
    // Store filters locally
    setFilters(values);
    // Get request collection
    getRequestList(
      values,
      dataGridState.paginationData,
      dataGridDispatch,
      dispatch,
    );
  };

  /**
   * Manages page selection change.
   * @param data
   */
  const handleSelectPage = (data: string | number) => {
    dataGridDispatch({
      type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
      payload: {
        pageNumber: data,
      },
    });
  };

  return (
    <>
      <div className="customer-container">
        <Helmet>
          <body className="customer customer-requests" />
          <title>Allegato4.it - Elenco richieste</title>
          <meta name="description" content="customer list page" />
          <script>{"smartsupp('chat:hide')"}</script>
        </Helmet>
        <div className="customer__content">
          <Header className="customerHeader" dividing>
            <Grid>
              <Grid.Column width={3}>
                <h1>
                  <FormattedMessage
                    id="request.title"
                    defaultMessage="Elenco richieste"
                  />
                </h1>
              </Grid.Column>
              <Grid.Column width={13}>
                <CustomerRequestFilterForm onSubmit={onSubmit} />
              </Grid.Column>
            </Grid>
          </Header>
          <div className="customerSectionBody">
            <DataGrid
              isLoading={dataGridState.isLoading}
              elements={state.requestList}
              columns={[
                {
                  key: 'customer.businessName',
                  name: (
                    <span>
                      <FormattedMessage
                        id="request.customerName"
                        defaultMessage="Cliente"
                      />
                    </span>
                  ),
                  formatter: ({ data }) =>
                    data.customer ? data.customer.businessName : '',
                },
                {
                  key: 'createdAt',
                  name: (
                    <span>
                      <FormattedMessage
                        id="request.startDate"
                        defaultMessage="Avvio compilazione"
                      />
                    </span>
                  ),
                  formatter: ({ data }: RequestFormatterParams) => {
                    // Expected output: 1 Gennaio 2022, 00:00:00
                    const options = {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                    } as const;
                    return data.createdAt
                      ? new Date(data.createdAt).toLocaleDateString(
                          'it-IT',
                          options,
                        )
                      : '-';
                  },
                },
                {
                  key: 'endDate',
                  name: (
                    <span>
                      <FormattedMessage
                        id="request.endDate"
                        defaultMessage="Chiusura compilazione"
                      />
                    </span>
                  ),
                  formatter: ({ data }: RequestFormatterParams) => {
                    // Expected output: 1 Gennaio 2022, 00:00:00
                    const options = {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                    } as const;
                    return data.endDate
                      ? new Date(data.endDate).toLocaleDateString(
                          'it-IT',
                          options,
                        )
                      : '-';
                  },
                },
                // {
                //   key: 'progressPercentage',
                //   name: (
                //     <span>
                //       <FormattedMessage
                //         id="request.progressPercentage"
                //         defaultMessage="Completamento"
                //       />
                //     </span>
                //   ),
                //   formatter: ({ data }: RequestFormatterParams) =>
                //     `${data.progressPercentage || 0}%`,
                // },
                {
                  key: 'expirationDate',
                  name: (
                    <span>
                      <FormattedMessage
                        id="request.expirationDate"
                        defaultMessage="Scadenza compilazione"
                      />
                    </span>
                  ),
                  formatter: ({ data }: RequestFormatterParams) => {
                    // Expected output: 1 Gennaio 2022, 00:00:00
                    const options = {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                    } as const;
                    const requestUrl = `/request/${data.id}`;
                    /** Display expiration date and restart compilation button
                     * only if the request is not completed */
                    const expiredDocument = moment().isAfter(
                      data.expirationDate,
                    );

                    if (expiredDocument) {
                      return (
                        <>
                          <FormattedMessage
                            id="request.expiredRequest"
                            defaultMessage="Richiesta scaduta"
                          />
                        </>
                      );
                    }
                    return (
                      <>
                        <span>
                          {data.expirationDate
                            ? new Date(data.expirationDate).toLocaleDateString(
                                'it-IT',
                                options,
                              )
                            : '-'}
                        </span>
                        <br />
                        <Link to={requestUrl}>
                          <FormattedMessage
                            id="request.restore.action"
                            defaultMessage="Riprendi compilazione"
                          />
                        </Link>
                      </>
                    );
                  },
                },
                {
                  key: 'action',
                  name: (
                    <span>
                      <FormattedMessage
                        id="request.documents"
                        defaultMessage="Documenti"
                      />
                    </span>
                  ),
                  formatter: ({ data }: RequestFormatterParams) => {
                    const disabled = _.isEmpty(data.attachment);
                    return (
                      <>
                        <Button
                          disabled={disabled}
                          type="button"
                          className={`button--primary`}
                          onClick={() =>
                            AttachmentService.downloadDocument(
                              data.attachment.id || '',
                            )
                          }
                        >
                          <div>Download</div>
                        </Button>
                      </>
                    );
                  },
                },
              ]}
              totalItems={state.requestList.length}
              page={dataGridState.paginationData.page}
              onPageSelect={handleSelectPage}
              paginate
              pageSize={dataGridState.paginationData.page_size}
              pageCount={dataGridState.paginationData.page_count}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerRequest;
