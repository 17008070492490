import {
  AppointmentStatus,
  StateMachineAction,
  StateMachineActionType,
  StateMachineState,
  StateMachineStatus,
} from './types';

/**
 * The state machine initial state is basically empty, because it needs the server side
 * settings.
 */
export const initialState: StateMachineState = {
  current: '',
  parent: '',
  status: StateMachineStatus.Empty,
  history: [],
  forward: false,
  backward: false,
  calendarStatus: AppointmentStatus.Empty,
  calendarEvent: null,
  loading: false,
  paymentEntity: null,
};

/**
 * The state machine reducer to manage the internal state.
 * @param state The current state
 * @param action The action that is going to be perfomed on the state.
 * @returns The next state
 */
export const stateMachineReducer = (
  state: StateMachineState,
  action: StateMachineAction,
): StateMachineState => {
  const { type } = action;
  const { current, history } = state;

  switch (type) {
    /**
     * The action runs only when the component is inizialized for the first time.
     */
    case StateMachineActionType.Init:
      const { config } = action;

      // Get the initial states.
      const initialStates = Object.keys(config.states).filter(key => {
        return config.states[key].type === 'initial';
      });

      const [firstState] = initialStates;
      const initial = state.request?.state || firstState;
      const initialProps = config.states[initial];

      return {
        ...state,
        status: StateMachineStatus.Loading,
        current: initial,
        index: initialProps?.properties.index,
        parent: initialProps?.properties.parent,
        config,
      };

    /**
     * The action runs to change the current state into the required one.
     * If the operation is not allowed, it just returns the current state, but no error
     * is thrown.
     */
    case StateMachineActionType.To:
      const { to } = action;

      // Do not perform the operation if the state machine is not ready.
      if (state.status !== StateMachineStatus.Ready || !current) {
        return state;
      }

      // Check if there is a transition from the current state to the next state.
      const isTransition =
        state.config?.transitions[to]?.from &&
        state.config.transitions[to].from.includes(current);

      if (!isTransition) {
        return state;
      }

      // Get the state properties (mainly for the state index);
      const nextProp = state.config?.states[to];
      if (nextProp) {
        history.push(current);

        return {
          ...state,
          current: to,
          history,
          index: nextProp?.properties.index,
          parent: nextProp?.properties.parent,
        };
      }

      return state;

    /**
     * The state should never be "fail" because it means that the server didn't answer any data (or there is some
     * error in the response). The current state will be overwritten, just to be sure nothing wrong happens.
     */
    case StateMachineActionType.Fail:
      return {
        ...initialState,
        status: StateMachineStatus.Fail,
      };

    /**
     * Set the request status as ready only when all info has been retrieved from the DB.
     */
    case StateMachineActionType.Ready:
      return {
        ...state,
        status: StateMachineStatus.Ready,
      };

    /**
     * Set the request in a loading state.
     */
    case StateMachineActionType.Loading:
      return {
        ...state,
        loading: action.loading,
      };

    /**
     * Set the request payment entity.
     */
    case StateMachineActionType.SetPaymentEntity:
      return {
        ...state,
        paymentEntity: action.paymentEntity,
      };

    /**
     * The "Back" action works basically as the "To" one, but it gets the next state looking in the history stack.
     */
    case StateMachineActionType.Backward:
      // Do not perform the operation if the state machine is not ready.
      if (state.status !== StateMachineStatus.Ready || !current) {
        return state;
      }

      // Make a shallow copy of the history in case of errors.
      const safeHistory = [...history];
      const prev = history && history.pop();

      if (prev) {
        // Check if there is a transition from the current state to the previous state.
        const isTransition =
          state.config?.transitions[prev]?.from &&
          state.config.transitions[prev].from.includes(current);

        if (!isTransition) {
          return {
            ...state,
            history: safeHistory,
          };
        }

        // Get the state properties (mainly for the state index);
        const prevProp = state.config?.states[prev];

        if (prevProp) {
          return {
            ...state,
            current: prev,
            index: prevProp?.properties.index,
            parent: prevProp?.properties.parent,
            backward: action.backward,
          };
        }
      }

      return {
        ...state,
        history: safeHistory,
        backward: action.backward,
      };

    /**
     * That's a pretty ugly way to submit the form.
     * Every form MUST TAKE CARE of their stuff every time the "Submit" action is fired.
     * Unfortunately, I cannot submit by ID because semantic-ui stops the propagation of the submit even in the menu item.
     */
    case StateMachineActionType.Forward:
      const { forward: submitting } = action;
      return {
        ...state,
        forward: submitting,
      };

    case StateMachineActionType.SetRequest:
      const { request } = action;

      // Get the state properties using the request data.
      const requestProp = state.config?.states[request.state];

      return {
        ...state,
        request,
        current: request.state,
        index: requestProp?.properties.index,
        parent: requestProp?.properties.parent,
        forward: false,
      };

    case StateMachineActionType.SetCalendar:
      const { calendarStatus, calendarEvent } = action;
      return {
        ...state,
        calendarStatus,
        calendarEvent: calendarEvent || null,
      };

    default:
      break;
  }

  return state;
};
