// General application configuration
export const CONFIG = {
  OFFICE_LOGIN_ACTIVE: false,
};

// Routes of application
export const FE_ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  PASSWORD_RECOVERY: '/password-recovery',
  PASSWORD_RESET: '/password-reset',
  ACTIVATE: '/activate',
  // TODO: Wait for the real URL (it actually links to the WP section)
  TERMS: '/terms',
  // TODO: Wait for real URL after the "new request" section
  INVOICE: '/invoice',
  PROFILE: '/profile',
  REQUEST: '/request',
  REQUEST_NEW: '/request/new',
  CUSTOMER: '/customer',
  BANK: '/bank',
  WEB_USER: '/web_user',
  STRIPE_SUCCESS: '/stripe-success',
  STRIPE_CANCEL: '/stripe-cancel',
  DASHBOARD: '/dashboard',
  AGENT: '/agent',
  VOUCHER: '/voucher',
};

// Define language settings
export const DEFAULT_LOCALE = 'it';

// Width and height of topbar and sidebar
export const HEIGHT_TOPBAR = '4rem';
export const WIDTH_SIDEBAR = '5rem';

// Variable constant in local storage definition
export const LOCAL_STORAGE = {
  ACCESS_TOKEN: 'accessToken',
  EXPIRES_IN: 'expiresIn',
  PROVIDER: 'provider',
  REFRESH_TOKEN: 'refreshToken',
  SCOPE: 'scope',
  TOKEN_TYPE: 'tokenType',
};

// Custom event definition
export const CUSTOM_EVENT = {
  LOGOUT: 'custom.event.logout',
};

export enum ROLES {
  ADMIN = 'ROLE_ADMIN',
  ACCOUNTANT = 'ROLE_ACCOUNTANT',
  ACCOUNTANT_CLIENT = 'ROLE_ACCOUNTANT_CLIENT',
  BANK = 'ROLE_BANK',
  BANK_CLIENT = 'ROLE_BANK_CLIENT',
  WEB_USER = 'ROLE_WEB_USER',
}

export const CasseProfessionali = {
  Enpab: 'ENPAB - Biologi',
  Enpacl: 'ENPACL - Consulenti del lavoro',
  Epap: 'EPAP - Pluricategorie Attuari, Chimici, Fisici, Dottori Agronomi e Forestali, Geologi',
  Enpam: 'Fondazione ENPAM - Medici e odontoiatri',
  CassaDottoriCommercialisti:
    'Cassa Dottori Commercialisti - Dottori Commercialisti',
  CassaForense: 'Cassa Forense - Avvocati',
  Inarcassa: 'INARCASSA - Ingegneri e architetti',
};

export const CasseProfessionaliTooltips = {
  Enpab: `<p>Beneficiari Finali professionisti iscritti all’Ente Nazionale di Previdenza e Assistenza a favore dei Biologi (ENPAB), in forma singola o associata attraverso il ricorso a società tra professionisti (STP) o altra forma associativa, i cui soci risultino tutti iscritti all’ENPAB, che siano in regola con gli adempimenti statutari e regolamentari dell’ENPAB in materia di iscrizione e di contribuzione. L’attestazione di tale regolarità sarà rilasciata dall’ENPAB al Beneficiario Finale e dovrà essere acquisita dai Soggetti Richiedenti ai fini della presentazione della richiesta di accesso alla garanzia del Fondo PMI.</p>
  <p><b>Istruzioni operative per Soggetti Richiedenti/Beneficiari Finali</b></p>
  <p>Il professionista iscritto potrà richiedere la attestazione attraverso l’accesso al sito web dell’ENPAB al seguente link: <a href="http://www.enpab.it/component/content/article.html?id=24&Itemid=146#iscritti" target="_blank">http://www.enpab.it/component/content/article.html?id=24&Itemid=146#iscritti</a></p>`,
  Enpacl: `<p>Beneficiari Finali i professionisti iscritti all’ Ente Nazionale di Previdenza e Assistenza per i Consulenti del Lavoro (ENPACL), in forma singola ovvero associata, attraverso il ricorso a società tra professionisti (STP) o altra forma associativa, in regola con gli adempimenti statutari dell’Ente stesso in materia di iscrizione e contribuzione. Tali professionisti devono risultare tutti iscritti all’ENPACL e in regola con gli adempimenti statutari dell’Ente stesso in materia di iscrizione e contribuzione. L’attestazione di tale regolarità sarà rilasciata dall’ ENPACL ai Soggetti Richiedenti ai fini della presentazione della richiesta di accesso alla garanzia del Fondo PMI.</p>
  <p><b>Istruzioni operative per Soggetti Richiedenti/Beneficiari Finali</b></p>
  <p>Il Soggetto Richiedente dovrà acquisire la suddetta attestazione inviando richiesta all’ENPACL, ai seguenti indirizzi e-mail: <a href="mailto:upa@enpacl.it">upa@enpacl.it</a> e trasmetterla al Gestore del Fondo in fase di eventuali controlli e/o escussione.</p>`,
  Epap: `<p>Beneficiari Finali professionisti iscritti all’Ente di Previdenza ed Assistenz a Pluricategoriale (EPAP), in forma individuale, associata o societaria, contribuenti ex art. 1 comma 2bis a) del Regolamento per l'attuazione delle attività statutarie dell’EPAP.
  In caso di studi associati o Società tra professionisti, le quote - computate in termini di partecipazione agli utili - dovranno essere possedute in maggioranza da iscritti all’EPAP. I professionisti, in forma individuale e/o associata, dovranno essere in regola con gli adempimenti statutari e regolamentari dell’EPAP in materia di iscrizione e contribuzione. L’attestazione di tale regolarità sarà rilasciata dall’EPAP ai Soggetti Richiedenti ai fini della presentazione della richiesta di accesso alla garanzia del Fondo PMI.</p>
  <p><b>Istruzioni operative per Soggetti Richiedenti/Beneficiari Finali</b></p>
  <p>ll Soggetto Richiedente dovrà acquisire la suddetta attestazione attraverso il sito web di EPAP al seguente link: <a href="https://www.epaponline.it/sipa_web.dll?Modello_RichiestaCrcEntiSoggettiEsterni" target="_blank">https://www.epaponline.it/sipa_web.dll?Modello_RichiestaCrcEntiSoggettiEsterni</a> e trasmetterla al Gestore del Fondo in fase di eventuali controlli e/o escussione.</p>`,
  Enpam: `<p>Beneficiari Finali professionisti iscritti alla Fondazione ENPAM, in forma singola o associata attraverso il ricorso a società tra professionisti (STP) come regolate dall’art. 10 della legge 12 novembre 2011, n. 183, i cui soci risultino tutti iscritti alla Fondazione ENPAM e che siano in regola con gli adempimenti statutari della Fondazione ENPAM in materia di iscrizione e contribuzione. L’attestazione di tale regolarità sarà rilasciata dalla Fondazione ENPAM ai Soggetti Richiedenti ai fini della presentazione della richiesta di accesso alla garanzia del Fondo PMI.</p>
  <p><b>Istruzioni operative per Soggetti Richiedenti/Beneficiari Finali</b></p>
  <p>Il Soggetto Richiedente dovrà acquisire la suddetta attestazione inviando richiesta alla Fondazione ENPAM al seguente indirizzo e-mail: <a href="mailto:protocollo@pec.enpam.it">protocollo@pec.enpam.it</a> e trasmetterla al Gestore del Fondo in fase di eventuali controlli e/o escussione.</p>`,
  CassaDottoriCommercialisti: `<p>Beneficiari Finali professionisti iscritti alla Cassa Nazionale di Previdenza ed Assistenza a favore dei Dottori Commercialisti (CNPADC) che siano in regola con il pagamento dei contributi. Le società/associazioni tra professionisti, nelle quali tutti i professionisti siano iscritti alla CNPADC, potranno beneficiare della sotto-sezione CDP - Cassa Dottori Commercialisti, solo nella misura in cui tutti i soci/associati siano in regola con il pagamento dei contributi dovuti alla CNPADC diversamente, potranno beneficiare della sotto-sezione CDP – Cassa Dottori Commercialisti, esclusivamente a titolo individuale, solo i Professionisti Iscritti, soci/associati, che siano in regola con detto pagamento. Anche il Professionista Iscritto, che sia socio di una società/associazione tra professionisti di tipo multidisciplinare, ovvero composta anche da soci/associati non iscritti alla CNPADC, potrà beneficiare a titolo individuale della sotto-sezione CDP – Cassa Dottori Commercialisti, purché il Professionista Iscritto sia in regola con il pagamento dei contributi dovuti alla CNPADC. L’attestazione di tale regolarità sarà rilasciata da CNPADC al Beneficiario Finale e dovrà essere acquisita dai Soggetti Richiedenti ai fini della presentazione della richiesta di accesso alla garanzia del Fondo PMI.</p>
  <p><b>Istruzioni operative per Soggetti Richiedenti/Beneficiari Finali</b></p>
  <p>Il Soggetto Richiedente dovrà acquisire dal Beneficiario Finale la suddetta attestazione e trasmetterla al Gestore del Fondo in fase di eventuali controlli e/o escussione. Il professionista iscritto potrà richiedere l’attestazione attraverso l’accesso all’area riservata agli iscritti della Cassa Dottori Commercialisti del sito www.cnpadc.it, all’interno del quale è disponibile il servizio online “Certificazione Posizione Contributiva – CPC” che rilascia in tempo reale la “certificazione di iscrizione e di regolarità contributiva” al seguente link: <a href="https://servizi.cnpadc.it/" target="_blank">https://servizi.cnpadc.it/</a></p>`,
  CassaForense: `<p>Beneficiari Finali professionisti iscritti alla Cassa Nazionale di Previdenza e Assistenza Forense (Cassa Forense), in forma individuale o associata, o pensionati attivi che siano in regola con gli adempimenti statutari della Cassa Forense in materia di iscrizione e contribuzione. L’attestazione di tale regolarità sarà rilasciata dalla Cassa Forense al Beneficiario Finale e dovrà essere acquisita dai Soggetti Richiedenti ai fini della presentazione della richiesta di accesso alla garanzia del Fondo PMI. Nell’ambito della Sotto-Sezione CDP-CNPAF, non sono ammesse al beneficio delle garanzie rilasciate dal Fondo PMI le Società tra Professionisti (STP) e le Società tra Avvocati (STA) o altre forme associative i cui soci non risultino tutti iscritti alla Cassa Forense.</p>
  <p><b>Istruzioni operative per Soggetti Richiedenti/Beneficiari Finali</b></p>
  <p>Il Soggetto Richiedente dovrà acquisire dal Beneficiario Finale la suddetta attestazione e trasmetterla al Gestore del Fondo in fase di eventuali controlli e/o escussione. Il professionista iscritto potrà richiedere l’attestazione attraverso l’accesso al sito web di Cassa Forense al seguente link: <a href="https://www.cassaforense.it/contatti/richiesta-durc/" target="_blank">https://www.cassaforense.it/contatti/richiesta-durc/</a></p>`,
  Inarcassa: `<p>Beneficiari Finali i seguenti Professionisti che siano in regola con gli adempimenti dichiarativi e contributivi previsti da INARCASSA: i) persone fisiche e loro Associazioni, iscritte a fini previdenziali ad Inarcassa per l’esercizio della professione di Ingegnere o degli Architetto, Pianificatore, Paesaggista e Conservatore; ii) Società di Professionisti (SDP) costituite in via esclusiva da professionisti iscritti ad un Albo degli Ingegneri o degli Architetti, Pianificatori, Paesaggisti e Conservatori; e iii) Società Tra Professionisti (STP) iscritte e i cui soci professionisti siano iscritti in via esclusiva ad un Albo degli Ingegneri o degli Architetti, Pianificatori, Paesaggisti e Conservatori. L’attestazione di tale regolarità sarà rilasciata da Inarcassa al Beneficiario Finale e dovrà essere acquisita dai Soggetti Richiedenti ai fini della presentazione della richiesta di accesso alla garanzia del Fondo PMI.</p>
  <p><b>Istruzioni operative per Soggetti Richiedenti/Beneficiari Finali</b></p>
  <p>Il Soggetto Richiedente dovrà acquisire dal Beneficiario finale la suddetta attestazione e trasmetterla al Gestore del Fondo in fase di eventuali controlli e/o escussione. Il professionista iscritto potrà richiedere l’attestazione attraverso l’accesso all’Area Riservata Inarcassa On line al seguente link: <a href="https://www.inarcassa.it/group/iol/" target="_blank">https://www.inarcassa.it/group/iol/</a> nella sezione “Domande e Certificati” alla voce “Certificati”.</p>`,
};
