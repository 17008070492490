import React from 'react';
import { Form, SubmitButton, Field } from 'formik-semantic-ui-react';
import { Formik, FormikHelpers } from 'formik';
import { Grid, Header } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { PasswordInputFormik } from 'components/PasswordInputFormik';

import { PasswordResetRequest } from 'services/passwordRecovery/types';
import { resetPassword } from 'services/passwordRecovery';
import { PasswordResetProps } from './types';
import { useSearchParams } from 'react-router-dom';

const PasswordResetForm: React.FC<PasswordResetProps> = ({ setResponse }) => {
  const [queryParams] = useSearchParams();

  /**
   * The initial value of the token comes from the URL parameters.
   */
  const initialValues: PasswordResetRequest = {
    token: queryParams.get('token'),
    plainPassword: '',
    plainPasswordCheck: '',
  };

  const intl = useIntl();

  const onSubmit = (
    values: PasswordResetRequest,
    formikHelpers: FormikHelpers<PasswordResetRequest>,
  ) => resetPassword(values, formikHelpers, setResponse);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        token: Yup.string().required().nullable(),
        plainPassword: Yup.string()
          .required('La password è richiesta')
          .nullable()
          .min(8)
          .matches(
            /[\'^£$%&*()}{@#~?!><>,|=_+-]/,
            'La password deve contenere almeno un carattere speciale',
          )
          .matches(/\d/, 'La password deve contenere almeno un numero')
          .matches(
            /[a-z]/,
            'La password deve contenere almeno una lettera minuscola',
          )
          .matches(
            /[A-Z]/,
            'La password deve contenere almeno una lettera maiuscola',
          ),
        plainPasswordCheck: Yup.string()
          .required('La password di conferma è richiesta')
          .nullable()
          .oneOf(
            [Yup.ref('plainPassword'), null],
            intl.formatMessage({
              id: 'register.errorPasswordNoMatch',
            }),
          ),
      })}
    >
      {({ setFieldValue, handleBlur }) => (
        <Form>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3" className="password-reset__title">
                  <FormattedMessage
                    id="passwordReset.title"
                    defaultMessage="Imposta la password"
                  />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Field name="plainPassword">
                  {({ field }) => (
                    <PasswordInputFormik
                      placeholder={'Password*'}
                      label="Password*"
                      type="password"
                      setFieldValue={setFieldValue}
                      field={field}
                      onBlur={handleBlur}
                    />
                  )}
                </Field>
                {/* <Input
                  name="plainPassword"
                  placeholder={intl.formatMessage({
                    id: 'app.password',
                    defaultMessage: 'password',
                  })}
                  type="password"
                  label={
                    <FormattedMessage
                      id="app.password"
                      defaultMessage="password"
                    />
                  }
                />
                <ErrorMessage name="plainPassword" component="span" /> */}
              </Grid.Column>
              <Grid.Column>
                <Field name="plainPasswordCheck">
                  {({ field }) => (
                    <PasswordInputFormik
                      placeholder={'Conferma password*'}
                      label="Conferma password*"
                      type="password"
                      setFieldValue={setFieldValue}
                      field={field}
                      onBlur={handleBlur}
                    />
                  )}
                </Field>
                {/* <Input
                  name="plainPasswordCheck"
                  placeholder={intl.formatMessage({
                    id: 'app.confirmPassword',
                    defaultMessage: 'Conferma password',
                  })}
                  type="password"
                  label={
                    <FormattedMessage
                      id="app.confirmPassword"
                      defaultMessage="Conferma password"
                    />
                  }
                />
                <ErrorMessage name="plainPasswordCheck" component="span" /> */}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column textAlign="center">
                <SubmitButton
                  className="password-reset__button--submit"
                  content={
                    <FormattedMessage
                      id="passwordReset.submit"
                      defaultMessage="Reimposta password"
                    />
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordResetForm;
