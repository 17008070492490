import { MySelf } from 'utils/types/mySelf';

export enum appActionConstants {
  SET_SESSION = 'App/SET_SESSION',
  SET_USER_LOADING = 'App/SET_USER_LOADING',
  REVOKE_SESSION = 'App/REVOKE_SESSION',
  SET_MYSELF = 'App/SET_MYSELF',
  SET_UNDER_MAINTENANCE = 'App/SET_UNDER_MAINTENANCE',
  SET_OPEN_APPOINTMENT_MODAL = 'App/SET_OPEN_APPOINTMENT_MODAL',
}

export type AppAction =
  | { type: appActionConstants.SET_SESSION }
  | {
      type: appActionConstants.SET_MYSELF;
      payload: { mySelf: MySelf };
    }
  | {
      type: appActionConstants.SET_USER_LOADING;
      payload: { isLoading: boolean };
    }
  | { type: appActionConstants.REVOKE_SESSION }
  | {
      type: appActionConstants.SET_MYSELF;
      payload: { mySelf: MySelf };
    }
  | {
      type: appActionConstants.SET_UNDER_MAINTENANCE;
      payload: { underMaintenance: boolean };
    }
  | {
      type: appActionConstants.SET_OPEN_APPOINTMENT_MODAL;
      payload: { isOpen: boolean };
    };
