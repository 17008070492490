import React, { useContext, useReducer } from 'react';
import { Grid, Button, Icon, Modal } from 'semantic-ui-react';
import { Form, SubmitButton, Select } from 'formik-semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Formik, Field, ErrorMessage } from 'formik';
import { isActiveVoucherOptions, isUsedVoucherOptions } from './const';
import * as Yup from 'yup';
import { VoucherFilterFormProps, VoucherFilterFormValues } from './types';
import { isThatRole } from 'utils/function/acl';
import { ROLES } from 'utils/global/globalCostants';
import { AppContext } from 'pages/App';
import { toast } from 'react-toastify';
import GenerateVoucherForm from '../GenerateVoucherForm';
import {
  generateVoucherReducer,
  initialGenerateVoucherState,
} from '../GenerateVoucherForm/reducer/reducer';
import { GenerateVoucherActionConstants } from '../GenerateVoucherForm/reducer/actions';
import AsyncSelect from 'react-select/async';
import { AgentValue } from '../GenerateVoucherForm/types';
import { loadAgent } from 'services/agent';

const VoucherFilterForm: React.FC<VoucherFilterFormProps> = ({
  onSubmit,
  voucherDispatch,
}) => {
  const initialVoucherFilterFormValues: VoucherFilterFormValues = {
    isActive: 'all',
    isUsed: 'all',
    agentFilter: null,
    agentFilterId: null,
    agentId: '',
  };
  /**
   * The hook to fetch the app context.
   */
  const context = useContext(AppContext);

  /**
   * Method used to manage the new action to create a new Voucher.
   */
  const onCreateVoucher = () => {
    if (isThatRole(ROLES.ADMIN, context.state)) {
      generateVoucherDispatch({
        type: GenerateVoucherActionConstants.SET_MODAL_IS_OPEN,
        payload: {
          modalOpen: true,
        },
      });
      return;
    } else {
      toast.error('Impossibile generare voucher - Accesso negato');
      return;
    }
  };

  const [generateVoucherState, generateVoucherDispatch] = useReducer(
    generateVoucherReducer,
    initialGenerateVoucherState,
  );

  const onCloseModal = () => {
    generateVoucherDispatch({
      type: GenerateVoucherActionConstants.SET_MODAL_IS_OPEN,
      payload: {
        modalOpen: false,
      },
    });
  };

  return (
    <Formik
      initialValues={initialVoucherFilterFormValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        requestType: Yup.object().nullable(),
      })}
    >
      {({ resetForm, values }) => (
        <>
          <Modal
            closeIcon
            open={generateVoucherState.modalOpen}
            onClose={onCloseModal}
          >
            <Modal.Content>
              {generateVoucherState.modalOpen && (
                <GenerateVoucherForm
                  voucherDispatch={voucherDispatch}
                  generateVoucherDispatch={generateVoucherDispatch}
                  voucherFilterValues={values}
                />
              )}
            </Modal.Content>
          </Modal>

          <Form>
            <Grid padded>
              <Grid.Row width={12}>
                <Grid.Column>
                  <Select name="isActive" options={isActiveVoucherOptions} />
                </Grid.Column>
                <Grid.Column>
                  <Select name="isUsed" options={isUsedVoucherOptions} />
                </Grid.Column>
                <Grid.Column className="agentSearch">
                  <Field name="agentFilter">
                    {({ field, form }) => (
                      <>
                        <AsyncSelect
                          className="field agentSearch"
                          isClearable={true}
                          value={field.value}
                          loadOptions={(
                            inputValue: string,
                            callback: (options: AgentValue[]) => void,
                          ) => {
                            loadAgent(inputValue, callback);
                          }}
                          placeholder="Ricerca per agente"
                          noOptionsMessage={() => 'Nessun risultato trovato'}
                          cacheOptions
                          defaultOptions
                          onChange={value =>
                            form.setFieldValue(field.name, value)
                          }
                        />
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    className="error"
                    name="agentFilter"
                    component="span"
                  />
                </Grid.Column>
                <Grid.Column width={6} className="voucher__buttons">
                  <SubmitButton
                    className="button--primary--negative"
                    content={
                      <FormattedMessage
                        id="voucherFilter.apply"
                        defaultMessage="Applica"
                      />
                    }
                    loading={generateVoucherState.isLoading}
                  />
                  <Button
                    content="Reset"
                    type="button"
                    className="button--underline"
                    onClick={() => resetForm()}
                  />
                </Grid.Column>
                <Grid.Column className="generate-voucher">
                  <Button
                    type="button"
                    className="button--primary"
                    onClick={onCreateVoucher}
                  >
                    <div className="center">
                      <FormattedMessage
                        id="generate.new.voucher"
                        defaultMessage="Genera voucher"
                      />
                      <Icon name="plus" />
                    </div>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default VoucherFilterForm;
