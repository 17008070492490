import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Icon, Modal, Step } from 'semantic-ui-react';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { AxiosResponse } from 'axios';
import { Event } from '@microsoft/microsoft-graph-types';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Form } from 'formik-semantic-ui-react';
import { createEvent, getEvent, updateEvent } from 'services/calendar';
import {
  AppointmentFormValues,
  AppointmentStates as AppointmentCreateStates,
  AppointmentUpdateValues,
  ForwardButtonProps,
} from './types';
import { useStateMachine } from 'services/stateMachine/useStateMachine';
import { StateMachineActionType } from 'services/stateMachine/types';
import { StandardResponseError } from 'services/client/types';
import { forOwn, get } from 'lodash';
import { toast } from 'react-toastify';
import AppointmentCalendar from './AppointmentCalendar';
import { AppointmentInfo } from './AppointmentInfo';
import { useAppointment } from 'pages/Appointment/context';
import { RequestEvent } from 'services/request/types';

export const AppointmentCreateForm: React.FC = () => {
  /**
   * The state machine object.
   */
  const { state, dispatch } = useStateMachine();
  /**
   * The react-intl object.
   */
  const intl = useIntl();

  /**
   * The appointment context utility.
   */
  const { event, onClose } = useAppointment();

  /**
   * The step group index.
   */
  const [index, setIndex] = useState<AppointmentCreateStates>(
    AppointmentCreateStates.Calendar,
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [submittingForm, setSubmittingForm] = useState<boolean>(false);

  /**
   * The initial values.
   */
  const [initialValues, setInitialValues] = useState<AppointmentFormValues>({
    type: null,
    note: null,
    attachments: [],
    email: state.request?.customer.email || '',
    start: null,
    end: null,
  });

  /**
   * Load event data from the M365 API.
   */
  useEffect(() => {
    if (event) {
      setLoading(true);
      getEvent(event.eventId)
        .then((response: AxiosResponse<Event>) => {
          const [attendee] = response.data.attendees || [];

          setInitialValues({
            type: event.type || null,
            note: event.text || null,
            attachments: [],
            email: attendee.emailAddress?.address || null,
            start: response.data.start?.dateTime
              ? moment(response.data.start?.dateTime).format(
                  'YYYY-MM-DD HH:mm:ss',
                )
              : null,
            end: response.data.end?.dateTime
              ? moment(response.data.end?.dateTime).format(
                  'YYYY-MM-DD HH:mm:ss',
                )
              : null,
          });
        })
        .finally(() => setLoading(false));
    }
  }, [event]);

  /**
   * The form validation schema.
   */
  const validationSchema = Yup.object().shape({
    type: Yup.string()
      .label(
        intl.formatMessage({
          id: 'event.type',
          defaultMessage: 'Motivazione',
        }),
      )
      .required()
      .nullable(),
    note: Yup.string()
      .label(
        intl.formatMessage({
          id: 'event.note',
          defaultMessage: 'Note',
        }),
      )
      .required()
      .nullable(),
    attachments: Yup.array()
      .of(
        Yup.mixed()
          .test(
            'size',
            intl.formatMessage({
              id: 'event.attachmentSizeError',
              defaultMessage:
                'La dimensione di un allegato non può superare i 3MB',
            }),
            file => file.size <= 3000000,
          )
          .test(
            'type',
            intl.formatMessage({
              id: 'event.attachmentTypeError',
              defaultMessage: 'I formati accettati sono: PDF',
            }),
            file => file && ['application/pdf'].includes(file.type),
          ),
      )
      .nullable(),
    email: Yup.string()
      .label(
        intl.formatMessage({
          id: 'event.email',
          defaultMessage: 'E-mail',
        }),
      )
      .email()
      .required()
      .nullable(),
    start: Yup.date()
      .label(
        intl.formatMessage({
          id: 'event.start',
          defaultMessage: 'Data',
        }),
      )
      .required()
      .nullable()
      .min(moment().add(1, 'days').set({ hours: 9, minute: 0, second: 0 })),
  });

  /**
   * Go back to the previous step.
   * @returns
   */
  const goBackward = () => {
    switch (index) {
      case AppointmentCreateStates.Calendar:
      case AppointmentCreateStates.End:
        onClose();
        break;

      case AppointmentCreateStates.Info:
        setIndex(AppointmentCreateStates.Calendar);
        break;

      default:
        break;
    }
  };

  const onSubmit = async (values, helpers) => {
    if (event) {
      onUpdate(values, helpers);
    } else {
      onCreate(values, helpers);
    }
  };

  /**
   * Create or update the event.
   * @param values the form values
   * @returns the result of the operation
   */
  const onCreate = async (
    values: AppointmentFormValues,
    helpers: FormikHelpers<AppointmentFormValues>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { attachments, ...cleanData } = values;
    const { request } = state;

    cleanData.request = request?.id;

    if (request) {
      createEvent(cleanData)
        .then((response: AxiosResponse<Event>) => {
          const newEvent: RequestEvent = {
            eventId: response.data.id || '',
            type: cleanData.type,
            text: cleanData.note,
            duration: moment(values.end).diff(values.start, 'minutes'),
          };

          dispatch({
            type: StateMachineActionType.SetRequest,
            request: {
              ...request,
              events: [...request.events, newEvent],
            },
          });

          toast.success('Appuntamento salvato correttamente');

          // Go to the final state.
          setIndex(AppointmentCreateStates.End);
        })
        .catch((error: AxiosResponse<StandardResponseError>) => {
          const errors = get(error, 'data.validation_messages', null);

          if (errors) {
            // Set the errors in the Formik bag.
            forOwn(errors, (value, key) => {
              for (const i in value) {
                helpers.setFieldError(key, value[i]);
                break;
              }
            });
          } else {
            toast.error("Errore durante la creazione dell'appuntamento");
          }
        })
        .finally(() => setSubmittingForm(false));
    }
  };

  const onUpdate = async (
    values: AppointmentUpdateValues,
    helpers: FormikHelpers<AppointmentUpdateValues>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { attachments, email, ...cleanData } = values;
    const { request } = state;

    cleanData.id = event?.eventId;
    cleanData.request = request?.id;

    if (request) {
      updateEvent(cleanData)
        .then((response: AxiosResponse<Event>) => {
          // Update the event lists.
          const updatedEvents = request.events.map<RequestEvent>(e => {
            if (e.eventId === response.data.id) {
              return {
                ...e,
                type: cleanData.type || '',
                text: cleanData.note || '',
                duration: moment(values.end).diff(values.start, 'minutes'),
              };
            }

            return e;
          });

          dispatch({
            type: StateMachineActionType.SetRequest,
            request: { ...request, events: updatedEvents },
          });

          toast.success('Appuntamento aggiornato correttamente');

          // Go to the final state.
          setIndex(AppointmentCreateStates.End);
        })
        .catch((error: AxiosResponse<StandardResponseError>) => {
          const errors = get(error, 'data.validation_messages', null);

          if (errors) {
            // Set the errors in the Formik bag.
            forOwn(errors, (value, key) => {
              for (const i in value) {
                helpers.setFieldError(key, value[i]);
                break;
              }
            });
          } else {
            toast.error("Errore durante l'aggiornamento dell'appuntamento");
          }
        })
        .finally(() => {
          helpers.setSubmitting(false);
          setSubmittingForm(false);
        });
    }
  };

  return (
    <Modal open closeOnEscape onClose={onClose}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ isValidating, isSubmitting }) => (
          <>
            <Modal.Header className="modal__requestHeader">
              <Step.Group fluid widths={3}>
                <Step
                  active={index === AppointmentCreateStates.Calendar}
                  completed={
                    index === AppointmentCreateStates.Info ||
                    index === AppointmentCreateStates.End
                  }
                >
                  <Icon name="clock outline" size="small" />
                  <span> Scegli un orario</span>
                </Step>
                <Step
                  active={index === AppointmentCreateStates.Info}
                  completed={index === AppointmentCreateStates.End}
                >
                  <Icon name="edit" size="mini" />
                  <span> Inserisci le informazioni</span>
                </Step>
                <Step
                  active={index === AppointmentCreateStates.End}
                  completed={index === AppointmentCreateStates.End}
                >
                  <Icon name="check circle outline" size="mini" />
                  <span> Fine</span>
                </Step>
              </Step.Group>
            </Modal.Header>
            <Modal.Content className="modal__requestContent">
              <Form loading={isValidating || isSubmitting || loading}>
                {index === AppointmentCreateStates.Calendar && (
                  <AppointmentCalendar />
                )}
                {index === AppointmentCreateStates.Info && <AppointmentInfo />}
                {index === AppointmentCreateStates.End && <AppointmentEnd />}
              </Form>
            </Modal.Content>
            <Modal.Actions
              className={`appointment_modal_actions${
                index === AppointmentCreateStates.End && '--end'
              }`}
            >
              {index !== AppointmentCreateStates.End && (
                <Button
                  onClick={goBackward}
                  disabled={isValidating || isSubmitting}
                  className="button--dark"
                >
                  Indietro
                </Button>
              )}
              <ForwardButton
                index={index}
                setIndex={setIndex}
                setSubmittingForm={setSubmittingForm}
                submittingForm={submittingForm}
              />
            </Modal.Actions>
          </>
        )}
      </Formik>
    </Modal>
  );
};

const ForwardButton: React.FC<ForwardButtonProps> = ({
  index,
  setIndex,
  setSubmittingForm,
  submittingForm,
}) => {
  const {
    isValidating,
    isSubmitting,
    submitForm,
    values: { start },
  } = useFormikContext<AppointmentFormValues>();

  /**
   * The appointment utility.
   */
  const { onClose } = useAppointment();

  /**
   * Go forward (or submit the form if everything is fine).
   * @returns
   */
  const goForward = () => {
    setSubmittingForm(true);
    switch (index) {
      case AppointmentCreateStates.Calendar:
        if (start) setIndex(AppointmentCreateStates.Info);
        setSubmittingForm(false);
        break;

      case AppointmentCreateStates.Info:
        submitForm();
        break;

      case AppointmentCreateStates.End:
        onClose();
        break;

      default:
        setSubmittingForm(false);
        break;
    }
  };

  return (
    <Button
      onClick={goForward}
      disabled={index !== AppointmentCreateStates.End && submittingForm}
      loading={submittingForm}
    >
      {index !== AppointmentCreateStates.End ? 'Avanti' : 'Chiudi'}
    </Button>
  );
};

const AppointmentEnd = () => {
  return <div>Appuntamento confermato</div>;
};
